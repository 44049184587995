import { Injectable } from '@angular/core';
import { esriRequest } from 'src/esri/request';

import { DialogNoticeService } from '../dialog-notice.service';

// When querying to get the metadata for a service, these are
// the properties of the response that could contain layers.
const LAYER_TYPE_KEYS = [
  'layers',
  'tables',
  'networkLayers',
  'eventLayers'
];

// This service gets the urls for layers on a given feature service.
// The url for a layer usually includes the layer's number
// e.g. https...Project_Priority_Data/FeatureServer/5
// This service is necessary because often, when new layers are added
// to a service, the numbers for existing layers change. Configuring
// this app to get layers by name, rather than by hardcoded number,
// allows this app to adapt when layers are reordered.
@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  constructor(
    private dialogNoticeService: DialogNoticeService
  ) {
  }

  // use common request lookup to prevent multiple queries for the same service
  private promises: { [url: string]: Promise<any> } = {};

  // Using <T> allows the object defining layer names (layers: T)
  // to have the same properties as the object returning urls
  async getLayerUrls<T>(serverConfig: { url: string, layers: T }): Promise<T> {
    const layerIds = await this.getLayerIds(serverConfig);
    const res = {};
    Object.keys(serverConfig.layers).forEach(key => {
      res[key] = serverConfig.url + '/' + layerIds[serverConfig.layers[key]];
    });
    return <T>res;
  }

  async getLayerIds<T>(serverConfig: { url: string, layers: T }): Promise<T> {
    const data = await this.getServerMetadata(serverConfig.url);
    // create lookup object where key is layer-name and value is layer-id
    const layerIds: any = {};
    LAYER_TYPE_KEYS.forEach(typeKey => {
      if (data[typeKey]) {
        data[typeKey].forEach(x => {
          layerIds[x.name] = x.id;
        });
      }
    });
    return <T>layerIds;
  }

  async getMaxRecordCount<T>(serverConfig: { url: string, layers: T }) {
    const res = await this.getServerMetadata(serverConfig.url);
    return res.maxRecordCount;
  }

  private getServerMetadata(url: string) {
    if (!this.promises[url]) {
      this.promises[url] = (async () => {
        try {
          const res = await esriRequest(url, {
            query: {
              f: 'json'
            }
          });
          return res.data;
        } catch (err) {
          const msg = `Could not reach endpoint: ${url}`;
          const htmlMsg = `
          <p><b>Could not reach endpoint, app may not function properly.</b></p>
          <p>${url}</p>
          <p><b>The service could be down or has moved...</b></p>
          `;
          await this.dialogNoticeService.error({
            title: 'SERVICE ERROR',
            hasOk: true,
            message: '',
            htmlContent: htmlMsg
          });
          //alert('Could not reach endpoint: ' + url);
          throw new Error(msg);
        }
      })();
    }
    return this.promises[url];
  }
}

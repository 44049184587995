import { Injectable } from '@angular/core';
import { ApprovalPolicy } from '../scope-approval/approval-workflow-policy';
import { PinAttributes } from '../services/amalgamator/pin-attributes';
import { PriorityImpactReport } from '../services/priority-impact-service';
import { EMAIL_FOOTER } from './common/footer';
import { getApprovalLink } from './common/get-approval-link';
import { getApprovalStagesHtml } from './common/get-approval-stages-html';
import { EMAIL_HEADER } from './common/header';
import { EMAIL_COMMON_STYLES } from './common/styles';

@Injectable({
  providedIn: 'root'
})
export class EmailContentLrtpService {

  constructor() {
    return
  }

  createInitialEmailHtml(policies: ApprovalPolicy[], attributesForPins: PinAttributes[], arrayOfProjTypes: any[][], approvalInfo: any, reports?: PriorityImpactReport[]): string {
    return `
    <html>
      <head></head>
      <body>
        <table ${EMAIL_COMMON_STYLES.TABLE_ATTRS}>
          <tbody>
            ${EMAIL_HEADER}
            ${this.getIntroTr({ multi: policies.length > 1, attributesForPins, arrayOfProjTypes })}
            ${policies.map((policy, i) => this.getInitScopeSummaryTr(policy, arrayOfProjTypes, attributesForPins, i, approvalInfo, reports || [])).join('')}
            ${EMAIL_FOOTER}
          </tbody>
        </table>
      </body>
    </html>`;
  }

  createFinalEmailHtml(policies: ApprovalPolicy[], attributesForPins: PinAttributes[], arrayOfProjTypes: any[][], approvalInfo: any, reports?: PriorityImpactReport[]): string {
    return `
    <html>
      <head></head>
      <body>
        <table ${EMAIL_COMMON_STYLES.TABLE_ATTRS}>
          <tbody>
            ${EMAIL_HEADER}
            ${this.getIntroTrFinal({ multi: policies.length > 1, attributesForPins, arrayOfProjTypes })}
            ${policies.map((policy, i) => this.getInitScopeSummaryTr(policy, arrayOfProjTypes, attributesForPins, i, approvalInfo, reports || [])).join('')}
            ${EMAIL_FOOTER}
          </tbody>
        </table>
      </body>
    </html>`;
  }

  getImpacts(reports: PriorityImpactReport[]) {
    const LRTPCounts = reports.filter(item => item.layerTitle.includes('LRTP'));
    return `
    <tr>
      <td>
        <br>Consistent with Long Range Plan Categories:<br>
        ${LRTPCounts.map(item => `<strong>${item.layerTitle}</strong>: Impact Count: ${Math.floor(item.PINCOUNT)}<br>`).join('')}
      </td>
    </tr>`;
  }

  getIntroTr(params: { multi: boolean, attributesForPins: PinAttributes[], arrayOfProjTypes: any[][] }) {
    return `
      <tr>
        <td>
          You are receiving this email because you have elected to be notified when a
          project scope is developed and submitted to PSS that has <b>LRTP impact implications</b>. ${params.multi ? 'The projects identified below have been entered and are' : 'The project identified below has been entered and is'}
          being vetted in the project scoping and prioritization system. Should you
          have questions about this project, feel free to contact those championing and
          vetting the project listed below:
        </td>
      </tr>`;
  }

  getIntroTrFinal(params: { multi: boolean, attributesForPins: PinAttributes[], arrayOfProjTypes: any[][] }) {
    return `
      <tr>
        <td>
          You are receiving this email because you have elected to be notified when a
          project scope is developed and submitted to PSS that has <b>LRTP impact implications</b>.
          <br><br>${params.multi ?
    'The scopes ' :
    'The scope '
}
          listed below have been properly entered and vetted in the project scoping and prioritization
          system.  A Project Identification Number (PIN) will be created within the Project Scheduling System (PSS).
          If you have additional questions or concerns, please don't hesitate to contact the individuals listed below who entered and
          approved the project.
        </td>
      </tr>`;
  }

  getProjectDetails(projTypes, pinInfo, reports: PriorityImpactReport[]) {
    const { PIN } = pinInfo;
    return `
      <tr>
        <td>
          <br>Project Link: https://iowadotprojectpriorities.com/#pin=${PIN}
          <br><br>Project Purpose – ${projTypes.map(type => `${type.PROJECTTYPE.alias}: ${type.PURPOSEDESCRIPTION}`).join(', ')}
          <br>${reports.length ? this.getImpacts(reports) : ''}
        </td>
      </tr>`;
  }

  getInitScopeSummaryTr(policy: ApprovalPolicy, arrayOfProjTypes, attributesForPins, i, approvalInfo, reports: PriorityImpactReport[]): string {
    const pin = attributesForPins[i];
    const projTypes = arrayOfProjTypes[i];
    const { USERNAME } = pin;
    const bridgeFhwaNumber = `Bridge FHWA Number:  <strong>${approvalInfo[0].misc.bridgeFhwaNumber}</strong>`;
    const bridgeNumber = `Bridge Maintenance Number:  <strong>${approvalInfo[0].misc.bridgeMaintenanceNumber}</strong>`;

    return `
      <tr>
        <td>
          <br>
          <br>Scope Id: <strong>${policy.scope_id}</strong>
          <br>Funding Sources: <strong>${policy.fundingType}</strong>
          <br>
          <br>
          ${bridgeFhwaNumber}<br>
          ${bridgeNumber}<br>
          <br>Personnel who Entered the project: ${USERNAME}<br>
          <br><strong>Approval Stages</strong>
          <br>${getApprovalStagesHtml(policy)}
          <br><strong><a href="${getApprovalLink(policy.scope_id)}">View Approval Process</a></strong>
          <br>${this.getProjectDetails(projTypes, pin, reports)}
        </td>
      </tr>`;
  }
}

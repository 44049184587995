import { Injectable } from '@angular/core';
import { EndpointService } from './dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';
import { RAMS_EFFECTIVE_DATE_CLAUSE } from 'src/app/services/rams-effective-date-clause';
import { esriRequest } from 'src/esri/request';
import { BehaviorSubject } from 'rxjs';
import { shortenWinterRouteName } from './route-name.winter-route-shortening';

interface RamsEntry {
  FROM_MEASURE: number;
  TO_MEASURE: number;
  OFFICIAL_COMMON_NAME: string;
  WINTER_ROUTE_NAME: string;
}

class Cache {
  caches = {};

  use(cacheParameters: { group: string, key: string, fn: () => any }) {
    const { group, key, fn } = cacheParameters;
    if (!this.caches[group]) {
      this.caches[group] = {};
    }
    if (!(Object.prototype.hasOwnProperty.call(this.caches[group], key))) {
      this.caches[group][key] = fn();
    }
    return this.caches[group][key];
  }
}

@Injectable({
  providedIn: 'root'
})
export class RouteNameService {

  constructor(private endpointService: EndpointService) {
  }

  private cache = new Cache();
  private urlsPromise = this.endpointService.getLayerUrls(ENDPOINT_CONFIG.RAMS_ALL_ROUTES);

  getWinterRouteObservable(route: string, from?: number, to?: number) {
    return this.cache.use({
      group: 'winter-route-observable',
      key: `${route}_${from}_${to}`,
      fn: () => {
        const promise = this.getWinterRoutePromise(route, from, to);
        return this.asBehaviorSubject(promise);
      }
    });
  }

  getCommonNameObservable(route: string, from?: number, to?: number) {
    return this.cache.use({
      group: 'common-name-observable',
      key: `${route}_${from}_${to}`,
      fn: () => {
        const promise = this.getCommonNamePromise(route, from, to);
        return this.asBehaviorSubject(promise);
      }
    });
  }

  getWinterRoutePromise(route: string, from?: number, to?: number) {
    return this.cache.use({
      group: 'winter-route-promise',
      key: `${route}_${from}_${to}`,
      fn: async () => {
        const entry = await this.getNearestEntry(route, from, to);
        return entry ? shortenWinterRouteName(entry.WINTER_ROUTE_NAME) : '[no alias found]';
      }
    });
  }

  getCommonNamePromise(route: string, from?: number, to?: number) {
    return this.cache.use({
      group: 'common-name-promise',
      key: `${route}_${from}_${to}`,
      fn: async () => {
        const entry = await this.getNearestEntry(route, from, to);
        return entry && entry.OFFICIAL_COMMON_NAME || '[no alias found]';
      }
    });
  }

  private asBehaviorSubject(promise: Promise<string>): BehaviorSubject<string> {
    const subj = new BehaviorSubject<string>('[loading alias...]');
    promise.then(val => {
      subj.next(val);
    });
    return subj;
  }

  private getNearestEntry(route: string, from?: number, to?: number): Promise<RamsEntry> {
    return this.cache.use({
      group: 'nearest-entry',
      key: `${route}_${from}_${to}`,
      fn: async () => {
        const entries = await this.getRamsEntries(route);
        let smallestDiff = null;
        let nearestEntry: RamsEntry;
        const noFrom = from === null || from === undefined;
        const noTo = to === null || to === undefined;
        if (noFrom && noTo) {
          return entries[0];
        }
        entries.forEach(entry => {
          const fromDiff = noFrom ? 0 : Math.abs(from - entry.FROM_MEASURE);
          const toDiff = noTo ? 0 : Math.abs(to - entry.TO_MEASURE);
          const diff = fromDiff + toDiff;
          if (smallestDiff === null || diff < smallestDiff) {
            smallestDiff = diff;
            nearestEntry = entry;
          }
        });
        return nearestEntry;
      }
    });
  }

  private getRamsEntries(route: string): Promise<RamsEntry[]> {
    return this.cache.use({
      group: 'rams-entries',
      key: route,
      fn: async () => {
        const urls = await this.urlsPromise;
        const query: any = {
          where: `ROUTE_ID = '${route}' and ${RAMS_EFFECTIVE_DATE_CLAUSE}`,
          returnGeometry: false,
          outFields: 'FROM_MEASURE,TO_MEASURE,OFFICIAL_COMMON_NAME,WINTER_ROUTE_NAME,TABLE_PRIORITY',
          orderByFields: 'TABLE_PRIORITY',
          f: 'json'
        };
        const res = await esriRequest(urls.ALL_ROUTES + '/query', { query });
        return <RamsEntry[]>res.data.features.map(f => f.attributes);
      }
    });
  }
}

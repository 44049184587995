<div class="basic-dialog-titlebar mat-elevation-hairline">

  <div class="basic-dialog-title">{{data.title}}</div>
  <div style="flex-grow:1"></div>
  <!--<button mat-icon-button (click)="dialogRef.close()" tabindex="-1"><mat-icon>close</mat-icon></button>-->
</div>

<div class="basic-dialog-body-container">
  <div [innerHTML]="htmlContent"></div>
  <div>{{ data.message }}</div>
</div>

<div class="basic-dialog-footer">
  <button *ngIf="hasOk" mat-raised-button color="primary" (click)="closeDialog(true)">{{ okBtnText }}</button>
  <button *ngIf="hasCancel" mat-raised-button style="margin-left: 8px;"
          (click)="closeDialog(false)">{{ cancelBtnText }}</button>
</div>

import { environment } from 'src/environments/environment';

export const PINS_RX_LAYER_HIDE_FIELDS = [
  'STAGE',
  'ROUTES_AND_MEASURES_JSON',
  'PROJECT_TYPES_JSON',
  'PROGRAM_TYPES_JSON',
  'OBJECTID',
  'CREATED_USER',
  'CREATED_USER',
  'CREATED_DATE',
  'LAST_EDITED_USER',
  'LAST_EDITED_DATE',
  'PIN_ID',
  'ROUTE_1',
  'ROUTE_2',
  'ROUTE_3',
  'ROUTE_4',
  'COUNTY_1',
  'COUNTY_2',
  'COUNTY_3',
  'COUNTY_4',
  'CREATION_DATE',
  'UTC_OFFSET',
  'COUNTY_NAME',
  'ALL_ROUTES',
  'CONTEXT_FLAG',
  'CRASH_YEARS',
  'DESIRED_FISCAL_MONTH',
  'EVIDENT_INJURY_COST_VALUE',
  'TOTAL_PROG_EST_SUM',
  'TOTAL_DEV_EST_SUM',
  'FINANCING_FLAG',
  'FLOODPLAIN',
  'LIGHTING',
  'PP_MODEL_RUN_DATE',
  'NOT_PROGRAMMED_RANK',
  'OBJECTIVES',
  'PARENT_PIN',
  'PRIMARY_ROUTE',
  'SAFETY_FLAG',
  'SCHEDULE_FLAG',
  'TECHNICAL_FLAG',
  'ROUTES'
].concat(environment.isCommission ? [
  'ROWNEED',
  'ROWRELOCATION',
  'TANDE',
  'CULTURALSITE',
  'HISTORICALSITE',
  'LEVIESAFFECTED',
  'CONCURRENCEPOINT',
  'LEVELOFDOCUMENTATION',
  'ROWDISPOSAL',
  'CONTEXT_SCORE',
  'CONTEXT_FLAG',
  'COST_SCORE',
  'CRASH_YEARS',
  'DESIRED_FISCAL_MONTH',
  'EVIDENT_INJURY_COST_VALUE',
  'TOTAL_DEV_EST_SUM',
  'TOTAL_PROG_EST_SUM',
  'FINANCING_SCORE',
  'FINANCING_FLAG',
  'FLOODPLAIN',
  'LIGHTING',
  'PP_MODEL_RUN_DATE',
  'NOT_PROGRAMMED_RANK',
  'OBJECTIVES',
  'PARENT_PIN',
  'READABLE_PRIMARY_ROUTE',
  'PD_COMPLETED_BY_OTHER',
  'SAFETY_FLAG',
  'SCHEDULE_SCORE',
  'SCHEDULE_FLAG',
  'TECHNICAL_SCORE',
  'TECHNICAL_FLAG',
  'HAS_GEOMETRY'
] : []);

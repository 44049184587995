import { formatSqlDate } from './format-sql-date';
import { Filter } from '../filter';

export const FILTER_TRANSLATORS = {
  MinMax: (filter: Filter) => {
    const v1 = +filter.value[0];
    const v2 = +filter.value[1];
    return `${filter.field.name} >= ${v1} and ${filter.field.name} <= ${v2}`;
  },
  DateMinMax: (filter: Filter) => {
    const d1 = formatSqlDate(+filter.value[0]);
    const d2 = formatSqlDate(+filter.value[1]);
    return `${filter.field.name} between date '${d1}' and date '${d2}'`;
  },
  ContainsString: (filter: Filter) => {
    const noQuotes = filter.value.replace(/'/g, '').replace(/"/g, '');
    return `upper(${filter.field.name}) like '%${noQuotes.toUpperCase()}%'`;
  },
  MultiSelect: (filter: Filter) => {
    if (!filter.value.length) {
      return `1=2`;
    } else if (filter.field['isCommaDelimited']) {
      // comma-delimited is not a real esri type
      // It is a type created by the amalgamator to handle one-to-many
      // joins by fitting multiple values into one field
      const clauses = [];
      filter.value.forEach(x => {
        // is in the middle of a comma delimited list
        clauses.push(`(${filter.field.name} like '%, ${x},%')`);
        // is first in a comma delimited list
        clauses.push(`(${filter.field.name} like '${x},%')`);
        // is last in a comma delimited list
        clauses.push(`(${filter.field.name} like '%, ${x}')`);
        // is the single item in a comma delimited list of one item
        clauses.push(`(${filter.field.name} = '${x}')`);
      });
      return `(${clauses.join(' or ')})`;
    } else {
      const inVals = filter.value.map(x => (typeof x === 'number') ? x : `'${x}'`); // wrap each in single quotes
      return `${filter.field.name} in (${inVals.join(',')})`;
    }
  },
  StageSelect: (filter: Filter) => {
    const inVals = filter.value.map(x => `'${x}'`); // wrap each in single quotes
    return `STAGE in (${inVals.join(',')})`;
  },
  MultiFieldSearch: (filter: Filter) => {
    if (filter.value === null || filter.value === '' || filter.searchFieldNames.length === 0) {
      return null;
    } else {
      const noQuotes = filter.value.replace(/'/g, '').replace(/"/g, '');
      return filter.searchFieldNames.map(fieldName => {
        return `upper(${fieldName}) like '%${noQuotes.toUpperCase()}%'`;
      }).join(' or ');
    }
  },
  QuickFilter: (filter: Filter) => {
    if (!filter.filterExpression || filter.filterExpression === '' || filter.filterExpression === null) {
      return null;
    } else {
      return filter.filterExpression;
    }
  },
  // special filter that has no UI component
  // typically generated by a spatial query result, still uses the filterExpression to filter results
  Spatial: (filter: Filter) => {
    if (!filter.filterExpression || filter.filterExpression === '' || filter.filterExpression === null) {
      return null;
    } else {
      return filter.filterExpression;
    }
  },
  // special kind of MinMax with specific UI component
  Slider: (filter: Filter) => {
    const v1 = +filter.value[0];
    const v2 = +filter.value[1];
    return `${filter.field.name} >= ${v1} and ${filter.field.name} <= ${v2}`;
  },
};

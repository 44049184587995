import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { ParallelScanner } from './parallel-scanner';
import { EndpointService } from '../dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from '../dynamic-endpoint/endpoint.service.config';
import { ScanResults } from './scan';
import { RAMS_EFFECTIVE_DATE_CLAUSE } from 'src/app/services/rams-effective-date-clause';
import { environment } from 'src/environments/environment';
import { RAMS_VERSIONS } from 'src/app/services/rams-versions';
import { RamsRequestsService } from 'src/app/services/rams-requests.service';
import { UserInfoService } from '../user-info.service';
import { LoggingService } from '../logging.service';

// The MultiScanner is used to query all the endpoints for
// the feature collections used by the amalgamator.

const UNRESPONSIVE_TIMEOUT = 1000; // milliseconds

export interface MultiScannerResults {
  projectScoping: ScanResults;
  scopeGeometry: ScanResults;
  pinGeometry: ScanResults;
  priorityResults: ScanResults;
  projectTypes: ScanResults;
  programTypes: ScanResults;
  workTypes: ScanResults;
  workGroups: ScanResults;
  counties: ScanResults;
  iowaStateSenateDistricts: ScanResults;
  iowaStateHouseDistricts: ScanResults;
  iowaCongressionalDistricts: ScanResults;
  valuation: ScanResults;
  projectNumber: ScanResults;
  commissionConfig?: ScanResults;
}

export const PROJ_SCOPE_WHERE = `STATUS in ('New','Active','Complete','Scoping','InApproval','Approved','Red File')`;

@Injectable({
  providedIn: 'root'
})
export class MultiScanner {

  loadingProgress = (() => {
    const initValue: any = {
      projectScoping: 0,
      scopeGeometry: 0,
      pinGeometry: 0,
      combinedResults: 0,
      projectTypes: 0,
      programTypes: 0,
      workTypes: 0,
      workGroups: 0,
      counties: 0,
      iowaStateSenateDistricts: 0,
      iowaStateHouseDistricts: 0,
      iowaCongressionalDistricts: 0,
      valuation: 0,
      projectNumber: 0,
      total: 0
    };
    if (environment.isCommission) {
      initValue.commissionConfig = 0;
    }
    return new BehaviorSubject(initValue);
  })();

  loading = new BehaviorSubject<boolean>(null);
  unresponsive = new BehaviorSubject<boolean>(false);
  isUserPlanScenario = false;

  constructor(
    private endpointService: EndpointService,
    private scanner: ParallelScanner,
    private ramsRequestsService: RamsRequestsService,
    private userInfoService: UserInfoService,
    private logging: LoggingService
  ) {
    this.isUserPlanScenario = this.userInfoService.isPlanningScenario;
  }

  // called once by amalgamator
  async getScans(): Promise<MultiScannerResults> {
    this.loading.next(true);
    // this.logging.log('🚀 ~ file: multi-scanner.ts ~ line 82 ~ MultiScanner ~ getScans ~ getScans()');
    // reconcile (no post) RAMS to make sure we have latest changes
    await this.ramsRequestsService.reconcileVersion().catch((err) => {
      console.error(`Get Scans: Reconcile Error: ${err}`);
    });
    const start = +new Date();
    let timeout = setTimeout(() => {
      this.unresponsive.next(true);
    }, UNRESPONSIVE_TIMEOUT);

    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
    const ramsUrls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.RAMS_LRS_MAPSERVER);
    const maxRecordCount = await this.endpointService.getMaxRecordCount(ENDPOINT_CONFIG.PPRI_DATA);
    const take = Math.min(maxRecordCount, 1000);

    // used to filter out planning scenarios
    const projectScopingQuery = this.isUserPlanScenario ?
      PROJ_SCOPE_WHERE : `${PROJ_SCOPE_WHERE} AND PIN NOT LIKE 'X%'`;

    // used to filter out planning scenarios
    const scopeGeometryQuery = this.isUserPlanScenario ?
      RAMS_EFFECTIVE_DATE_CLAUSE + ' AND (PROJECT_SCOPE_ID IS NOT NULL AND PSS_PIN_ID IS NULL)' :
      RAMS_EFFECTIVE_DATE_CLAUSE + ' AND (PROJECT_SCOPE_ID IS NOT NULL AND PSS_PIN_ID IS NULL AND PROJECT_SCOPE_ID NOT LIKE \'X%\')';

    // used to filter out planning scenarios
    const pinGeometryQuery = this.isUserPlanScenario ?
      RAMS_EFFECTIVE_DATE_CLAUSE + ' AND (PSS_PIN_ID IS NOT NULL)' :
      RAMS_EFFECTIVE_DATE_CLAUSE + ' AND (PSS_PIN_ID IS NOT NULL AND PSS_PIN_ID NOT LIKE \'X%\')';

    const scans: any = {
      projectScoping: this.scanner.scan({
        url: urls.PROJECT_SCOPING,
        where: projectScopingQuery,
        take
      }),
      scopeGeometry: this.scanner.scan({
        url: ramsUrls.PROJECT_SCOPING,
        where: scopeGeometryQuery,
        outFields: 'ROUTE_ID,FROM_MEASURE,TO_MEASURE,PROJECT_SCOPE_ID',
        gdbVersion: RAMS_VERSIONS.VIEW, // version for testing, version is optional
        take
      }),
      pinGeometry: this.scanner.scan({
        url: ramsUrls.PROJECT_SCOPING,
        where: pinGeometryQuery,
        outFields: 'ROUTE_ID,FROM_MEASURE,TO_MEASURE,PSS_PIN_ID',
        gdbVersion: RAMS_VERSIONS.VIEW, // version for testing, version is optional
        take
      }),
      priorityResults: this.scanner.scan({
        url: environment.isDev || environment.isLocalHost
          ? urls.PROJECT_PRIORITY_RESULTS_DEVELOPMENT
          : urls.PROJECT_PRIORITY_RESULTS,
        take
      }),
      projectTypes: this.scanner.scan({
        url: urls.PROJECT_TYPES,
        where: 'PROJECTTYPE is not null',
        outFields: 'PROJECTTYPE,PURPOSEDESCRIPTION,PIN',
        take
      }),
      programTypes: this.scanner.scan({
        url: urls.PROGRAM_TYPES,
        where: 'PROGRAMTYPE is not null',
        outFields: 'PROGRAMTYPE,PIN,FUNDING_DESCRIPTION',
        take
      }),
      workTypes: this.scanner.scan({
        url: urls.PROJECT_WORK_TYPES,
        where: 'PIN is not null',
        outFields: 'WORKCODE,PIN',
        take
      }),
      workGroups: this.scanner.scan({
        url: urls.WORK_GROUP,
        where: 'PIN is not null',
        outFields: 'WORKGROUP,PIN',
        take
      }),
      counties: this.scanner.scan({
        url: urls.PROJECT_COUNTY,
        where: 'PIN is not null',
        outFields: 'COUNTY,PIN',
        take
      }),
      iowaStateSenateDistricts: this.scanner.scan({
        url: urls.IOWA_STATE_SENATE_DISTRICTS,
        where: 'PIN is not null',
        outFields: 'STATE_SENATE_DISTRICT,STATE_SENATE_REP_NAME,PIN',
        take
      }),
      iowaStateHouseDistricts: this.scanner.scan({
        url: urls.IOWA_STATE_HOUSE_DISTRICTS,
        where: 'PIN is not null',
        outFields: 'STATE_HOUSE_DISTRICT,STATE_HOUSE_REP_NAME,PIN',
        take
      }),
      iowaCongressionalDistricts: this.scanner.scan({
        url: urls.IOWA_CONGRESSIONAL_DISTRICTS,
        where: 'PIN is not null',
        outFields: 'US_HOUSE_DISTRICT,US_HOUSE_REP_NAME,PIN',
        take
      }),
      valuation: this.scanner.scan({
        url: urls.VALUATION,
        where: 'PIN is not null',
        take
      }),
      projectNumber: this.scanner.scan({
        url: urls.PROJECT_NUMBER,
        where: 'PIN is not null',
        take
      })
    };

    const loadingProgressItems = [
      scans.projectScoping.loadingProgress,
      scans.scopeGeometry.loadingProgress,
      scans.pinGeometry.loadingProgress,
      scans.priorityResults.loadingProgress,
      scans.projectTypes.loadingProgress,
      scans.programTypes.loadingProgress,
      scans.workTypes.loadingProgress,
      scans.workGroups.loadingProgress,
      scans.counties.loadingProgress,
      scans.iowaStateSenateDistricts.loadingProgress,
      scans.iowaStateHouseDistricts.loadingProgress,
      scans.iowaCongressionalDistricts.loadingProgress,
      scans.valuation.loadingProgress,
      scans.projectNumber.loadingProgress
    ];

    if (environment.isCommission) {
      scans.commissionConfig = this.scanner.scan({
        url: urls.SCOPING_COMMISSION_CONFIGURATION,
        where: 'PIN is not null',
        outFields: 'PIN,COMMISSION_VIEW',
        take
      });
      loadingProgressItems.push(scans.commissionConfig.loadingProgress);
    }


    combineLatest(loadingProgressItems).subscribe(() => {
      const progress: any = {
        projectScoping: scans.projectScoping.loadingProgress.value,
        scopeGeometry: scans.scopeGeometry.loadingProgress.value,
        pinGeometry: scans.pinGeometry.loadingProgress.value,
        priorityResults: scans.priorityResults.loadingProgress.value,
        projectTypes: scans.projectTypes.loadingProgress.value,
        programTypes: scans.projectTypes.loadingProgress.value,
        workTypes: scans.workTypes.loadingProgress.value,
        workGroups: scans.workGroups.loadingProgress.value,
        counties: scans.counties.loadingProgress.value,
        iowaStateSenateDistricts: scans.iowaStateSenateDistricts.loadingProgress.value,
        iowaStateHouseDistricts: scans.iowaStateHouseDistricts.loadingProgress.value,
        iowaCongressionalDistricts: scans.iowaCongressionalDistricts.loadingProgress.value,
        valuation: scans.valuation.loadingProgress.value,
        projectNumber: scans.projectNumber.loadingProgress.value,
        total: 0 // set below
      };

      if (environment.isCommission) {
        progress.commissionConfig = scans.commissionConfig.loadingProgress.value;
      }

      progress.total = this.totalPropValues(progress) / (Object.keys(progress).length - 1);

      if (timeout) {
        clearTimeout(timeout);
      }
      if (this.unresponsive.value) {
        this.unresponsive.next(false);
      }
      timeout = setTimeout(() => {
        this.unresponsive.next(true);
      }, UNRESPONSIVE_TIMEOUT);

      this.loadingProgress.next(progress);
    });

    const promisesToAwait = [
      scans.projectScoping.results,
      scans.scopeGeometry.results,
      scans.pinGeometry.results,
      scans.priorityResults.results,
      scans.projectTypes.results,
      scans.programTypes.results,
      scans.workTypes.results,
      scans.workGroups.results,
      scans.counties.results,
      scans.iowaStateSenateDistricts.results,
      scans.iowaStateHouseDistricts.results,
      scans.iowaCongressionalDistricts.results,
      scans.valuation.results,
      scans.projectNumber.results
    ];

    if (environment.isCommission) {
      promisesToAwait.push(scans.commissionConfig.results);
    }

    const res = await Promise.all(promisesToAwait);

    if (timeout) {
      clearTimeout(timeout);
    }
    if (this.unresponsive.value) {
      this.unresponsive.next(false);
    }

    const scanResults: MultiScannerResults = {
      projectScoping: res[0],
      scopeGeometry: res[1],
      pinGeometry: res[2],
      priorityResults: res[3],
      projectTypes: res[4],
      programTypes: res[5],
      workTypes: res[6],
      workGroups: res[7],
      counties: res[8],
      iowaStateSenateDistricts: res[9],
      iowaStateHouseDistricts: res[10],
      iowaCongressionalDistricts: res[11],
      valuation: res[12],
      projectNumber: res[13]
    };

    if (environment.isCommission) {
      scanResults.commissionConfig = res[14];
    }
    this.loading.next(false);
    const end = +new Date();
    console.log('time: ' + (end - start));
    return scanResults;
  }

  private totalPropValues(obj): number {
    const keys = Object.keys(obj);
    const vals = keys.map(key => obj[key]);
    const total = +vals.reduce((acc, val) => acc + val);
    return total;
  }
}

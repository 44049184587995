<ng-container *ngIf="rxLayer && !(viewDetailsForPin | async)">
  <ng-container *ngIf="!(refreshing || (submitApproveService.working | async))">
    <div>
      <div class="attr-tbl-dialog-titlebar">
        <div class="layer-title">{{ rxLayer.title }}</div>
        <div style="flex-grow: 1"></div>
        <div *ngIf="!(rxLayer.visibleFieldsPromise | async)" style="flex-grow:1"></div>
        <mat-checkbox *ngIf="canDisableSendEmail" [ngModel]="willNotSendEmail | async" (ngModelChange)="updateWillNotSendEmail($event)"
                      style="margin-right: 8px; font-family: Roboto, 'Helvetica Neue', sans-serif; font-size: 14px; font-weight: 500;"
        >Don't send email</mat-checkbox>
        <button mat-raised-button color="primary" style="margin-right: 8px;" (click)="submit()"
                [disabled]="!canSubmitApprove">{{ submitApproveAction }}</button>
        <button mat-raised-button color="primary" style="margin-right: 8px;" (click)="showApprovalDiagram()">Scope
          Approval Process
        </button>
        <!--<button mat-raised-button color="primary" style="margin-right: 8px;" (click)="emailTest()" *ngIf="isAdmin" [disabled]="!rxLayer.tableData.selection.selected.length">Email Test</button>-->
        <button mat-raised-button color="primary" style="margin-right: 8px;" (click)="resendEmail()"
                [disabled]="!(isAdmin && canResendEmail)" *ngIf="!resendingEmail">Resend Email
        </button>
        <button mat-icon-button tabindex="-1" (click)="dockBottom()" *ngIf="dock !== 'DockBottom'">
          <mat-icon>expand_more</mat-icon>
        </button>
        <button mat-icon-button tabindex="-1" (click)="fullscreen()" *ngIf="dock !== 'FullScreen'">
          <mat-icon>expand_less</mat-icon>
        </button>
        <button mat-icon-button tabindex="-1" (click)="dialogRef.close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="rxLayer.visibleFieldsPromise | async">
        <app-filter-bar [appTutorialTarget]="'approvalFilterBar'" [rxLayer]="rxLayer" class="filter-bar"></app-filter-bar>
      </ng-container>
    </div>
    <app-attribute-table (tableRowsLoading)="tableRowsLoading($event)" (showDetails)="showDetails($event)"
                         [rxLayer]="rxLayer" [isRowCheckable]="true" [showDetailButton]="true"
                         style="flex-grow: 1"></app-attribute-table>
  </ng-container>

  <div *ngIf="refreshing || (submitApproveService.working | async)" style="margin: 24px">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
</ng-container>

<ng-container *ngIf="viewDetailsForPin | async">
  <app-audit-details [pin]="viewDetailsForPin | async"
                 (clickBack)="viewDetailsForPin.next(null)"
                 (triggerRefresh)="refreshFeatures()"
                 (clickClose)="dialogRef.close()">
  </app-audit-details>
</ng-container>

import { Injectable } from '@angular/core';
import { Filter, FilterType } from '../../rx-layer/filter';
import { GuidService } from '../guid.service';

@Injectable({
  providedIn: 'root'
})
export class LegacyHashService {

  constructor(
    private guidService: GuidService
  ) {
  }

  translateOlderVersion(_contents) {
    const contents: any = Object.prototype.hasOwnProperty.call(_contents, 'filters')
      ? _contents
      : { filters: _contents };

    contents.filters = contents.filters
      .map(f => this.translateOldFilter(f))
      .filter(f => f !== null);

    return contents;
  }

  private translateOldFilter(f): Filter {
    if ((f.alias === 'PinType' || (f.field && f.field.name === 'TYPE_LABEL')) && f.visible === false) {
      return this.getStageFilter(f);
    } else if ((f.alias === 'search' || (f.field && f.field.name === 'search')) && f.visible === false) {
      return this.getSearchFilter(f);
    } else if (f.field && f.field.extendedCollectionName) {
      return this.getExtFilter(f);
    } else {
      // Explicit property declaration ensures deprecated props are excluded.
      return <Filter>{
        guid: this.guidService.createGuid(),
        type: f.type,
        field: {
          name: f.field.name
          // PinsRxLayerService will fill in remaining field info.
        },
        value: f.value,
        active: f.active
      };
    }
  }

  private getSearchFilter(f) {
    return <Filter>{
      guid: this.guidService.createGuid(),
      type: FilterType.MultiFieldSearch,
      field: null,
      searchFieldNames: ['PIN', 'DESCRIPTION'],
      value: f.value,
      active: true
    };
  }

  private getStageFilter(f) {
    const newVal = [];
    if (f.value.indexOf('scoping') > -1) {
      newVal.push('SCOPE_SCOPING');
      newVal.push('SCOPE_INAPPROVAL');
      newVal.push('SCOPE_APPROVED');
    }
    if (f.value.indexOf('approved') > -1) {
      newVal.push('PIN_APPROVED');
    }
    if (f.value.indexOf('programmed') > -1) {
      newVal.push('PIN_PROGRAMMED');
    }
    return <Filter>{
      guid: this.guidService.createGuid(),
      type: FilterType.StageSelect,
      field: null,
      value: newVal,
      active: true
    };
  }

  private getExtFilter(f) {
    const ext = f.field.extendedCollectionName;
    switch (ext) {
    case 'COMBINED_RESULTS':
      return <Filter>{
        guid: this.guidService.createGuid(),
        type: f.type,
        field: {
          name: f.field.name
          // PinsRxLayerService will fill in remaining field info.
        },
        value: f.value,
        active: f.active
      };
    case 'PROJECT_TYPES':
    case 'PROGRAM_TYPES':
    case 'WORK_TYPES':
    case 'WORK_GROUPS':
    case 'COUNTIES':
      return <Filter>{
        guid: this.guidService.createGuid(),
        type: f.type,
        field: {
          name: ext
          // PinsRxLayerService will fill in remaining field info.
        },
        value: f.value,
        active: f.active
      };
    default:
      return null;
    }
  }
}

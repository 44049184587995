import { Injectable } from '@angular/core';

import { ApprovedPinInfo } from './approved-pin-info';
import { EMAIL_COMMON_STYLES } from './common/styles';
import { EMAIL_HEADER } from './common/header';
import { EMAIL_FOOTER } from './common/footer';
import { getApprovalLink } from './common/get-approval-link';
import { getApprovalStagesHtml } from './common/get-approval-stages-html';
import { ApprovalPolicy } from '../scope-approval/approval-workflow-policy';

@Injectable({
  providedIn: 'root'
})
export class EmailContentFinalSafetyService {
  constructor() {
    return
  }

  getFinalSafetyApprovalEmailHtml(approvedPinsInfo: ApprovedPinInfo[]): string {
    const policies = approvedPinsInfo[0].audits;
    return `
    <html>
      <head></head>
      <body>
        <table ${EMAIL_COMMON_STYLES.TABLE_ATTRS}>
          <tbody>
            ${EMAIL_HEADER}
            ${this.getIntroTr({ multi: policies.length > 1, approvedPinsInfo })}
            ${EMAIL_FOOTER}
            </tbody>
            </table>
            </body>
            </html>`;
  }

  getIntroTr(params: { multi: boolean, approvedPinsInfo: ApprovedPinInfo[] }) {
    // find the funding type with a true value. If none, then the variable will be undefined.
    const atts = params.approvedPinsInfo[0].pinAttributes;
    const { USERNAME, PROJECT_TYPES_JSON, PIN } = atts;
    const selectedTypes = JSON.parse(PROJECT_TYPES_JSON);
    return `
        <tr>
        <td>
          You are receiving this email because you have elected to be notified when a
          project scope is developed and submitted to PSS that identifies Safety as a
          stated project purpose. ${params.multi ? 'The projects identified below have been entered and are' : 'The project identified below has been entered and is'}
          being vetted in the project scoping and prioritization system. Should you
          have questions about this project, feel free to contact those championing and
          vetting the project listed below:
        </td>
      </tr>
      <tr>
        <td>
        Personnel who Entered the project: ${USERNAME}
        <br>
        Project Link: https://iowadotprojectpriorities.com/#pin=${PIN}
        <br>
        Project Purpose – ${selectedTypes.map(type => type.PURPOSEDESCRIPTION).join(', ')}
        <br>
        Consistent with Long Range Plan Categories – [Include text of LRTP Impact Categories]
        </td>
      </tr>`;
  }

  getInitScopeSummaryTr(policy: ApprovalPolicy): string {
    return `
    <tr>
      <td>
        <br>
        <br>
        <strong>${policy.scope_id}</strong><br>
        Funding Sources: <strong>${policy.fundingType}</strong><br>
        <br>
        <strong>Approval Stages:</strong><br>
        ${getApprovalStagesHtml(policy)}<br>
        <strong><a href="${getApprovalLink(policy.scope_id)}">View Approval Process</a></strong>
      </td>
    </tr>`;
  }

}

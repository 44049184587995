<div class="attachment-dialog-titlebar mat-elevation-hairline">

  <div class="attachment-dialog-title">{{data.title}}</div>
  <div style="flex-grow:1"></div>
  <!--<button mat-icon-button (click)="dialogRef.close()" tabindex="-1"><mat-icon>close</mat-icon></button>-->
</div>

<div class="attachment-dialog-body-container">

  <div mat-dialog-content>
    <div [innerHTML]="htmlContent"></div>
    <div>{{ data.message }}</div>
    <p>
      <button mat-stroked-button [matMenuTriggerFor]="attachmentsMenu" matTooltip="Attachments" class="header-icon-btn">
        <mat-icon>attachment</mat-icon>
        {{ (attachmentService.state | async).displayCount }}
      </button>

      <mat-menu #attachmentsMenu="matMenu">
        <app-attachments-menu (click)="$event.stopPropagation()"></app-attachments-menu>
      </mat-menu>
    </p>

  </div>

</div>

<div class="attachment-dialog-footer">
  <button *ngIf="hasOk" mat-raised-button color="primary" (click)="ok()">Done</button>
</div>

import { AzureAD } from './azure-ad';
import { StorageKeys } from 'src/app/services/hash/storage-keys';
import { HashKeys } from 'src/app/services/hash/hash-keys';

// called by main.ts
export function handleTokens(): { isRedirecting: boolean } {

  const hash = window.location.hash;
  const key = hash.split('=')[0];
  switch (key) {
  case HashKeys.save: // tokens used for saving filters and layers
    sessionStorage.setItem(StorageKeys.scopingSaveHash, hash);
    // save in session storage
    // (it will not still be in the url after redirects to get id_token and access_token)
    // retrieved from sessionStorage in HashService
    break;
  case HashKeys.scope: // tokens used for loading a particular scope or pin
  case HashKeys.pin:
    sessionStorage.setItem(StorageKeys.pinHash, hash);
    break;
  case HashKeys.idToken: // Azure AD login id token
    AzureAD.loadUserFromHash(hash);
    break;
  case HashKeys.approve:
    sessionStorage.setItem(StorageKeys.approvalHash, hash);
    break;
  }

  if (AzureAD.user) {
    return { isRedirecting: false };
  } else {
    AzureAD.redirectToLogin(); // new id_token is needed
    return { isRedirecting: true };
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_ROLES, UserInfoService } from 'src/app/services/user-info.service';
import { DialogNoticeService } from 'src/app/services/dialog-notice.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-on-behalf-of-dialog',
  templateUrl: './on-behalf-of-dialog.component.html',
  styleUrls: ['./on-behalf-of-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnBehalfOfDialogComponent implements OnInit {

  // roles not associated with approval
  excludedRoles = [
    APP_ROLES.APPLICATION_ACCESS,
    APP_ROLES.COMMISSION_APPLICATION_ACCESS,
    APP_ROLES.LEB_NEPA_REVIEW
  ];
  roles: { name: string, fc: FormControl }[] = [];
  selectedRoles: string[] = null; // read via dialog instance from submit-approve.service
  cancel = true;
  loading = new BehaviorSubject<boolean>(null);

  constructor(
    public cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<OnBehalfOfDialogComponent>,
    private userInfoService: UserInfoService,
    private dialogNoticeService: DialogNoticeService
  ) {
  }

  async ngOnInit() {
    await this.getRoles();
  }

  approve() {
    this.cancel = false;
    const selectedRoles = this.roles
      .filter(g => g.fc.value)
      .map(g => g.name);

    if (selectedRoles.length === 0) {
      this.dialogNoticeService.alert({ message: 'You must select at least 1 role.', hasCancel: false });
    } else {
      this.selectedRoles = selectedRoles;
      this.dialogRef.close();
    }
  }

  private async getRoles() {
    this.loading.next(true);
    const userInfo = this.userInfoService.userInfo.value;
    const roles = userInfo.roles.filter(r => this.excludedRoles.indexOf(r) === -1);
    if (roles == null) {
      this.roles = [];
    } else {
      this.roles = roles.sort((a, b) => a < b ? -1 : 1).map(r => {
        return {
          name: r,
          fc: new FormControl(false)
        };
      });
    }
    this.cdr.markForCheck();
    this.loading.next(false);
  }

}

import { Injectable } from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { PROGRAM_TYPE_LOOKUP } from '../scope-approval/program-type-lookup';
import { EndpointService } from './dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';
import { PinsRxLayerService } from './layer/pins-rx-layer.service';

interface FundingTypeDesc {
  PROGRAM_TYPES: string[];
  PROGRAM_TYPE_CODES: string[];
}

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(
    private endpointService: EndpointService,
    private pinsRxLayerService: PinsRxLayerService,
  ) {
  }

  async getProgramTypes(pins: string[]) {
    const where = `PIN IN (${pins.map(p => `'${p}'`).join(',')})`;
    const attributesForPins = (await this.pinsRxLayerService.getAttributesForPins(where)).map(a => {
      return {
        PIN: a.PIN,
        PROGRAM_TYPES: a.PROGRAM_TYPES,
        PROGRAM_TYPE_CODES: []
      };
    });
    attributesForPins.map(attrs => {
      const a = attrs.PROGRAM_TYPES.split(',')
        .map(x => x.trim())
        .filter(x => x.length)
        .map(x => PROGRAM_TYPE_LOOKUP[x]);
      attrs.PROGRAM_TYPE_CODES = a as string[];
    });
    return attributesForPins;
  }

  async getProgramTypeDomain(fieldName: string) {
    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
    const pTypes = new FeatureLayer({
      url: urls.PROGRAM_TYPES
    });
    await pTypes.load();
    return pTypes.fields
      .filter(x => x.name.toLocaleLowerCase() === fieldName.toLocaleLowerCase())
      .map(x => x.domain);
  }

  getProgramTypeDesc(programTypeCodes: string[]) {
    return programTypeCodes.map(f => {
      const types = f.split(',').map(x => x.trim());
      const codes = types.filter(x => x.length)
        .map(x => PROGRAM_TYPE_LOOKUP[x]);
      return {
        PROGRAM_TYPES: types,
        PROGRAM_TYPE_CODES: codes
      } as FundingTypeDesc;
    });
  }

}

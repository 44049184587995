import { AfterViewChecked, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { TutorialMaskService } from '../services/tutorial-mask.service';

@Directive({
  selector: '[appTutorialTarget]'
})
export class TutorialTargetDirective implements AfterViewChecked, OnDestroy {

  @Input('appTutorialTarget') targetId: string;

  constructor(
    private elementRef: ElementRef,
    private tutorialMaskService: TutorialMaskService,
    private renderer: Renderer2
  ) {
  }

  ngAfterViewChecked(): void {
    this.registerTarget();
  }

  ngOnDestroy(): void {
    this.tutorialMaskService.removeTargetElement(this.elementRef);
  }

  private registerTarget() {
    // this.renderer.setProperty(this.elementRef.nativeElement, 'id', this.targetId); // not using ID to avoid conflicts
    this.renderer.setAttribute(this.elementRef.nativeElement, this.tutorialMaskService.ATTR_MASK_TARGET, this.targetId);
    this.tutorialMaskService.addTargetElement(this.elementRef);
  }


}

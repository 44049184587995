// used for date filters
export function formatSqlDate(dateInMilliseconds: number) {
  const date = new Date(dateInMilliseconds);

  const month = pad(2, date.getMonth() + 1);
  const day = pad(2, date.getDate());
  const year = pad(4, date.getFullYear());

  return `${year}-${month}-${day}`;
}

function pad(chars: number, input: number) {
  return (input + '').padStart(chars, '0');
}

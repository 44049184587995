import { Injectable } from '@angular/core';

import { ApprovalPolicy } from '../scope-approval/approval-workflow-policy';
import { EMAIL_HEADER } from './common/header';
import { EMAIL_FOOTER } from './common/footer';
import { getApprovalLink } from './common/get-approval-link';
import { getApprovalStagesHtml } from './common/get-approval-stages-html';
import { EMAIL_COMMON_STYLES } from 'src/app/email/common/styles';

@Injectable({
  providedIn: 'root'
})
export class EmailContentInitialService {
  constructor() {
    return
  }

  getInitialCreateEmailHtml(policies: ApprovalPolicy[], approvalInfo: any): string {
    return `
    <html>
      <head></head>
      <body>
        <table ${EMAIL_COMMON_STYLES.TABLE_ATTRS}>
          <tbody>
            ${EMAIL_HEADER}
            ${this.getIntroTr({ multi: policies.length > 1 })}
            ${policies.map(policy => this.getInitScopeSummaryTr(policy, approvalInfo)).join('')}
            ${EMAIL_FOOTER}
          </tbody>
        </table>
      </body>
    </html>`;
  }

  getIntroTr(params: { multi: boolean }) {
    return `
    <tr>
      <td>
        You have created ${params.multi ? 'the following new scopes' : 'a new scope'},
        which will need to be submitted for the approval process to begin before ${params.multi ? 'PINs' : 'a PIN'} and project number can be assigned.
      </td>
    </tr>`;
  }

  getInitScopeSummaryTr(policy: ApprovalPolicy, approvalInfo: any): string {
    const bridgeFhwaNumber = `Bridge FHWA Number:  <strong>${approvalInfo[0].misc.bridgeFhwaNumber}</strong>`;
    const bridgeNumber = `Bridge Maintenance Number:  <strong>${approvalInfo[0].misc.bridgeMaintenanceNumber}</strong>`;
    return `
    <tr>
      <td>
        <br>
        <br>
        Scope Id: <strong>${policy.scope_id}</strong><br>
        Funding Sources: <strong>${policy.fundingType}</strong>
        <br>
        <br>
        ${bridgeFhwaNumber}<br>
        ${bridgeNumber}<br>
        <br>
        <strong>Approval Stages</strong><br>
        ${getApprovalStagesHtml(policy)}<br>
        <strong><a href="${getApprovalLink(policy.scope_id)}">View Approval Process</a></strong>
      </td>
    </tr>`;
  }


}

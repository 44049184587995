// used by
// src/app/services/pins-rx-layer.service.symbology.ts
// src/app/score-pill

export const SCORE_COLORS_ORDER = [
  'red',
  'orange',
  'yellow',
  'green',
  'blue'
];

export const SCORE_COLORS_HEX = {
  red: '#E53935',
  orange: '#FF9800',
  yellow: '#E8D800',
  green: '#43A047',
  blue: '#3F51B5',
  black: '#000000',
};

export const SCORE_COLORS_SEMI_TRANSPARENT = {
  red: 'rgba(229,57,53,.8)', // #E53935
  orange: 'rgba(255,152,0,.8)', // #FF9800
  yellow: 'rgba(232,216,0,.8)', // #E8D800
  green: 'rgba(67,160,71,.8)', // #43A047
  blue: 'rgba(63,81,181,.8)', // #3F51B5
  black: 'rgba(0,0,0,.8)'
};

export const SCORE_COLORS_MIN_MAX = {
  red: {
    min: 0,
    max: 50
  },
  redPoint: {
    min: 100,
    max: 150
  },
  orange: {
    min: 50,
    max: 60
  },
  orangePoint: {
    min: 150,
    max: 160
  },
  yellow: {
    min: 60,
    max: 70
  },
  yellowPoint: {
    min: 160,
    max: 170
  },
  green: {
    min: 70,
    max: 80
  },
  greenPoint: {
    min: 170,
    max: 180
  },
  blue: {
    min: 80,
    max: 100
  },
  bluePoint: {
    min: 180,
    max: 200
  }
};

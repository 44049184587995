import { FilterType } from '../rx-layer/filter';

export const FILTER_TYPE_DEFAULTS: { [fieldName: string]: FilterType } = {
  PIN: FilterType.MultiSelect,
  STATUS: FilterType.MultiSelect,
  NBI2: FilterType.MultiSelect,
  NUM: FilterType.MultiSelect,
  FLAG: FilterType.MultiSelect,
  SDFO: FilterType.MultiSelect,
  GFP: FilterType.MultiSelect,
  NBI22: FilterType.MultiSelect,
  HIGHWAY_CLASS: FilterType.MultiSelect,
  NEXT_APPROVER: FilterType.ContainsString
};

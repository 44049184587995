import { environment } from 'src/environments/environment';

const IS_LOCAL_HOST_ENVIRONMENT = environment.isLocalHost;

// Story 1806 Migrate Geometry into RAMS - written to handle RAMS calls
/*
export const RAMS_VERSIONS = {
    EDIT_GDB_VERSION: !IS_LOCAL_HOST_ENVIRONMENT ? 'RAMS.PROJ' : 'RAMS.PROJ_TESTING',
    VIEW_GDB_VERSION: !IS_LOCAL_HOST_ENVIRONMENT ? 'RAMS.PROJ' : 'RAMS.PROJ_TESTING',
    RECONCILE_TARGET_GDB_VERSION: 'RAMS.QAQC'
};
*/
export const RAMS_VERSION_PROJ = 'RAMS.PROJ';
export const RAMS_VERSION_QAQC = 'RAMS.QAQC';

export const RAMS_VERSIONS = {
  EDIT: RAMS_VERSION_PROJ,
  TARGET: RAMS_VERSION_QAQC,
  VIEW: RAMS_VERSION_PROJ,
};


import { AnyAll, ApprovalPolicy } from 'src/app/scope-approval/approval-workflow-policy';

export function getApprovalStagesHtml(policy: ApprovalPolicy): string {
  try {
    return policy.stages.map(x =>
      `${x.operator === AnyAll.NOTICE_ONLY ? '<strong>EMAIL NOTICE ONLY</strong>' : x.operator}: Approval Groups - ${x.groups.join(', ')} ${x.onlyRequiredWhen ? '(depending on Scope specifications)' : ''}`
    ).join('<br/>');
  } catch (err) {
    console.error(err);
    return '<strong>Unable to generate stages</strong>';
  }
}

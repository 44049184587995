import { detect } from 'detect-browser';

export function verifyBrowser(): { compatible: boolean } {
  const browser = detect();
  const supportedBrowsers = ['chrome', 'firefox', 'edge', 'safari', 'opera'];
  if (browser && supportedBrowsers.indexOf(browser.name) === -1) {
    alert(`This app does not currently support the browser "${browser.name}". Please use Chrome, Firefox, Edge, Safari, or Opera.`);
    return { compatible: false };
  }
  return { compatible: true };
}

import { Injectable } from '@angular/core';
import { PinsRxLayerService } from './pins-rx-layer.service';
import { BehaviorSubject } from 'rxjs';
import { GuidService } from '../guid.service';
import { PinAttributes } from '../amalgamator/pin-attributes';

export interface GraphicFieldInfo {
  routeId: string;
  fromMeasure: string;
  toMeasure: string;
}

export interface RouteAndMeasure {
  // properties from ROUTES_AND_MEASURES_JSON
  ROUTE_ID: string;
  FROM_MEASURE: number;
  TO_MEASURE: number;
  PATHS: any;
  WKID: number;

  // additional properties, used temporarily for the selected PIN
  TMP_GUID: string;
  SELECTED: boolean;
  LOADING: boolean;
  IS_PRIMARY: boolean;
  JUST_CREATED: boolean;
  INCLUDED?: boolean; // used when selecting multiple routes via polygon.  User needs to include the route segment before submitting, or delete it
}

@Injectable({
  providedIn: 'root'
})
export class RouteAndMeasureService {

  routesAndMeasures = new BehaviorSubject<RouteAndMeasure[]>([]);
  routesAndMeasuresPristine = new BehaviorSubject<boolean>(true);

  constructor(
    private pinsRxLayerService: PinsRxLayerService,
    private guidService: GuidService
  ) {
    this.pinsRxLayerService.selection.subscribe(selection => {
      if (selection) {

        // unwrap routes slices
        const attrs: PinAttributes = JSON.parse(selection.attributesJson);
        const rawRoutesAndMeasures = JSON.parse(attrs.ROUTES_AND_MEASURES_JSON);
        const routesAndMeasures = rawRoutesAndMeasures.map(x => (<RouteAndMeasure>{
          TMP_GUID: this.guidService.createGuid(),
          ROUTE_ID: x.ROUTE_ID,
          FROM_MEASURE: x.FROM_MEASURE,
          TO_MEASURE: x.TO_MEASURE,
          PATHS: x.PATHS,
          SELECTED: false,
          IS_PRIMARY: x.ROUTE_ID === attrs.PRIMARY_ROUTE,
          JUST_CREATED: false
        }));
        this.routesAndMeasures.next(routesAndMeasures);
      } else {
        this.routesAndMeasures.next([]);
      }
      this.routesAndMeasuresPristine.next(true);
    });
  }

  updateRouteAndMeasure(slice: RouteAndMeasure) {
    if (slice) {
      const rms = this.routesAndMeasures.getValue().filter(r => r.TMP_GUID !== slice.TMP_GUID);
      rms.push(slice);
      this.routesAndMeasures.next(rms);
      this.routesAndMeasuresPristine.next(false);
    }
  }

  getLrsLength(routesAndMeasure: RouteAndMeasure, decimalPlaces: number) {
    return Math.abs(routesAndMeasure.TO_MEASURE - routesAndMeasure.FROM_MEASURE).toFixed(decimalPlaces);
  }

  getTotalLrsLength(routesAndMeasures: RouteAndMeasure[], decimalPlaces: number) {
    const reducer = (accum, curr) => accum + curr;
    let totalLrsLength = null;
    if (routesAndMeasures.length > 0) {
      totalLrsLength = routesAndMeasures.map(rm => {
        return Math.abs(rm.TO_MEASURE - rm.FROM_MEASURE);
      }).reduce(reducer);
      return totalLrsLength.toFixed(decimalPlaces);
    }
    return null;
  }

  createFromPolylines(features: __esri.Graphic[], fieldInfo: GraphicFieldInfo) {
    return features.map((feature, index) => {
      const polyline = feature.geometry as __esri.Polyline;
      return {
        TMP_GUID: this.guidService.createGuid(),
        ROUTE_ID: feature.attributes[fieldInfo.routeId],
        FROM_MEASURE: feature.attributes[fieldInfo.fromMeasure],
        TO_MEASURE: feature.attributes[fieldInfo.toMeasure],
        PATHS: polyline.paths,
        SELECTED: true,
        IS_PRIMARY: index === 0 ? true : false, // make first segment primary
        JUST_CREATED: true
      } as RouteAndMeasure;
    });
  }

}

import { PinAttributes } from '../services/amalgamator/pin-attributes';
import { CountyService } from '../services/county.service';
import { RouteNameService } from '../services/route-name.service';
import { RefPostService } from '../services/ref-post.service';
import { PriorityRxLayersService } from '../services/layer/priority-rx-layers.service';
import { ApprovedPinInfo } from './approved-pin-info';
import { ImpactRxLayersService } from '../services/layer/impact-rx-layers.service';
import { ApprovalAuditService } from '../scope-approval/approval-audit-service';
import { getEvents } from './common/get-events';
import { BridgeInfoService } from '../services/bridge-info.service';
import { ApprovalPolicy } from '../scope-approval/approval-workflow-policy';
import { RouteAndMeasure, RouteAndMeasureService } from '../services/layer/route-and-measure.service';

export async function getApprovedPinInfo(
  atts: PinAttributes,
  lookups,
  countyService: CountyService,
  routeNameService: RouteNameService,
  routeAndMeasureService: RouteAndMeasureService,
  refPostService: RefPostService,
  priorityRxLayersService: PriorityRxLayersService,
  impactRxLayersService: ImpactRxLayersService,
  auditService: ApprovalAuditService,
  bridgeInfoService: BridgeInfoService,
  approvalPolicy: ApprovalPolicy
): Promise<ApprovedPinInfo> {
  const programFunding = JSON.parse(atts.PROGRAM_TYPES_JSON).map(x => {
    const type = lookups.programType['' + x.PROGRAMTYPE];
    const desc = x.FUNDING_DESCRIPTION === null ? '' : x.FUNDING_DESCRIPTION.trim();
    return `${type} ${desc}`;
  }).join(', ');

  const projectPurpose = JSON.parse(atts.PROJECT_TYPES_JSON).map(x => {
    const type = lookups.projectType['' + x.PROJECTTYPE];
    const desc = x.PURPOSEDESCRIPTION === null ? '' : x.PURPOSEDESCRIPTION.trim();
    return `${type} ${desc}`;
  }).join(', ');

  let counties = 'MISSING';
  try {
    const countyEntries = await countyService.getCounties(atts.PIN);
    counties = countyEntries.map(c => {
      return `${c.name} (${c.number})`;
    }).join(', ');
  } catch (err) {
    console.error('countyService.getCounties() error', err);
  }

  const routesAndMeasures = JSON.parse(atts.ROUTES_AND_MEASURES_JSON);
  const prm = routesAndMeasures.find(x => x.ROUTE_ID === atts.PRIMARY_ROUTE) as RouteAndMeasure;
  let readableRouteName = 'MISSING';
  try {
    readableRouteName = await routeNameService.getCommonNamePromise(prm.ROUTE_ID, prm.FROM_MEASURE, prm.TO_MEASURE);
  } catch (err) {
    console.error('routeNameService.getCommonNamePromise() error', err);
  }

  // LRS route and measure total length
  const lrsPrimaryLength = `${routeAndMeasureService.getLrsLength(prm, 2)}`;
  const lrsTotalLength = `${routeAndMeasureService.getTotalLrsLength(routesAndMeasures, 2)}`;

  // Get Ref Posts
  const [refPostfrom, refPostTo] = await Promise.all([
    refPostService.getReferencePost(prm.ROUTE_ID, prm.FROM_MEASURE, { useCache: true }),
    refPostService.getReferencePost(prm.ROUTE_ID, prm.TO_MEASURE, { useCache: true })
  ]);
  const refPostFromValue = '' + (refPostfrom ? refPostfrom.repostMileRound : 'None');
  const refPostFromName = '' + (refPostfrom ? refPostfrom.repostName : 'None');
  const refPostToValue = '' + (refPostTo ? refPostTo.repostMileRound : 'None');
  const refPostToName = '' + (refPostTo ? refPostTo.repostName : 'None');
  const refPostLength = '' + refPostService.calculateProjectMiles(refPostfrom, refPostTo);

  const levelOfStudyRequired = lookups.LEVELSTUDYREQUIRED[atts.LEVELSTUDYREQUIRED];
  const rowNeed = lookups.ROWNEED[atts.ROWNEED];
  const outsideServices = lookups.OUTSIDESERVICES[atts.OUTSIDESERVICES];
  const pdCompletedBy = lookups.PD_COMPLETED_BY[atts.PD_COMPLETED_BY];
  const desiredFiscalMonth = lookups.DESIRED_FISCAL_MONTH[atts.DESIRED_FISCAL_MONTH];
  const levelOfDocumentation = lookups.LEVELOFDOCUMENTATION[atts.LEVELOFDOCUMENTATION];

  // Get Bridge Info
  let bridgeMaintenanceNumber = 'N/A';
  let bridgeFhwaNumber = 'N/A';
  const res = await getBridgeInfo(atts.PIN, atts.HAS_GEOMETRY, prm.ROUTE_ID, programFunding);
  if (res.length > 0) {
    bridgeMaintenanceNumber = res.map(r => {
      return `${r.bridgeId}`;
    }).join(', ');
    bridgeFhwaNumber = res.map(r => {
      return `${r.structureNumber}`;
    }).join(', ');
  }

  const workGroups = atts.WORK_GROUPS
    .split(',')
    .map(x => x.trim())
    .filter(x => x.length)
    .map(x => lookups.workGroup[x])
    .join(', ');

  const workTypes = atts.WORK_TYPES
    .split(',')
    .map(x => x.trim())
    .filter(x => x.length)
    .map(x => lookups.workType[x])
    .join(', ');

  const budgetMedianCalc = (() => {
    const minNum = Number(atts.BUDGETRANGEMIN);
    const maxNum = Number(atts.BUDGETRANGEMAX);
    if (minNum > 0 && maxNum > 0) {
      const median = minNum + ((maxNum - minNum) / 2);
      return '$' + (Math.round(median * 100) / 100).toString();
    } else {
      return 'Unable to Calculate';
    }
  })();

  const [priorityCounts, impactCounts, audits] = await Promise.all([
    priorityRxLayersService.getCountsForPin(atts.PIN),
    impactRxLayersService.getCountsForPin(atts.PIN),
    auditService.getAudits(atts.PIN)
  ]);

  return {
    pinAttributes: atts,
    priorityCounts,
    impactCounts,
    audits,
    pssEvents: getEvents(atts, impactCounts),
    approvalPolicy: approvalPolicy,
    misc: {
      programFunding,
      projectPurpose,
      counties,
      readableRouteName,
      refPostFromValue,
      refPostFromName,
      refPostToValue,
      refPostToName,
      refPostLength,
      lrsPrimaryLength,
      lrsTotalLength,
      levelOfStudyRequired,
      rowNeed,
      outsideServices,
      pdCompletedBy,
      desiredFiscalMonth,
      levelOfDocumentation,
      workGroups,
      workTypes,
      budgetMedianCalc,
      bridgeMaintenanceNumber,
      bridgeFhwaNumber
    }
  };

  async function getBridgeInfo(PIN: string, hasGeometry: number, routeId: string, funding?: string) {
    try {
      // bridge info  if we have geometry, regardless of funding
      if (hasGeometry === 1) {
        return await bridgeInfoService.getBridgeInfoForProject(PIN, routeId);
      }
    } catch {
      throw Error('Failed to Get Bridge Info');
    }
  }

}




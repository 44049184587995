export const GENERAL_EXCLUDE_FIELDS = [
  'PP_UKEY',
  'PIN_ID',
  'GLOBALID',
  'OBJECTID',
  'COUNTY_1',
  'COUNTY_2',
  'COUNTY_3',
  'COUNTY_4',
  'CREATED_DATE',
  'LAST_EDITED_DATE',
  'BEGIN_MILEPOST',
  'END_MILEPOST',
  'CREATED_USER',
  'LAST_EDITED_USER',
  'RECORD_LAST_UPDATED',
  'CREATION_DATE',
  'REST_UPDATED',
  'UTC_OFFSET',
  'MODEL_RUN_DATE',
  'PIN',
  'PP_PIN',
  'PARENT_PIN',
  'ORDER_ID',
  'OID',
  'PP_MODEL_RUN_DATE',
  'USER_CREATE',
  'USER_MOD',
  'ADD_DATE',
  'MOD_DATE',
  'MOD_BY',
  'SHAPE__Length',
  'CREATOR',
  'EDITOR',
  'EDITED_DATE',
  'idAdminB',
  'edit_by',
  'edit_date',
  'Shape',
  'Shape.STArea()',
  'Shape.STLength()',
  'Shape__Area',
  'Shape__Length',
  'REST_UTC_OFFSET',
  'CREATE_DATE_UTC_OFFSET',
  'EDIT_DATE_UTC_OFFSET',
  'VERIFIED_DATE_UTC_OFFSET',
  'EFFECTIVE_START_DATE',
  'EFFECTIVE_END_DATE',
  'SYMBOL'
];

export const LAYER_SPECIFIC_EXCLUDE_FIELDS = {
  'Bridges (point)': [
    'NBI1',
    'NBI3',
    'NBI4',
    'NBI5A',
    'NBI5B',
    'NBI5C',
    'NBI5E',
    'NBI12',
    'NBI20',
    'NBI21',
    'NBI26',
    'NBI28A',
    'NBI28B',
    'NBI33',
    'NBI35',
    'NBI36A',
    'NBI36B',
    'NBI36C',
    'NBI36D',
    'NBI37',
    'NBI38',
    'NBI42A',
    'NBI42B',
    'NBI44A_NEAR',
    'NBI44B_NEAR',
    'NBI44A_FAR',
    'NBI44B_FAR',
    'NBI46_NEAR',
    'NBI46_FAR',
    'NBI47_FEET',
    'NBI47_INCHES',
    'NBI48',
    'NBI50A',
    'NBI50B',
    'NBI53_FEET',
    'NBI53_INCHES',
    'NBI54A',
    'NBI54B_FEET',
    'NBI54B_INCHES',
    'NBI55A',
    'NBI55B_FEET',
    'NBI55B_INCHES',
    'NBI56_FEET',
    'NBI56_INCHES',
    'NBI67',
    'NBI69',
    'NBI70',
    'NBI90',
    'NBI91',
    'NBI92A2',
    'NBI92B1',
    'NBI92B2',
    'NBI92C1',
    'NBI92C2',
    'NBI93B',
    'NBI93C',
    'NBI98A',
    'NBI98B',
    'NBI100',
    'NBI101',
    'NBI102',
    'NBI103',
    'NBI105',
    'NBI110',
    'NBI111',
    'NBI113',
    'MAINTCENTER',
    'GFP',
    'GFPFHWA',
    'NBI31',
    'RESTRICTIONS',
    'JURISDREMA',
    'ACTUAL_TONS_3_3_STATE',
    'ACTUAL_TONS_3S3_STATE',
    'ACTUAL_TONS_4_STATE',
    'ACTUAL_TONS_3_3_COUNTY',
    'ACTUAL_TONS_3S3_COUNTY',
    'ACTUAL_TONS_4_COUNTY'
  ]
};

import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-service-health',
  templateUrl: './service-health.component.html',
  styleUrls: ['./service-health.component.scss']
})
export class ServiceHealthComponent {

  isCommission = environment.isCommission;

  constructor(
    public dialogService: DialogService
  ) {
  }

}

import { Injectable } from '@angular/core';

import { Audit } from './audit';
import {
  AnyAll,
  ApprovalGroupCondition,
  ApprovalPolicy,
  ApprovalReport,
  AttributeMinCondition,
  DomainValueCondition,
  ImpactMinCondition
} from './approval-workflow-policy';
import { ImpactRxLayersService } from '../services/layer/impact-rx-layers.service';
import { RxLayer } from '../rx-layer/rx-layer';
import { ApprovalPolicyFactory } from 'src/app/scope-approval/approval-policy-factory';
import { ApprovalAuditService } from 'src/app/scope-approval/approval-audit-service';
import { APP_ROLES } from 'src/app/services/user-info.service';
import { LoggingService } from '../services/logging.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalReportService {

  constructor(
    private impactRxLayersService: ImpactRxLayersService,
    private approvalPolicyFactory: ApprovalPolicyFactory,
    private auditService: ApprovalAuditService,
    private logging: LoggingService
  ) {
  }

  async getReportBasic(attrs: any): Promise<ApprovalReport> {
    const policy = this.approvalPolicyFactory.generatePolicy(attrs.PIN, attrs.DISTRICT, attrs.PROGRAM_TYPES);
    const audits: Audit[] = await this.auditService.getAudits(attrs.PIN);
    return await this.getReport(attrs, audits, policy);
  }

  async getReport(attrs: any, audits: Audit[], policy: ApprovalPolicy): Promise<ApprovalReport> {
    const scope_id = attrs.PIN;

    const hasApproved = {};
    audits.forEach(a => {
      a.USER_GROUPS.split(',').forEach(g => hasApproved[g] = true);
    });

    const impactCountLookup: { [layerName: string]: number } = await this.getImpactCounts(policy);

    const stages = policy.stages.map(stage => {

      // groups
      const groups = stage.groups.map(g => {
        let isApproved = !!hasApproved[g];
        // automatically approve LEB NEPA
        if (g.toLowerCase() === APP_ROLES.LEB_NEPA_REVIEW.toLowerCase()) {
          isApproved = true;
        }
        return {
          name: g,
          approved: isApproved
        };
      });

      // required
      let required = true;
      // LEB NEPA review is now just 'review' only...it's not required (see groups above)
      if (stage.onlyRequiredWhen) {
        const passedConditionCount = stage.onlyRequiredWhen.conditions.filter(c => this.evalCondition(attrs, c, impactCountLookup)).length;
        if (stage.operator === AnyAll.ALL) {
          if (passedConditionCount > 0) {
            for (const key in impactCountLookup) {
              if (impactCountLookup[key] > 0) {
                required = true;
              }
            }
          } else {
            required = false;
          }
        }
        // this logic below caused LEB NEPA review to be missed
        // required = stage.operator === AnyAll.ALL ? passedConditionCount === stage.onlyRequiredWhen.conditions.length : passedConditionCount > 0;
      }

      // passed
      const countApproved = groups.filter(g => g.approved).length;
      const passed = stage.operator === AnyAll.ALL
        ? countApproved === groups.length
        : countApproved > 0;

      return {
        operator: stage.operator,
        groups: groups,
        passed: passed,
        required: required,
        currentPending: false // will be set below
      };
    }).filter(x => x.required);


    let assignedPending = false;
    stages.forEach(s => {
      if (assignedPending) {
        return;
      }
      if (!s.passed) {
        s.currentPending = true;
        assignedPending = true;
      }
    });

    return <ApprovalReport>{
      scope_id: scope_id,
      stages: stages
    };
  }

  private evalCondition(attrs: any, condition: ApprovalGroupCondition, icl: { [layerName: string]: number }) {
    switch (condition.type) {
    case 'impact-min': {
      const layerName = (<ImpactMinCondition>condition).layerName;
      const minCount = (<ImpactMinCondition>condition).minCount;
      const count = icl[layerName];
      if (count == null) {
        throw new Error(`Could not evaluate impact-min for approval stage condition (${condition})`);
      }
      return count >= minCount;
    }
    case 'attr-min': {
      const _attrName = (<AttributeMinCondition>condition).attributeName;
      const minValue = (<AttributeMinCondition>condition).minValue;
      if (!Object.prototype.hasOwnProperty.call(attrs, _attrName)) {
        throw new Error(`Could not evaluate attribute value for approval stage condition (${condition})`);
      }
      const _val = attrs[_attrName];
      return _val >= minValue;
    }
    case 'domain-value': {

      const attrName = (<DomainValueCondition>condition).attributeName;
      const passingVals = (<DomainValueCondition>condition).passingValues;
      if (!Object.prototype.hasOwnProperty.call(attrs, attrName)) {
        throw new Error(`Could not evaluate domain-value for approval stage condition (${condition})`);
      }
      const val = attrs[attrName];
      return passingVals.indexOf(val) > -1;
    }
    }
  }

  private async getImpactCounts(policy: ApprovalPolicy): Promise<{ [layerName: string]: number }> {

    const impactMinConditions: ImpactMinCondition[] = [];
    policy.stages.forEach(stage => {
      if (!stage.onlyRequiredWhen) {
        return;
      }
      if (!stage.onlyRequiredWhen.conditions) {
        return;
      }
      stage.onlyRequiredWhen.conditions.forEach(c => {
        if (c.type.indexOf('impact-') === 0) {
          impactMinConditions.push(<ImpactMinCondition>c);
        }
      });
    });

    const getCountPromise = async (imc: ImpactMinCondition): Promise<{ layerName: string, count: number }> => {
      const layers = this.allImpactLayers();
      // this.logging.log('🚀 ~ file: approval-report-service.ts ~ line 188 ~ ApprovalReportService ~ getCountPromise ~ layers ', layers );
      const layer = layers.find(l => l.title === imc.layerName);
      if (!layer) {
        throw new Error('Could not find impact layer: ' + imc.layerName);
      }
      const count = await layer.queryCount({
        where: `PIN='${policy.scope_id}'`,
        returnCountOnly: true,
        f: 'json'
      });
      return {
        layerName: imc.layerName,
        count
      };
    };

    const promises = impactMinConditions.map(x => getCountPromise(x));
    const counts = await Promise.all(promises);
    const lookup: { [layerName: string]: number } = {};
    counts.forEach(x => {
      lookup[x.layerName] = x.count;
    });
    return lookup;
  }

  private allImpactLayers(): RxLayer[] {
    const layersByCategory = this.impactRxLayersService.getLayers();
    const allLayers: RxLayer[] = [];
    Object.keys(layersByCategory).forEach(key => {
      layersByCategory[key].forEach((l: RxLayer) => {
        allLayers.push(l);
      });
    });
    return allLayers;
  }

  /*
  private allImpactLayersPromise = (async () => {
    const layersByCategory = await this.impactRxLayersService.layersPromise;
    const allLayers: RxLayer[] = [];
    Object.keys(layersByCategory).forEach(key => {
      layersByCategory[key].forEach((l: RxLayer) => {
        allLayers.push(l);
      });
    });
    return allLayers;
  })();
  */

}

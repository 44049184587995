import { JoinDefinition } from '../join-definition';
import { esriRequest } from 'src/esri/request';

// This is used for deep filters such as: Filter to PINs that have any pavement sections where IRI < 10

// We accomblish this by first querying pavement sections with an IRI of less than 10.
// Then we get all unique values for the PIN field on those pavement sections.
// Finally, we apply a whereclause to PINs in the format of "PIN in (<list of distinct pins for the given pavement sections>)"

// see also rx-layer/join-definition

export async function getSurrogateSql(sqlForForeignTable: string, joinDef: JoinDefinition) {

  // query all features on remote table, for distinct ids (distinct values for field with name foreignKeyName)
  const query = {
    where: sqlForForeignTable,
    outFields: joinDef.foreignKeyName,
    returnDistinctValues: true,
    returnGeometry: false,
    f: 'json'
  };
  const url = joinDef.endpointUrl + '/query';
  const res = await esriRequest(url, { query });
  if ((!res.data) || (!res.data.features)) {
    console.error(`request to ${url} did not return features`);
  }

  // get distinct values for foreign key
  const uniqueVals = [];
  res.data.features.forEach(f => {
    const val = f.attributes[joinDef.foreignKeyName];
    if (uniqueVals.indexOf(val) === -1) {
      uniqueVals.push(val);
    }
  });

  // create sql to apply to local table
  const concatVals = uniqueVals.map(v => {
    // wrap in single quotes if type is string (but don't if it's a number)
    return (typeof v === 'string') ? `'${v}'` : `${v}`;
  }).join(',');

  return `${joinDef.localFieldName} in (${concatVals})`;
}

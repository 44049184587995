import { PINS_RX_LAYER_HIDE_FIELDS } from './pins-rx-layer.hide-fields';
import { SCOPE_PIN_SHORT_SEGMENTS_RENDERER } from './pins-rx-layer.service.symbology';
import { RxLayerOptions } from '../../rx-layer/rx-layer-options';
import { environment } from 'src/environments/environment';

export const PINS_LAYER_TITLE = 'Scopes and PINs';

export const DEFAULT_DISPLAY_COLUMNS = environment.isCommission ? [
  'PIN',
  'DESCRIPTION',
  'PROG_EST_SUM',
  'PERFORMANCE_SCORE',
  'SAFETY_SCORE',
  'IMPORTANCE_SCORE', // Road Class
  'FREIGHT_SCORE',
  'PAVEMENT_SCORE',
  'BRIDGE_SCORE',
  'TRAFFIC_SCORE',
  'MOBILITY_SCORE'
]
  : [
    'PIN',
    'DESCRIPTION',
    'PROG_EST_SUM',
    'DEV_EST_SUM',
    'PERFORMANCE_SCORE',
    'SAFETY_SCORE',
    'PAVEMENT_SCORE',
    'BRIDGE_SCORE',
    'TRAFFIC_SCORE'
  ];

export function getPinsRxLayerConfig(fields: __esri.Field[], features: __esri.Graphic[], visible = true) {
  return <RxLayerOptions>{
    title: PINS_LAYER_TITLE,
    visible: visible,
    filters: [],
    displayColumns: DEFAULT_DISPLAY_COLUMNS,
    hideFields: PINS_RX_LAYER_HIDE_FIELDS,
    listMode: 'hide',
    /*
    spatialReference: {
      wkt: 'PROJCS["LRS Lambert",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",304800.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-93.5],PARAMETER["Standard_Parallel_1",40.375],PARAMETER["Standard_Parallel_2",43.625],PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",40.0],UNIT["Meter",1.0]]'
    },*/
    spatialReference: {
      wkid: 3857
    },
    clientSideGraphicOptions: {
      displayField: 'DESCRIPTION',
      geometryType: 'polyline',
      fields: fields,
      initFeatures: features,
      renderer: SCOPE_PIN_SHORT_SEGMENTS_RENDERER
    }
  };
}

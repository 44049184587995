<ng-container *ngIf="!rxLayer">
  <mat-spinner style="margin:auto"
               class="gray-spinner"
               diameter="48"
  ></mat-spinner>
</ng-container>
<ng-container *ngIf="rxLayer">
  <div class="attr-tbl-dialog-titlebar">
    <div class="layer-title">{{ rxLayer.title }}</div>
    <ng-container *ngIf="fields && (!isEditingPINs) && (!isSubmitting) else no_filter_bar">
      <app-filter-bar [rxLayer]="rxLayer" class="filter-bar"></app-filter-bar>
    </ng-container>
    <ng-template #no_filter_bar>
      <div class="filter-bar"></div>
    </ng-template>

    <div *ngIf="!fields" style="flex-grow:1"></div>

    <ng-container *ngIf="maxSelectionReached">
      <div><span style="color: red; padding-right: 8px">{{maxSelectionErrMessage}}</span></div>
    </ng-container>

    <button *ngIf="!isSubmitting" mat-raised-button color="primary" style="margin-right: 14px;" (click)="toggleEdit()"
            [disabled]="!rxLayer.tableData.selection.selected.length && !maxSelectionReached">
      {{ isEditingPINs ? 'Back' : 'Select' }}
    </button>

    <button *ngIf="isEditingPINs" style="margin-right: 14px;" mat-raised-button color="primary" (click)="publishPins()"
            [disabled]="!rxLayer.tableData.selection.selected.length">
      Create New Scopes
    </button>

    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>

  </div>
  <app-attribute-table [style.display]="(!isEditingPINs) && (!isSubmitting) ? 'flex' : 'none'" [rxLayer]="rxLayer"
                       [isRowCheckable]="true" [disableCheckable]="true" style="flex-grow: 1"></app-attribute-table>

  <ng-container *ngIf="isEditingPINs">
    <form [formGroup]="pinForm" style="display: flex;flex-direction: column;flex-grow: 1;overflow-x: auto"
          class="styled-scroll">
      <div class="styled-scroll">
        <table mat-table [dataSource]="selectedPins" formArrayName="pinFormArray" class="br-stage-table">

          <ng-container *ngFor="let field of fields" [matColumnDef]="field.name">
            <th mat-header-cell *matHeaderCellDef>{{ field.alias }}&nbsp;</th>
            <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" style="padding-right: 24px;">

              <!-- Read only field -->
              <ng-container *ngIf="field.name==='BRIDGE_ID'">
                {{ pinFormArray.at(index).value[field.name] }}
              </ng-container>


              <ng-container *ngIf="field.name==='DESCRIPTION'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 256px">
                  <input matInput formControlName="{{field.name}}" [value]="pinFormArray.at(index).value[field.name]">
                </mat-form-field>
              </ng-container>

              <ng-container *ngIf="field.name==='DESIRED_FISCAL_YEAR'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 96px">
                  <input matInput formControlName="{{field.name}}" [value]="pinFormArray.at(index).value[field.name]">
                </mat-form-field>
              </ng-container>

              <ng-container *ngIf="field.name==='ROADWAY'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 128px">
                  <input matInput formControlName="{{field.name}}" [value]="pinFormArray.at(index).value[field.name]">
                </mat-form-field>
              </ng-container>

              <!-- Dollar amounts -->
              <ng-container *ngIf="field.name==='BUDGET_MIN' || field.name==='BUDGET_MAX'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 128px">
                  <span matPrefix>$ &nbsp;</span>
                  <input matInput formControlName="{{field.name}}" [value]="pinFormArray.at(index).value[field.name]">
                </mat-form-field>
              </ng-container>

              <!-- Drop down two pane multi select -->
              <ng-container *ngIf="field.name ==='WORK_GROUP' || field.name === 'WORK_TYPE'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 100%">
                  <mat-select multiple="true" formControlName="{{field.name}}"
                              [value]="pinFormArray.at(index).value[field.name]" panelClass="multiselect-list-panel">
                    <mat-option
                      *ngFor="let option of (pinFormArray.at(index).controls[field.name].selectOptions | async)"
                      style="display:none" [value]="option.value">
                      {{ option.alias }}
                    </mat-option>
                    <app-multiselect-list [formSubject]="pinFormArray.at(index).controls[field.name]"></app-multiselect-list>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <!-- drop down multi select -->
              <ng-container *ngIf="field.name==='PROGRAM_FUNDING'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 96px">
                  <mat-select multiple formControlName="{{field.name}}"
                              [value]="pinFormArray.at(index).value[field.name]">
                    <mat-option *ngFor="let option of field.domain.codedValues" [value]="option.code">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <!-- drop down multi select -->
              <ng-container *ngIf="field.name==='PROJECT_PURPOSE'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 96px">
                  <mat-select multiple formControlName="{{field.name}}"
                              [value]="pinFormArray.at(index).value[field.name]">
                    <mat-option *ngFor="let option of field.domain.codedValues" [value]="option.code">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <!-- drop down select -->
              <ng-container *ngIf="field.name==='LEVEL_OF_STUDY' || field.name==='TRAFFIC_CRITICAL' ||
              field.name==='INTERCHANGE_REQUIRED' || field.name==='OUTSIDE_SERVICES' || field.name==='PD_COMPLETED_BY'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 160px">
                  <mat-select formControlName="{{field.name}}" [value]="pinFormArray.at(index).value[field.name]">
                    <mat-option *ngFor="let option of field.domain.codedValues" [value]="option.code">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <!-- drop down select -->
              <ng-container *ngIf="field.name==='ROWNEED' || field.name==='ROWRELOCATION' || field.name==='TANDE'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 160px">
                  <mat-select formControlName="{{field.name}}" [value]="pinFormArray.at(index).value[field.name]">
                    <mat-option *ngFor="let option of field.domain.codedValues" [value]="option.code">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <!-- drop down select -->
              <ng-container *ngIf="field.name==='CULTURALSITE' || field.name==='HISTORICALSITE'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 160px">
                  <mat-select formControlName="{{field.name}}" [value]="pinFormArray.at(index).value[field.name]">
                    <mat-option *ngFor="let option of field.domain.codedValues" [value]="option.code">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <!-- drop down select -->
              <ng-container
                *ngIf="field.name==='CONCURRENCEPOINT' || field.name==='LEVIESAFFECTED' || field.name==='SURVEY_REQUIRED'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 160px">
                  <mat-select formControlName="{{field.name}}" [value]="pinFormArray.at(index).value[field.name]">
                    <mat-option *ngFor="let option of field.domain.codedValues" [value]="option.code">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <!-- drop down select -->
              <ng-container *ngIf="field.name==='LEVELOFDOCUMENTATION'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 180px">
                  <mat-select formControlName="{{field.name}}" [value]="pinFormArray.at(index).value[field.name]">
                    <mat-option *ngFor="let option of field.domain.codedValues" [value]="option.code">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <!-- Custom drop down select -->
              <ng-container *ngIf="field.name==='DISTRICT'">
                <mat-form-field appearance="fill" class="slim no-label" style="width: 96px">
                  <mat-select formControlName="{{field.name}}" [value]="pinFormArray.at(index).value[field.name]">
                    <mat-option *ngFor="let option of (fs.DISTRICT.selectOptions | async)" [value]="option.value">
                      {{ option.alias }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </form>
  </ng-container>
  <div *ngIf="isSubmitting" style="padding: 24px">
    <div *ngFor="let res of submissionResults">
      Bridge ID: {{ res.BRIDGE_ID }} &nbsp;&nbsp;&nbsp;
      <ng-container *ngIf="res.progress">{{ res.progress | async }}</ng-container>
    </div>
    <div *ngIf="impactsRunning">
      <br>
      <span><mat-spinner diameter="24"></mat-spinner>Checking / running impacts for selected projects...</span>
    </div>
    <div *ngIf="impactsComplete">
      <br>
      <span style="color: green;">Impact Calculations Complete!</span>
    </div>
    <!--
    <ng-container *ngIf="submissionComplete && pinsWithNEPA.length > 0">
      <br>
      <span>Below is a list of PINs that may require LEB/NEPA review.</span>
      <div *ngFor="let pin of pinsWithNEPA">
        <span style="color: green;">{{ pin }}</span>
      </div>
    </ng-container>
    -->
    <ng-container *ngIf="submissionComplete">
      <br>
      <div style="margin: 16px 0">Submission Complete!</div>
      <button mat-raised-button color="primary" (click)="backAfterSubmission();">Back</button>
    </ng-container>

    <div *ngIf="impactsErrorMessage">
      <br>
      <span style="color: red;">{{impactsErrorMessage}}</span>
    </div>

  </div>
</ng-container>

<div *ngIf="loading | async">
  <mat-spinner diameter="24"></mat-spinner>
</div>
<div *ngIf="!(loading | async)">
  <div style="margin-bottom: 16px;line-height: 24px">
    You have {{roles.length}} security roles.<br>
    Which ones would you like to approve on behalf of?
  </div>
  <div *ngFor="let role of roles">
    <mat-checkbox color="primary" [formControl]="role.fc">
      {{ role.name }}
    </mat-checkbox>
  </div>

  <div style="display:flex">
    <div style="flex-grow:1"></div>
    <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <div style="min-width: 8px;"></div>
    <button mat-raised-button color="primary" (click)="approve()">Approve</button>
  </div>
</div>

import { ProgramType } from '../types/program-type';

export const PROGRAM_TYPE_LOOKUP = {
  '0': ProgramType._NR,
  '1': ProgramType._MI,
  '2': ProgramType._4R,
  '3': ProgramType._SR,
  '4': ProgramType._BR,
  '5': ProgramType._3R,
  '6': ProgramType._OT,
  '7': ProgramType._MB,
  '8': ProgramType._MP,
  '9': ProgramType._EC,
  '10': ProgramType._ADA
};

import { Injectable } from '@angular/core';
import { POLICY_TEMPLATES } from './policy-templates';
import { ApprovalPolicy, ApprovalPolicyTemplate } from './approval-workflow-policy';
import { PROGRAM_TYPE_LOOKUP } from './program-type-lookup';
import { ApprovalPolicyMergeService } from './approval-policy-merge.service';
import { ClonerService } from '../services/cloner.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalPolicyFactory {

  constructor(
    private clonerService: ClonerService,
    private mergeService: ApprovalPolicyMergeService
  ) {
  }

  getFirstApprovers(PIN: string, DISTRICT: number, PROGRAM_TYPES: string) {
    const policy = this.generatePolicy(PIN, DISTRICT, PROGRAM_TYPES);
    const anyAllToBooleanOperator = {
      ANY: ' or ',
      ALL: ' and '
    };
    return policy.stages[0].groups.sort((a, b) => a > b ? 1 : -1).join(anyAllToBooleanOperator[policy.stages[0].operator]);
  }

  generatePolicy(PIN: string, DISTRICT: number, PROGRAM_TYPES: string) {
    if (DISTRICT !== 0 && !DISTRICT) {
      alert(`Scope ${PIN} has no selected district.`);
      return null;
    }

    const fundingTypes: string[] = PROGRAM_TYPES.split(',')
      .map(x => x.trim())
      .filter(x => x.length)
      .map(x => PROGRAM_TYPE_LOOKUP[x]);

    if (!fundingTypes || fundingTypes.length === 0) {
      alert('No funding types were found for ' + PIN);
      return null;
    }

    const policyTemplate = this.getPolicyTemplate(fundingTypes);

    const policy: ApprovalPolicy = {
      stages: policyTemplate.stages,
      fundingType: policyTemplate.fundingType,
      scope_id: PIN
    };

    if (!policy) {
      // alert is already given by getPolicyTemplate() in this case
      return null;
    }

    this.setDistrictNumbers(policy, DISTRICT);
    return policy;
  }

  private setDistrictNumbers(policy: ApprovalPolicy, DISTRICT: number) {
    policy.stages.forEach(stage => {
      stage.groups = stage.groups.map(group => {
        return group.replace('District X', 'District ' + DISTRICT);
      });
    });
  }

  private getPolicyTemplate(fundingTypes: string[]): ApprovalPolicyTemplate {

    const templateLookup: { [fundingType: string]: ApprovalPolicyTemplate } = {};
    POLICY_TEMPLATES.forEach(t => {
      templateLookup[t.fundingType] = t;
    });

    const invalidTypes = [];
    const _templates = fundingTypes.map(t => {
      if (!templateLookup[t]) {
        invalidTypes.push(t);
      } else {
        return templateLookup[t];
      }
    });

    if (invalidTypes.length) {
      alert(invalidTypes.length > 1
        ? `No policy templates found for funding types: ${invalidTypes.join(',')}`
        : `No policy template found for ${invalidTypes[0]}`);
      return null;
    }

    // deep copy to prevent modifications to policy from modifying templates
    const templates: ApprovalPolicyTemplate[] = this.clonerService.deepClone<ApprovalPolicyTemplate[]>(_templates);

    return templates.length === 1 ? templates[0] : this.mergeService.mergePolicyTemplates(templates);
  }
}

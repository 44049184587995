import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found.component';
import { AuthGuardService as ArcGISAuthGuard } from './services/security/auth-guard.service';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./feature-modules/dashboard/dashboard.module')
        .then((m) => m.DashboardModule),
    canActivate: [ArcGISAuthGuard]
  },
  {
    path: '',
    loadChildren: () =>
      import('./feature-modules/home/home.module')
        .then((m) => m.HomeModule),
    canActivate: [ArcGISAuthGuard]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

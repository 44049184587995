import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { AzureAD } from 'src/init/azure-ad';
import { UserInfoService } from '../services/user-info.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { FormSubject } from 'src/app/types/form-subject';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent implements OnDestroy {
  loading = new BehaviorSubject<boolean>(false);
  showSave = new BehaviorSubject<boolean>(false);
  displayName = AzureAD.user.fullName;
  email = AzureAD.user.email;
  isCommission = environment.isCommission;
  private subs: Subscription[] = [];

  roles = this.userInfoService.userInfo.pipe(
    map(userInfo => {
      return userInfo.roles.sort((a, b) => (a > b ? 1 : -1));
    })
  );

  emailGroups = ['PSS Submittal Emails'];

  emailGroupsFs = (() => {
    const fs = new FormSubject<string[]>();
    this.subs.push(
      this.userInfoService.userInfo.subscribe(ui => {
        fs.setValue(ui.emailGroups);
      })
    );
    this.subs.push(
      combineLatest([fs.valueStream, this.userInfoService.userInfo]).subscribe(([x, y]) => {
        const X = x.sort((a, b) => (a > b ? 1 : -1));
        const Y = y.emailGroups.sort((a, b) => (a > b ? 1 : -1));
        const same = JSON.stringify(X) === JSON.stringify(Y);
        this.showSave.next(!same);
      })
    );
    return fs;
  })();

  constructor(private userInfoService: UserInfoService) {
  }

  discardEmailGroupChanges() {
    this.emailGroupsFs.setValue(this.userInfoService.userInfo.value.emailGroups);
  }

  async saveEmailGroupChanges() {
    this.loading.next(true);
    this.emailGroupsFs.disable();
    try {
      const groups = this.emailGroupsFs.value;
      await this.userInfoService.putEmailGroups(groups);
      await this.userInfoService.populateUserInfo();
    } catch (err) {
      this.loading.next(false);
      this.emailGroupsFs.enable();
      throw new Error(err);
    }
    this.loading.next(false);
    this.emailGroupsFs.enable();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  signOut() {
    AzureAD.signOut();
  }
}

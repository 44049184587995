import { Component, OnInit } from '@angular/core';
import { HashingService } from './services/hash/hashing.service';
import { LrsLayerService } from './services/layer/lrs-layer.service';
import { PriorityRxLayersService } from './services/layer/priority-rx-layers.service';
import { LrsService } from './services/layer/lrs.service';
import { PinsSelectionLayerService } from './services/layer/pins-selection-layer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private hashingService: HashingService, // included to cause instantiation on app load
    private pinSelectionLayerService: PinsSelectionLayerService, // included to cause instantiation on app load
    private lrsService: LrsService, // included to cause instantiation on app load
    private lrsLayerService: LrsLayerService, // included to cause instantiation on app load
    private priorityRxLayersService: PriorityRxLayersService, // included to cause instantiation on app load
  ) {
  }

  ngOnInit() {
    this.hashingService.init();
    // this is a hack to get the map to load
    // the linter was trying to get rid of these services because they were not used. the linter is dumb.
    const _ = this.pinSelectionLayerService;
    const __ = this.lrsService;
    const ___ = this.lrsLayerService;
    const ____ = this.priorityRxLayersService;
    [_, __, ___, ____].forEach(x => x);
  }

}

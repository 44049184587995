import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { BridgeRxLayerService } from 'src/app/services/layer/bridge-rx-layer.service';
import { FieldValueFormatService } from '../../services/field-value-format.service';
import { DetailsPaneFormSubjects } from '../../details-pane/details-pane-form-subjects';
import { WorkMatrixService } from 'src/app/services/work-matrix.service';
import { FormSubject } from 'src/app/types/form-subject';
import { ScopeSaveDeleteService } from 'src/app/services/scope-save-delete/scope-save-delete.service';
import { RxLayer } from 'src/app/rx-layer/rx-layer';
import { PinsRxLayerService } from 'src/app/services/layer/pins-rx-layer.service';
import { OnDemandFMEService } from 'src/app/services/on-demand-fme.service';
import { ImpactAuditService } from 'src/app/services/impact-audit.service';
import { ApprovalReportService } from 'src/app/scope-approval/approval-report-service';
import { ApprovalReport } from 'src/app/scope-approval/approval-workflow-policy';
import { APP_ROLES } from 'src/app/services/user-info.service';
import { BridgeConversion, BridgeConversionService } from 'src/app/services/bridge-conversion.service';
import { DialogNoticeService } from 'src/app/services/dialog-notice.service';
import { BasicDialogComponent } from '../basic-dialog/basic-dialog.component';
import { SubmitApproveEmailService } from 'src/app/scope-approval/submit-approve-email.service';

// import { SharedModule } from 'src/app/modules/shared-module';

const BR_DISPLAY_COLS = [
  'BRIDGE_ID',
  'PROGRAM_FUNDING',
  'DISTRICT',
  'PROJECT_PURPOSE',
  'DESCRIPTION',
  'WORK_GROUP',
  'WORK_TYPE',
  'LEVEL_OF_STUDY',
  'TRAFFIC_CRITICAL',
  'PD_COMPLETED_BY',
  'INTERCHANGE_REQUIRED',
  'DESIRED_FISCAL_YEAR',
  'BUDGET_MIN',
  'BUDGET_MAX',
  'OUTSIDE_SERVICES',
  'ROWNEED',
  'ROWRELOCATION',
  'TANDE',
  'CULTURALSITE',
  'HISTORICALSITE',
  'LEVIESAFFECTED',
  'CONCURRENCEPOINT',
  'LEVELOFDOCUMENTATION',
  'SURVEY_REQUIRED'
];

// This is not being used, a work in progress - see disableFields()
const DISABLED_COLS = [
  'PROGRAM_FUNDING',
  'PROJECT_PURPOSE'
];

@Component({
  templateUrl: './bridge-dialog.component.html',
  styleUrls: ['./bridge-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BridgeDialogComponent implements OnInit, OnDestroy {

  isEditingPINs = false;
  isSubmitting = false;
  submissionResults = [];
  submissionComplete = false;
  selectedPins: Array<any> = [];
  fields: __esri.Field[];
  displayedColumns: Array<string> = BR_DISPLAY_COLS;
  pinForm: FormGroup;
  rxLayer: RxLayer;
  maxNumPinsForSubmission = 25; // limits the number of PINs that can be batched..because these will be submitted to an on-demand FME job
  maxSelectionReached = false;
  maxSelectionErrMessage = '';

  impactsRunning = false;
  impactsComplete = false;
  impactsErrorMessage = null;
  impactsFmeStream: Subject<string>;
  pinAttrsPolicyCheck: any[] = [];
  pinsWithNEPA = [];
  bridgesConverted: BridgeConversion[] = [];
  private subs: Subscription[] = [];

  get pinFormArray() {
    return this.pinForm.get('pinFormArray') as FormArray;
  }

  constructor(
    public dialogRef: MatDialogRef<BridgeDialogComponent>,
    public bridgeRxLayerService: BridgeRxLayerService,
    private fieldValueFormatService: FieldValueFormatService,
    public fs: DetailsPaneFormSubjects,
    public workMatrixService: WorkMatrixService,
    private fb: FormBuilder,
    private scopeSaveDeleteService: ScopeSaveDeleteService,
    private cdr: ChangeDetectorRef,
    private pinsRxLayerService: PinsRxLayerService,
    private submitApproveEmailService: SubmitApproveEmailService,
    private onDemandFme: OnDemandFMEService,
    private impactAuditService: ImpactAuditService,
    private approvalReportService: ApprovalReportService,
    private bridgeConversionService: BridgeConversionService,
    private dialogNoticeService: DialogNoticeService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.pinForm = this.fb.group({
      pinFormArray: this.fb.array([])
    });
    this.subs.push(
      this.bridgeRxLayerService.layer$.subscribe(async (l) => {
        if (l) {
          this.rxLayer = l;
          await this.configFields();
          this.cdr.markForCheck();
          // subscribe to record selection..and check for max number of pins selected
          this.rxLayer.tableData.selection.changed.subscribe(_ => {
            // check for max number of pins to submit
            if (this.rxLayer.tableData.selection.selected.length > this.maxNumPinsForSubmission) {
              this.maxSelectionReached = true;
              this.maxSelectionErrMessage = `You selected ${this.rxLayer.tableData.selection.selected.length} for your batch.  You cannot submit more than ${this.maxNumPinsForSubmission} PINs within a single batch.`;
            } else {
              this.maxSelectionReached = false;
              this.maxSelectionErrMessage = '';
            }
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private async configFields() {
    this.fields = (await this.rxLayer.visibleFieldsPromise).slice(0);
    this.fields.push(<__esri.Field>{
      name: 'WORK_TYPE',
      type: 'string',
      alias: 'Work Type',
      domain: null,
      editable: true,
      nullable: true,
      length: 500
    });
    this.fields.find(f => f.name === 'DESCRIPTION').alias = 'Description';
    /*
    // setup domains for just BR/MB projects
    const programFundingDomain = [
      {
        name: 'BR',
        code: 4
      },
      {
        name: 'MB',
        code: 7
      }
    ];
    (this.fields.find(f => f.name === 'PROGRAM_FUNDING').domain as __esri.CodedValueDomain).codedValues = programFundingDomain;
    const projectPurposeDomain = [
      {
        name: 'Bridge',
        code: 1
      },
    ];
    (this.fields.find(f => f.name === 'PROJECT_PURPOSE').domain as __esri.CodedValueDomain).codedValues = projectPurposeDomain;
    */
  }


  formatValue(field: __esri.Field, val: any) {
    return this.fieldValueFormatService.formatValue(val, field);
  }

  emptyFormArray() {
    for (const i of this.pinFormArray.value) {
      this.pinFormArray.removeAt(i);
    }
  }

  createFormGroup(pin) {
    const formGroup: any = {};
    this.fields.map(field => {
      let value;
      switch (field.name) {
      case 'WORK_GROUP':
        value = pin[field.name]
          .split(',').map(x => x.trim())
          .filter((item: string) => item.length)
          .map((str: string) => Number(str)); // split comma separted values into Array<numbers>
        break;
      case 'PROGRAM_FUNDING':
        value = [pin[field.name] ? pin[field.name] : 4]; // default to 4
        break;
      case 'PROJECT_PURPOSE':
        value = [pin[field.name] ? pin[field.name] : 1]; // default to 1
        break;
      default:
        value = pin[field.name];
      }
      formGroup[field.name] = new FormSubject(value);
    });
    formGroup['WORK_TYPE'] = new FormSubject([]);
    return formGroup;
  }

  async toggleEdit() {
    this.emptyFormArray();
    if (this.isEditingPINs) {  // moving back from PIN editing page to PIN selection page
      this.isEditingPINs = false;
    } else { // moving from PIN selection page to PIN editing page
      this.isEditingPINs = true;
      const selectedIDs = new Set(this.rxLayer.tableData.selection.selected);
      this.selectedPins = this.rxLayer.tableData.tableRows.value.filter(row => selectedIDs.has(row.OBJECTID));
      this.selectedPins.forEach(pin => {
        const formGroup = this.createFormGroup(pin);
        this.workMatrixService.linkWorkFormSubjects(formGroup.WORK_TYPE, formGroup.WORK_GROUP);
        this.pinFormArray.push(new FormGroup(formGroup));
      });
    }
  }

  async publishPins() {
    const keyMap = {
      PROGRAM_FUNDING: 'PROGRAM_FUNDING',
      DISTRICT: 'DISTRICT',
      PROJECT_PURPOSE: 'PROJECT_PURPOSE',
      DESCRIPTION: 'DESCRIPTION',
      WORK_GROUP: 'WORK_GROUPS',
      LEVEL_OF_STUDY: 'LEVELSTUDYREQUIRED',
      TRAFFIC_CRITICAL: 'TRAFFICCRITICAL',
      INTERCHANGE_REQUIRED: 'INTERCHANGEREQUIRED',
      PD_COMPLETED_BY: 'PD_COMPLETED_BY',
      DESIRED_FISCAL_YEAR: 'DESIREDFISCALYEAR',
      BUDGET_MIN: 'BUDGETRANGEMIN',
      BUDGET_MAX: 'BUDGETRANGEMAX',
      OUTSIDE_SERVICES: 'OUTSIDESERVICES',
      ROADWAY: 'READABLE_PRIMARY_ROUTE',
      PRIMARY_ROUTE: 'PRIMARY_ROUTE',
      BRIDGE_ID: 'BRIDGE_ID',
      PRIORITY_SIIMS: 'PRIORITY_SIIMS',
      ROW_NEED: 'ROWNEED',
      PRIORITY_DATE: 'PRIORITY_DATE',
      TO_MEASURE: 'TO_MEASURE',
      FROM_MEASURE: 'FROM_MEASURE',
      WORK_TYPE: 'WORK_TYPES',
      ROWNEED: 'ROWNEED',
      ROWRELOCATION: 'ROWRELOCATION',
      TANDE: 'TANDE',
      CULTURALSITE: 'CULTURALSITE',
      HISTORICALSITE: 'HISTORICALSITE',
      LEVIESAFFECTED: 'LEVIESAFFECTED',
      CONCURRENCEPOINT: 'CONCURRENCEPOINT',
      LEVELOFDOCUMENTATION: 'LEVELOFDOCUMENTATION',
      SURVEY_REQUIRED: 'SURVEY_REQUIRED'
    };

    const pinsAttrs = this.pinFormArray.value.map(newPIN => {
      // push UNIQUE ID, will be used to query/update later
      this.bridgesConverted.push({
        UNIQUE_ID: newPIN.UNIQUE_ID,
        PROJECT_SCOPE_ID: null,
        OBJECTID: null
      });
      const renamedFields = {};
      Object.keys(newPIN).forEach(key => {
        renamedFields[keyMap[key]] = newPIN[key];
      });
      return renamedFields;
    });

    if (pinsAttrs.filter(x => this.scopeSaveDeleteService.getArrayFields(x).progFunding.length < 1).length > 0) {
      this.dialogNoticeService.alert({ message: 'At least one Program Funding type must be selected for each scope.' });
      return;
    }
    this.isEditingPINs = false;
    this.isSubmitting = true;
    this.submissionResults = pinsAttrs.map(x => ({ BRIDGE_ID: x.BRIDGE_ID }));
    this.cdr.markForCheck();
    const pins = [];
    for (let i = 0; i < pinsAttrs.length; i++) {
      const entry = this.submissionResults.find(x => x.BRIDGE_ID === pinsAttrs[i].BRIDGE_ID);
      const s = this.scopeSaveDeleteService.save({
        attributes: pinsAttrs[i],
        writeGeometry: true
      });
      if (!s.abort) {
        entry.progress = s.progress;
        this.cdr.markForCheck();
        entry.PIN = await s.PIN;
        pins.push(entry.PIN);
        this.cdr.markForCheck();
      }
    }
    // send initial email for new scope
    try {
      const pinsString = pins.map(x => `'${x}'`).join(',');
      const newAttributesForPins = await this.pinsRxLayerService.getAttributesForPins(`PIN in (${pinsString})`);
      await this.submitApproveEmailService.sendInitialEmail(newAttributesForPins, newAttributesForPins[0].USERNAME);
      // set pin attrs data to use for a policy check later on
      this.pinAttrsPolicyCheck = newAttributesForPins;
    } catch (error) {
      console.log('Error sending initial email:' + error);
    }
    await this.saveConvertedBridges(pins);
    // check impacts / run if needed / complete submission
    await this.checkImpacts(pins, pinsAttrs);
  }

  private async saveConvertedBridges(pins: string[]) {
    // build list of converted pins with bridges unique id
    for (let i = 0; i < pins.length; i++) {
      this.bridgesConverted[i].PROJECT_SCOPE_ID = pins[i];
    }
    await this.bridgeConversionService.saveBridgeConversions(this.bridgesConverted);
  }

  private async checkImpacts(pins: string[], pinsAttrs: any[]) {
    this.impactsRunning = true; // set impacts running to display text / spinner
    // query audit service to check if pins have had impacts calculated
    const pinsWithImpact = await this.impactAuditService.getImpactAudits(pins);
    let pinsToRun = pins;
    if (pinsWithImpact.length > 0) {
      pinsToRun = pins.filter(pin => {
        if (pinsWithImpact.indexOf(pin) === -1) {
          return pin;
        }
      });
    }
    // call on-demand FME job to run LEB-NEPA impacts
    if (pinsToRun.length > 0) {
      await this.runImpacts(pinsToRun);
    } else {
      this.completeSubmission();
    }
  }

  private async getPinsWithNEPA() {
    // empty the array
    const pins = [];
    for (const attrs of this.pinAttrsPolicyCheck) {
      const report: ApprovalReport = await this.approvalReportService.getReportBasic(attrs);
      const hasOleNEPA = this.reportHasOleNEPA(report);
      if (hasOleNEPA) {
        pins.push(attrs.PIN);
      }
    }
    return pins;
  }

  private reportHasOleNEPA(report: ApprovalReport): boolean {
    let hasNEPA = false;
    report.stages.forEach(stage => {
      stage.groups.forEach(group => {
        if (group.name === APP_ROLES.LEB_NEPA_REVIEW) {
          hasNEPA = true;
        }
      });
    });
    return hasNEPA;
  }

  private async completeSubmission() {
    this.impactsRunning = false;
    this.impactsComplete = true;
    this.submissionComplete = true;
    this.pinsWithNEPA = await this.getPinsWithNEPA();
    setTimeout(async () => {
      this.impactsFmeStream = null;
      this.cdr.markForCheck();
    }, 500);
  }

  private submissionError() {
    this.impactsFmeStream = null;
    this.impactsErrorMessage = 'ERROR running impacts...LEB-NEPA review still might be needed.';
    this.impactsRunning = false;
    this.submissionComplete = true;
    this.cdr.markForCheck();
  }

  private async runImpacts(pins: any[]) {
    this.impactsFmeStream = await this.onDemandFme.runImpactsNEPA(pins);
    this.cdr.markForCheck();
    const sub = this.impactsFmeStream.subscribe(status => {
      if (status === 'SUCCESS') {
        this.completeSubmission();
        sub.unsubscribe();
      }
      if (status === 'ERROR') {
        this.submissionError();
        sub.unsubscribe();
      }
    });
  }

  backAfterSubmission() {
    this.isSubmitting = false;
    this.submissionComplete = false;
    this.impactsComplete = false;
    this.impactsRunning = false;
    this.rxLayer.tableData.refresh();
    this.submissionResults = [];
    this.pinsWithNEPA = [];
    this.cdr.markForCheck();
  }

  closeDialog() {
    const { dirty } = this.pinFormArray;
    if (dirty) {
      const dialogRef = this.dialog.open(BasicDialogComponent, {
        data: {
          title: 'Close Bridge Table?',
          message: 'Are you sure you want to close the window? Any unsaved work will be lost.',
          width: '400px',
          height: '200px'
        }
      });
      dialogRef.afterClosed().subscribe(continueClose => {
        if (continueClose) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
}

/*
@NgModule({
  declarations: [BridgeDialogComponent],
  imports: [SharedModule],
})
export class BridgeDialogModule {}
*/

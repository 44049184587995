import { Injectable } from '@angular/core';

// ArGIS Core
import Polyline from '@arcgis/core/geometry/Polyline';

import { ENDPOINT_CONFIG } from '../dynamic-endpoint/endpoint.service.config';
import { FormDataService } from '../form-data.service';
import { EndpointService } from '../dynamic-endpoint/endpoint.service';
import { esriRequest } from 'src/esri/request';
import { GeometryEngineService } from 'src/app/services/geometry-engine.service';
import { RAMS_EFFECTIVE_DATE_CLAUSE } from 'src/app/services/rams-effective-date-clause';
import { RouteAndMeasure } from 'src/app/services/layer/route-and-measure.service';
import { GuidService } from 'src/app/services/guid.service';
import { ConcurrencyService } from 'src/app/services/layer/concurrency.service';
import { environment } from 'src/environments/environment';
import Point from '@arcgis/core/geometry/Point';
import { RouteRefPost } from '../ref-post.service';
import { RefPostEntryMode } from 'src/app/dialogs/ref-post-entry-dialog/ref-post-entry-dialog.component';
import { ClonerService } from '../cloner.service';
import { RxJsLoggingLevel } from 'src/app/functions/rxjs-debug';

export const LRS_SPATIAL_REF_WKID = 3857;

export interface LrsPointGeometry {
  x: number;
  y: number;
  z: number;
}

export interface LrsResult {
  routeId: string;
  measure: number;
  geometryType: string;
  geometry: LrsPointGeometry;
}

export interface LrsResults {
  results: LrsResult[];
  status: string;
}

@Injectable({
  providedIn: 'root'
})

export class LrsService {

  constructor(
    private clonerService: ClonerService,
    private formDataService: FormDataService,
    private endpointService: EndpointService,
    private geometryEngineService: GeometryEngineService,
    private guidService: GuidService,
    private concurrencyService: ConcurrencyService,
  ) {
  }

  async geometryToMeasure(point: __esri.Point) {
    const layerUrl = await this.getLayerURL();
    const body = this.formDataService.toFormData({
      locations: [{
        geometry: {
          x: point.x,
          y: point.y
        }
      }],
      tolerance: 100,
      inSR: LRS_SPATIAL_REF_WKID,
      f: 'json'
    });
    const res = await esriRequest(layerUrl + '/geometryToMeasure', {
      body,
      responseType: 'json',
      method: 'post'
    });
    return res.data.locations[0].results;
  }

  async measureToGeometry(ROUTE_ID: string, FROM_MEASURE: number, TO_MEASURE: number) {
    const layerUrl = await this.getLayerURL();
    const body = this.formDataService.toFormData({
      locations: [{
        routeId: ROUTE_ID,
        fromMeasure: FROM_MEASURE,
        toMeasure: TO_MEASURE
      }],
      outSR: LRS_SPATIAL_REF_WKID,
      f: 'json'
    });
    const res = await esriRequest(layerUrl + '/measureToGeometry', {
      body,
      responseType: 'json',
      method: 'post'
    });
    if (!res.data.locations.length) {
      alert(`Lrs endpoint return 0 locations for ROUTE_ID: ${ROUTE_ID}, FROM_MEASURE: ${FROM_MEASURE}, TO_MEASURE: ${TO_MEASURE}`);
    }
    return res.data.locations[0].geometry as __esri.Polyline;
  }

  async measureToVertex(ROUTE_ID: string, FROM_MEASURE: number, TO_MEASURE: number) {
    const layerUrl = await this.getLayerURL();
    const body = this.formDataService.toFormData({
      locations: [{
        routeId: ROUTE_ID,
        fromMeasure: FROM_MEASURE,
        toMeasure: TO_MEASURE
      }],
      outSR: LRS_SPATIAL_REF_WKID,
      f: 'json'
    });
    const res = await esriRequest(layerUrl + '/measureToGeometry', {
      body,
      responseType: 'json',
      method: 'post'
    });
    if (!res.data.locations.length) {
      alert(`Lrs endpoint return 0 locations for ROUTE_ID: ${ROUTE_ID}, FROM_MEASURE: ${FROM_MEASURE}, TO_MEASURE: ${TO_MEASURE}`);
    }
    // get ends
    return this.getGeometryEnds(res)[0];
  }

  async geometryPolyToMeasure(polygon: __esri.Polygon): Promise<LrsResults[]> {
    const layerUrl = await this.getLayerURL();
    const geom = await this.getRouteEndpointsIntersect(polygon);
    if (geom) {
      const locations = this.pointsToLrsLocations(geom);
      const body = this.formDataService.toFormData({
        locations: locations,
        tolerance: 1,
        inSR: polygon.spatialReference,
        f: 'json'
      });
      const res = await esriRequest(layerUrl + '/geometryToMeasure', {
        body,
        responseType: 'json',
        method: 'post'
      });
      return res.data.locations as LrsResults[];
    } else {
      return null;
    }
  }

  async getRefPostOffset(slice: RouteAndMeasure, selectedRefPost: RouteRefPost, mode: RefPostEntryMode) {
    const updatedSlice = this.clonerService.deepClone<RouteAndMeasure>(slice);
    switch (mode) {
    case 'from':
      updatedSlice.FROM_MEASURE = selectedRefPost.MEASURE_PLUS_OFFSET;
      break;
    case 'to':
      updatedSlice.TO_MEASURE = selectedRefPost.MEASURE_PLUS_OFFSET;
      break;
    default:
      console.error('Unhandled mode ->', mode);
    }
    // update geometry
    const geom = await this.measureToGeometry(updatedSlice.ROUTE_ID, updatedSlice.FROM_MEASURE, updatedSlice.TO_MEASURE);
    updatedSlice.PATHS = geom.paths;
    return updatedSlice;
  }

  // also runs a currency check
  async getDominantRouteAndMeasures(lrsResults: LrsResults[]): Promise<RouteAndMeasure[]> {
    const results: RouteAndMeasure[] = [];
    // push all values into a flat array
    const allValues: LrsResult[] = [];
    lrsResults.forEach(lr => {
      lr.results.forEach(r => {
        allValues.push(r);
      });
    });

    // sort by route id
    allValues.sort((a, b) => (a.routeId > b.routeId) ? 1 : ((b.routeId > a.routeId) ? -1 : 0));
    // console.log(`All Route Values: ${JSON.stringify(allValues)}`);

    // generate a unique list of route ids
    const uniqueRouteIds = allValues.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj['routeId']).indexOf(obj['routeId']) === pos;
    });

    // generate RouteAndMeasure for each unique route id
    for (let i = 0; i < uniqueRouteIds.length; i++) {
      const routeId = uniqueRouteIds[i].routeId;
      // filter by routeId, get matches, sorted by measure
      const matchingRoutes = allValues.filter(m => m.routeId === routeId).sort((a, b) => a.measure < b.measure ? -1 : 1);
      // get from measure
      const fromMeasure = matchingRoutes[0].measure;
      // get last measure...it's possible we could get more than two results..need to check into this
      const toMeasure = matchingRoutes[matchingRoutes.length - 1].measure;
      // create RouteAndMeasure
      const rm: RouteAndMeasure = {
        TMP_GUID: this.guidService.createGuid(),
        ROUTE_ID: routeId,
        FROM_MEASURE: fromMeasure,
        TO_MEASURE: toMeasure,
        PATHS: [],
        WKID: LRS_SPATIAL_REF_WKID,
        SELECTED: false,
        LOADING: false,
        IS_PRIMARY: false,
        JUST_CREATED: true,
        INCLUDED: false
      };
      // check for dominate route
      const dominantRoutes = await this.concurrencyService.getDominantRoutes(rm);
      if (dominantRoutes.length > 1 || (dominantRoutes.length === 1 && dominantRoutes[0].ROUTE_ID !== rm.ROUTE_ID)) {
        const newRoutes = dominantRoutes.map(x => ({
          TMP_GUID: this.guidService.createGuid(),
          ROUTE_ID: x.ROUTE_ID,
          FROM_MEASURE: x.FROM_MEASURE,
          TO_MEASURE: x.TO_MEASURE,
          WKID: LRS_SPATIAL_REF_WKID,
          PATHS: [],
          SELECTED: false,
          LOADING: false,
          IS_PRIMARY: false,
          JUST_CREATED: true,
          INCLUDED: false
        }));
        // check if we already have a matching route with different measures
        const unique = results.filter(m => {
          const newRoute = newRoutes[0];
          const diffMeasures = this.areMeasuresDifferent(m.FROM_MEASURE, m.TO_MEASURE, newRoute.FROM_MEASURE, newRoute.TO_MEASURE);
          if (m.ROUTE_ID === newRoute.ROUTE_ID && diffMeasures) {
            return m;
          }
        });
        // if we have matching route ids but unique measures, push the new routes results..
        if (unique.length > 0) {
          results.push(newRoutes[0]);
        }
      } else {
        // some results will include the same starting / ending depending on how the routes got selected, we don't want them
        if (rm.FROM_MEASURE !== rm.TO_MEASURE) {
          results.push(rm);
        }
      }
      // this a check on the result data...might not matter but should be looked into
      if (matchingRoutes.length > 2) {
        // some routes will return two results, each measure is very very close but slightly different.  Is this related to dominate routes?
        if (environment.isLocalHost && environment.loggingLevel === RxJsLoggingLevel.DEBUG) {
          console.log(`TO DO - Greater than 2 measures for route: ${routeId}, ${matchingRoutes.length}...need to handle.`);
        }
      }
    }

    // add paths to final results
    for (let i = 0; i < results.length; i++) {
      const rm = results[i];
      const geoRes = await this.measureToGeometry(rm.ROUTE_ID, rm.FROM_MEASURE, rm.TO_MEASURE);
      if (geoRes) {
        rm.PATHS = geoRes.paths;
      }
    }

    return results;
  }

  private getGeometryEnds(response: __esri.RequestResponse): __esri.Point[] {
    const ends = [];
    if (response.data.locations.length > 0) {
      const startGeom = response.data.locations[0].geometry.paths[0][0];
      // nasty accessor code for getting to nested array's
      // calc indexes
      const locLastIdx = response.data.locations.length - 1;
      const pathLastIdx = response.data.locations[locLastIdx].geometry.paths.length - 1;
      const pointLastIdx = response.data.locations[locLastIdx].geometry.paths[pathLastIdx].length - 1;
      // get last point using indexes
      const endGeom = response.data.locations[locLastIdx].geometry.paths[pathLastIdx][pointLastIdx];
      // push points
      ends.push(
        // start
        new Point({
          spatialReference: response.data.spatialReference,
          x: startGeom[0],
          y: startGeom[1]
        }),
        //end
        new Point({
          spatialReference: response.data.spatialReference,
          x: endGeom[0],
          y: endGeom[1]
        })
      );
    }
    return ends;
  }

  // rounds measure values and compares
  private areMeasuresDifferent(fromA, toA, fromB, toB): boolean {
    const multiplier = 100000;
    const fromARnd = Math.round(fromA * multiplier) / multiplier;
    const toARnd = Math.round(toA * multiplier) / multiplier;
    const fromBRnd = Math.round(fromB * multiplier) / multiplier;
    const toBRnd = Math.round(toB * multiplier) / multiplier;
    if (fromARnd !== fromBRnd || toARnd !== toBRnd) {
      return true;
    } else {
      return false;
    }
  }

  // convert array of points to locations for LRS
  private pointsToLrsLocations(points: __esri.Point[]) {
    const locations = [];
    // push geometry to locations
    points.forEach(g => {
      locations.push({
        geometry: {
          x: g.x,
          y: g.y
        }
      });
    });
    return locations;
  }

  // convert input polygon to intersecting route location endpoints
  private async getRouteEndpointsIntersect(polygon: __esri.Polygon) {
    const body = this.formDataService.toFormData({
      where: RAMS_EFFECTIVE_DATE_CLAUSE,
      outFields: ['ROUTE_ID'],
      geometry: JSON.stringify(polygon),
      geometryType: 'esriGeometryPolygon',
      spatialRel: 'esriSpatialRelIntersects',
      inSR: polygon.spatialReference,
      returnGeometry: true,
      f: 'json'
    });
    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.RAMS_PUBLIC_LRS);
    const res = await esriRequest(urls.IOWA_LRS_NETWORK + '/query', {
      body,
      responseType: 'json',
      method: 'post'
    });
    // handle routes / get their endpoints
    if (res.data.features.length > 0) {
      const polylines: [__esri.Polyline] = res.data.features.map(g => {
        const polyline = new Polyline({
          paths: g.geometry.paths,
          spatialReference: polygon.spatialReference,
        });
        return polyline;
      });
      const endPoints = await this.geometryEngineService.getEndpointsByClip(polylines, polygon.extent);
      return endPoints;
    } else {
      console.error(`Unable to get any intersecting routes: ${res}`);
      return null;
    }
  }


  private async getLayerURL() {
    // for testing RAMS in localhost
    // const ids = !IS_LOCAL_HOST_ENVIRONMENT ? await this.endpointService.getLayerIds(ENDPOINT_CONFIG.RAMS_PUBLIC_LRS) : await this.endpointService.getLayerIds(ENDPOINT_CONFIG.RAMS_PUBLIC_LRS_TEST);
    // const baseUrl = !IS_LOCAL_HOST_ENVIRONMENT ? ENDPOINT_CONFIG.RAMS_PUBLIC_LRS.url : ENDPOINT_CONFIG.RAMS_PUBLIC_LRS_TEST.url;
    const exts = '/exts/LRSServer/networkLayers/';
    const ids = await this.endpointService.getLayerIds(ENDPOINT_CONFIG.RAMS_PUBLIC_LRS);
    const baseUrl = ENDPOINT_CONFIG.RAMS_PUBLIC_LRS.url;
    const layerUrl = `${baseUrl}${exts}${ids.IOWA_LRS_NETWORK}`;
    return layerUrl;
  }

}

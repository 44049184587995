export const HEATMAP_RENDERER_BLUE = {
  type: 'heatmap',
  blurRadius: 8.764705882352942,
  colorStops: [
    { color: 'rgba(133, 193, 200, 0)', ratio: 0 },
    { color: 'rgba(133, 193, 200, 0)', ratio: 0.01 },
    { color: 'rgba(133, 193, 200, 1)', ratio: 0.01 },
    { color: 'rgba(133, 193, 200, 1)', ratio: 0.01 },
    { color: 'rgba(144, 161, 190, 1)', ratio: 0.08534829166666666 },
    { color: 'rgba(156, 129, 132, 1)', ratio: 0.16069658333333334 },
    { color: 'rgba(167, 97, 170, 1)', ratio: 0.23604487500000002 },
    { color: 'rgba(175, 73, 128, 1)', ratio: 0.31139316666666667 },
    { color: 'rgba(184, 48, 85, 1)', ratio: 0.38674145833333334 },
    { color: 'rgba(192, 24, 42, 1)', ratio: 0.46208975 },
    { color: 'rgba(200, 0, 0, 1)', ratio: 0.5374380416666668 },
    { color: 'rgba(211, 51, 0, 1)', ratio: 0.6127863333333333 },
    { color: 'rgba(222, 102, 0, 1)', ratio: 0.688134625 },
    { color: 'rgba(233, 153, 0, 1)', ratio: 0.7634829166666667 },
    { color: 'rgba(244, 204, 0, 1)', ratio: 0.8388312083333334 },
    { color: 'rgba(255, 255, 0, 1)', ratio: 0.9141795 }
  ],
  maxPixelIntensity: 46.86112803769384,
  minPixelIntensity: 0
};



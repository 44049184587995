import { DomainValueCondition, ImpactMinCondition } from '../approval-workflow-policy';

// If ANY of these conditions are met, the given OLE_NEPA approval stage will be required.
export const STANDARD_LEB_NEPA_CONDITIONS = [
  <DomainValueCondition>{
    type: 'domain-value',
    attributeName: 'LEVIESAFFECTED',
    passingValues: [1] // Yes
  },
  <DomainValueCondition>{
    type: 'domain-value',
    attributeName: 'ROWNEED',
    passingValues: [3, 4] // 5-10 Acres or 10+ Acres
  },
  <ImpactMinCondition>{
    type: 'impact-min',
    layerName: 'Environmental Hotspots',
    minCount: 1
  },
  <ImpactMinCondition>{
    type: 'impact-min',
    layerName: 'Wildlife Refuges',
    minCount: 1
  },
  <ImpactMinCondition>{
    type: 'impact-min',
    layerName: 'Conservation & Rec Lands',
    minCount: 1
  }
];

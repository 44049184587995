import { POLICY_TEMPLATE_3R } from './3R';
import { POLICY_TEMPLATE_4R } from './4R';
import { POLICY_TEMPLATE_BR } from './BR';
import { POLICY_TEMPLATE_EC } from './EC';
import { POLICY_TEMPLATE_MI } from './MI';
import { POLICY_TEMPLATE_NR } from './NR';
import { POLICY_TEMPLATE_OT } from './OT';
import { POLICY_TEMPLATE_SR } from './SR';
import { POLICY_TEMPLATE_MB } from './MB';
import { POLICY_TEMPLATE_MP } from './MP';
import { POLICY_TEMPLATE_RAIL } from './RAIL';
import { POLICY_TEMPLATE_ADA } from './ADA';

export const POLICY_TEMPLATES = [
  POLICY_TEMPLATE_3R,
  POLICY_TEMPLATE_4R,
  POLICY_TEMPLATE_BR,
  POLICY_TEMPLATE_EC,
  POLICY_TEMPLATE_MI,
  POLICY_TEMPLATE_NR,
  POLICY_TEMPLATE_OT,
  POLICY_TEMPLATE_SR,
  POLICY_TEMPLATE_MB,
  POLICY_TEMPLATE_MP,
  POLICY_TEMPLATE_RAIL,
  POLICY_TEMPLATE_ADA
];

import { Injectable } from '@angular/core';
import { filter, skip, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import Polyline from '@arcgis/core/geometry/Polyline';

import { FeaturesAmalgamator } from '../amalgamator/amalgamator.features';
import { FieldsAmalgamator } from '../amalgamator/amalgamator.fields';
import { MultiScanner, MultiScannerResults } from '../amalgamator/multi-scanner';
import { RxLayer } from '../../rx-layer/rx-layer';
import { MapService } from '../map/map.service';
import { getPinsRxLayerConfig } from './pins-rx-layer.config';
import { FormSubject } from 'src/app/types/form-subject';
import { GuidService } from '../guid.service';
import { Filter, FilterType } from '../../rx-layer/filter';
import { SinglePinGetter } from '../amalgamator/single-pin-getter';
import { SaveHashContents } from '../hash/save-hash.models';
import { PinAttributes } from '../amalgamator/pin-attributes';
import { FilterFactoryService } from 'src/app/services/filter-factory.service';
import { ProjectStage } from 'src/app/types/project-stages';
import { ProjectInfoService } from '../project-info.service';
import { AppLayoutService } from '../app-layout.service';
import { HashingService } from '../hash/hashing.service';
import { StorageKeys } from 'src/app/services/hash/storage-keys';
import {
  CIM_LINE_RENDERER,
  SCOPE_PIN_DEFAULT_RENDERER,
  SCOPE_PIN_SHORT_SEGMENTS_RENDERER
} from './pins-rx-layer.service.symbology';
import { SubSink } from 'subsink';
import { LoggingService } from '../logging.service';

export class PinSelection {
  pin: string;
  project_scope_id: string;
  pin_id: number;
  attributesJson: string;
  geometry: Polyline;
}

@Injectable({
  providedIn: 'root'
})
export class PinsRxLayerService {

  private _layer = new BehaviorSubject<RxLayer>(null);
  layer$ = this._layer.asObservable();

  opacityBeforeSelection = new FormSubject<number>(1); // opacity used unless a single pin is selected

  private _selectedPin = new BehaviorSubject<string>(null);
  private spatialFilter: Filter = {
    guid: this.guidService.createGuid(),
    type: FilterType.Spatial,
    field: null,
    searchFieldNames: null,
    value: null,
    filterExpression: null,
    active: false,
  };

  selection = new BehaviorSubject<PinSelection>(null);
  scans$ = new BehaviorSubject<MultiScannerResults>(null);

  private defaultDisplayColumns: string[] = null;
  private defaultFilters: Filter[] = [
    {
      guid: this.guidService.createGuid(),
      type: FilterType.StageSelect,
      field: null,
      value: [
        ProjectStage.scopeScoping,
        ProjectStage.scopeInApproval,
        ProjectStage.scopeApproved,
        ProjectStage.pinProgrammed,
        ProjectStage.pinApproved,
        // ProjectStage.scopePlanScenario // switched off by default
      ],
      active: true
    }, {
      guid: this.guidService.createGuid(),
      type: FilterType.MultiFieldSearch,
      field: null,
      searchFieldNames: ['PIN', 'PROJECT_SCOPE_ID', 'DESCRIPTION'],
      value: '',
      active: true
    }, {
      guid: this.guidService.createGuid(),
      type: FilterType.QuickFilter,
      field: null,
      searchFieldNames: null,
      value: null,
      filterExpression: 'IS_SCOPE_TO_PIN = 1',
      iconName: 'published_with_changes',
      toolTip: 'Scope to PIN Projects',
      active: false,
    },
    {
      guid: this.guidService.createGuid(),
      type: FilterType.QuickFilter,
      field: null,
      searchFieldNames: null,
      value: null,
      filterExpression: 'CONSTRUCTION_EVENT = 1',
      iconName: 'construction',
      toolTip: 'Programmed Projects Currently Under Construction',
      active: false,
    }, {
      guid: this.guidService.createGuid(),
      type: FilterType.QuickFilter,
      field: null,
      searchFieldNames: null,
      value: null,
      filterExpression: 'HIGHWAY_CANDIDATE = 1',
      iconName: 'engineering',
      toolTip: 'Highway Candidate Projects',
      active: false,
    }
  ];

  // broadcast applying spatial filter
  private _applyingSpatialFilter = new BehaviorSubject<boolean>(null);
  applyingSpatialFilter$ = this._applyingSpatialFilter.asObservable(); // broadcast applying filter, so things like table can show 'loading'

  // make sure to add ALL subscriptions to subsink otherwise they won't unsubscribe
  private subs = new SubSink();

  constructor(
    private appLayoutService: AppLayoutService,
    private featuresAmalgamator: FeaturesAmalgamator,
    private fieldsAmalgamator: FieldsAmalgamator,
    private multiScanner: MultiScanner,
    private singlePinGetter: SinglePinGetter,
    private mapService: MapService,
    private guidService: GuidService,
    private snackBar: MatSnackBar,
    private hashingService: HashingService,
    private filterFactoryService: FilterFactoryService,
    private projectInfoService: ProjectInfoService,
    private logging: LoggingService
  ) {
  }

  // layer won't load / add to map unless marked visible
  // other option to have it added to map is rxLayer.visibleFieldsPromise
  async init(map: __esri.Map, visible = true, callSource?: string): Promise<RxLayer> {
    // this.logging.log('🚀 ~ file: pins-rx-layer.service.ts  ~ PinsRxLayerService ~ init ~ callSource', callSource);
    const scans = await this.multiScanner.getScans();
    this.scans$.next(scans);
    const pins = this.featuresAmalgamator.createPins(scans); // technically scopes and pins
    const fields = this.fieldsAmalgamator.createPinFields(scans);

    // Need to take(1) to complete - https://github.com/ReactiveX/rxjs/issues/2536
    const savedSettings = await this.hashingService.currentHashContent$.pipe(take(1)).toPromise();
    const savedFilters = this.getSavedFilters(savedSettings, fields);
    const config = getPinsRxLayerConfig(fields, pins, visible);
    this.defaultDisplayColumns = config.displayColumns;

    if (savedSettings && savedSettings.activeTableCols) {
      config.displayColumns = savedSettings.activeTableCols;
    }

    config.filters = savedFilters || this.defaultFilters;
    config.map = map;
    // config.map = (await this.mapService.getAssets()).map;

    const rxLayer = new RxLayer(config);
    this.initSubscriptions(rxLayer);

    this.hashingService.currentHashContent$.pipe(
      skip(2)
    ).subscribe(content => {
      const _savedFilters = this.getSavedFilters(content, fields);
      if (_savedFilters) {
        rxLayer.filters.next(_savedFilters);
      }
      if (content && content.activeTableCols) {
        rxLayer.tableData.displayColumns.setValue(content.activeTableCols);
      }
    });

    const pinHash = sessionStorage.getItem(StorageKeys.pinHash);
    sessionStorage.removeItem(StorageKeys.pinHash);
    if (pinHash) {
      window.location.hash = pinHash;
      this._selectedPin.next(pinHash.split('=')[1]);
    }
    this._layer.next(rxLayer);
    return rxLayer;
  }

  async getLayer() {
    return this._layer.pipe(
      filter(layer => layer !== null),
      take(1)
    ).toPromise();
  }

  // WE MUST manually tell the service to unsubscribe all it's subscriptions, otherwise they stack
  dispose() {
    this.subs.unsubscribe();
  }

  restoreDefaultRenderer() {
    const rxLayer = this._layer.getValue();
    if (rxLayer.featureLayer) {
      rxLayer.setRenderer(SCOPE_PIN_SHORT_SEGMENTS_RENDERER);
    }
  }

  applySimpleRenderer() {
    const rxLayer = this._layer.getValue();
    if (rxLayer.featureLayer) {
      rxLayer.setRenderer(CIM_LINE_RENDERER);
    }
  }

  // rxLayerPromise: Promise<RxLayer> = (async () => {
  //   const scans = await this.scansPromise;
  //   const pins = this.featuresAmalgamator.createPins(scans); // technically scopes and pins
  //   const fields = this.fieldsAmalgamator.createPinFields(scans);
  //   // Need to take(1) to complete - https://github.com/ReactiveX/rxjs/issues/2536
  //   const savedSettings = await this.hashingService.currentHashContent$.pipe(take(1)).toPromise();
  //   const savedFilters = this.getSavedFilters(savedSettings, fields);
  //   const config = getPinsRxLayerConfig(fields, pins);
  //   const defaultFilters: Filter[] = [
  //     {
  //       guid: this.guidService.createGuid(),
  //       type: FilterType.StageSelect,
  //       field: null,
  //       value: [
  //         ProjectStage.scopeScoping,
  //         ProjectStage.scopeInApproval,
  //         ProjectStage.scopeApproved,
  //         ProjectStage.pinProgrammed,
  //         ProjectStage.pinApproved,
  //         // ProjectStage.scopePlanScenario // switched off by default
  //       ],
  //       active: true
  //     }, {
  //       guid: this.guidService.createGuid(),
  //       type: FilterType.MultiFieldSearch,
  //       field: null,
  //       searchFieldNames: ['PIN', 'PROJECT_SCOPE_ID', 'DESCRIPTION'],
  //       value: '',
  //       active: true
  //     },{
  //       guid: this.guidService.createGuid(),
  //       type: FilterType.QuickFilter,
  //       field: null,
  //       searchFieldNames: null,
  //       value: null,
  //       filterExpression: 'IS_SCOPE_TO_PIN = 1',
  //       iconName: 'published_with_changes',
  //       toolTip: 'Scope to PIN Projects',
  //       active: false,
  //     },
  //     {
  //       guid: this.guidService.createGuid(),
  //       type: FilterType.QuickFilter,
  //       field: null,
  //       searchFieldNames: null,
  //       value: null,
  //       filterExpression: 'CONSTRUCTION_EVENT = 1',
  //       iconName: 'construction',
  //       toolTip: 'Programmed Projects Currently Under Construction',
  //       active: false,
  //     }, {
  //       guid: this.guidService.createGuid(),
  //       type: FilterType.QuickFilter,
  //       field: null,
  //       searchFieldNames: null,
  //       value: null,
  //       filterExpression: 'HIGHWAY_CANDIDATE = 1',
  //       iconName: 'engineering',
  //       toolTip: 'Highway Candidate Projects',
  //       active: false,
  //     }
  //   ];
  //   const defaultDisplayColumns = config.displayColumns;

  //   if (savedSettings && savedSettings.activeTableCols) {
  //     config.displayColumns = savedSettings.activeTableCols;
  //   }

  //   config.filters = savedFilters || defaultFilters;
  //   // Need to take(1) to complete - https://github.com/ReactiveX/rxjs/issues/2536
  //   const mapAssets = await this.mapService.mapAssets$.pipe(take(1)).toPromise();
  //   config.map = mapAssets?.map ? mapAssets.map : null;
  //   // config.map = (await this.mapService.getAssets()).map;

  //   const rxLayer = new RxLayer(config);

  //   // subscribe to map service zoom change, we can apply custom symbology
  //   this.mapService.mapZoom$.subscribe(z => {
  //     // detect whole number
  //     if (z !== -1 && z % 1 === 0) {
  //       if (z < 10) {
  //         rxLayer.setRenderer(SCOPE_PIN_SHORT_SEGMENTS_RENDERER);
  //       } else {
  //         rxLayer.setRenderer(SCOPE_PIN_DEFAULT_RENDERER);
  //       }
  //     }
  //   });

  //   this.hashingService.currentHashContent$.pipe(
  //     skip(2)
  //   ).subscribe(content => {
  //     const _savedFilters = this.getSavedFilters(content, fields);
  //     if (_savedFilters) {
  //       rxLayer.filters.next(_savedFilters);
  //     }
  //     if (content && content.activeTableCols) {
  //       rxLayer.tableData.displayColumns.setValue(content.activeTableCols);
  //     }
  //   });

  //   // clear hash
  //   this.hashingService.clearHash$.pipe(
  //     filter(clear => clear !== null && clear !== undefined)
  //   ).subscribe(() => {
  //     rxLayer.filters.next(defaultFilters);
  //     if (defaultDisplayColumns) {
  //       rxLayer.tableData.displayColumns.setValue(defaultDisplayColumns);
  //     }
  //   });

  //   const pinHash = sessionStorage.getItem(StorageKeys.pinHash);
  //   sessionStorage.removeItem(StorageKeys.pinHash);
  //   if (pinHash) {
  //     window.location.hash = pinHash;
  //     this._selectedPin.next(pinHash.split('=')[1]);
  //   }

  //   this._selectedPin.subscribe(async pin => {
  //     if (pin) {
  //       const query = { where: `PIN = '${pin}' OR PROJECT_SCOPE_ID = '${pin}'`, returnGeometry: true };
  //       const res = await rxLayer.queryFeatures(query);
  //       const feature = res[0];
  //       this.selection.next({
  //         pin: feature.attributes.PIN,
  //         pin_id: feature.attributes.PIN_ID,
  //         project_scope_id: feature.attributes.PROJECT_SCOPE_ID,
  //         attributesJson: JSON.stringify(feature.attributes),
  //         geometry: feature.geometry
  //       });
  //     } else {
  //       this.selection.next(null);
  //     }
  //   });

  //   this.selection.subscribe(selection => {
  //     if (selection) {
  //       // select pin on map or alert that pin has no geometry
  //       const geometry = selection.geometry;
  //       if ((!geometry) || geometry.paths.length === 0) {
  //         const typeName = this.projectInfoService.getTypeName(selection.pin);
  //         const message = `The selected ${typeName} has no geometry.`;
  //         this.snackBar.open(message, 'Ok', {
  //           duration: 1600,
  //         });
  //       } else if (this.appLayoutService.getLayoutMode() === 'table') {
  //         this.zoomToExtentOfSelection();
  //       }
  //     }
  //   });

  //   combineLatest([
  //     this.selection,
  //     this.opacityBeforeSelection.valueStream,
  //     this.appLayoutService.layoutMode$
  //   ]).subscribe(() => {
  //     this.setOpacity(this.selection.value, rxLayer);
  //   });

  //   this._layer.next(rxLayer);
  //   return rxLayer;
  // })();

  zoomToExtentOfSelection() {
    const s = this.selection.value;
    if (s) {
      this.mapService.mapAssets$.pipe(
        filter(assets => assets !== null && assets !== undefined),
        take(1)
      ).subscribe(assets => {
        const expand = this.appLayoutService.getLayoutMode() === 'table' ? 2 : 1;
        const newExtent = s.geometry.extent.clone().expand(expand);
        assets.view.goTo(newExtent);
      });
    }
  }

  selectPin(pin: string) {
    this._selectedPin.next(pin);
  }

  async applyPinFilter(pin, removePrevious = false, selectPin = false) {
    // create filter
    // const rxLayer = await this.rxLayerPromise;
    const rxLayer = this._layer.value;
    const pinsVisibleFields = await rxLayer.visibleFieldsPromise;
    const fieldName = 'PIN';
    const pinField = pinsVisibleFields.find(field => field.name === fieldName);
    const newFilter = this.filterFactoryService.createFilter(pinField);
    newFilter.value = [pin];
    newFilter.active = true;
    // remove any previous PIN filters
    if (removePrevious) {
      const filters = rxLayer.filters.value.filter(f => {
        if (f.field) {
          return f.field.name !== fieldName;
        } else {
          return true;
        }
      });
      filters.push(newFilter);
      rxLayer.filters.next(filters);
    } else {
      rxLayer.filters.next([...rxLayer.filters.value, newFilter]);
    }
    if (selectPin) {
      this.selectPin(pin);
    }
  }

  async refreshPin(pin: string, replacesScope?: string) {
    try {
      // this.logging.log('🚀 ~ file: pins-rx-layer.service.ts ~ line 466 ~ PinsRxLayerService ~ refreshPin ~ pin', pin);
      // const rxLayer = await this.rxLayerPromise;
      const rxLayer = this._layer.value;
      const query = {
        where: `PIN = '${replacesScope ? replacesScope : pin}'`,
        outFields: 'OBJECTID',
        returnGeometry: false
      };

      const current = (await rxLayer.queryFeatures(query));
      const old_OBJECTID = current.length ? current[0].attributes.OBJECTID : null;

      const scans = await this.singlePinGetter.getScanResultsForSinglePin(pin);

      const feature = this.featuresAmalgamator.createPins(scans)[0]; // technically scopes and pins

      let edits;
      if (old_OBJECTID !== null && feature) {
        feature.attributes.OBJECTID = old_OBJECTID;
        edits = {
          updateFeatures: [feature]
        };
      } else if (old_OBJECTID !== null && !feature) {
        edits = {
          deleteFeatures: [current[0]]
        };
      } else if (old_OBJECTID === null && feature) {
        delete feature.attributes.OBJECTID;
        edits = {
          addFeatures: [feature]
        };
      }
      if (edits) {
        await rxLayer.applyEdits(edits);
        rxLayer.tableData.pageState.next(rxLayer.tableData.pageState.value);
      }
    } catch (error) {
      console.warn(`failed to refresh PIN: ${pin}`);
    }
  }

  async getAttributesForPins(where: string): Promise<PinAttributes[]> {
    // const rxLayer = await this.rxLayerPromise;
    const rxLayer = this._layer.value;
    if (rxLayer) {
      const res = await rxLayer.queryFeatures({
        where,
        returnGeometry: false,
        outFields: '*',
        f: 'json'
      });
      return res.map(x => x.attributes);
    }
    return [];
  }

  async applySpatialFilter(extent: __esri.Extent): Promise<string[]> {
    // broadcast applying filter, so things like table can show 'loading'
    this._applyingSpatialFilter.next(true);
    // const rxLayer = await this.rxLayerPromise;
    const rxLayer = this._layer.value;
    const pinField = 'PIN';
    const res = (await rxLayer.queryFeatures({
      returnGeometry: false,
      geometry: extent,
      where: '1=1',
      spatialRelationship: 'intersects',
      outFields: pinField,
      f: 'json'
    }) as any[]).map(r => r.attributes[pinField]);
    await this._applySpatialFilter(res, pinField);
    this._applyingSpatialFilter.next(false);
    return res;
  }

  async clearSpatialFilter() {
    // const rxLayer = await this.rxLayerPromise;
    const rxLayer = this._layer.value;
    const filters = rxLayer.filters.value.filter(x => x.guid !== this.spatialFilter.guid);
    rxLayer.filters.next(filters);
  }

  private initSubscriptions(rxLayer: RxLayer) {

    // subscribe to map service zoom change, we can apply custom symbology
    this.subs.sink = this.mapService.mapZoom$.subscribe(z => {
      // detect whole number
      if (z !== -1 && z % 1 === 0) {
        if (z < 10) {
          rxLayer.setRenderer(SCOPE_PIN_SHORT_SEGMENTS_RENDERER);
        } else {
          rxLayer.setRenderer(SCOPE_PIN_DEFAULT_RENDERER);
        }
      }
    });

    // clear hash
    this.subs.sink = this.hashingService.clearHash$.pipe(
      filter(clear => clear !== null && clear !== undefined)
    ).subscribe(() => {
      rxLayer.filters.next(this.defaultFilters);
      if (this.defaultDisplayColumns) {
        rxLayer.tableData.displayColumns.setValue(this.defaultDisplayColumns);
      }
    });

    // selected PIN
    this.subs.sink = this._selectedPin.subscribe(async pin => {
      if (pin) {
        const query = { where: `PIN = '${pin}' OR PROJECT_SCOPE_ID = '${pin}'`, returnGeometry: true };
        const res = await rxLayer.queryFeatures(query);
        const feature = res[0];
        this.selection.next({
          pin: feature.attributes.PIN,
          pin_id: feature.attributes.PIN_ID,
          project_scope_id: feature.attributes.PROJECT_SCOPE_ID,
          attributesJson: JSON.stringify(feature.attributes),
          geometry: feature.geometry
        });
      } else {
        this.selection.next(null);
      }
    });

    // Selection
    this.subs.sink = this.selection.subscribe(selection => {
      if (selection) {
        // select pin on map or alert that pin has no geometry
        const geometry = selection.geometry;
        if ((!geometry) || geometry.paths.length === 0) {
          const typeName = this.projectInfoService.getTypeName(selection.pin);
          const message = `The selected ${typeName} has no geometry.`;
          this.snackBar.open(message, 'Ok', {
            duration: 1600,
          });
        } else if (this.appLayoutService.getLayoutMode() === 'table') {
          this.zoomToExtentOfSelection();
        }
      }
    });

    this.subs.sink = combineLatest([
      this.selection,
      this.opacityBeforeSelection.valueStream,
      this.appLayoutService.layoutMode$
    ]).subscribe(() => {
      this.setOpacity(this.selection.value, rxLayer);
    });

  }

  private async _applySpatialFilter(pins: string[], fieldName) {
    // const rxLayer = await this.rxLayerPromise;
    const rxLayer = this._layer.value;
    const pin_numbers = pins.map(x => `'${x}'`).join(',');
    this.spatialFilter.filterExpression = `${fieldName} in (${pin_numbers})`;
    this.spatialFilter.active = true;
    // need to remove previous filter then add again
    const filters = rxLayer.filters.value.filter(x => x.guid !== this.spatialFilter.guid);
    filters.push(this.spatialFilter);
    rxLayer.filters.next(filters);
  }

  private setOpacity(selection, rxLayer: RxLayer) {
    const a = this.opacityBeforeSelection.value;
    const isDetailsMode = this.appLayoutService.getLayoutMode() === 'details';
    const b = (selection || isDetailsMode) ? .1 : 1;
    rxLayer.opacity.setValue(a * b);
  }

  private getSavedFilters(savedSettings: SaveHashContents, pinFields: __esri.Field[]) {
    if (savedSettings && savedSettings.filters) {
      savedSettings.filters.forEach(filter => {
        if (filter.field) {
          const fieldMatch = pinFields.find(f => f.name === filter.field.name);
          if (fieldMatch) {
            filter.field = fieldMatch;
          }
        }
      });
      return savedSettings.filters;
    } else {
      return null;
    }
  }

}

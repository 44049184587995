import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { skip } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { RxLayer } from '../rx-layer/rx-layer';
import { ImpactStats } from './layer/impact-rx-layers.service';
import { PopupWidgetService } from './map/popup-widget.service';
import { LazyDialogService } from './lazy-dialog.service';

// currently not working with lazy loading - probably related to module circular dependency
import { BridgeDialogComponent } from '../dialogs/bridge-dialog/bridge-dialog.component';
import {
  AttributeTableDialogComponent,
  AttrTblDialogData
} from '../dialogs/attribute-table-dialog/attribute-table-dialog.component';
import {
  ApprovalWorkflowDialogComponent
} from '../dialogs/approval-workflow-dialog/approval-workflow-dialog.component';
import { AttachmentDialogComponent } from '../dialogs/attachment-dialog/attachment-dialog.component';
import { PriorityImpactReport } from './priority-impact-service';
import {
  PriorityImpactDialogFactoryService
} from '../dialogs/priority-impact-report-dialog/priority-impact-dialog-factory.service';
import { BasicDialogData } from '../dialogs/basic-dialog/basic-dialog.component';

// Moved to lazy load
// import { ApprovalPendingDialogComponent } from '../dialogs/approval-pending-dialog/approval-pending-dialog.component';
// import { ChangelogDialogComponent } from '../dialogs/changelog-dialog/changelog-dialog.component';
// import { HighwayCandidateDialogComponent } from '../dialogs/highway-candidate-dialog/highway-candidate-dialog.component';
// import { DataServicesDialogComponent } from '../dialogs/data-services-dialog/data-services-dialog.component';
// import { MapPopupComponent } from '../dialogs/map-popup/map-popup.component';
// import { AttributeTableDialogComponent } from '../dialogs/attribute-table-dialog/attribute-table-dialog.component';
// import { MapLrsRoutePopupComponent } from '../dialogs/map-lrs-route-popup/map-lrs-route-popup.component';
// import { ScopeToPinDialogComponent } from '../dialogs/scope-to-pin-dialog/scope-to-pin-dialog.component';
// import { BasemapGalleryDialogComponent } from '../dialogs/basemap-gallery-dialog/basemap-gallery-dialog.component';
// import { HelpDialogComponent } from '../dialogs/help-dialog/help-dialog.component';
// import { PlanToScopeDialogComponent } from '../dialogs/plan-to-scope-dialog/plan-to-scope-dialog.component';
// import { HashDialogComponent } from '../dialogs/hash-dialog/hash-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private _dialogOpened = new BehaviorSubject<MatDialogRef<any, any>>(null);
  dialogOpened$ = this._dialogOpened.asObservable();

  private _dialogBeforeClosed = new BehaviorSubject<MatDialogRef<any, any>>(null);
  dialogBeforeClosed$ = this._dialogBeforeClosed.asObservable();

  // any new dialogs need to be added using this.registerDialog(dialog)
  // other services can gain access to dialog
  private _dialogRefs = new BehaviorSubject<MatDialogRef<any, any>[]>(null);
  dialogRefs$ = this._dialogRefs.asObservable();

  constructor(
    private dialog: MatDialog,
    private lazyDialogService: LazyDialogService,
    private popupWidgetService: PopupWidgetService,
    private priorityImpactDialogFactoryService: PriorityImpactDialogFactoryService
  ) {
    this._dialogRefs.pipe(skip(1)).subscribe(dialogRefs => {
      dialogRefs.map(d => d.afterOpened().subscribe(() => {
        this._dialogOpened.next(d);
      }));
      dialogRefs.map(d => d.beforeClosed().subscribe(() => {
        this._dialogBeforeClosed.next(d);
      }));
    });
  }

  mapPopupIsOpen = false;
  mapLrsRoutePopupIsOpen = false;
  downloadInfoDialog: MatDialogRef<any>;

  async openChangelog() {
    const dialog = await this.lazyDialogService.open('changelog-dialog');
    // const dialog = this.dialog.open(ChangelogDialogComponent, {});
    this.registerDialog(dialog);
  }

  async openDataServicesDialog() {
    const dialog = await this.lazyDialogService.open('data-services-dialog');
    //const dialog = this.dialog.open(DataServicesDialogComponent, {});
    this.registerDialog(dialog);
  }

  async openSystemToPlanEntryDialog(config: BasicDialogData) {
    const dialogConfig = this.configure(config);
    config.hasCancel = true;
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    const dialog = await this.lazyDialogService.open('system-to-plan-entry', dialogConfig);
    this.registerDialog(dialog);
    return dialog;
  }

  async openHighwayCandidateDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = '600px';
    dialogConfig.height = '600px';
    dialogConfig.maxWidth = '600px';
    dialogConfig.width = '600px';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    const dialog = await this.lazyDialogService.open('highway-candidate-dialog', dialogConfig);
    // const dialog = this.dialog.open(HighwayCandidateDialogComponent, dialogConfig);
    this.registerDialog(dialog);
  }

  async openApprovalPendingDialog() {
    const dialog = await this.lazyDialogService.open('approval-pending-dialog');
    // const dialog = this.dialog.open(ApprovalPendingDialogComponent, {});
    this.registerDialog(dialog);
  }

  async openPriorityImpactReportDialog(report: any, title: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40vw';
    dialogConfig.data = {
      reports: report,
      title: title
    };
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    const dialog = await this.lazyDialogService.open('priority-impact-report-dialog', dialogConfig);
    this.registerDialog(dialog);
  }

  async openPriorityImpactDetailDialog(reports: PriorityImpactReport[]) {
    const dialogConfig = new MatDialogConfig();
    const dialogData = this.priorityImpactDialogFactoryService.getDialogData(reports[0].layerTitle);
    dialogData.data.reports = reports;
    dialogConfig.width = '75vw';
    dialogConfig.data = dialogData.data;
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    const dialog = await this.lazyDialogService.open('priority-impact-detail-dialog', dialogConfig);
    this.registerDialog(dialog);
  }

  openAttrTblDialog(rxLayer: RxLayer, params?: {
    tableOnly: boolean;
    memo?: string,
    titleOverride?: string,
    impactStats?: ImpactStats;
  }) {
    const config: MatDialogConfig = {
      maxWidth: 'calc(100vw - 16px)',
      width: 'calc(100vw - 16px)',
      position: {
        bottom: '8px',
        left: '8px'
      },
      hasBackdrop: false,
      panelClass: 'attr-tbl-dialog-panel',
      data: <AttrTblDialogData>{
        rxLayer,
        tableOnly: params && params.tableOnly,
        memo: params ? params.memo : null,
        titleOverride: params ? params.titleOverride : null,
        impactStats: params ? params.impactStats : null
      }
    };
    // Not working
    // const dialog = await this.lazyDialogService.open('attribute-table-dialog', config);
    const dialog = this.dialog.open(AttributeTableDialogComponent, config);
    this.registerDialog(dialog);
    return dialog;
  }

  async openMapPopup(clickPos: { x: number, y: number }) {
    this.mapPopupIsOpen = true;
    const { position } = this.popupWidgetService.getInitialMapPopupPosition(clickPos);
    const config: MatDialogConfig = {
      position,
      hasBackdrop: true,
      panelClass: 'map-popup-dialog-panel-hidden',
      backdropClass: 'transparent'
    };
    const dialog = await this.lazyDialogService.open('map-popup-dialog', config);
    // const dialog = this.dialog.open(MapPopupComponent, config);
    dialog.afterClosed().subscribe(() => {
      this.popupWidgetService.close();
      this.mapPopupIsOpen = false;
    });
    // tab change, reposition location
    dialog.componentInstance.tabChange$.pipe(skip(1)).subscribe(() => {
      dialog.componentInstance.hide();
      // check/update popup location
      this.popupWidgetService.updateMapPopupPosition(dialog, clickPos, true);
      dialog.componentInstance.show();
    });
    dialog.afterOpened().subscribe(() => {
      // check/update popup location
      this.popupWidgetService.updateMapPopupPosition(dialog, clickPos, true);
      dialog.componentInstance.show();
    });
    this.registerDialog(dialog);
  }

  async openMapLrsRoutePopup(clickPos: { x: number, y: number }) {
    this.mapLrsRoutePopupIsOpen = true;
    const { position } = this.popupWidgetService.getInitialMapPopupPosition(clickPos);
    const config: MatDialogConfig = {
      position,
      hasBackdrop: true,
      panelClass: 'map-popup-dialog-panel-hidden',
      backdropClass: 'transparent'
    };
    //const dialog = this.dialog.open(MapLrsRoutePopupComponent, config);
    const dialog = await this.lazyDialogService.open('map-lrs-route-popup-dialog', config);
    dialog.afterClosed().subscribe(() => {
      this.popupWidgetService.close();
      this.mapLrsRoutePopupIsOpen = false;
    });
    dialog.afterOpened().subscribe(() => {
      // check/update popup location
      this.popupWidgetService.updateMapPopupPosition(dialog, clickPos);
      dialog.componentInstance.show();
    });
    this.registerDialog(dialog);
  }

  async openBridgeDialog() {
    const config: MatDialogConfig = {
      // custom dialog config
      maxHeight: 'calc(100vh - 16px)',
      height: 'calc(100vh - 16px)',
      maxWidth: 'calc(100vw - 16px)',
      width: 'calc(100vw - 16px)',
    };
    // Lazy loading not working...doesn't load fields
    // const dialog = await this.lazyDialogService.open('bridge-dialog', config);
    const dialog = this.dialog.open(BridgeDialogComponent, config);
    this.registerDialog(dialog);
  }

  async openApprovalWorkflowDialog() {
    const config: MatDialogConfig = {
      /*
      maxHeight: 'calc(100vh - 16px)',
      height: 'calc(100vh - 16px)',
      maxWidth: 'calc(100vw - 16px)',
      width: 'calc(100vw - 16px)',
      */
      maxWidth: 'calc(100vw - 16px)',
      width: 'calc(100vw - 16px)',
      position: {
        bottom: '8px',
        left: '8px'
      },
      hasBackdrop: false,
      panelClass: 'attr-tbl-dialog-panel'
    };
    const dialog = this.dialog.open(ApprovalWorkflowDialogComponent, config);
    // not working with lazy loading yet, causes module loading issues
    // const dialog = await this.lazyDialogService.open('approval-workflow-dialog', config);
    this.registerDialog(dialog);
  }

  async openAttachmentsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    // not working with lazy loading yet, causes module loading issues
    // const dialog = await this.lazyDialogService.open('attachment-dialog', dialogConfig);
    const dialog = this.dialog.open(AttachmentDialogComponent, dialogConfig);
    this.registerDialog(dialog);
  }

  async openScopeToPinDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = '400px';
    dialogConfig.height = '400px';
    dialogConfig.maxWidth = '600px';
    dialogConfig.width = '600px';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    const dialog = await this.lazyDialogService.open('scope-to-pin-dialog', dialogConfig);
    //const dialog = this.dialog.open(ScopeToPinDialogComponent, dialogConfig);
    this.registerDialog(dialog);
  }

  async openPlanToScope() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = '250px';
    dialogConfig.height = '250px';
    dialogConfig.maxWidth = '500px';
    dialogConfig.width = '500px';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    const dialog = await this.lazyDialogService.open('plan-to-scope-dialog', dialogConfig);
    //const dialog = this.dialog.open(PlanToScopeDialogComponent, dialogConfig);
    this.registerDialog(dialog);
  }

  async openHelpDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = '400px';
    dialogConfig.height = '400px';
    dialogConfig.maxWidth = '500px';
    dialogConfig.width = '500px';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    const dialog = await this.lazyDialogService.open('help-dialog', dialogConfig);
    //const dialog = this.dialog.open(HelpDialogComponent, dialogConfig);
    this.registerDialog(dialog);
  }

  async openHashApprovalWorkflowDialog(data: { hash: string }) {
    const config: MatDialogConfig = {
      /*
      maxHeight: 'calc(100vh - 16px)',
      height: 'calc(100vh - 16px)',
      maxWidth: 'calc(100vw - 16px)',
      width: 'calc(100vw - 16px)',
      */
      maxWidth: 'calc(100vw - 16px)',
      width: 'calc(100vw - 16px)',
      position: {
        bottom: '8px',
        left: '8px'
      },
      hasBackdrop: false,
      panelClass: 'attr-tbl-dialog-panel',
      data
    };
    // const dialog = await this.lazyDialogService.open('help-dialog', config);
    const dialog = this.dialog.open(ApprovalWorkflowDialogComponent, config);
    this.registerDialog(dialog);
  }

  async showBasemapGallery() {
    const config: MatDialogConfig = {
      maxHeight: 'calc(100vh - 104px)',
      backdropClass: 'transparent',
      panelClass: 'basemap-gallery-dialog-panel',
      position: {
        right: '60px',
        top: '92px'
      }
    };
    //const dialog = this.dialog.open(BasemapGalleryDialogComponent, config);
    const dialog = await this.lazyDialogService.open('basemap-gallery-dialog', config);
    this.registerDialog(dialog);
  }

  async openHashDialog() {
    const config: MatDialogConfig = {
      maxHeight: '350px',
      height: '350px',
      maxWidth: '475px',
      width: '475px',
      disableClose: false,
      // hashing service sets the window.location.hash && history....which will trigger the hash dialog to close if set to true
      closeOnNavigation: false,
      position: {
        right: '8px',
        bottom: '48px'
      },
      backdropClass: 'transparent'
    };
    const dialog = await this.lazyDialogService.open('hash-dialog', config);
    //const dialog = this.dialog.open(HashDialogComponent, config);
    this.registerDialog(dialog);
  }

  getDialogs() {
    return this._dialogRefs.getValue();
  }

  closeDownloadInfoDialog() {
    this.downloadInfoDialog.close();
  }

  closeDialog(id: string) {
    const dialog = this.dialog.getDialogById(id);
    if (dialog) {
      dialog.close();
    }
  }

  closeAllDialogs() {
    this.dialog.closeAll();
  }

  // register dialog references, other components / services can subscribe
  private registerDialog(dialog: MatDialogRef<any, any>) {
    const match = this._dialogRefs.getValue()?.filter(dr => dr.id === dialog.id);
    if (match && match.length === 0) {
      this._dialogRefs.next([...this._dialogRefs.getValue(), dialog]);
    }
    if (match === null || match === undefined) {
      this._dialogRefs.next([dialog]);
    }
  }

  private configure(config: BasicDialogData): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    const dialogWidth = +config.width > 0 ? +config.width : 500;
    const hasCancel = typeof config.hasCancel === 'boolean' ? config.hasCancel : false;
    const hasOk = typeof config.hasOk === 'boolean' ? config.hasOk : true;
    dialogConfig.data = {
      title: config.title,
      message: config.message,
      width: dialogWidth,
      hasCancel: hasCancel,
      hasOk: hasOk,
      data: config.data
    };
    return dialogConfig;
  }

}

import { Injectable } from '@angular/core';

import { FormDataService } from './form-data.service';
import { esriRequest } from 'src/esri/request';
import { EndpointService } from './dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';
import { DialogNoticeService } from './dialog-notice.service';

export interface BridgeConversion {
  OBJECTID: number;
  PROJECT_SCOPE_ID: string;
  UNIQUE_ID: string;
}

@Injectable({
  providedIn: 'root'
})
export class BridgeConversionService {

  constructor(
    private formDataService: FormDataService,
    private endpointService: EndpointService,
    private dialogNoticeService: DialogNoticeService
  ) {
  }

  async saveBridgeConversions(bridgeConversions: BridgeConversion[]) {
    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
    const res = await this._getObjectIds(bridgeConversions, urls);
    const updates = [];
    res.forEach(conversion => {
      const options: __esri.RequestOptions = {
        body: this.formDataService.toFormData({
          features: [{ attributes: conversion }],
          f: 'json'
        }),
        responseType: 'json',
        method: 'post'
      };
      updates.push(esriRequest(urls.SCOPING_BRIDGES + '/updateFeatures', options));
    });
    const r = await Promise.all(updates);
    // filter responses / make sure they were successful
    const success = r.filter(s => s.data.updateResults[0].success === true);
    // compare count of success counts vs. updates
    if (success.length === updates.length) {
      return r;
    } else {
      const msg = 'Scoping Bridges Update Failed : /updateFeatures failed.  Update requests: ' + JSON.stringify(updates);
      await this.dialogNoticeService.error({
        title: 'Scoping Bridges Error',
        message: msg
      });
      throw new Error(msg);
    }
  }

  // reset PROJECT_SCOPE_ID for a specific PIN
  async resetConversion(pin: string) {
    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
    const attrs = await this._getObjectId(pin, urls);
    if (attrs) {
      // null PROJECT_SCOPE_ID
      attrs.PROJECT_SCOPE_ID = null;
      const updateOptions: __esri.RequestOptions = {
        body: this.formDataService.toFormData({
          features: [{ attributes: attrs }],
          f: 'json'
        }),
        responseType: 'json',
        method: 'post'
      };
      const res = await esriRequest(urls.SCOPING_BRIDGES + '/updateFeatures', updateOptions);
      // filter responses / make sure they were successful
      if (res.data.updateResults[0].success) {
        return res;
      } else {
        const msg = 'Reset Bridge Conversion: /updateFeatures failed.  Update requests: ' + JSON.stringify(updateOptions);
        throw new Error(msg);
      }
    }
  }

  private async _getObjectId(pin: string, urls) {
    const options: __esri.RequestOptions = {
      body: this.formDataService.toFormData({
        where: `PROJECT_SCOPE_ID = '${pin}'`,
        outFields: 'OBJECTID, PROJECT_SCOPE_ID',
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    };
    const res = await esriRequest(urls.SCOPING_BRIDGES + '/query', options);
    if (res.data.features.length === 1) {
      return res.data.features[0].attributes;
    } else {
      return null;
    }
  }

  private async _getObjectIds(bridgeConversions: BridgeConversion[], urls): Promise<BridgeConversion[]> {
    const queries = [];
    bridgeConversions.forEach(conversion => {
      const options: __esri.RequestOptions = {
        body: this.formDataService.toFormData({
          where: `UNIQUE_ID = '${conversion.UNIQUE_ID}'`,
          outFields: 'OBJECTID, UNIQUE_ID',
          f: 'json'
        }),
        responseType: 'json',
        method: 'post'
      };
      queries.push(esriRequest(urls.SCOPING_BRIDGES + '/query', options));
    });
    const res = await Promise.all(queries).catch(error => console.log('error: ' + error.msg)) as __esri.RequestResponse[];
    // filter response and update add OBJECTID to bridge conversions
    const attrs = [];
    res.forEach(response => attrs.push(response.data.features.map(x => x.attributes)));
    bridgeConversions.forEach(conversion => {
      attrs.forEach(a => {
        if (a[0].UNIQUE_ID === conversion.UNIQUE_ID) {
          conversion.OBJECTID = a[0].OBJECTID;
        }
      });
    });
    return bridgeConversions;
  }
}

import { Audit } from './audit';

export interface UserPendingApprovalReport {
  atts: any;
  policy: ApprovalPolicy;
  audits: Audit[];
  report: ApprovalReport;
  pendingStage: ApprovalStageReport;
}

export interface ApprovalStage {
  operator: AnyAll;
  groups: string[]; // names of Active Directory groups
  onlyRequiredWhen?: {
    operator: AnyAll;
    conditions: ApprovalGroupCondition[];
  };
}

export interface ApprovalStageReport {
  operator: AnyAll;
  groups: GroupReport[];
  passed: boolean;
  required: boolean;
  currentPending: boolean; // is this the current pending stage
}

// outlines what approval stages a scope must go through for a given fundingType
export interface ApprovalPolicyTemplate {
  fundingType: string; // always only a single funding type
  stages: ApprovalStage[];
}

// outlines what approval stages a given scope must go through
export interface ApprovalPolicy {
  scope_id: string; // from feature.attributes.PIN
  fundingType: string; // either a single funding type, or a comma separated list in the case of a merge from multiple templates
  stages: ApprovalStage[];
}

export interface GroupReport {
  name: string; // name of Active Directory group
  approved: boolean;
}

// outlines the current progress made on approval stages for a given scope
export interface ApprovalReport {
  scope_id: string; // from feature.attributes.PIN
  stages: ApprovalStageReport[];
}

export enum AnyAll {
  ANY = 'ANY',
  ALL = 'ALL',
  NOTICE_ONLY = 'NOTICE ONLY'
}

export interface ApprovalGroupCondition {
  type: string;
}

export class ImpactMinCondition implements ApprovalGroupCondition {
  type = 'impact-min';
  layerName: string; // TITLE from entry in PIN Impact Layers (see services/impact-rx-layers.service.ts)
  minCount: number;
}

export class DomainValueCondition implements ApprovalGroupCondition {
  type: 'domain-value';
  attributeName: string; // name of field in feature.attributes
  passingValues: number[];
}

export class AttributeMinCondition implements ApprovalGroupCondition {
  type: 'attr-min';
  attributeName: string; // name of field in feature.attributes
  minValue: number;
}

import * as msal from "@azure/msal-browser";
import { EventType } from "@azure/msal-browser";

const CLIENT_IDS = {
  "iowadotprojectpriorities.com": "1ec919e8-9cf0-40dc-be21-ebe1ac6b2b78",
  "scopingdev.iowadotprojectpriorities.com": "af34c471-2124-4156-8215-6954b0346194",
  "commission.iowadotprojectpriorities.com": "be2af7ac-204b-484c-9ada-6da61e44d01a",
  "localhost:4200": "3c0b4cf3-0e97-45d1-a68e-5ae94bded990"
};

const msalConfig = {
  auth: {
    clientId: CLIENT_IDS[window.location.host],
    authority: "https://login.microsoftonline.com/a1e65fcc-32fa-4fdd-8692-0cc2eb06676e",
  },
  cache: {
    cacheLocation: "localStorage"
  }
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export class AzureAD {

  static loadUserFromHash(hash) {
    // const idReqInfo = authContext.getRequestInfo(hash);
    // authContext.saveTokenFromHash(idReqInfo); // will cause .getCachedUser() to return a user if hash is valid
  }

  static redirectToLogin() {
    // source: https://stackoverflow.com/a/69600451
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    // Set active account on page load
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload["account"]) {
        const account = event.payload["account"];
        msalInstance.setActiveAccount(account);
      }
    });

    console.log("get active account", msalInstance.getActiveAccount());

    // handle auth redired/do all initial setup for msal
    msalInstance.handleRedirectPromise().then(authResult => {
      // Check if user signed in
      const account = msalInstance.getActiveAccount();
      if (!account) {
        // redirect anonymous user to login page
        msalInstance.loginRedirect().catch(console.error);
      }
    }).catch(console.log);
  }

  static signOut() {
    msalInstance.logoutRedirect().catch(console.error);
  }

  static get user() {
    const cachedUser = msalInstance.getAllAccounts()[0];
    return cachedUser ? {
      email: properCase(cachedUser.username),
      fullName: cachedUser.name
    } : null;
  }
}

function properCase(email) {
  const splitOnAt = email.split("@");
  if ((!splitOnAt) || (splitOnAt.length !== 2)) {
    return email;
  }
  const isUpperCased = splitOnAt[0].toUpperCase() === splitOnAt[0];
  const isLowerCased = splitOnAt[0].toLowerCase() === splitOnAt[0];
  if (!(isUpperCased || isLowerCased)) {
    return email;
  }
  const splitOnDots = splitOnAt[0].split(".");
  const properCapitalize = splitOnDots.map(x => {
    return `${x.substr(0, 1).toUpperCase()}${x.substr(1).toLowerCase()}`;
  });
  const wholeFirstSection = properCapitalize.join(".");
  return `${wholeFirstSection}@${splitOnAt[1]}`;
}

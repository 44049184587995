import { Injectable } from '@angular/core';

import { AnyAll, ApprovalReport } from '../scope-approval/approval-workflow-policy';
import { EMAIL_COMMON_STYLES } from './common/styles';
import { EMAIL_HEADER } from './common/header';
import { EMAIL_FOOTER } from './common/footer';
import { getApprovalLink } from './common/get-approval-link';
import { APP_ROLES, UserInfoService } from 'src/app/services/user-info.service';

export interface PendingApprovalEmailParameters {
  reports: ApprovalReport[];
  usersForRoles: { [role: string]: string[] };
}


@Injectable({
  providedIn: 'root'
})
export class EmailContentPendingService {
  constructor() {
    return
  }

  getPendingApprovalEmailHtml(params: PendingApprovalEmailParameters): string {
    return `
    <html>
      <head></head>
      <body>
        <table ${EMAIL_COMMON_STYLES.TABLE_ATTRS}>
          <tbody>
            ${EMAIL_HEADER}
            ${this.getIntroTr({ multi: params.reports.length > 1 })}
            ${params.reports.map(report => this.getScopeTr(report)).join('')}
            ${this.getUsersForRolesTr(params)}
            ${EMAIL_FOOTER}
          </tbody>
        </table>
      </body>
    </html>`;
  }

  getIntroTr(params: { multi: boolean }) {
    return `
    <tr>
      <td>
        The following ${params.multi ? 'scopes are' : 'scope is'} pending approval.
        As a member of one of the following groups, approval is required from either yourself or other users in the groups identified below.
      </td>
    </tr>`;
  }

  getScopeTr(report: ApprovalReport) {
    const pendingStage = report.stages.find(c => c.currentPending === true);
    if (!pendingStage) {
      const msg = `No pending stage found for ${report.scope_id}`;
      alert(msg);
      throw new Error(msg);
    }
    const groups = [];
    pendingStage.groups.map(g => {
      // not approved and No Admin
      if (!g.approved && g.name !== APP_ROLES.ADMIN) {
        groups.push(g.name);
      }
    });
    if (!groups.length) {
      const msg = `No remaining groups found for pending approval stage for ${report.scope_id}`;
      alert(msg);
      throw new Error(msg);
    }
    const operatorText = pendingStage.operator === AnyAll.ALL ? 'all' : 'any';
    return `
    <tr>
      <td>
        <br>
        <strong>${report.scope_id}</strong><br>
        Pending approval from ${groups.length > 1
    ? `${operatorText} of the following groups: ${groups.join(', ')}`
    : `group: ${groups[0]}`
}<br>
        <strong><a href="${getApprovalLink(report.scope_id)}">View Approval Process</a></strong><br>
      </td>
    </tr>`;
  }

  getUsersForRolesTr(params: PendingApprovalEmailParameters) {
    const groupsObj = {};
    params.reports.forEach(report => {
      report.stages.forEach(stage => {
        if (stage.currentPending) {
          stage.groups.forEach(group => {
            if (!group.approved) {
              groupsObj[group.name] = 1;
            }
          });
        }
      });
    });
    const userInfoService: UserInfoService = new UserInfoService();
    const groups = Object.keys(groupsObj).filter(g => g !== APP_ROLES.ADMIN); // remove admin group from the list
    const relevantUsersByGroup = groups.map(group => ({
      group,
      users: (userInfoService.removeHntbStaffEmail(params.usersForRoles[group]) || ['none']).join(', ')
    }));
    return `
    <tr>
      <td>
        <br>
        The following users can approve on behalf of these roles:<br>
        ${relevantUsersByGroup.map(x => `<br><strong>${x.group}:</strong> ${x.users}`).join('<br>')}
      </td>
    </tr>`;
  }

}

import { ElementRef, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';


export type Daylight = DaylightLeftTop | DaylightRightTop | DaylightLeftBottom | DaylightRightBottom;

interface DaylightBase {
  width: number;
  height: number;
}

interface DaylightLeftTop extends DaylightBase {
  left: number;
  top: number;
}

interface DaylightRightTop extends DaylightBase {
  right: number;
  top: number;
}

interface DaylightLeftBottom extends DaylightBase {
  left: number;
  bottom: number;
}

interface DaylightRightBottom extends DaylightBase {
  right: number;
  bottom: number;
}

export interface TargetDialog {
  targetId: string;
  element: ElementRef<any>;
}

export interface TargetMenu {
  panelId: string;
}

@Injectable({
  providedIn: 'root'
})
export class TutorialMaskService {
  public readonly ATTR_MASK_TARGET = 'mask-service-target';
  public readonly PROP_DIALOG_MASK_TARGET = 'maskServiceDialogTarget';

  constructor() {
    return;
  }

  visible$ = new BehaviorSubject<boolean>(false);
  daylights$ = new BehaviorSubject<Daylight[]>([]);

  private readonly dayLightPadding = 12;
  private readonly markupPadding = 12;

  private _targetElements = new BehaviorSubject<ElementRef<any>[]>([]);
  targetElements$ = this._targetElements.asObservable();

  private _targetMatMenus = new BehaviorSubject<TargetMenu[]>([]);
  targetMatMenus$ = this._targetMatMenus.asObservable();

  private _targetDialogs = new BehaviorSubject<TargetDialog[]>([]);
  targetDialogs$ = this._targetDialogs.asObservable();

  menuTriggerEventHandler(trigger: MatMenuTrigger, open: boolean) {
    if (trigger) {
      if (open) {
        // add the menu as a mask target after it opens
        trigger.menuOpened.pipe(take(1)).subscribe(() => {
          this.addTargetMatMenu({
            panelId: trigger.menu.panelId
          });
        });
        trigger.openMenu();
      } else {
        // remove the menu as a mask target after it closes
        trigger.menuClosed.pipe(take(1)).subscribe(() => {
          this.removeTargetMatMenu({
            panelId: trigger.menu.panelId
          });
        });
        trigger.closeMenu();
      }
    }
  }

  getMarkupStyle(targetElement: ElementRef, padding?: string) {
    const rect = targetElement.nativeElement.getBoundingClientRect();
    const mPadding = padding ? Number(padding) : this.markupPadding;
    const widthCalc = rect.width + mPadding;
    const heightCalc = rect.height + mPadding;
    const leftCalc = (rect.left) - (mPadding + 4);
    const topCalc = (rect.top) - (mPadding + 4);
    const result = {
      top: `${Math.round(topCalc)}px`,
      left: `${Math.round(leftCalc)}px`,
      width: `${Math.round(widthCalc)}px`,
      height: `${Math.round(heightCalc)}px`,
      display: 'block'
    };
    return result;
  }

  getDaylight(targetElement: ElementRef, padding?: string): Daylight {
    if (targetElement) {
      const rect = targetElement.nativeElement.getBoundingClientRect();
      return this.calcDaylight(rect, padding);
    } else {
      return null;
    }
  }

  getMenuDaylight(panelId: string, padding?: string): Daylight {
    const panel = document.getElementById(panelId);
    if (panel) {
      const rect = panel.getBoundingClientRect();
      return this.calcDaylight(rect, padding);
    } else {
      return null;
    }
  }

  addTargetDialog(dialog: MatDialogRef<any>, element: ElementRef) {
    // check client rect, we don't want any elements with 0 height / width
    // during the dialog lifecycle it's possible that the client rect could be 0 in size
    const rect = element.nativeElement.getBoundingClientRect();
    if (rect.width > 0 && rect.height > 0) {
      const targetId = String(dialog.componentInstance[this.PROP_DIALOG_MASK_TARGET]);
      const targetDialogs = this._targetDialogs.getValue();
      if (targetDialogs.length === 0) {
        targetDialogs.push({ targetId, element });
      } else {
        targetDialogs.forEach(td => {
          const match = targetId === td.targetId;
          if (!match) {
            targetDialogs.push({ targetId, element });
          }
        });
      }
      this._targetDialogs.next(targetDialogs);
    }
  }

  removeTargetDialog(dialog: MatDialogRef<any, any>) {
    const removed = this._targetDialogs.getValue().filter(d => {
      const elementId = dialog.componentInstance ?
        dialog.componentInstance[this.PROP_DIALOG_MASK_TARGET] : dialog[this.PROP_DIALOG_MASK_TARGET];
      return d.targetId !== elementId;
    });
    this._targetDialogs.next(removed);
  }

  addTargetMatMenu(menu: TargetMenu) {
    const match = this._targetMatMenus.getValue().filter(t => {
      return t.panelId === menu.panelId;
    });
    if (match.length === 0) {
      this._targetMatMenus.next([...this._targetMatMenus.getValue(), menu]);
    }
  }

  removeTargetMatMenu(menu: TargetMenu) {
    const removed = this._targetMatMenus.getValue().filter(t => {
      return t.panelId !== menu.panelId;
    });
    this._targetMatMenus.next(removed);
  }

  addTargetElement(element: ElementRef) {
    const match = this._targetElements.getValue().filter(t => {
      const targetId = String(t.nativeElement.getAttribute(this.ATTR_MASK_TARGET)).toLowerCase();
      const elementId = String(element.nativeElement.getAttribute(this.ATTR_MASK_TARGET)).toLowerCase();
      return targetId === elementId;
    });
    if (match.length === 0) {
      this._targetElements.next([...this._targetElements.getValue(), element]);
    }
  }

  removeTargetElement(element: ElementRef) {
    const removed = this._targetElements.getValue().filter(t => {
      const targetId = String(t.nativeElement.getAttribute(this.ATTR_MASK_TARGET)).toLowerCase();
      const elementId = String(element.nativeElement.getAttribute(this.ATTR_MASK_TARGET)).toLowerCase();
      return targetId !== elementId;
    });
    this._targetElements.next(removed);
  }

  private calcDaylight(rect: DOMRect, padding?: string): Daylight {
    const dPadding = padding ? Number(padding) : this.dayLightPadding;
    const widthCalc = rect.width + dPadding;
    const heightCalc = rect.height + dPadding;
    const leftCalc = (rect.left) - (dPadding / 2);
    const topCalc = (rect.top) - (dPadding / 2);
    return {
      top: Math.round(topCalc),
      left: Math.round(leftCalc),
      height: Math.round(heightCalc),
      width: Math.round(widthCalc)
    };
  }

}

import { Injectable } from '@angular/core';
import { PinsRxLayerService } from './layer/pins-rx-layer.service';
import { FormDataService } from './form-data.service';
import { esriRequest } from 'src/esri/request';
import { EndpointService } from '../services/dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from '../services/dynamic-endpoint/endpoint.service.config';

class CountyEntry {
  number: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class CountyService {
  constructor(
    private pinsRxLayerService: PinsRxLayerService,
    private endpointsService: EndpointService,
    private formDataService: FormDataService
  ) {
  }

  async getCounties(pin): Promise<CountyEntry[]> {
    const routesAndMeasures = await this.getRoutesAndMeaures(pin);
    return this.getCountiesFromRoutesAndMeasures(routesAndMeasures);
  }

  private async getRoutesAndMeaures(pin) {
    const attributesForPins = await this.pinsRxLayerService.getAttributesForPins(`PIN = '${pin}'`);

    if (!attributesForPins.length) {
      console.error(`Could not find PIN ${pin}`);
      return [];
    }

    if (attributesForPins.length > 1) {
      console.error(`Found multiple PINs matching ${pin}`);
      return [];
    }

    const attributes = attributesForPins[0];
    return attributes.ROUTES_AND_MEASURES_JSON
      ? JSON.parse(attributes.ROUTES_AND_MEASURES_JSON).map(x => {
        const { ROUTE_ID, FROM_MEASURE, TO_MEASURE } = x;
        return { ROUTE_ID, FROM_MEASURE, TO_MEASURE }; // exclude paths
      })
      : [];
  }

  async getCountiesFromRoutesAndMeasures(routesAndMeasures: { ROUTE_ID: string; FROM_MEASURE: number; TO_MEASURE: number }[]): Promise<CountyEntry[]> {
    if (!(routesAndMeasures && routesAndMeasures.length)) {
      return [];
    }
    const urlsRAMS = await this.endpointsService.getLayerUrls(ENDPOINT_CONFIG.RAMS_PUBLIC_LRS);
    const urlsCounty = await this.endpointsService.getLayerUrls(ENDPOINT_CONFIG.COUNTY_VIEW);

    const RAMS_COUNTY_NUMBER = urlsRAMS.COUNTY_NUMBER;
    const COUNTY_VIEW_ENDPOINT = urlsCounty.IOWA_COUNTIES; // 'https://services.arcgis.com/8lRhdTsQyJpO52F1/ArcGIS/rest/services/County_View/FeatureServer/0';

    const where = this.getCountiesWhereClause(routesAndMeasures);
    const countyNumbers = await (async () => {
      const options: __esri.RequestOptions = {
        body: this.formDataService.toFormData({
          where: where,
          outFields: 'COUNTY_NUMBER,FROM_MEASURE,TO_MEASURE',
          returnGeometry: false,
          returnDistinctValues: true,
          orderByFields: 'FROM_MEASURE',
          f: 'json'
        }),
        responseType: 'json',
        method: 'post'
      };
      const res = await esriRequest(RAMS_COUNTY_NUMBER + '/query', options);
      return res.data.features.map(x => x.attributes).filter(x => x.COUNTY_NUMBER !== null);
    })();


    if (!countyNumbers.length) {
      return [];
    }

    const counties = await (async () => {
      const uniqueCountyNumbers = new Set(countyNumbers.map(x => x.COUNTY_NUMBER));
      const where2 = `COUNTY_NUMBER in (${Array.from(uniqueCountyNumbers).join(',')})`;
      const query2 = {
        where: where2,
        outFields: 'COUNTY_NUMBER,COUNTY_NAME',
        returnGeometry: false,
        f: 'json'
      };
      const res = await esriRequest(COUNTY_VIEW_ENDPOINT + '/query', { query: query2 });
      return res.data.features.map(x => ({
        // Normalize County Name
        name: String(x.attributes.COUNTY_NAME).toUpperCase(),
        number: x.attributes.COUNTY_NUMBER
      }));
    })();
    return counties;
  }

  private getCountiesWhereClause(routesAndMeasures) {
    const wheresByRouteid = {};

    routesAndMeasures.forEach(x => {
      if (wheresByRouteid[x.ROUTE_ID] === 'whole-route') {
        return;
      }

      if (!wheresByRouteid[x.ROUTE_ID]) {
        wheresByRouteid[x.ROUTE_ID] = [];
      }

      const conditions = [];
      if (x.FROM_MEASURE !== null && x.FROM_MEASURE !== 0) {
        conditions.push(`TO_MEASURE >= ${x.FROM_MEASURE}`);
      }
      if (x.TO_MEASURE !== null) {
        conditions.push(`${x.TO_MEASURE} >= FROM_MEASURE`);
      }

      if (conditions.length) {
        wheresByRouteid[x.ROUTE_ID].push(`(${conditions.join(' and ')})`);
      } else {
        wheresByRouteid[x.ROUTE_ID] = 'whole-route';
      }
    });

    const routeIds = Object.keys(wheresByRouteid);
    return routeIds
      .map(ROUTE_ID => {
        const wcs = wheresByRouteid[ROUTE_ID];
        return wcs === 'whole-route' || !wcs.length
          ? `(ROUTE_ID = '${ROUTE_ID}')`
          : `(ROUTE_ID = '${ROUTE_ID}' and (${wcs.join(' or ')}))`;
      })
      .join(' or ');
  }
}

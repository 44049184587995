import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FieldValueFormatService {

  formatValue(value: any, field: __esri.Field) {
    if (!field) {
      return value;
    }
    // format null values
    /*
    if (value === null) {
      return this.formatNull(value, field);
    }
    */
    if (field.domain || field['patchedDomain']) {
      if (field['isCommaDelimited']) {
        return value.split(',').map(x => x.trim()).filter(x => x.length).map(v => this.formatDomain(v, field)).join(', ');
      } else {
        return this.formatDomain(value, field);
      }
    }
    if (<any>field.type === 'esriFieldTypeDate' || <any>field.type === 'date') {
      return this.formatDate(value);
    }
    if (this.isCurrency(field)) {
      const decimalPlaces = this.decimalPlaces(field);
      return this.formatCurrency(decimalPlaces !== null ? this.toPlaces(value, decimalPlaces) : value);
    } else if (<any>field.type === 'esriFieldTypeDouble' || field.type === 'double') {
      const decimalPlaces = this.decimalPlaces(field);
      return decimalPlaces !== null ? this.toPlaces(value, decimalPlaces) : value;
    }
    return value;
  }

  private formatNull(value: any, field: __esri.Field) {
    let v = value;
    const fType = field.type.toLowerCase().replace('esrifieldtype', '');
    const isCurrency = this.isCurrency(field);
    switch (fType) {
    case 'small-integer':
      v = 0;
      break;
    case 'integer':
      v = 0;
      break;
    case 'single':
      v = 0;
      break;
    case 'long':
      v = 0;
      break;
    case 'string':
      v = '-';
      break;
    case 'double':
      if (isCurrency) {
        v = '$0';
      } else {
        v = 0.0;
      }
      break;
    case 'date':
      v = new Date('12/20/1981');
      break;
    default:
      break;
    }
    return v;
  }

  private formatDate(value: any) {
    if (value === null) {
      return 'null';
    }
    const d = new Date(value);
    return d.toDateString().substr(4);
  }

  private formatDomain(value: any, field: __esri.Field) {
    const lookup = {};
    const domain = field.domain || field['patchedDomain'];
    domain['codedValues'].forEach(cv => {
      lookup[cv.code + ''] = cv.name;
    });
    return lookup[value + ''];
  }

  private formatCurrency(value) {
    if (typeof value !== 'number') {
      return value;
    }
    return '$' + (value).toLocaleString(<any>{ style: 'currency' });
  }

  private isCurrency(field: __esri.Field) {
    const isDouble = <any>field.type === 'esriFieldTypeDouble' || field.type === 'double';
    if (!isDouble) {
      return false;
    }
    if (field.name.indexOf('_ADDED_VALUE') > 0 || field.name.indexOf('_PROJECT_COSTS') > 0) {
      return true;
    }
    if (field.alias.indexOf(' Sum') > 0) {
      return true;
    }
    return false;
  }

  private decimalPlaces(field: __esri.Field) {
    if (field.name.indexOf('_ADDED_VALUE') > 0 || field.name.indexOf('_PROJECT_COSTS') > 0) {
      return 0;
    }
    if (field.name.indexOf('_ROI') > 0 || field.name.indexOf('_PERCENT_PROJECT_COST') > 0) {
      return 2;
    }
    return null;
  }

  private toPlaces(value, places) {
    const v = +value;
    const m = Math.pow(10, places);
    return Math.trunc(v * m) / m;
  }
}

import { PriorityImpactDetailData } from './priority-impact-detail-data';

// NOTE:
// tried to use import { PriorityImpactLayerTitle } from "src/app/services/priority-impact-service";
// impactLayerTitles: [PriorityImpactLayerTitle.PreviousFloodingEvents] // got errors after prod build

export const IMPACT_DETAIL_PREVIOUS_FLOOD: PriorityImpactDetailData = {
  dialogTitle: `HIGH PRIORITY IMPACT NOTICE: Previous Flooding Events`,
  impactLayerTitles: ['Previous Flooding Events']
};

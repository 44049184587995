export function shortenWinterRouteName(winterRouteName: string) {
  return ` ${winterRouteName} `
    .replace(/ STATE OF IOWA, /g, ' ')
    .replace(/ CITY OF [a-zA-Z ]*, /g, ' ')
    .replace(/ COUNTY OF [a-zA-Z ]*, /g, ' ')
    .replace(/ NORTHEAST /g, ' NE ')
    .replace(/ NORTHWEST /g, ' NW ')
    .replace(/ SOUTHEAST /g, ' SE ')
    .replace(/ SOUTHWEST /g, ' SW ')
    .replace(/ NORTH /g, ' N ')
    .replace(/ SOUTH /g, ' S ')
    .replace(/ EAST /g, ' E ')
    .replace(/ WEST /g, ' W ')
    .replace(/ STREET /g, ' ST ')
    .replace(/ DRIVE /g, ' DR ')
    .replace(/ AVENUE /g, ' AVE ')
    .replace(/ ROUTE /g, ' RTE ')
    .replace(/ ROAD /g, ' RD ')
    .replace(/ {2}/g, ' ')
    .trim();
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AzureAD } from 'src/init/azure-ad';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { PinAttributes } from 'src/app/services/amalgamator/pin-attributes';
import { DialogNoticeService } from './dialog-notice.service';
import { ProjectInfoService } from './project-info.service';
import { ENDPOINT_CONFIG } from 'src/app/services/dynamic-endpoint/endpoint.service.config';
import { RxJsLoggingLevel } from '../functions/rxjs-debug';

const API_KEY = ENDPOINT_CONFIG.AWS.USERS.apiKey;
const GET_USER_ROLES_URL = `${ENDPOINT_CONFIG.AWS.USERS.url}/user/roles/read`;
const PUT_EMAIL_GROUPS_URL = `${ENDPOINT_CONFIG.AWS.USERS.url}/user/email-groups/update`;
const PUT_TUTORIAL_SEEN_URL = `${ENDPOINT_CONFIG.AWS.USERS.url}/user/tutorial/update`;
const GET_USERS_FOR_ROLES_URL = `${ENDPOINT_CONFIG.AWS.USERS.url}/roles/users/read`;
const GET_USERS_FOR_EMAIL_GROUPS = `${ENDPOINT_CONFIG.AWS.USERS.url}/users/email-groups/read`;
const GET_USER_SYNC = `${ENDPOINT_CONFIG.AWS.USERS.url}/user/sync/read`;

export class UserInfo {
  username: string;
  roles: string[];
  emailGroups: string[];
  userNotFound: boolean;
  tutorialSeen: boolean;
}

// matching roles within https://admin.iowadotprojectpriorities.com/
export const APP_ROLES = {
  _4R: '4R',
  ADMIN: 'Admin',
  BR: 'BR',
  DISTRICT_X: 'District X',
  DIVISION: 'Division',
  APPLICATION_ACCESS: 'Application Access', // provide access to application
  COMMISSION_APPLICATION_ACCESS: 'Commission Application Access', // provide access to Commission site
  DEVELOPER_APPLICATION_ACCESS: 'Developer Application Access', // provide access to Dev site
  LEB_NEPA_REVIEW: 'LEB NEPA Review',
  PSS_SUBMITTAL: 'PSS Submittal',
  SCOPE_PROJECTS: 'Scope Projects',
  SR: 'SR',
  ADA: 'ADA',
  PLANNING_SCENARIO: 'Planning Scenario', // Provides ability to designate planning projects
  PROGRAM_MANAGEMENT: 'Program Management' // Provides ability to designate / edit highway candidates
};

// facilitates editing / updates for users that share the same role
// user A creates scope, user B shares a role is user B can edit
export const GROUP_EDIT_ROLES = [
  APP_ROLES.DISTRICT_X,
  APP_ROLES.ADA,
  APP_ROLES.SR,
  APP_ROLES.BR
];

export const APP_EMAIL_GROUPS = {
  _4R_GROUP: '4R Email Group',
  PSS_SUBMITTAL_EMAILS: 'PSS Submittal Emails',
  SAFETY_GROUP: 'Safety Group',
  ADMIN_GROUP: 'Admin'
};

export const LRTP_NOTICE_STAFF = environment.isLocalHost ?
  ['bscholer@hntb.com'] :
  ['bscholer@hntb.com', 'Garrett.Pedersen@iowadot.us', 'bryan.bradley@iowadot.us'];

// used for help contact form / testing
// used for Bcc email addresses
export const HNTB_ADMIN_STAFF = [
  'bscholer@hntb.com',
  // 'bgottschalk@hntb.com'
];

// used for Bcc email addresses
export const IOWA_ADMIN_STAFF = [
  'bryan.bradley@iowadot.us',
  'Derek.Peck@iowadot.us'
];

// used to strip out to email addresses, some of these aren't active and will throw bounce backs
export const HNTB_STAFF_IDOT = [
  'ben.scholer@iowadot.us',
  'Robert.Hosack@iowadot.us',
  'Wuxuan.Xiang@iowadot.us',
  'Darin.Welch@iowadot.us',
  'Bill.Cozzens@iowadot.us',
  'Mark.Pierson@iowadot.us',
  'Michael.Lodes@iowadot.us',
  'Bryan.Gottschalk@iowadot.us'
];

export const CAN_DISABLE_SEND_STAFF = [
  'ben.scholer@iowadot.us',
  'robert.hosack@iowadot.us',
  'derek.peck@iowadot.us'
]

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(
    private projectInfoService?: ProjectInfoService,
    private dialogNoticeService?: DialogNoticeService,
    private http?: HttpClient
  ) {
  }

  userInfo = new BehaviorSubject<UserInfo>(null);
  isAdmin = false;
  isBR = false;
  isPlanningScenario = false;
  isProgramManagement = false;
  isScopeProjects = false;
  isOleNepaReview = false;
  canDisableSendEmail = false;
  tutorialSeen = null;
  private headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'X-Api-Key': API_KEY
    }
  );

  async populateUserInfo(): Promise<UserInfo> {
    const loadingMsgEl = document.getElementById('init-loading-msg');
    if (loadingMsgEl) {
      loadingMsgEl.innerHTML = 'Verifying user roles...';
    }
    const userInfo = await this.getUserInfo(AzureAD.user.email); // will throw exception if request fails or results are invalid
    this.verifyRoles(userInfo); // will throw exception if user does not have access
    this.isAdmin = this._isAdmin(userInfo);
    this.isBR = this._isBR(userInfo);
    this.isProgramManagement = this._isProgramManagement(userInfo);
    this.isPlanningScenario = this._isPlanningScenario(userInfo);
    this.isScopeProjects = this._isScopeProjects(userInfo);
    this.isOleNepaReview = this._isOleNepaReview(userInfo);
    this.canDisableSendEmail = this._canDisableSendEmail(userInfo);
    this.tutorialSeen = userInfo.tutorialSeen ? userInfo.tutorialSeen : false;
    this.userInfo.next(userInfo);
    return userInfo;
  }

  // remove any HNTB staff@iowadot.us from addresses array
  removeHntbStaffEmail(addresses: any[]) {
    const hntbStaff = HNTB_STAFF_IDOT;
    const clean = addresses.filter(a => hntbStaff.indexOf(a) < 0);
    return clean;
  }

  async putEmailGroups(emailGroups: string[]) {
    try {
      const url = PUT_EMAIL_GROUPS_URL;
      const options = {
        headers: this.headers
      };
      const body = {
        user: this.userInfo.value.username,
        emailGroups
      };
      await this.http.put(url, body, options).toPromise();
    } catch {
      const msg = 'Could not write email groups.';
      this.showWarn('User Info Service', msg);
      throw new Error(msg);
    }
  }

  async putTutorialSeen(wasSeen: boolean) {
    try {
      const url = PUT_TUTORIAL_SEEN_URL;
      const options = {
        headers: this.headers
      };
      const body = {
        user: this.userInfo.value.username,
        tutorialSeen: wasSeen
      };
      await this.http.put(url, body, options).toPromise();
      this.tutorialSeen = wasSeen;
    } catch {
      const msg = 'Could not write tutorial seen.';
      this.showWarn('User Info Service', msg);
      throw new Error(msg);
    }
  }

  canDeleteProject(atts: PinAttributes): boolean {
    const currentUserInfo = this.userInfo.value;
    const isNew = !atts.PIN;
    const isPin = isNew ? false : this.projectInfoService.isPin(atts.PIN);
    let currentUserMatch = false;
    if (atts.USERNAME) {
      currentUserMatch = currentUserInfo.username.toLowerCase() === atts.USERNAME.toLowerCase() ? true : false;
    }
    let canDelete = (this.isAdmin || currentUserMatch);
    if (isPin) {
      canDelete = false;
    }
    return canDelete;
  }

  async canEditProject(atts: PinAttributes): Promise<boolean> {
    const currentUserInfo = this.userInfo.value;
    const isNew = !atts.PIN;
    const isPin = isNew ? false : this.projectInfoService.isPin(atts.PIN);
    const hasScopeProjects = this.isScopeProjects;
    let currentUserMatch = false;
    let sharedRoleMatch = false;
    if (atts.USERNAME) {
      currentUserMatch = currentUserInfo.username.toLowerCase() === atts.USERNAME.toLowerCase() ? true : false;
      if (!currentUserMatch) {
        sharedRoleMatch = await this.groupEditRoleMatch(currentUserInfo.roles, atts.USERNAME);
      }
    }

    let canSave = (isNew && (hasScopeProjects || this.isAdmin))
      ||
      ((!isNew) && (this.isAdmin || currentUserMatch || this.isOleNepaReview || sharedRoleMatch));

    // here we could allow admins / program management to edit PINs
    if (isPin && (this.isAdmin || this.isProgramManagement)) {
      canSave = true;
    }
    if (isPin && (!this.isAdmin || !this.isProgramManagement)) {
      canSave = false;
    }

    if (environment.isLocalHost && environment.loggingLevel === RxJsLoggingLevel.DEBUG) {
      const logging = {
        isPin: isPin,
        isAdmin: this.isAdmin,
        isProgramManagement: this.isProgramManagement,
        hasScopeProjects: hasScopeProjects,
        isOleNepaReview: this.isOleNepaReview,
        currentUserMatch: currentUserMatch,
        sharedRoleMatch: sharedRoleMatch,
        canSave: canSave,
        isNew: isNew
      };
      // console.log('----------------------');
      // Object.keys(logging).forEach(k => console.log(`canSaveProject: ${k}`, logging[k]));
      // console.log('----------------------');
    }
    return canSave;
  }

  async getUsersForRoles(roles: string[]): Promise<{ [role: string]: string[] }> {
    let res;
    try {
      const options = {
        headers: this.headers,
        params: {
          roles: roles.join(',')
        }
      };
      res = await this.http.get(GET_USERS_FOR_ROLES_URL, options).toPromise<any>();
    } catch {
      const msg = 'Could not query users for roles.';
      this.showWarn('User Info Service', msg);
      throw new Error(msg);
    }
    return res;
  }

  async getUsersForEmailGroups(emailGroups: string[]): Promise<{ [groups: string]: string[] }> {
    let res;
    try {
      const options = {
        headers: this.headers,
        params: {
          emailGroups: emailGroups.join(',')
        }
      };
      res = await this.http.get(GET_USERS_FOR_EMAIL_GROUPS, options).toPromise<any>();
    } catch {
      const msg = 'Could not query users for email groups.';
      this.showWarn('User Info Service', msg);
      throw new Error(msg);
    }
    return res;
  }

  private async groupEditRoleMatch(currentUserRoles: string[], createdByUserName): Promise<boolean> {
    const createdByUserRoles = (await this.getUserInfo(createdByUserName)).roles;
    const matchingRoles = currentUserRoles.filter(r => createdByUserRoles.indexOf(r) > -1);
    if (matchingRoles.length > 0) {
      // compare the matching roles to ones enabled for 'group editing'
      const groupEdit = matchingRoles.filter(r => {
        // replace district numbers with X
        const roleReplace = r.replace(/District [0-9]+/gim, 'District X');
        const match = GROUP_EDIT_ROLES.find(g => g.search(roleReplace) > -1);
        return match;
      }).length > 0 ? true : false;
      if (environment.isLocalHost && environment.loggingLevel === RxJsLoggingLevel.DEBUG) {
        const logging = {
          currentUserRoles: currentUserRoles,
          createdByUserRoles: createdByUserRoles,
          matchingRoles: matchingRoles,
          groupEdit: groupEdit
        };
        // console.log('----------------------');
        // Object.keys(logging).forEach(k => console.log(`groupEditRoleMatch: ${k}`, logging[k]));
        // console.log('----------------------');
      }
      return groupEdit;
    } else {
      return false;
    }
  }

  private async getUserInfo(username): Promise<UserInfo> {
    const getUserLambda = async user => {
      let results: UserInfo;
      try {
        const options = {
          headers: this.headers,
          params: {
            username: user
          }
        };
        results = <UserInfo>(await this.http.get(GET_USER_ROLES_URL, options).toPromise());
      } catch {
        const msg = 'Could not query user roles and email groups.';
        this.showWarn('User Info Service', msg);
        throw new Error(msg);
      }
      return results;
    };

    const userSyncLambda = () => {
      const url = `${GET_USER_SYNC}`;
      return this.http.get(url).toPromise();
    };

    let userInfo: UserInfo;
    userInfo = await getUserLambda(username);


    if (userInfo.userNotFound) {
      await userSyncLambda();
      userInfo = await getUserLambda(username);
    }

    if (!(userInfo && userInfo.username)) {
      const msg = 'Invalid user results';
      this.showWarn('User Info Service', msg);
      throw new Error(msg);
    }
    return userInfo;
  }

  private verifyRoles(userInfo: UserInfo) {
    if (environment.isDev) {
      const isDevUser = userInfo.roles.indexOf(APP_ROLES.DEVELOPER_APPLICATION_ACCESS) > -1;
      if (!isDevUser) {
        const msg = `<b>This environment is reserved for developer testing.</b><br><br>If you are looking for the production application
        please go to <a href='https://iowadotprojectpriorities.com'>https://iowadotprojectpriorities.com</a>.<br><br>Thanks!`;
        this.dialogNoticeService.warn({
          htmlContent: msg,
          message: '',
          hasCancel: false,
          hasOk: false
        });
        throw new Error(msg);
      }
    }

    const requiredRole = environment.isCommission ? APP_ROLES.COMMISSION_APPLICATION_ACCESS : APP_ROLES.APPLICATION_ACCESS;

    if (userInfo.roles.indexOf(requiredRole) === -1) {
      const msg = `Your account ${userInfo.username} does not have the role "${requiredRole}" which is required to access this app.`;
      this.showWarn('Role Issue', msg);
      throw new Error(msg);
    }
  }

  private _isScopeProjects(userInfo: UserInfo): boolean {
    return userInfo.roles
      .map(r => r.toLowerCase())
      .indexOf(APP_ROLES.SCOPE_PROJECTS.toLowerCase()) > -1;
  }

  private _isAdmin(userInfo: UserInfo): boolean {
    return userInfo.roles
      .map(r => r.toLowerCase())
      .indexOf(APP_ROLES.ADMIN.toLowerCase()) > -1;
  }

  private _isProgramManagement(userInfo: UserInfo): boolean {
    return userInfo.roles
      .map(r => r.toLowerCase())
      .indexOf(APP_ROLES.PROGRAM_MANAGEMENT.toLowerCase()) > -1;
  }

  private _isPlanningScenario(userInfo: UserInfo): boolean {
    // return userInfo.roles
    // .map(r => r.toLowerCase())
    // .indexOf(APP_ROLES.PLANNING_SCENARIO.toLowerCase()) > -1;

    // Currently allowing all users acess to planning scenarios.
    // This might be locked down later
    return true;
  }

  private _isOleNepaReview(userInfo: UserInfo): boolean {
    return userInfo.roles
      .map(r => r.toLowerCase())
      .indexOf(APP_ROLES.LEB_NEPA_REVIEW.toLowerCase()) > -1;
  }

  private _isBR(userInfo: UserInfo): boolean {
    return userInfo.roles
      .map(r => r.toLowerCase())
      .indexOf(APP_ROLES.BR.toLowerCase()) > -1;
  }

  private _canDisableSendEmail(userInfo: UserInfo): boolean {
    return CAN_DISABLE_SEND_STAFF
      .map(r => r.toLowerCase())
      .indexOf(userInfo.username.toLowerCase()) > -1;
  }

  private showWarn(title: string, msg: string) {
    this.dialogNoticeService.warn({
      title: title,
      message: msg,
      hasOk: true
    });
  }

}

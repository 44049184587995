import { MultiScannerResults } from './multi-scanner';
import { environment } from 'src/environments/environment';


export class RelatedTableLookups {

  geometries_byPin: { [key: string]: __esri.Graphic[] };
  geometries_byScopeId: { [key: string]: __esri.Graphic[] };

  priorityResults_byPin: { [key: string]: __esri.Graphic[] };
  projectTypes____byPin: { [key: string]: __esri.Graphic[] };
  programTypes____byPin: { [key: string]: __esri.Graphic[] };
  workTypes_______byPin: { [key: string]: __esri.Graphic[] };
  workGroups______byPin: { [key: string]: __esri.Graphic[] };
  counties________byPin: { [key: string]: __esri.Graphic[] };
  iowaStateSenateDistricts_byPIN: { [key: string]: __esri.Graphic[] };
  iowaStateHouseDistricts_byPIN: { [key: string]: __esri.Graphic[] };
  iowaCongressionalDistricts_byPIN: { [key: string]: __esri.Graphic[] };
  projectNumber___byPin: { [key: string]: __esri.Graphic[] };
  commissionConfig__byPin: { [key: string]: __esri.Graphic[] };
  valuationConfig__byPin: { [key: string]: __esri.Graphic[] };

  constructor(multiScannerResults: MultiScannerResults) {
    const createLookup = (features: __esri.Graphic[], field: string) => {
      const lookup: { [key: string]: __esri.Graphic[] } = {};
      features.forEach(f => {
        const key = f.attributes[field];
        if (key != null) {
          if (!lookup[key]) {
            lookup[key] = [];
          }
          lookup[key].push(f);
        }
      });
      return lookup;
    };

    const scopeGeometries = multiScannerResults.scopeGeometry.features.filter(x => x.geometry);
    const pinGeometries = multiScannerResults.pinGeometry.features.filter(x => x.geometry);

    // the field is misnamed PSS_PIN_ID, in reality it's PSS_PIN
    this.geometries_byScopeId = createLookup(scopeGeometries, 'PROJECT_SCOPE_ID');
    this.geometries_byPin = createLookup(pinGeometries, 'PSS_PIN_ID');

    this.priorityResults_byPin = createLookup(multiScannerResults.priorityResults.features, 'PIN');
    this.projectTypes____byPin = createLookup(multiScannerResults.projectTypes.features, 'PIN');
    this.programTypes____byPin = createLookup(multiScannerResults.programTypes.features, 'PIN');
    this.workTypes_______byPin = createLookup(multiScannerResults.workTypes.features, 'PIN');
    this.workGroups______byPin = createLookup(multiScannerResults.workGroups.features, 'PIN');
    this.counties________byPin = createLookup(multiScannerResults.counties.features, 'PIN');
    this.iowaStateSenateDistricts_byPIN = createLookup(multiScannerResults.iowaStateSenateDistricts.features, 'PIN');
    this.iowaStateHouseDistricts_byPIN = createLookup(multiScannerResults.iowaStateHouseDistricts.features, 'PIN');
    this.iowaCongressionalDistricts_byPIN = createLookup(multiScannerResults.iowaCongressionalDistricts.features, 'PIN');
    this.projectNumber___byPin = createLookup(multiScannerResults.projectNumber.features, 'PIN');
    this.valuationConfig__byPin = createLookup(multiScannerResults.valuation.features, 'PIN');
    if (environment.isCommission) {
      this.commissionConfig__byPin = createLookup(multiScannerResults.commissionConfig.features, 'PIN');
    }
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';

const API_KEY = ENDPOINT_CONFIG.AWS.ATTACHMENTS.apiKey;
const BASE_URL = ENDPOINT_CONFIG.AWS.ATTACHMENTS.url;
const S3_URL = ENDPOINT_CONFIG.AWS.ATTACHMENTS.s3Url;

export interface S3File {
  ETag: string;
  FileName: string;
  FolderName: string;
  LastModified: string;
  Key: string;
  SignedUrl: string;
  Size: number;
  StorageClass: string;
}

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  constructor(
    private http: HttpClient
  ) {
  }

  headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'X-Api-Key': API_KEY
    }
  );

  async saveEmailFile(filename: string, content: string) {
    const url = `${BASE_URL}/email-attachments/create`;
    const options = {
      headers: this.headers
    };
    const data = { filename, content };
    const res = await this.http.post(url, data, options).toPromise() as any;
    if (!res.Key) {
      const err = `Could not save email attachment ${filename}`;
      alert(err);
      throw new Error(err);
    }
    return `${S3_URL}/${res.Key}`;
  }

  async postFile(file: File, folder: string): Promise<any> {
    let options = {
      headers: this.headers
    };
    const url = `${BASE_URL}/attachment/signed-url`;
    const s3Key = `${folder}/${file.name}`;
    const data = {
      key: s3Key,
      contentType: file.type // needs to match the Content-Type header
    };
    try {
      const result = await this.http.post(url, data, options).toPromise() as any;
      if (result.signedUrl) {
        options = {
          headers: new HttpHeaders(
            {
              'Content-Type': file.type // needs to match the contentType used to generate the signed url
            }
          )
        };
        // just put the file directly, don't use new FormData();
        const res = await this.http.put(result.signedUrl, file, options).toPromise() as any;
        return res;
      } else {
        throw Error('Unable to generate S3 signed url');
      }

    } catch (err) {
      console.error('postFile ->', err);
      alert(`Could not put s3 file: ${file.name} in folder: ${folder}`);
      return;
    }
  }

  async deleteFile(file: S3File) {
    const url = `${BASE_URL}/attachment/delete`;
    const params = {
      key: file.Key,
    };
    const options = {
      headers: this.headers,
      params: params
    };
    try {
      const res = await this.http.delete(url, options).toPromise();
      return res;
    } catch (err) {
      alert(`Could not delete s3 file: ${file.FileName} in folder: ${file.FolderName}`);
      console.error('deleteFile ->', err);
      return [];
    }
  }

  async getFolderContents(folder: string, signedUrl = true): Promise<S3File[]> {
    const url = `${BASE_URL}/attachments/read?pin=${folder}&signedUrl=${signedUrl}`;
    let res: any;
    const options = {
      headers: this.headers
    };
    try {
      res = await this.http.get(url, options).toPromise();
      // const contents = <any>res.Contents.map(x => x.Key.split('/')[1]).filter(x => x !== '');
      return res.Contents as S3File[];
    } catch (err) {
      alert(`Could not get s3 files for ${folder}`);
      console.error('getFolderContents ->', err);
      return [];
    }
  }

  private convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  private convertFilePromise(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => {
          resolve(btoa(event.target.result.toString()));
        };
      } catch (err) {
        reject(err);
      }
    });
  }

}

import { Injectable } from "@angular/core";

import { FormSubject } from "src/app/types/form-subject";

export const TOTAL_FUNDING_DESCRIPTIONS = 20;
export const TOTAL_PROJECT_PURPOSE_DESCS = 20;

export const DETAILS_PANE_FORM_SUBJ_DEFAULT_VALS = {

  // project tab
  HIGHWAY_CANDIDATE: null,
  PROGRAM_TYPES: [],
  PROJECT_TYPES: [],
  DESCRIPTION: null,
  DISTRICT: null,
  DISTRICT_PRIORITY: null,
  WORK_GROUPS: [],
  WORK_TYPES: [],
  LEVELSTUDYREQUIRED: null,
  INTERCHANGEREQUIRED: null,
  DESIREDFISCALYEAR: (new Date()).getFullYear(),
  DESIRED_FISCAL_MONTH: null,
  ADDITIONAL_JUSTIFICATION: null,
  BUDGETRANGEMIN: null,
  BUDGETRANGEMAX: null,
  DEV_EST_SUM: null,
  PROG_EST_SUM: null,
  TRAFFICCRITICAL: null,
  OUTSIDESERVICES: null,
  PD_COMPLETED_BY: null,
  SURVEY_REQUIRED: null,

  HOLD_FISCAL_YEAR: 0,

  FUNDING_DESCRIPTION_0: null,
  FUNDING_DESCRIPTION_1: null,
  FUNDING_DESCRIPTION_2: null,
  FUNDING_DESCRIPTION_3: null,
  FUNDING_DESCRIPTION_4: null,
  FUNDING_DESCRIPTION_5: null,
  FUNDING_DESCRIPTION_6: null,
  FUNDING_DESCRIPTION_7: null,
  FUNDING_DESCRIPTION_8: null,
  FUNDING_DESCRIPTION_9: null,
  FUNDING_DESCRIPTION_10: null,
  FUNDING_DESCRIPTION_11: null,
  FUNDING_DESCRIPTION_12: null,
  FUNDING_DESCRIPTION_13: null,
  FUNDING_DESCRIPTION_14: null,
  FUNDING_DESCRIPTION_15: null,
  FUNDING_DESCRIPTION_16: null,
  FUNDING_DESCRIPTION_17: null,
  FUNDING_DESCRIPTION_18: null,
  FUNDING_DESCRIPTION_19: null,
  FUNDING_DESCRIPTION_20: null,
  FUNDING_DESCRIPTION_21: null,
  FUNDING_DESCRIPTION_22: null,
  FUNDING_DESCRIPTION_23: null,

  PROJECT_PURPOSE_DESC_0: null,
  PROJECT_PURPOSE_DESC_1: null,
  PROJECT_PURPOSE_DESC_2: null,
  PROJECT_PURPOSE_DESC_3: null,
  PROJECT_PURPOSE_DESC_4: null,
  PROJECT_PURPOSE_DESC_5: null,
  PROJECT_PURPOSE_DESC_6: null,
  PROJECT_PURPOSE_DESC_7: null,
  PROJECT_PURPOSE_DESC_8: null,
  PROJECT_PURPOSE_DESC_9: null,
  PROJECT_PURPOSE_DESC_10: null,
  PROJECT_PURPOSE_DESC_11: null,
  PROJECT_PURPOSE_DESC_12: null,
  PROJECT_PURPOSE_DESC_13: null,
  PROJECT_PURPOSE_DESC_14: null,
  PROJECT_PURPOSE_DESC_15: null,
  PROJECT_PURPOSE_DESC_16: null,
  PROJECT_PURPOSE_DESC_17: null,
  PROJECT_PURPOSE_DESC_18: null,
  PROJECT_PURPOSE_DESC_19: null,
  PROJECT_PURPOSE_DESC_20: null,
  PROJECT_PURPOSE_DESC_21: null,
  PROJECT_PURPOSE_DESC_22: null,
  PROJECT_PURPOSE_DESC_23: null,

  // geometry
  PRIMARY_ROUTE: null,

  // row ole tab
  ROWNEED: null,
  ROWRELOCATION: null,
  TANDE: null,
  CULTURALSITE: null,
  HISTORICALSITE: null,
  LEVIESAFFECTED: null,
  CONCURRENCEPOINT: 0,
  LEVELOFDOCUMENTATION: 4,

  // Impact tab
  IMPACT_DISTANCE: 0.25
};

@Injectable({
  providedIn: "root"
})
export class DetailsPaneFormSubjects {

  // project tab
  HIGHWAY_CANDIDATE: FormSubject<number>;
  PROGRAM_TYPES = new FormSubject<number[]>([]);
  PROJECT_TYPES = new FormSubject<number[]>([]);
  DESCRIPTION: FormSubject<string>;
  DISTRICT: FormSubject<number>;
  DISTRICT_PRIORITY: FormSubject<number>;
  WORK_GROUPS = new FormSubject<number[]>([]);
  WORK_TYPES = new FormSubject<number[]>([]);
  LEVELSTUDYREQUIRED: FormSubject<number>;
  INTERCHANGEREQUIRED: FormSubject<number>;
  DESIREDFISCALYEAR: FormSubject<number>;
  DESIRED_FISCAL_MONTH: FormSubject<number>;
  ADDITIONAL_JUSTIFICATION: FormSubject<string>;
  BUDGETRANGEMIN: FormSubject<number>; // scopes only
  BUDGETRANGEMAX: FormSubject<number>; // scopes only
  DEV_EST_SUM: FormSubject<number>; // pins only
  PROG_EST_SUM: FormSubject<number>; // pins only
  TRAFFICCRITICAL: FormSubject<number>;
  OUTSIDESERVICES: FormSubject<number>; // scopes only
  PD_COMPLETED_BY: FormSubject<number>;
  SURVEY_REQUIRED: FormSubject<number>;

  HOLD_FISCAL_YEAR: FormSubject<number>; // value needs to be translated to ensure it is saved as 1 or 0 rather than boolean

  FUNDING_DESCRIPTION_0: FormSubject<string>;
  FUNDING_DESCRIPTION_1: FormSubject<string>;
  FUNDING_DESCRIPTION_2: FormSubject<string>;
  FUNDING_DESCRIPTION_3: FormSubject<string>;
  FUNDING_DESCRIPTION_4: FormSubject<string>;
  FUNDING_DESCRIPTION_5: FormSubject<string>;
  FUNDING_DESCRIPTION_6: FormSubject<string>;
  FUNDING_DESCRIPTION_7: FormSubject<string>;
  FUNDING_DESCRIPTION_8: FormSubject<string>;
  FUNDING_DESCRIPTION_9: FormSubject<string>;
  FUNDING_DESCRIPTION_10: FormSubject<string>;
  FUNDING_DESCRIPTION_11: FormSubject<string>;
  FUNDING_DESCRIPTION_12: FormSubject<string>;
  FUNDING_DESCRIPTION_13: FormSubject<string>;
  FUNDING_DESCRIPTION_14: FormSubject<string>;
  FUNDING_DESCRIPTION_15: FormSubject<string>;
  FUNDING_DESCRIPTION_16: FormSubject<string>;
  FUNDING_DESCRIPTION_17: FormSubject<string>;
  FUNDING_DESCRIPTION_18: FormSubject<string>;
  FUNDING_DESCRIPTION_19: FormSubject<string>;
  FUNDING_DESCRIPTION_20: FormSubject<string>;
  FUNDING_DESCRIPTION_21: FormSubject<string>;
  FUNDING_DESCRIPTION_22: FormSubject<string>;
  FUNDING_DESCRIPTION_23: FormSubject<string>;

  PROJECT_PURPOSE_DESC_0: FormSubject<string>;
  PROJECT_PURPOSE_DESC_1: FormSubject<string>;
  PROJECT_PURPOSE_DESC_2: FormSubject<string>;
  PROJECT_PURPOSE_DESC_3: FormSubject<string>;
  PROJECT_PURPOSE_DESC_4: FormSubject<string>;
  PROJECT_PURPOSE_DESC_5: FormSubject<string>;
  PROJECT_PURPOSE_DESC_6: FormSubject<string>;
  PROJECT_PURPOSE_DESC_7: FormSubject<string>;
  PROJECT_PURPOSE_DESC_8: FormSubject<string>;
  PROJECT_PURPOSE_DESC_9: FormSubject<string>;
  PROJECT_PURPOSE_DESC_10: FormSubject<string>;
  PROJECT_PURPOSE_DESC_11: FormSubject<string>;
  PROJECT_PURPOSE_DESC_12: FormSubject<string>;
  PROJECT_PURPOSE_DESC_13: FormSubject<string>;
  PROJECT_PURPOSE_DESC_14: FormSubject<string>;
  PROJECT_PURPOSE_DESC_15: FormSubject<string>;
  PROJECT_PURPOSE_DESC_16: FormSubject<string>;
  PROJECT_PURPOSE_DESC_17: FormSubject<string>;
  PROJECT_PURPOSE_DESC_18: FormSubject<string>;
  PROJECT_PURPOSE_DESC_19: FormSubject<string>;
  PROJECT_PURPOSE_DESC_20: FormSubject<string>;
  PROJECT_PURPOSE_DESC_21: FormSubject<string>;
  PROJECT_PURPOSE_DESC_22: FormSubject<string>;
  PROJECT_PURPOSE_DESC_23: FormSubject<string>;

  // geometry
  PRIMARY_ROUTE: FormSubject<string>;

  // row ole tab
  ROWNEED: FormSubject<number>;
  ROWRELOCATION: FormSubject<number>;
  TANDE: FormSubject<number>;
  CULTURALSITE: FormSubject<number>;
  HISTORICALSITE: FormSubject<number>;
  LEVIESAFFECTED: FormSubject<number>;
  CONCURRENCEPOINT: FormSubject<number>;
  LEVELOFDOCUMENTATION: FormSubject<number>; // Environmental Classification

  // Impact Tab
  IMPACT_DISTANCE: FormSubject<number>;

  constructor() {
    Object.keys(DETAILS_PANE_FORM_SUBJ_DEFAULT_VALS).forEach(key => {
      this[key] = new FormSubject(DETAILS_PANE_FORM_SUBJ_DEFAULT_VALS[key]);
    });
  }
}

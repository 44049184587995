// this interface describes the attributes that a PIN has
// this is a merge between the attributes of the Project Scoping layer,
// the Project Prority Results (or "Combined" Results) layer,
// the Project Priority Valuation layer,
// and the fields for the lookups:
// PROJECT_TYPES (Project Purpose), PROGRAM_TYPES (Program Funding), WORK_TYPES (Work Codes), WORK_GROUPS, COUNTIES
// fields are set to null so that amalgamator.fields can make sure the properties match the fields that are set

import {NumberField} from "../export-and-doc/pininfo";

export class PinAttributes {
  constructor() {
    return
  }

  // From Project Scoping
  OBJECTID: number = null;
  OBJECTIVES: string = null;
  DESCRIPTION: string = null;
  LEVELSTUDYREQUIRED: number = null;
  DESIREDFISCALYEAR: number = null;
  TRAFFICCRITICAL: number = null;
  LEVIESAFFECTED: number = null;
  INTERCHANGEREQUIRED: number = null;
  OUTSIDESERVICES: number = null;
  ROWNEED: number = null;
  ACQUISITIONANTICIPATED: number = null;
  ROWRELOCATION: number = null;
  TITLEANTICIPATED: number = null;
  EASEMENTANTICIPATED: number = null;
  ROWDISPOSAL: number = null;
  TANDE: number = null;
  CULTURALSITE: number = null;
  HISTORICALSITE: number = null;
  FLOODPLAIN: number = null;
  CONCURRENCEPOINT: number = null;
  LEVELOFDOCUMENTATION: number = null;
  BUDGETRANGEMIN: number = null;
  BUDGETRANGEMAX: number = null;
  CREATED_USER: string = null;
  CREATED_DATE: number = null;
  LAST_EDITED_USER: string = null;
  LAST_EDITED_DATE: number = null;
  USERNAME: string = null;
  PIN_ID: number = null;
  PIN: string = null;
  STATUS: string = null;
  PROJECT_TYPE: string = null;
  MITIGATION: string = null;
  PERMIT_404_REQUIRED: string = null;
  FIRST_LETTING: number = null;
  PROG_EST_SUM: number = null;
  DEV_EST_SUM: number = null;
  FUNDS: string = null;
  HIGHWAY_CLASS: string = null;
  RECORD_LAST_UPDATED: number = null;
  CREATION_DATE: number = null;
  ACCESS_CONTROL: string = null;
  BEGIN_MILEPOST: string = null;
  CORRIDOR: string = null;
  DISTRICT: number = null;
  END_MILEPOST: string = null;
  LIGHTING: string = null;
  MATERIALS_ENGINEER: string = null;
  MILES: number = null;
  PROJECT_MANAGER: string = null;
  RAILROAD_INVOLVED: string = null;
  SEGMENT_MANAGER: string = null;
  TRAFFIC_SIGNALS: string = null;
  TRAFFIC_SIGNS: string = null;
  UTILITIES: string = null;
  UTILITIES_IMPACTED: string = null;
  UTILITY_STATUS: string = null;
  REST_UPDATED: number = null;
  PARK_INSTITUTION: string = null;
  UTC_OFFSET: string = null;
  PRIMARY_ROUTE: string = null;
  DISTRICT_PRIORITY: number = null;
  HOLD_FISCAL_YEAR: number = null;
  PD_COMPLETED_BY: number = null;
  PD_COMPLETED_BY_OTHER: string = null;
  DESIRED_FISCAL_MONTH: number = null;
  READABLE_PRIMARY_ROUTE: string = null;
  PROJECT_SCOPE_ID: string = null;
  APPROVAL_BATCH: string = null;
  NEXT_APPROVER: string = null;
  SURVEY_REQUIRED: number = null;
  ADDITIONAL_JUSTIFICATION: string = null;
  CONSTRUCTION_EVENT: number = null;
  IMPACT_DISTANCE: number = null;
  MIN_ZOOM: number = null;
  MAX_ZOOM: number = null;
  HIGHWAY_CANDIDATE: number = null;
  LRTP_CAPACITY_CONSISTENCY: number = null;
  LRTP_OPERATIONS_CONSISTENCY: number = null;
  LRTP_ICE_CONSISTENCY: number = null;
  LRTP_PCR_CONSISTENCY: number = null;
  LRTP_RESILIENCY_CONSISTENCY: number = null;
  LRTP_SUPER2_CONSISTENCY: number = null;
  LRTP_PNR_CANDIDATE_COUNT: number = null;
  LRTP_BRIDGE_COUNT: number = null;
  LRTP_TOTAL_BOTTLENECKS: number = null;
  LRTP_TOTAL_FREIGHTBOTTLENECKS: number = null;

  // From Project Priority Results
  // skip fields in COMBINED_RESULTS_FIELD_EXCLUDE from amalgamator.fields.ts
  // OBJECTID: number = null;
  // PIN_ID: number = null;
  // PIN: string = null;
  SAFETY_SCORE: number = null;
  IMPORTANCE_SCORE: number = null;
  PP_MODEL_RUN_DATE: number = null;
  SICL_INTERSECTION_FLAG: number = null;
  FREIGHT_SCORE: number = null;
  PERFORMANCE_SCORE: number = null;
  PAVEMENT_FLAG: number = null;
  PAVEMENT_SCORE: number = null;
  BRIDGE_SCORE: number = null;
  TOTAL_CRASHES: number = null;
  TOTAL_FATAL_CRASHES: number = null;
  TRAFFIC_SCORE: number = null;
  BRIDGE_FLAG: number = null;
  TRAFFIC_FLAG: number = null;
  SAFETY_FLAG: number = null;
  SOCIETAL_CRASH_COST: number = null;
  TOTAL_MAJINJ_CRASHES: number = null;
  MOBILITY_SCORE: number = null;
  INRIX_SCORE: number = null;
  ATMS_SCORE: number = null;
  COST_OF_CONGESTION: number = null;
  COMM_COST_CONGESTION: number = null;
  MIN_AN_CONGESTION_HRS: number = null;
  MAX_AN_CONGESTION_HRS: number = null;
  AVG_AN_CONGESTION_HRS: number = null;
  ATMS_EVENTS_PERMILE: number = null;
  ANNUAL_BOTTLENECKS: number = null;
  ACCESSIBILITYSCORE: number = null;
  VOLUMEPERHOUR: number = null;
  THROUGHVEHICLEDELAY: number = null;
  UNDER_CONSTRUCTION: string = null;
  COUNTY_ROAD_DENSITY_SCORE: number = null;
  TOTAL_PROG_EST_SUM: number = null;
  TOTAL_DEV_EST_SUM: number = null;
  COST_SCORE: number = null;
  FINANCING_SCORE: number = null;
  CONTEXT_SCORE: number = null;
  TECHNICAL_SCORE: number = null;
  SCHEDULE_SCORE: number = null;
  AREA_UNDER_CURVE: number = null;
  FINANCING_FLAG: number = null;
  CONTEXT_FLAG: number = null;
  TECHNICAL_FLAG: number = null;
  SCHEDULE_FLAG: number = null;
  PARENT_PIN: string = null;
  ACTUALEVENTDATEWARNING: number = null;
  EVENTSNOTSTARTED: number = null;
  EVENTS_AHEAD: number = null;
  EVENTS_BEHIND: number = null;
  RANK: number = null;
  TOTAL_EVENTS: number = null;
  PERCENTILE_RANK: string = null;
  PROGRAMMED_RANK: number = null;
  NOT_PROGRAMMED_RANK: number = null;
  // REST_UPDATED: number = null;
  // UTC_OFFSET: string = null;
  FATAL_COST_VALUE: number = null;
  FATAL_CRASHES: number = null;
  TOTAL_FATAL_COST: number = null;
  DISABLING_INJURY_COST_VALUE: number = null;
  TOTAL_DISABLING_INJURY_CRASHES: number = null;
  TOTAL_DISABLING_INJURY_COST: number = null;
  EVIDENT_INJURY_COST_VALUE: number = null;
  TOTAL_EVIDENT_INJURY_CRASHES: number = null;
  TOTAL_EVIDENT_INJURY_COST: number = null;
  POSSIBLE_INJURY_COST_VALUE: number = null;
  TOTAL_POSSIBLE_INJURY_CRASHES: number = null;
  TOTAL_POSSIBLE_INJURY_COST: number = null;
  PDO_COST_VALUE: number = null;
  TOTAL_PDO_CRASHES: number = null;
  TOTAL_PDO_COST: number = null;
  CRASH_YEARS: string = null;
  // PROJECT_SCOPE_ID: string = null;


  // From Project Priority Valuation
  // skip fields in VALUATION_FIELD_EXCLUDE from amalgamator.fields.ts
  // OBJECTID: number = null;
  PAVEMENT_ADDED_VALUE: number = null;
  PAVEMENT_PROJECT_COSTS: number = null;
  PAVEMENT_ROI: number = null;
  BRIDGE_ADDED_VALUE: number = null;
  BRIDGE_PROJECT_COSTS: number = null;
  BRIDGE_ROI: number = null;
  SAFETY_CRASH_SAVINGS: number = null;
  SAFETY_PROJECT_COSTS: number = null;
  SAFETY_ROI: number = null;
  OPERATIONS_DELAY_SAVINGS: number = null;
  OPERATIONS_PROJECT_COST: number = null;
  OPERATIONS_ROI: number = null;
  OTHER_ADDED_VALUE: number = null;
  OTHER_PROJECT_COSTS: number = null;
  OTHER_ROI: number = null;
  OTHER_DESCRIPTION: string = null;
  MISCELLANEOUS_PROJECT_COSTS: number = null;
  TOTAL_PROJECT_VALUE_ADDED: number = null;
  TOTAL_PROJECT_COSTS: number = null;
  TOTAL_PROJECT_ROI: number = null;
  PAVEMENT_PERCENT_PROJECT_COST: number = null;
  BRIDGE_PERCENT_PROJECT_COST: number = null;
  SAFETY_PERCENT_PROJECT_COST: number = null;
  OPERATIONS_PERCENT_PROJ_COST: number = null;
  OTHER_PERCENT_PROJECT_COST: number = null;
  MISC_PERCENT_PROJECT_COST: number = null;
  // PIN: string = null;
  // PIN_ID: number = null;

  // From lookup tables
  PROJECT_TYPES: string = null; // comma separated
  PROGRAM_TYPES: string = null; // comma separated
  WORK_TYPES: string = null; // comma separated
  WORK_GROUPS: string = null; // comma separated
  COUNTIES: string = null; // comma separated
  IOWA_STATE_SENATE_DISTRICTS: string = null; // comma separated
  IOWA_STATE_HOUSE_DISTRICTS: string = null; // comma separated
  IOWA_CONGRESSIONAL_DISTRICTS: string = null; // comma separated
  IOWA_US_HOUSE_REP_NAME: string = null; // comma separated

  // JSON snapshots
  PROGRAM_TYPES_JSON: string = null;
  PROJECT_TYPES_JSON: string = null;
  ROUTES_AND_MEASURES_JSON: string = null;

  // Flag to indicate if geometry for this Scope/PIN exists in Project Geometry
  HAS_GEOMETRY: 1 | 0 = null;

  // Stage used to specify hybrid of status and having or not having PROG_EST_SUM
  STAGE: string = null;

  // FROM Project Number Table
  PROJECT_NUMBER: string = null;

  // Flag to indicate a 'scope to pin' project
  IS_SCOPE_TO_PIN: 1 | 0 = null;
}

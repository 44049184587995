import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Scan, ScanResults } from './scan';
import { esriRequest } from 'src/esri/request';
import { DialogNoticeService } from 'src/app/services/dialog-notice.service';

// The ParallelScanner is used to aquire all the features from
// a given endpoint by splitting queries into seperate requests
// that can be made in parallel.

class ScanPlan {
  url: string;
  where: string;
  gdbVersion: string;
  slices: {
    skip: number;
    take: number;
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class ParallelScanner {

  constructor(
    private dialogNoticeService: DialogNoticeService,
  ) {
  }

  // Story 1806 Migrate Geometry into RAMS - updated to handle gdbVersion
  scan(params: { url: string, where?: string, outFields?: string, gdbVersion?: string, take?: number }): Scan {
    const progress = new BehaviorSubject<number>(0);
    const where = typeof params.where === 'string' ? params.where : '1=1';

    const scanResults: Promise<ScanResults> = (async () => {
      const plan = await this.getScanPlan(params.url, where, params.take, params.gdbVersion);
      // check the batch size
      if (plan.slices.length > 75) { // I have no idea how this is determined, but 63 is the highest number of slices I've seen, so 75 is a good guess?
        const msg = `Loading ${plan.slices.length} slices:  Too many request slices will result in memory error. Please contact the developer.`;
        await this.dialogNoticeService.warn({
          title: 'DATA LOADING ISSUE',
          hasOk: true,
          message: msg
        });
        console.error(msg, plan);
        // throw new Error(msg);
      }
      return this.execScanPlan(plan, progress, params.outFields || '*');
    })();

    return <Scan>{
      loadingProgress: progress,
      results: scanResults
    };
  }

  // Story 1806 Migrate Geometry into RAMS - updated to handle gdbVersion
  private async getScanPlan(url: string, where: string, maxCountOverride: number, _gdbVersion?: string): Promise<ScanPlan> {
    const gdbVersion = _gdbVersion || '';
    const plan: ScanPlan = {
      url: url,
      where: where,
      gdbVersion: gdbVersion,
      slices: []
    };

    const count = await this.getCount(url, where, gdbVersion);
    if (!count) {
      this.handleCountError({ count, url, where, throw: count !== 0 });
    }

    const take = maxCountOverride || 1000;

    for (let skip = 0; skip < count; skip += take) {
      plan.slices.push({
        skip: skip,
        take: take
      });
    }
    return plan;
  }

  private async execScanPlan(plan: ScanPlan, progress: BehaviorSubject<number>, outFields: string): Promise<ScanResults> {
    let completed = 0;
    const totalRequests = plan.slices.length + 1; // +1 for the request to get fields
    const increment = () => {
      completed++;
      progress.next(Math.floor((completed / totalRequests) * 100));
    };

    const fieldsPromise = (async () => {
      const _fields = await this.getFields(plan.url);
      increment();
      return _fields;
    })();
    const slicePromises = plan.slices.map(async x => {
      const slice = await this.getSlice(plan.url, plan.where, x.skip, x.take, outFields, plan.gdbVersion);
      increment();
      return slice;
    });
    const [slices, fields] = await Promise.all([
      Promise.all(slicePromises),
      fieldsPromise
    ]);
    const features = slices.reduce((a, b) => a.concat(b));

    return <ScanResults>{ features, fields };
  }

  private async getFields(url: string) {
    const res = await esriRequest(url, {
      query: {
        f: 'json'
      }
    });
    return res.data.fields;
  }

  // Story 1806 Migrate Geometry into RAMS - updated to handle gdbVersion
  private async getCount(url: string, where: string, _gdbVersion?: string) {
    const gdbVersion = _gdbVersion || '';
    const res = await esriRequest(url + '/query', {
      query: {
        where: where,
        gdbVersion: gdbVersion,
        returnCountOnly: true,
        f: 'json'
      }
    });
    return res.data.count;
  }

  // Story 1806 Migrate Geometry into RAMS - updated to handle gdbVersion
  private async getSlice(url: string, where: string, skip: number, take: number, outFields: string, _gdbVersion?: string) {
    const gdbVersion = _gdbVersion || '';
    const query = {
      where,
      outFields,
      outSR: 3857,
      gdbVersion: gdbVersion,
      resultOffset: skip,
      resultRecordCount: take,
      f: 'json'
    };
    try {
      const res = await esriRequest(url + '/query', {
        query: query
      });
      return res.data.features;
    } catch (err) {
      console.error(`getSlice() Error: ${url}`, query);
    }
  }

  private async handleCountError(params: { count: any; url: string; where: string; throw: boolean }) {
    const jsonRes = JSON.stringify(params.count);
    const errMsg = `Endpoint unexpectedly returned count ${jsonRes}. ${params.url} where: ${params.where}`;
    await this.dialogNoticeService.error(
      {
        title: 'SERVICE LOAD ERROR',
        message: errMsg
      });
    // alert(errMsg);
    if (params.throw) {
      throw new Error(errMsg);
    }
  }
}

import { RxLayerOptions, SpatialReference } from './rx-layer-options';
import { BehaviorSubject, combineLatest, merge, Subscription } from 'rxjs';

// ArcGIS Core
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Renderer from '@arcgis/core/renderers/Renderer';

import { Filter } from './filter';
import { generatePopupTemplate } from './generate-popup-template';
import { filtersToSql } from './filters-to-sql/filters-to-sql';
import { FormSubject } from 'src/app/types/form-subject';
import { RxLayerTableData } from './rx-layer-table-data';
import { DISPLAY_FIELD_OVERRIDES } from './display-field-overrides';
import { esriRequest } from 'src/esri/request';
import { AppInjectorService } from '../services/app-injector.service';
import { RxLayersMgmtService } from './rx-layers-mgmt.service';
import { LoggingService } from '../services/logging.service';

// The RxLayer is meant to be used in a context where
// * layers will be turned on and off on the map
// * counts and tabular data need to be displayed
// * features need to be filtered

// "Rx" refers to rxjs, since the Observable and BehaviorSubject classes are used heavily to manage value-change events.

// The "only update while observabled" principle:
// A key strength of using this class is that it refrains from making http requests for things that aren't being displayed.
// The custom MonitoredBehaviorSubject class (used in RxLayerTableControl) keeps track
// of whether any part of the app is subscribing to certain data points.
// For example, if some part of the app is subscribing to tableControl.filteredCount, then the necessary http request is made to get the
// updated count when filters change. However, if there are no subscribers, the request is not sent.
// Following the same principle, the underlying FeatureLayer class will not be instantiated until visible is set to true.

export class RxLayer {

  title: string;
  spatialReference: SpatialReference;
  filters: BehaviorSubject<Filter[]>; // may be set externally by filter-bar component
  visible: FormSubject<boolean>; // use visible.setValue() externally to set visibility
  opacity: FormSubject<number>; // use opacity.setValue() externally to set opacity
  baseWhereClause: BehaviorSubject<string>; // use baseWhereClause.next() to set externally
  heatModeEnabled: FormSubject<boolean>; // use heatMode.setValue() externally to set visibility, is heat mode enabled
  heatModeSupported: BehaviorSubject<boolean>; // use heatModeSupported.next() to set externally
  heatModeFields: BehaviorSubject<__esri.Field[]>; // use heatModeFields.next() to set externally
  error = new BehaviorSubject<{ msg: string; url: string }>(null);
  nonDefaultCols: boolean; // used in hash generation
  minScale: number; // used layers scale visibility
  maxScale: number; // used layers scale visibility

  // destroyed - notification
  private _destroyed = new BehaviorSubject<boolean>(null);
  destroyed = this._destroyed.asObservable();

  private _id: string; // uniq id generated by constructor
  private _featureLayer: __esri.FeatureLayer; // instantiated when visible is true or this.fieldsPromise getter is called
  private _geometryType: BehaviorSubject<string>; // use geometryType.next() to set externally, get the layer geometry type
  private _defaultRenderer: BehaviorSubject<any>; // use restoreDefaultRenderer() to set externally, stores the layers default renderer
  private _currentRenderer: BehaviorSubject<any>; // use setRenderer() to set externally, store the layers current renderer
  private _subs: Subscription[] = [];
  private _popupTemplate: __esri.PopupTemplate;

  // services - set via injector
  private rxLayersMgmtService: RxLayersMgmtService;
  private logging: LoggingService;

  private _fieldsPromise: Promise<__esri.Field[]>;
  get fieldsPromise(): Promise<__esri.Field[]> {
    if (!this._fieldsPromise) {
      this._fieldsPromise = this.getFields();
    }
    return this._fieldsPromise;
  }

  get serviceInfo(): Promise<any> {
    return this.getServiceInfo();
  }

  private _visibleFieldsPromise: Promise<__esri.Field[]>;
  get visibleFieldsPromise(): Promise<__esri.Field[]> {
    if (!this._visibleFieldsPromise) {
      this._visibleFieldsPromise = (async () => {
        const fields = await this.fieldsPromise;
        return this.options.hideFields
          ? fields.filter(f => this.options.hideFields.indexOf(f.name) === -1)
          : fields;
      })();
    }
    return this._visibleFieldsPromise;
  }

  private _wholeWhereClause: BehaviorSubject<string>;
  get wholeWhereClause(): BehaviorSubject<string> {
    if (!this._wholeWhereClause) {
      this._wholeWhereClause = new BehaviorSubject<string>('1=1');
      let token;
      this._subs.push(
        merge(this.filters, this.baseWhereClause).subscribe(async () => {
          token = Math.random();
          const _token = token;
          const sql = await filtersToSql(this.filters.value, this.baseWhereClause.value);
          if (token === _token && sql !== this._wholeWhereClause.value) {
            this._wholeWhereClause.next(sql);
          }
        })
      );
    }
    return this._wholeWhereClause;
  }

  private _tableData: RxLayerTableData;
  get tableData(): RxLayerTableData {
    if (!this._tableData) {
      this._tableData = new RxLayerTableData(this, this.options);
    }
    return this._tableData;
  }

  get tableDataSelectedRows(): any[] {
    const td: RxLayerTableData = this.tableData;
    if (td.selection.selected.length > 0) {
      const selectedRows = td.tableRows.value.filter(t => {
        return td.selection.selected.some(s => {
          return s === t.OBJECTID;
        });
      });
      return selectedRows;
    } else {
      return null;
    }
  }

  get tableDataSelectedPins(): string[] {
    const selectedRows = this.tableDataSelectedRows;
    if (selectedRows.length > 0) {
      const selectedPins = selectedRows.map(s => s.PIN);
      return selectedPins;
    } else {
      return null;
    }
  }

  _url: string; // used by hash generator

  get url(): string {
    return this._url;
  }

  get featureLayerId(): string {
    if (this._featureLayer) {
      return this._featureLayer.id;
    }
    return null;
  }

  get featureLayer(): FeatureLayer {
    if (this._featureLayer) {
      return this._featureLayer;
    }
    return null;
  }

  get defaultRenderer() {
    return this._defaultRenderer;
  }

  get id() {
    return this._id;
  }

  constructor(private options: RxLayerOptions) {
    // Manually retrieve the dependencies from the injector
    // so that constructor has no dependencies
    const injector = AppInjectorService.getInjector();
    this.rxLayersMgmtService = injector.get(RxLayersMgmtService);
    this.logging = injector.get(LoggingService);
    this.initialConstruction(options);
  }

  private initialConstruction(options: RxLayerOptions) {
    this._url = options.url;
    this.title = options.title;
    this.filters = new BehaviorSubject<Filter[]>(options.filters || []);
    this.visible = new FormSubject<boolean>(!!options.visible);
    this.opacity = new FormSubject<number>(1);
    this.baseWhereClause = new BehaviorSubject<string>(options.baseWhereClause || '');
    this.heatModeEnabled = new FormSubject<boolean>(false);
    this.heatModeSupported = new BehaviorSubject<boolean>(false); // defaults to not supported
    this.heatModeFields = new BehaviorSubject<__esri.Field[]>([]);
    this._geometryType = new BehaviorSubject<string>('');
    this._defaultRenderer = new BehaviorSubject<any>(null);
    this._currentRenderer = new BehaviorSubject<any>(null);
    this.spatialReference = options.spatialReference;
    this.minScale = options?.minScale;
    this.maxScale = options?.maxScale;

    this._id = `rxLayer-${this.createGuid()}`;

    // subscribe to geometry being set
    this._subs.push(
      this._geometryType.subscribe(type => {
        // check geometry type
        if (type && type.length > 0) {
          const geomType = type.toLowerCase();
          // if point layer, enable heat mode support
          if (geomType === 'esrigeometrypoint' || geomType === 'point') {
            this.heatModeSupported.next(true);
          }
        }
      })
    );

    // subscribe to renderer being set
    this._subs.push(
      this._currentRenderer.subscribe(renderer => {
        if (renderer && this._featureLayer && this._featureLayer.loaded) {
          this._featureLayer.renderer = renderer;
        }
      })
    );

    // subscribe to a filter being applied, if heat mode is enabled we need to update it
    this._subs.push(
      this.filters.subscribe(filter => {
        if (filter.length > 0 && this.heatModeEnabled.value) {
          if (!filter[0].active) {
            const renderer = this._currentRenderer.value;
            this.restoreDefaultRenderer();
            this.applyHeatMode(renderer);
          }
        }
      })
    );

    // subscribe to feature layer visible
    this._subs.push(
      this.visible.valueStream.subscribe(async v => {
        // this.logging.log('🚀 ~ file: rx-layer.ts ~ RxLayer ~ visible.valueStream ~ [title, visible]', [this.title, v]);
        try {
          if (this._featureLayer) {
            this._featureLayer.visible = v;
          } else if (v) {
            // don't instantiate FeatureLayer until it should be visible
            if (this._featureLayer) {
              this._featureLayer.visible = v;
              this.addLayerToMap();
            } else {
              if (this.options.map) {
                await this.instantiateFeatureLayer();
                this.addLayerToMap();
              }
            }
          }
        } catch (err) {
          console.error(err);
        }
      })
    );

    // register RxLayer Instance with service
    // this allows us to dispose of the layers later, if needed
    this.rxLayersMgmtService.register(this);
  }

  // set FeatureLayer renderer
  applyHeatMode(renderer: any) {
    this._currentRenderer.next(renderer);
    this.heatModeEnabled.setValue(true);
  }

  disablePopupTemplate() {
    this._featureLayer.popupTemplate = null;
  }

  enablePopupTemplate() {
    if (this._featureLayer && this._popupTemplate) {
      this._featureLayer.popupTemplate = this._popupTemplate;
    }
  }

  // renderer for the layer
  setRenderer(renderer: any) {
    if (renderer !== this._currentRenderer.value) {
      this._currentRenderer.next(renderer);
    }
  }

  // restore the default renderer for the layer
  restoreDefaultRenderer() {
    if (this._defaultRenderer && this._defaultRenderer.value) {
      this._currentRenderer.next(this._defaultRenderer.value);
      this.heatModeEnabled.setValue(false);
    } else {
      console.error('this._defaultRenderer is undefined');
    }
  }

  async queryFeatures(query, abortSignal?: AbortSignal, returnFeatureSet = false) {
    if (this.options.clientSideGraphicOptions) {
      if (!this._featureLayer) {
        if (!this._featureLayer) {
          await this.instantiateFeatureLayer();
        }
      }
      const results = (await this._featureLayer.queryFeatures(query));
      if (!returnFeatureSet) {
        return results.features;
      }
      return results;
    } else {
      const params: __esri.RequestOptions = {
        query: query,
        method: 'post'
      };
      if (abortSignal) {
        params.signal = abortSignal;
      }
      const res = await esriRequest(this.options.url + '/query', params);
      if (!returnFeatureSet) {
        return res.data.features;
      }
      return res.data as __esri.supportFeatureSet;
    }
  }

  async queryCount(query, abortSignal?: AbortSignal) {
    if (query.where && query.where === '1=1') {
      return 0;
    }
    if (this.options.clientSideGraphicOptions) {
      if (!this._featureLayer) {
        await this.instantiateFeatureLayer();
      }
      return (await this._featureLayer.queryFeatures(query)).features.length;
    } else {
      const params: __esri.RequestOptions = {
        query,
        method: 'post'
      };
      if (abortSignal) {
        params.signal = abortSignal;
      }
      const res = await esriRequest(this.options.url + '/query', params);
      return res ? res.data.count : 0;
    }
  }

  async applyEdits(edits) {
    if (!this.options.clientSideGraphicOptions) {
      console.error('Apply edits to layers without client-side graphics is not permitted via RxLayer.');
      return;
    }
    // If we pass empty geometry .applyEdits() will error, need check for, then add "fake geometry"
    const editsClean = this._fillNullGeometry(edits);
    return await this._featureLayer.applyEdits(editsClean);
  }

  dispose() {
    this._subs.forEach(sub => sub.unsubscribe());
    if (this._tableData) {
      this._tableData.dispose();
      this._tableData = null;
    }
    // this causes console errors that don't really matter
    // eventually it would be good to find a way to destory and handle any issues caused by it
    if (this._featureLayer) {
      this.options.map.remove(this._featureLayer);
      // this._featureLayer.destroy();
      this._destroyed.next(true);
    }
  }

  private createGuid() {
    const template = 'xxxx-xxxx-xxxxxxxxxxxx';
    return template.split('').map(c => {
      return c === '-' ? '-' : this.rndHexChar();
    }).join('');
  }

  private rndHexChar() {
    const rnd = Math.floor(Math.random() * 16);
    return rnd.toString(16);
  }

  // filter array of fields get integer type, then set
  private setHeatModeFields(fields: __esri.Field[]) {
    const heatFields = fields.filter(f => {
      return f.type.toLowerCase().indexOf('integer') > -1;
    });
    this.heatModeFields.next(heatFields);
  }

  private _fillNullGeometry(edits: __esri.FeatureLayerApplyEditsEdits): __esri.FeatureLayerApplyEditsEdits {
    Object.keys(edits).forEach(key => {
      const edit = edits[key];
      edit.forEach(item => {
        Object.keys(item).forEach(prop => {
          if (prop === 'geometry') {
            const geom = item[prop];
            switch (geom.type) {
            case 'polyline':
              if (geom.paths.length === 0) {
                // push fake geom to .applyEdits() doesn't throw an error
                geom.paths.push([[0, 0]]);
              }
              break;
            default:
              console.error(`Unabled geometry type: ${geom.type}`);
              break;
            }
          }
        });
      });
    });
    return edits;
  }

  private addLayerToMap() {
    if (this.options.map) {
      // check if layer is already added
      const layer = this.options.map.allLayers.find(l => l.title === this.options.title);
      // this.logging.log('🚀 ~ file: rx-layer.ts ~ RxLayer ~ addLayerToMap ~ layer', layer);
      // remove the layer, we are going to add it again
      if (layer) {
        this.options.map.remove(layer);
      }
      this.options.map.add(this._featureLayer, this.options.order);
    }
  }

  // sets this._featureLayer prop and returns a promise
  private async instantiateFeatureLayer(): Promise<FeatureLayer> {
    // this.logging.log('🚀 ~ file: rx-layer.ts ~ RxLayer ~ instantiateFeatureLayer', this.options);
    // This is required to initialize the layer properly if visible property is called, had some issues with this before
    await this.fieldsPromise;
    const params: __esri.FeatureLayerProperties = {
      title: this.title,
      visible: this.visible.value,
      opacity: this.opacity.value,
      definitionExpression: this.wholeWhereClause.value,
      spatialReference: this.spatialReference,
      outFields: ['*'],
      listMode: this.options?.listMode ? this.options.listMode : 'show',
      minScale: 10000000
      //minScale: this.minScale ? this.minScale : 10000000,
      //maxScale: this.maxScale ? this.maxScale: 10000000
    };
    // remove spatial ref. if it's undefined
    if (this.spatialReference === null || this.spatialReference === undefined) {
      delete params.spatialReference;
    }
    if (DISPLAY_FIELD_OVERRIDES[this.title]) {
      params.displayField = DISPLAY_FIELD_OVERRIDES[this.title];
    }
    if (this.options.clientSideGraphicOptions) {
      params.displayField = this.options.clientSideGraphicOptions.displayField;
      params.source = this.options.clientSideGraphicOptions.initFeatures;
      params.geometryType = this.options.clientSideGraphicOptions.geometryType;
      params.fields = this.options.clientSideGraphicOptions.fields;
      if (this.options.clientSideGraphicOptions.renderer) {
        params.renderer = this.options.clientSideGraphicOptions.renderer;
      }
    } else {
      params.url = this.options.url;
      if (this.options.renderer) {
        params.renderer = this.options.renderer;
      }
    }
    // create the feature layer
    this._featureLayer = new FeatureLayer(params);
    this._featureLayer['rxLayer'] = this;
    this._defaultRenderer.next(this._featureLayer.renderer);

    this._destroyed.next(false);

    // listen for layer view destory
    // this._featureLayer.on('layerview-destroy', () =>
    // this.logging.log('🚀 ~ file: rx-layer.ts ~ RxLayer ~ layerview-destory ~ title', this._featureLayer.title)
    // );

    this._subs.push(
      this.wholeWhereClause.subscribe(where => {
        this._featureLayer.definitionExpression = where;
      })
    );

    if (this.options.hideLayerWhileUpdating) {
      const isUpdating = new BehaviorSubject<boolean>(false);
      this._featureLayer.on('layerview-create', event => {
        event.layerView.watch('updating', updating => {
          isUpdating.next(!!updating);
        });
      });

      this._subs.push(
        combineLatest([
          this.opacity.valueStream,
          isUpdating
        ]).subscribe(([_opacity, _isUpdating]) => {
          // don't display layer while it is updating
          this._featureLayer.opacity = _isUpdating ? 0 : _opacity;
        })
      );
    } else {
      this._subs.push(
        this.opacity.valueStream.subscribe(o => {
          this._featureLayer.opacity = o;
        })
      );
    }

    if (!this.options.excludePopupTemplate) {
      this.fieldsPromise.then(fields => {
        this._popupTemplate = <any>generatePopupTemplate(fields, this.title);
        this._featureLayer.popupTemplate = this._popupTemplate;
      });
    }

    if (this.options.popupTemplate) {
      this._popupTemplate = this.options.popupTemplate;
      this._featureLayer.popupTemplate = this._popupTemplate;
    }

    return new Promise((resolve, reject) => {
      this._featureLayer.load().then(layer => {
        resolve(layer);
      }).catch(err => {
        reject(err);
      });
    });
  }

  private async getFields() {
    if (this.options.clientSideGraphicOptions) {
      return this.options.clientSideGraphicOptions.fields;
    }
    const query = {
      f: 'json'
    };
    try {
      const response = await esriRequest(this.options.url, { query });
      // set geometry type
      // TODO - Talk to John about this...a bit of a hack right now
      this._geometryType.next(response.data.geometryType);
      if (response.data.drawingInfo) {
        // set default renderer
        this._defaultRenderer.next(Renderer.fromJSON(response.data.drawingInfo.renderer));
        // set current renderer
        this._currentRenderer.next(Renderer.fromJSON(response.data.drawingInfo.renderer));
      }
      // set heatMode fields
      const fields = response.data.fields as __esri.Field[];
      this.setHeatModeFields(fields);
      return response.data.fields;
    } catch (err) {
      this.error.next({
        msg: 'Could not load fields for layer.',
        url: this.options.url
      });
    }
  }

  private async getServiceInfo() {
    const query = {
      f: 'json'
    };
    try {
      const response = await esriRequest(this.options.url, { query });
      if (response.data.drawingInfo) {
        // set default renderer
        this._defaultRenderer.next(Renderer.fromJSON(response.data.drawingInfo.renderer));
        // set current renderer
        this._currentRenderer.next(Renderer.fromJSON(response.data.drawingInfo.renderer));
      }
      return response.data;
    } catch (err) {
      this.error.next({
        msg: 'Could not get service info.',
        url: this.options.url
      });
    }
  }

}

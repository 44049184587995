import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { AzureAD } from 'src/init/azure-ad';

// Approval
import { PreSubmitCheckService, ScopeReadyResult } from './pre-submit-check-service';
import { ApprovalPolicyFactory } from './approval-policy-factory';
import { ApprovalAuditService } from './approval-audit-service';
import { ApprovalReportService } from './approval-report-service';
import { PROGRAM_TYPE_LOOKUP } from 'src/app/scope-approval/program-type-lookup';

import { PinsRxLayerService } from '../services/layer/pins-rx-layer.service';
import { PinAttributes } from '../services/amalgamator/pin-attributes';

// Dialogs
import { DialogNoticeService } from 'src/app/services/dialog-notice.service';
import { AttachmentDialogComponent } from '../dialogs/attachment-dialog/attachment-dialog.component';
import { BasicDialogComponent } from '../dialogs/basic-dialog/basic-dialog.component';
import { ImpactAuditService } from 'src/app/services/impact-audit.service';
import { OnBehalfOfDialogComponent } from '../dialogs/on-behalf-of-dialog/on-behalf-of-dialog.component';

// email content
import { SubmitApproveEmailService } from './submit-approve-email.service';
import { LazyDialogService } from '../services/lazy-dialog.service';
import { PriorityImpactService } from '../services/priority-impact-service';
import { UserInfoService } from '../services/user-info.service';

@Injectable({
  providedIn: 'root'
})
export class SubmitApproveService {

  constructor(
    private preSubmitCheckService: PreSubmitCheckService,
    private policyFactory: ApprovalPolicyFactory,
    private auditService: ApprovalAuditService,
    private reportService: ApprovalReportService,
    private pinsRxLayerService: PinsRxLayerService,
    private dialog: MatDialog,
    private dialogNoticeService: DialogNoticeService,
    private lazyDialogService: LazyDialogService,
    private impactAuditService: ImpactAuditService,
    private submitApproveEmailService: SubmitApproveEmailService,
    private priorityImpactService: PriorityImpactService,
    private userInfoService: UserInfoService,
  ) {
    if (userInfoService.canDisableSendEmail) this.willNotSendEmail.next(true);
  }

  public willNotSendEmail = new BehaviorSubject<boolean>(false);
  working = new BehaviorSubject<boolean>(false);

  async reset(pin: string, NEXT_APPROVER: string) {
    this.working.next(true);
    await this.auditService.reset(pin, NEXT_APPROVER);
    this.working.next(false);
  }

  async submitOrApproveScopes(attributesForScopes: PinAttributes[]): Promise<{ abort: boolean }> {

    // warn user that they won't be able to add attachments
    if (attributesForScopes.length > 1) {
      const { userCancel } = await this.promptAttachmentsNotSupported();
      if (userCancel) {
        return { abort: true };
      }
    }

    const notReadyScopes: ScopeReadyResult[] = attributesForScopes
      .map(attrs => this.preSubmitCheckService.validateScope(attrs))
      .filter(x => !x.pass);

    if (notReadyScopes.length > 0) {
      this.lazyDialogService.open('cannot-approve-dialog', {
        data: notReadyScopes
      });
      /*
      this.dialog.open(CannotApproveDialogComponent, {
        data: notReadyScopes
      });
      */
      return { abort: true };
    }

    // check to make sure impacts have been run
    const pins = attributesForScopes.map(a => a.PIN);
    const pinsWithImpact = await this.impactAuditService.getImpactAudits(pins);
    const matches = pinsWithImpact.filter(m => pins.indexOf(m) > -1);
    // if pins don't have impacts run, abort
    if (matches.length < 1) {
      const impactsMissing = pins.filter(p => pinsWithImpact.indexOf(p) < 0);
      this.lazyDialogService.open('impacts-missing-dialog', {
        data: impactsMissing,
        disableClose: true
      });
      /*
      this.dialog.open(ImpactsMissingDialogComponent, {
        data: impactsMissing,
        disableClose: true
      });
      */
      return { abort: true };
    }

    const hasMixedBridgeFunding = this.hasMixedBridgeFunding(attributesForScopes);
    if (hasMixedBridgeFunding) {
      this.dialog.open(BasicDialogComponent, {
        data: {
          title: 'MIXED PROGRAM FUNDING DETECTED',
          message: 'You selected a combination of program funding sources.  BR/MB funding must be submitted separately from the rest.'
        }
      });
      return { abort: true };
    }

    const { roles, cancel } = await this.promptToSelectRoles();

    if (cancel) {
      return { abort: true };
    }

    if (roles == null || roles.length === 0) {
      this.dialogNoticeService.alert(
        {
          message: 'No roles were selected to approve on behalf of.',
          hasCancel: false
        });
      return { abort: true };
    }

    this.working.next(true);

    let assets = attributesForScopes.map(atts => {
      return {
        atts,
        policy: null,
        audits: null,
        report: null
      };
    });

    assets.forEach(x => {
      x.policy = this.policyFactory.generatePolicy(x.atts.PIN, x.atts.DISTRICT, x.atts.PROGRAM_TYPES);
    });

    assets = await Promise.all(assets.map(async x => {
      x.audits = await this.auditService.getAudits(x.atts.PIN);
      return x;
    }));

    assets = await Promise.all(assets.map(async x => {
      x.report = await this.reportService.getReport(x.atts, x.audits, x.policy);
      return x;
    }));

    // makes calls to change statuses and enter audits
    await Promise.all(assets.map(x => {
      return this.auditService.submitOrApproveIndividualScope(x.atts, AzureAD.user.email, roles, x.report);
    }));

    await Promise.all(assets.map(x => {
      return this.pinsRxLayerService.refreshPin(x.atts.PIN);
    }));

    const pinList = attributesForScopes.map(x => `'${x.PIN}'`).join(',');
    const newAttrs = await this.pinsRxLayerService.getAttributesForPins(`PIN in (${pinList})`);

    // only prompt for attachments if we have a single project - do this BEFORE sending email notices
    if (attributesForScopes.length === 1) {
      await this.promptForAttachments();
    }

    if (!this.willNotSendEmail.getValue()) {
      await this.submitApproveEmailService.sendEmailNotices(newAttrs);
    }
    // to do---
    // batch emails
    // also do alert to notify user if some reached final approval but not others
    // be specific about whether this was because some required LEB-NEPA approval
    // ---

    // check for special impact notices - triggers dialogs if present
    this.priorityImpactService.runReport(pins);

    this.working.next(false);
    return { abort: false };
  }

  // we don't want to allow BR/MB with other funding types
  private hasMixedBridgeFunding(attributesForScopes: PinAttributes[]): boolean {
    const bridgeTypes = ['BR', 'MB'];
    const nonBridgeFunding = [];
    const bridgeFunding = [];
    attributesForScopes.map(attrs => {
      const programTypes: string[] = attrs.PROGRAM_TYPES.split(',')
        .map(x => x.trim())
        .filter(x => x.length)
        .map(x => PROGRAM_TYPE_LOOKUP[x]);
      programTypes.filter(f => {
        if (bridgeTypes.includes(f)) {
          bridgeFunding.push(f);
        } else {
          nonBridgeFunding.push(f);
        }
      });
    });
    if (nonBridgeFunding.length > 0 && bridgeFunding.length > 0) {
      return true;
    } else {
      return false;
    }
  }


  private async promptToSelectRoles(): Promise<{ roles: string[], cancel: boolean }> {
    return new Promise<any>(resolve => {
      const dialogRef = this.dialog.open(OnBehalfOfDialogComponent, {});
      dialogRef.afterClosed().subscribe(() => {
        resolve({
          roles: dialogRef.componentInstance.selectedRoles,
          cancel: dialogRef.componentInstance.cancel
        });
      });
    });
  }

  private async promptAttachmentsNotSupported(): Promise<{ userCancel: boolean }> {
    return new Promise<any>(resolve => {
      const config: MatDialogConfig = {
        data: {
          title: 'Multiple Scopes Selected - Attachments Not Supported',
          message: 'Please \'Submit / Approve\' one project at a time if you would like to include attachments during your submission.  You can also attach files to a Scope via the \'Edit Project Details\' menu.',
          width: 500
        },
        disableClose: true
      };
      const dialogRef = this.dialog.open(BasicDialogComponent, config);
      dialogRef.afterClosed().subscribe(async (cancel) => {
        resolve({
          userCancel: !cancel
        });
      });
    });
  }

  private async promptForAttachments(): Promise<{ attachCancel: boolean }> {
    return new Promise<any>(resolve => {
      const config: MatDialogConfig = {
        data: {
          title: 'Attach Supporting Documentation (optional)',
          message: 'Do you want to attach anything?  If so, click the button below.',
          width: 400
        },
        disableClose: true
      };
      const dialogRef = this.dialog.open(AttachmentDialogComponent, config);
      dialogRef.afterClosed().subscribe(() => {
        resolve({
          attachCancel: dialogRef.componentInstance.cancel
        });
      });
    });
  }


}

import { PriorityImpactDetailData } from './priority-impact-detail-data';

// NOTE:
// tried to use import { PriorityImpactLayerTitle } from "src/app/services/priority-impact-service";
// impactLayerTitles: [PriorityImpactLayerTitle.LRTPMobilitySafety] // got errors after prod build

export const IMPACT_DETAIL_LRTP_MOBILITY_SAFETY: PriorityImpactDetailData = {
  cardInfo: {
    cardTitle: 'LRTP Mobility & Safety within 1/4 Mile',
    cardDesc: `The geometry of this project/s is in proximity to an area identified in the LRTP for Super 2 improvements.
    Please contact Garrett Pedersen with Systems Planning.`,
    name: 'Garrett Pedersen',
    jobTitle: 'Systems Planning',
    email: 'Garrett.Pedersen@iowadot.us',
    phone: '515-239-1520'
  },
  dialogTitle: `HIGH PRIORITY IMPACT NOTICE: LRTP Mobility & Safety`,
  impactLayerTitles: ['LRTP Mobility & Safety']
};

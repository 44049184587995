import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

// Modules
import { BrowserModule } from '@angular/platform-browser';

// Components
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { HeaderComponent } from './header/header.component';
import { VersionIndicatorComponent } from './version-indicator/version-indicator.component';
import { ServiceHealthComponent } from './service-health/service-health.component';
import { UserIndicatorComponent } from './user-indicator/user-indicator.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

// Services
import { UserInfoService } from './services/user-info.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './modules/shared-module';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HeaderComponent,
    VersionIndicatorComponent,
    ServiceHealthComponent,
    UserIndicatorComponent,
    UserMenuComponent,
  ],
  exports: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (userInfoService: UserInfoService) => {
        return () => {
          return userInfoService.populateUserInfo();
        };
      },
      deps: [UserInfoService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

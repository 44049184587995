import { environment } from 'src/environments/environment';

import request from '@arcgis/core/request';
import { RxJsLoggingLevel } from 'src/app/functions/rxjs-debug';

export async function esriRequest(
  url: string,
  options?: __esri.RequestOptions
) {
  return <__esri.RequestResponse>request(url, options).catch(err => {
    // err.dojoType removed after 4.20 upgrade
    // triggered by options.signal
    if (err.name === 'AbortError') {
      if (environment.loggingLevel === RxJsLoggingLevel.DEBUG) {
        console.log('Request aborted', options);
      }
    } else {
      console.error('Request error', err);
      return;
    }

    if (options.query.returnCountOnly) {
      return {
        data: {
          count: 0
        }
      };
    } else {
      return {
        data: {
          features: [],
          fields: [],
        },
      };
    }
  });
}

export function getEvents(atts: any, impactCounts: { title: string, count: number }[]) {
  const findImpactCount = title => {
    const impact = impactCounts.find(x => x.title === title);
    if (!impact) {
      alert('Could not find impact: ' + title);
    }
    return impact.count;
  };

  const events = [
    {
      name: 'Interchange Justification Report by LEB (IJR1)',
      include: atts.INTERCHANGEREQUIRED === 1 && atts.PD_COMPLETED_BY === 6 // Office of Location and Environment (LEB)
    }, {
      name: 'Interchange Justification Report by Design/Bridges/Structures (IJR2)',
      include: atts.INTERCHANGEREQUIRED === 1 && atts.PD_COMPLETED_BY === 3 // Office of Design or Office of Bridges & Structures
    }, {
      name: 'Interchange Justification Report by District (IJR3) ',
      include: atts.INTERCHANGEREQUIRED === 1 && atts.PD_COMPLETED_BY === 1 // District
    }, {
      name: 'Interchange Justification Report by Systems Planning (IJR4)',
      include: atts.INTERCHANGEREQUIRED === 1 && (atts.PD_COMPLETED_BY === 7 || atts.PD_COMPLETED_BY === 8) // Local Public Agency or Other
    }, {
      name: 'Plot Plans and Summary Sheets to District (R00)',
      include: atts.ROWNEED > 0
    }, {
      name: 'Right of Way Layout (R01)',
      include: atts.ROWNEED > 0
    }, {
      name: 'Right of Way Appraisal (R02) ',
      include: atts.ROWNEED > 0
    }, {
      name: 'Right of Way Negotiation (R03) ',
      include: atts.ROWNEED > 0
    }, {
      name: 'Right of Way Acquisition (R04) ',
      include: atts.ROWNEED > 0
    }, {
      name: 'Right of Way Relocation (R05) ',
      include: atts.ROWNEED > 0 && atts.ROWRELOCATION > 0
    }, {
      name: 'LEB: Threatened/Endangered Species Desktop Review (TE0)',
      include: atts.TANDE > 0 || findImpactCount('T&E')
    }, {
      name: 'LEB: Cultural Resources Assessment (H00)',
      include: atts.ROWNEED > 0
      // }, {
      //   name: 'LEB: Phase I Archaeological Investigation (H01) ',
      //   include: atts.ROWNEED > 0 &&
    }, {
      name: 'LEB: Intensive Historical Evaluation (H03)',
      include: atts.ROWNEED > 0 && atts.HISTORICALSITE > 0
    }, {
      name: 'Concurrence Point 1-4 – Purpose and Need (CP1)',
      include: atts.CONCURRENCEPOINT > 0
    }, {
      name: 'Initial Railroad Concurrence Review (RR01)',
      include: findImpactCount('Active Rail Lines') || findImpactCount('Historic Rail Lines')
    }, {
      name: 'Traffic Critical Projects (TCP1) ',
      include: atts.TRAFFICCRITICAL > 0
    }, {
      name: 'LEB: NEPA Signed PCE (NE10)',
      include: atts.LEVELOFDOCUMENTATION === 4
    }, {
      name: 'LEB: NEPA Signed CE (NE11)',
      include: atts.LEVELOFDOCUMENTATION === 3
    }, {
      name: 'LEB: NEPA Signed EA (NE20) and Signed FONSI (NE21)',
      include: atts.LEVELOFDOCUMENTATION === 2
    }, {
      name: 'LEB: NEPA Signed DEIS (NE30), FEIS (NE31), and ROD (NE32)',
      include: atts.LEVELOFDOCUMENTATION === 1
    }, {
      name: 'ROW Preliminary Relocation Assistance Plan (P02)',
      include: atts.LEVELOFDOCUMENTATION === 2 || atts.LEVELOFDOCUMENTATION === 1
      // }, {
      //   name: 'Value Engineering Study During Planning Phase (VE1)',
      //   include: atts.PD_COMPLETED_BY == 6 && // and Environment and the Cost Estimate Range for the project could exceed $35M for work groups 2000 or 2500 and $45M for any other work group combinations
      // }, {
      //   name: 'Value Engineering Study During Design Phase (VE2)',
      //   include: //Include if Development Completed By=any other than Office of Location and Environment and the Cost Estimate Range for the project could exceed $35M for work groups 2000 or 2500 and $45M for any other work group combinations
    }, {
      name: 'Preliminary Utility Review (U00) ',
      include: findImpactCount('Transmission Lines') || findImpactCount('Major Pipelines') || findImpactCount('Cell Towers')
    }, {
      name: 'Financial Plan by LEB (FP1)',
      include: atts.BUDGETRANGEMAX > 100000 && atts.PD_COMPLETED_BY === 6
    }, {
      name: 'Financial Plan by Design (FP2) ',
      include: atts.BUDGETRANGEMAX > 100000 && atts.PD_COMPLETED_BY === 2
    }, {
      name: 'Financial Plan by Bridges & Structures (FP3)',
      include: atts.BUDGETRANGEMAX > 100000 && atts.PD_COMPLETED_BY === 3
    }, {
      name: 'Financial Plan by District (FP4) ',
      include: atts.BUDGETRANGEMAX > 100000 && atts.PD_COMPLETED_BY === 1
    },
  ];

  return events.filter(x => x.include).map(x => x.name);
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { DialogService } from '../dialog.service';
import { PopupWidgetService } from './popup-widget.service';
import { PinsRxLayerService } from '../layer/pins-rx-layer.service';
import { MapService } from './map.service';
import { LrsService } from '../layer/lrs.service';
import { RouteAndMeasure, RouteAndMeasureService } from '../layer/route-and-measure.service';
import { ConcurrencyService } from '../layer/concurrency.service';
import { CurrentTabService, Tab } from 'src/app/details-pane/details-pane-body/current-tab.service';
import { GuidService } from '../guid.service';
import { environment } from 'src/environments/environment';
import { filter, take } from 'rxjs/operators';
import { LoggingService } from '../logging.service';
import { AppLayoutService } from '../app-layout.service';
import { DialogNoticeService } from '../dialog-notice.service';
import { ClonerService } from '../cloner.service';
import { RxJsLoggingLevel } from 'src/app/functions/rxjs-debug';

export enum MapClickMode {
  ShowPopup = 'ShowPopup',
  SelectLrsRoute = 'SetLrsRoute',
  SetFromMeasure = 'SetFromMeasure',
  SetToMeasure = 'SetToMeasure'
}

@Injectable({
  providedIn: 'root'
})
export class MapClickHandlerService {
  mapClickMode = new BehaviorSubject<MapClickMode>(MapClickMode.ShowPopup);

  private lastClickPos = {
    x: Math.round(document.body.offsetWidth / 2),
    y: Math.round(document.body.offsetHeight / 2)
  };

  constructor(
    private appLayoutService: AppLayoutService,
    private clonerService: ClonerService,
    private popupWidgetService: PopupWidgetService,
    private dialogService: DialogService,
    private dialogNoticeService: DialogNoticeService,
    private pinsRxLayerService: PinsRxLayerService,
    private mapService: MapService,
    private lrsService: LrsService,
    private routeAndMeasureService: RouteAndMeasureService,
    private concurrencyService: ConcurrencyService,
    private currentTabService: CurrentTabService,
    private guidService: GuidService,
    private logging: LoggingService,
    private router: Router
  ) {

    let ignorePopupUntil = null;

    this.mapService.mapAssets$.pipe(
      filter(assets => assets !== null && assets !== undefined),
      take(1)
    ).subscribe(assets => {
      // this.logging.log('🚀 ~ file: map-click-handler.service.ts ~ line 49 ~ MapClickHandlerService ~ this.mapService.mapAssets$.pipe ~ assets', assets);
      assets.view.on('click', evt => {
        this.lastClickPos = {
          x: evt.x,
          y: evt.y
        };
        switch (this.mapClickMode.value) {
        case MapClickMode.SetFromMeasure:
          ignorePopupUntil = +new Date() + 1000;
          this.setMeasure(evt.mapPoint, 'FROM_MEASURE');
          break;
        case MapClickMode.SetToMeasure:
          ignorePopupUntil = +new Date() + 1000;
          this.setMeasure(evt.mapPoint, 'TO_MEASURE');
          break;
        default:
          ignorePopupUntil = null;
        }
      });
    });

    this.popupWidgetService.features.subscribe(graphics => {
      if (ignorePopupUntil && ignorePopupUntil > +new Date()) {
        return;
      }
      if (!graphics.length) {
        return;
      }
      switch (this.mapClickMode.value) {
      case MapClickMode.ShowPopup:
        if (graphics.length === 1 && graphics[0].layer.title === 'Scopes and PINs' && this.appLayoutService.getLayoutMode() === 'table') {
          this.selectPin(graphics[0]);
          setTimeout(() => {
            this.popupWidgetService.close();
          }, 1);
        } else {
          this.showPopup();
        }
        break;
      case MapClickMode.SelectLrsRoute:
        this.showLrsPopup();
        break;
      }
    });

    this.routeAndMeasureService.routesAndMeasures.subscribe(() => {
      // reset map click mode to show popup after adding new lrs route
      this.mapClickMode.next(MapClickMode.ShowPopup);
    });

    (async () => {

      const rxLayer = await this.pinsRxLayerService.getLayer();

      if (rxLayer) {
        combineLatest([
          this.appLayoutService.layoutMode$,
          this.mapClickMode,
          this.currentTabService.currentTab,
        ]).subscribe(([layout, clickMode, tab]) => {
          if (clickMode === MapClickMode.ShowPopup) {
            if (rxLayer.visible.value !== true) {
              rxLayer.visible.setValue(true);
            }
          } else {
            if (rxLayer.visible.value !== false) {
              rxLayer.visible.setValue(false);
            }
          }
          if (layout === 'details' && tab === Tab.geometry) {
            rxLayer.disablePopupTemplate();
          } else {
            rxLayer.enablePopupTemplate();
          }
        });
      }
    })();
  }

  async setMeasure(mapPoint: __esri.Point, fromOrTo: 'FROM_MEASURE' | 'TO_MEASURE') {
    const initRoutesAndMeasures = this.clonerService.deepClone<RouteAndMeasure[]>(this.routeAndMeasureService.routesAndMeasures.value);
    const initSelectedSlice = initRoutesAndMeasures.filter(x => x.SELECTED)[0];
    initSelectedSlice.LOADING = true;
    this.routeAndMeasureService.routesAndMeasures.next(initRoutesAndMeasures);

    const routesAndMeasures = this.clonerService.deepClone<any>(initRoutesAndMeasures);
    const selectedSlice = routesAndMeasures.filter(x => x.SELECTED)[0];
    this.routeAndMeasureService.routesAndMeasuresPristine.next(false);

    const measureRes = await this.lrsService.geometryToMeasure(mapPoint);

    const ROUTE_ID = selectedSlice.ROUTE_ID;
    const measureMatches = measureRes.filter(x => x.routeId === ROUTE_ID);
    if (!measureMatches.length) {
      await this.dialogNoticeService.alert({
        title: 'UNKNOWN MEASURE',
        message: `Could not find measure for route ${ROUTE_ID} at the given click location.`
      });
      this.mapClickMode.next(MapClickMode.ShowPopup);
      selectedSlice.SELECTED = false;
      selectedSlice.LOADING = false;
      this.routeAndMeasureService.routesAndMeasures.next(routesAndMeasures);
      return;
    }
    const measure = measureMatches[0].measure;
    let FROM_MEASURE = fromOrTo === 'FROM_MEASURE' ? measure : selectedSlice.FROM_MEASURE;
    let TO_MEASURE = fromOrTo === 'TO_MEASURE' ? measure : selectedSlice.TO_MEASURE;

    if (FROM_MEASURE !== null && TO_MEASURE !== null) {
      if (TO_MEASURE < FROM_MEASURE) {
        const f = FROM_MEASURE;
        FROM_MEASURE = TO_MEASURE;
        TO_MEASURE = f;
      }

      const geometry = await this.lrsService.measureToGeometry(ROUTE_ID, FROM_MEASURE, TO_MEASURE);
      selectedSlice.PATHS = geometry.paths;
    } else if (FROM_MEASURE !== null) {
      const geometry = await this.lrsService.measureToGeometry(ROUTE_ID, FROM_MEASURE, FROM_MEASURE + 1);
      selectedSlice.PATHS = [[geometry.paths[0][0]]];
    } else if (TO_MEASURE !== null) {
      const geometry = await this.lrsService.measureToGeometry(ROUTE_ID, TO_MEASURE, TO_MEASURE + 1);
      selectedSlice.PATHS = [[geometry.paths[0][0]]];
    }
    // console.log({paths: selectedSlice.PATHS});

    selectedSlice.FROM_MEASURE = FROM_MEASURE;
    selectedSlice.TO_MEASURE = TO_MEASURE;


    if (selectedSlice.FROM_MEASURE !== null && selectedSlice.TO_MEASURE !== null) {
      const dominantRoutes = await this.concurrencyService.getDominantRoutes(selectedSlice);
      if (environment.isLocalHost && environment.loggingLevel === RxJsLoggingLevel.DEBUG) {
        console.log({ dominantRoutes });
      }
      if (dominantRoutes.length > 1 || (dominantRoutes.length === 1 && dominantRoutes[0].ROUTE_ID !== selectedSlice.ROUTE_ID)) {
        const newRoutes = dominantRoutes.map(x => ({
          TMP_GUID: this.guidService.createGuid(),
          ROUTE_ID: x.ROUTE_ID,
          FROM_MEASURE: x.FROM_MEASURE,
          TO_MEASURE: x.TO_MEASURE,
          PATHS: [],
          SELECTED: false,
          LOADING: false,
          IS_PRIMARY: false,
          JUST_CREATED: true
        }));
        for (const r of newRoutes) {
          const geoRes = (await this.lrsService.measureToGeometry(r.ROUTE_ID, r.FROM_MEASURE, r.TO_MEASURE));
          if (geoRes) {
            r.PATHS = geoRes.paths;
          }
        }
        const i = routesAndMeasures.indexOf(selectedSlice);
        routesAndMeasures.forEach(x => {
          x.JUST_CREATED = false;
          x.SELECTED = false;
          x.LOADING = false;
        });
        routesAndMeasures.splice(i, 1, ...newRoutes);
        setTimeout(async () => {
          await this.dialogNoticeService.alert({
            title: 'DOMINANT ROUTES DETECTED',
            message: `The system uses dominant routes North or East.
            When a South or West route is selected on a 2 lane route, the application automatically changes the route to the corresponding dominant route.
            Your project and geometry have not changed.`
          });
        }, 10);
      } else {
        selectedSlice.LOADING = false;
        selectedSlice.SELECTED = false;
      }
    } else {
      selectedSlice.LOADING = false;
    }

    this.routeAndMeasureService.routesAndMeasures.next(routesAndMeasures);
  }

  private showPopup() {
    if (!this.dialogService.mapPopupIsOpen) {
      this.dialogService.openMapPopup(this.lastClickPos);
    }
  }

  private showLrsPopup() {
    if (!this.dialogService.mapLrsRoutePopupIsOpen) {
      this.dialogService.openMapLrsRoutePopup(this.lastClickPos);
    }
  }

  private selectPin(graphic) {
    this.pinsRxLayerService.selectPin(graphic.attributes.PIN);
  }

}

import { Filter } from '../filter';
import { FILTER_TRANSLATORS } from './filter-translators';
import { getSurrogateSql } from './surrogate-sql';

export async function filtersToSql(filters: Filter[], baseWhereClause: string) {

  const activeFilters = filters.filter(f => f.active);

  const sqlPromises = activeFilters.map(f => {
    const sql = filterToSql(f);
    if (f.externalTable) {
      return getSurrogateSql(sql, f.externalTable);
    } else {
      return Promise.resolve(sql);
    }
  });

  const sqls = await Promise.all(sqlPromises);

  const filtersClause = sqls
    .filter(sql => sql !== null)
    .map(sql => `(${sql})`)
    .join(' and ');

  const baseClause = baseWhereClause.trim();

  if (baseClause.length && filtersClause.length) {
    return `(${baseClause}) and (${filtersClause})`;
  } else if (baseClause.length) {
    return baseClause;
  } else if (filtersClause.length) {
    return filtersClause;
  } else {
    return '1=1';
  }
}

function filterToSql(filter: Filter) {
  if (!FILTER_TRANSLATORS[filter.type]) {
    throw new Error('No translator found for filter type ' + filter.type);
  }
  const sql = FILTER_TRANSLATORS[filter.type](filter);
  const shouldDiscard = sql === null || sql === '' || sql === '1=1';
  return shouldDiscard ? null : sql;
}

import { Injectable } from '@angular/core';
import { MultiScannerResults } from './multi-scanner';
import { setModernFieldType } from 'src/app/types/set-modern-field-type';
import { PinAttributes } from './pin-attributes';
import { createLookups } from 'src/app/services/amalgamator/create-lookups';
import { PROGRAM_TYPE_LOOKUP } from '../../scope-approval/program-type-lookup';

import CodedValueDomain from '@arcgis/core/layers/support/CodedValueDomain';

export const PRIORITY_RESULTS_FIELD_EXCLUDE = [
  // fields from PRIORITY results to exclude
  'OBJECTID',
  'PIN_ID', // already imported from Project Scoping fields
  'PIN', // already imported from Project Scoping fields
  'PROJECT_SCOPE_ID',
  'REST_UPDATED',
  'UTC_OFFSET'
];

export const VALUATION_FIELD_EXCLUDE = [
  // valuation fields to exclude
  'OBJECTID',
  'PIN_ID', // already imported from Project Scoping fields
  'PIN', // already imported from Project Scoping fields
  'PROJECT_SCOPE_ID'
];

// Project Number fields to exclude
export const PROJECT_NUMBER_FIELD_INCLUDE = [
  'PROJECT_NUMBER',
];

@Injectable({
  providedIn: 'root'
})
export class FieldsAmalgamator {
  createPinFields(msr: MultiScannerResults): __esri.Field[] {

    const lookup = createLookups(msr);
    if (JSON.stringify(lookup.programType) !== JSON.stringify(PROGRAM_TYPE_LOOKUP)) {
      console.error(`Program funding type lookup from endpoint does not match local lookup.`);
    }

    const PROJECT_SCOPING_FIELDS = msr.projectScoping.fields;

    const PRIORITY_RESULTS_FIELDS = msr.priorityResults.fields.filter(f => {
      return PRIORITY_RESULTS_FIELD_EXCLUDE.indexOf(f.name) === -1;
    });

    const VALUATION_FIELDS = msr.valuation.fields.filter(f => {
      return VALUATION_FIELD_EXCLUDE.indexOf(f.name) === -1;
    });

    const PROJECT_NUMBER_FIELDS = msr.projectNumber.fields.filter(f => {
      return PROJECT_NUMBER_FIELD_INCLUDE.indexOf(f.name) !== -1;
    });

    const fields: __esri.Field[] = [
      ...PROJECT_SCOPING_FIELDS,
      ...PRIORITY_RESULTS_FIELDS,
      ...VALUATION_FIELDS,
      ...PROJECT_NUMBER_FIELDS,
      ...this.getCommaDelimitedFields(msr),
      ...this.getExtendedFields()
    ];

    const duplicates = this.findDuplicateFieldNames(fields);

    if (duplicates.length) {
      console.error(`Multiple fields with same name: ${duplicates.join(',')}`);
    }

    const endpointHas = fields.map(x => x.name);
    const classHas = Object.keys(new PinAttributes());

    endpointHas.forEach(x => {
      if (classHas.indexOf(x) === -1) {
        console.log(`PinAttributes class is missing ${x}`);
      }
    });

    classHas.forEach(x => {
      if (endpointHas.indexOf(x) === -1) {
        console.log(`PinAttributes class has extra field ${x}`);
      }
    });

    fields
      .filter(f => f.domain && f.domain['codedValues'])
      .forEach(x => {
        // ArcGIS < 4.22
        // x.domain.type = 'coded-value'; // arcgis js api expects type to be 'coded-value'
        // x.domain['codedValues'] = this.sortCodedValues(x.domain['codedValues']);
        // ArcGIS 4.22+
        x.domain = CodedValueDomain.fromJSON(x.domain);
        x.domain.codedValues = this.sortCodedValues(x.domain.codedValues);
      });

    this.findField(fields, 'DESIREDFISCALYEAR').domain = this.createFiscalYearDomain();
    this.findField(fields, 'PERFORMANCE_SCORE').alias = 'Overall Score';
    this.findField(fields, 'READABLE_PRIMARY_ROUTE').alias = 'Primary Route';
    this.findField(fields, 'CONTEXT_SCORE').alias = 'Context Score';
    this.findField(fields, 'COST_SCORE').alias = 'Cost Score';
    this.findField(fields, 'FINANCING_SCORE').alias = 'Financing Score';
    this.findField(fields, 'TECHNICAL_SCORE').alias = 'Technical Score';
    this.findField(fields, 'PIN').alias = 'PIN / Scope ID';

    fields.forEach(f => {
      setModernFieldType(f);
    });
    return this.orderFields(fields);
  }

  private findField(fields: __esri.Field[], fieldName: string) {
    return fields.find(x => x.name === fieldName);
  }

  private sortCodedValues(vals) {
    return vals.sort((a, b) => (a.code < b.code ? -1 : 1));
  }

  private createFiscalYearDomain() {
    const yearCodedValues = [];
    const currentYear = new Date().getFullYear();
    const isPastFiscalYearDivide = new Date() >= new Date(`7/1/${currentYear}`);
    for (let i = -40; i < 40; i++) {
      yearCodedValues.push({
        code: i + currentYear,
        name: `${i - (isPastFiscalYearDivide ? 1 : 0)} (${i + currentYear})`
      });
    }
    return <any>{
      type: 'coded-value',
      codedValues: yearCodedValues
    };
  }

  private getCommaDelimitedFields(msr: MultiScannerResults) {
    const PROJECTTYPE = this.findField(msr.projectTypes.fields, 'PROJECTTYPE');
    const PROGRAMTYPE = this.findField(msr.programTypes.fields, 'PROGRAMTYPE');
    if (PROJECTTYPE.domain['codedValues'].length > 20) {
      console.error(`The details pane form subjects only account for up to 20 project purpose descriptions.`);
    }
    if (PROGRAMTYPE.domain['codedValues'].length > 20) {
      console.error(`The details pane form subjects only account for up to 20 program funding descriptions.`);
    }
    const WORKGROUP = this.findField(msr.workGroups.fields, 'WORKGROUP');
    const WORKCODE = this.findField(msr.workTypes.fields, 'WORKCODE');
    const COUNTY = this.findField(msr.counties.fields, 'COUNTY');
    const IOWA_STATE_SENATE_DISTRICTS = this.findField(msr.iowaStateSenateDistricts.fields, 'STATE_SENATE_DISTRICT');
    const IOWA_STATE_HOUSE_DISTRICTS = this.findField(msr.iowaStateHouseDistricts.fields, 'STATE_HOUSE_DISTRICT');
    const IOWA_US_HOUSE_REP_NAME = this.findField(msr.iowaCongressionalDistricts.fields, 'US_HOUSE_REP_NAME');
    const IOWA_CONGRESSIONAL_DISTRICTS = this.findField(msr.iowaCongressionalDistricts.fields, 'US_HOUSE_DISTRICT');

    const commaDelimitedFields = <any[]>[
      {
        type: 'string',
        name: 'PROJECT_TYPES', // comma separated list of project types (displayed as "Project Purpose")
        alias: PROJECTTYPE.alias, // (full entries including descriptions storred in PROJECT_TYPES_JSON)
        isCommaDelimited: true,
        isNumberList: true,
        patchedDomain: PROJECTTYPE.domain
      },
      {
        type: 'string',
        name: 'PROGRAM_TYPES', // comma separated list of program types (displayed as "Program Funding")
        alias: PROGRAMTYPE.alias, // (full entries including descriptions storred in PROGRAM_TYPES_JSON)
        isCommaDelimited: true,
        isNumberList: true,
        patchedDomain: PROGRAMTYPE.domain
      },
      {
        type: 'string',
        name: 'WORK_TYPES', // comma separated list of work types (displayed as "Work Code")
        alias: WORKCODE.alias,
        isCommaDelimited: true,
        isNumberList: true,
        patchedDomain: WORKCODE.domain
      },
      {
        type: 'string',
        name: 'WORK_GROUPS', // comma separated list of work groups
        alias: WORKGROUP.alias,
        isCommaDelimited: true,
        isNumberList: true,
        patchedDomain: WORKGROUP.domain
      },
      {
        type: 'string',
        name: 'COUNTIES', // comma separated list of counties
        alias: COUNTY.alias,
        isCommaDelimited: true
      },
      {
        type: 'string',
        name: 'IOWA_STATE_SENATE_DISTRICTS',
        alias: IOWA_STATE_SENATE_DISTRICTS.alias,
        isCommaDelimited: true
      },
      {
        type: 'string',
        name: 'IOWA_STATE_HOUSE_DISTRICTS',
        alias: IOWA_STATE_HOUSE_DISTRICTS.alias,
        isCommaDelimited: true
      },
      {
        type: 'string',
        name: 'IOWA_CONGRESSIONAL_DISTRICTS',
        alias: IOWA_CONGRESSIONAL_DISTRICTS.alias,
        isCommaDelimited: true
      },
      {
        type: 'string',
        name: 'IOWA_US_HOUSE_REP_NAME',
        alias: IOWA_US_HOUSE_REP_NAME.alias,
        isCommaDelimited: true
      }
    ];
    return <__esri.Field[]>commaDelimitedFields;
  }

  private getExtendedFields() {
    const extendedFields = <any[]>[
      {
        type: 'string',
        name: 'STAGE', // custom field based on status and PROG_EST_SUM, used for filtering
        alias: 'Stage'
      },
      {
        type: 'string',
        name: 'ROUTES_AND_MEASURES_JSON', // jsonized lrs entries, not filterable
        alias: 'Routes and Measures JSON'
      },
      {
        type: 'string',
        name: 'PROJECT_TYPES_JSON', // jsonized project type entries, not filterable
        alias: 'Project Types JSON'
      },
      {
        type: 'string',
        name: 'PROGRAM_TYPES_JSON', // jsonized program type entries, not filterable
        alias: 'Program Types JSON'
      },
      {
        type: 'double',
        name: 'HAS_GEOMETRY', // field to indicate of there is geometry for the given Scope or PIN
        alias: 'Has Geometry',
        patchedDomain: {
          type: 'coded-value',
          codedValues: [{ code: 0, name: 'No' }, { code: 1, name: 'Yes' }]
        }
      },
      {
        type: 'double',
        name: 'IS_SCOPE_TO_PIN', // field to indicate if project started out as Scope and Converted to PIN
        alias: 'Scope To PIN Project',
        patchedDomain: {
          type: 'coded-value',
          codedValues: [{ code: 0, name: 'No' }, { code: 1, name: 'Yes' }]
        }
      }
    ];
    return <__esri.Field[]>extendedFields;
  }

  private orderFields(fields) {
    const moveTo = (topOrEnd: string, fieldName: string) => {
      const i = fields.findIndex(f => f.name === fieldName);
      if (i > -1) {
        const f = fields.splice(i, 1)[0];
        fields[topOrEnd === 'top' ? 'unshift' : 'push'](f);
      }
    };

    moveTo('end', 'PERFORMANCE_SCORE');
    moveTo('end', 'SAFETY_SCORE');
    moveTo('end', 'IMPORTANCE_SCORE'); // displayed as Road Class Score
    moveTo('end', 'FREIGHT_SCORE');
    moveTo('end', 'PAVEMENT_SCORE');
    moveTo('end', 'BRIDGE_SCORE');
    moveTo('end', 'TRAFFIC_SCORE');
    moveTo('end', 'MOBILITY_SCORE');
    moveTo('end', 'ATMS_SCORE');
    moveTo('end', 'INRIX_SCORE');
    moveTo('end', 'COUNTY_ROAD_DENSITY_SCORE');
    moveTo('end', 'ACCESSIBILITYSCORE'); // last column
    moveTo('top', 'DEV_EST_SUM');
    moveTo('top', 'PROG_EST_SUM');
    moveTo('top', 'DESCRIPTION');
    moveTo('top', 'PIN'); // first column

    return fields;
  }

  private findDuplicateFieldNames(fields: __esri.Field[]) {
    const fieldNames = {};
    const duplicates = [];
    fields.forEach(f => {
      if (fieldNames[f.name]) {
        duplicates.push(f.name);
      } else {
        fieldNames[f.name] = 1;
      }
    });
    return duplicates;
  }
}

<div>Send PIN Request Email{{ data.attsForEach.length > 1 ? 's' : '' }}</div>

<ng-container *ngIf="verifyingAudits">
  Verifying approvals...
</ng-container>

<ng-container *ngIf="nonApprovedPins.length">
  The folowing PINs still have remaining approval stages that must be completed before being submitted to become a PIN:
  <div *ngFor="let pin of nonApprovedPins">
    {{ pin }}
  </div>
</ng-container>

<ng-container *ngIf="nonApprovedPins.length === 0 && !verifyingAudits">
  <mat-form-field style="min-width:300px">
    <mat-select placeholder="Recipients" [formControl]="control" multiple>
      <mat-option *ngFor="let receiver of receiverOptions" [value]="receiver">
        {{receiver}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div style="display:flex">
    <div style="flex-grow:1"></div>
    <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <div style="min-width: 8px;"></div>
    <button mat-raised-button color="primary" [disabled]="!control.value" (click)="send()">Send</button>
  </div>
</ng-container>

<div *ngIf="sending">sending...</div>
import { PriorityImpactDetailData } from './priority-impact-detail-data';

// NOTE:
// tried to use import { PriorityImpactLayerTitle } from "src/app/services/priority-impact-service";
// impactLayerTitles: [PriorityImpactLayerTitle.RailCrossingProjects] // got errors after prod build

export const IMPACT_DETAIL_RAIL_CROSSING_PROJECT: PriorityImpactDetailData = {
  cardInfo: {
    cardTitle: 'Rail Crossing Projects within 1/4 Mile',
    cardDesc: `For current rail crossing project details please contact Kristopher Klop.`,
    name: 'Kristopher Klop',
    jobTitle: 'Manager, Highway-Rail Grade Crossing Programs',
    email: 'kristopher.klop@iowadot.us',
    phone: '515-239-1108'
  },
  dialogTitle: `HIGH PRIORITY IMPACT NOTICE: Rail Crossing Projects`,
  impactLayerTitles: ['Rail Crossing Projects']
};

import { Injectable } from '@angular/core';
import { RouteAndMeasure } from './route-and-measure.service';
import { esriRequest } from 'src/esri/request';
import { ENDPOINT_CONFIG } from '../dynamic-endpoint/endpoint.service.config';
import { environment } from 'src/environments/environment';

const IS_LOCAL_HOST_ENVIRONMENT = environment.isLocalHost;

interface RouteSegment {
  routeId: string;
  fromMeasure: number;
  toMeasure: number;
  fromDate?: number;
  toDate?: number;
  sectionId?: number;
  isDominant?: boolean;
}

interface ConcurrencySection {
  dominantEntry: RouteSegment;
  initialRouteEntry: RouteSegment;
}

@Injectable({
  providedIn: 'root'
})
export class ConcurrencyService {

  async getDominantRoutes(routeAndMeasure: RouteAndMeasure): Promise<{ ROUTE_ID: string, FROM_MEASURE: number, TO_MEASURE: number }[]> {
    const concurrencies = await this.getConcurrencies(routeAndMeasure);
    concurrencies.forEach(c => {
      const from = Math.min(c.fromMeasure, c.toMeasure);
      const to = Math.max(c.fromMeasure, c.toMeasure);
      c.fromMeasure = from;
      c.toMeasure = to;
    });
    const sections = this.asOrderedSections(concurrencies, routeAndMeasure.ROUTE_ID);
    const withFilledInGaps = this.fillInGaps(sections, routeAndMeasure);
    const dominantEntries = withFilledInGaps.map(x => x.dominantEntry);
    const simplified = this.joinAdjacentEntriesWithSameRouteId(dominantEntries);
    return simplified.map(x => ({
      ROUTE_ID: x.routeId,
      FROM_MEASURE: x.fromMeasure,
      TO_MEASURE: x.toMeasure
    }));
  }

  private async getConcurrencies(routeAndMeasure: RouteAndMeasure): Promise<RouteSegment[]> {
    const location = {
      routeId: routeAndMeasure.ROUTE_ID,
      fromMeasure: routeAndMeasure.FROM_MEASURE,
      toMeasure: routeAndMeasure.TO_MEASURE
    };
    const query = {
      locations: JSON.stringify([location]),
      f: 'json'
    };
    const ext = '/exts/LRSServer/networkLayers/0/concurrencies';
    // for testing RAMS
    // const url = !IS_LOCAL_HOST_ENVIRONMENT ? ENDPOINT_CONFIG.RAMS_PUBLIC_LRS.url + ext : ENDPOINT_CONFIG.RAMS_PUBLIC_LRS_TEST.url + ext;
    const url = ENDPOINT_CONFIG.RAMS_PUBLIC_LRS.url + ext;
    const res = await esriRequest(url, { query });
    const now = +new Date();
    return res.data.locations[0].concurrencies.filter(c => {
      return (c.fromDate === null || c.fromDate <= now) && (c.toDate === null || c.toDate > now);
    });
  }

  private asOrderedSections(concurrencies: RouteSegment[], initialRouteId: string): ConcurrencySection[] {
    const bySectionId: { [sectionId: string]: ConcurrencySection } = {};

    concurrencies.forEach(c => {
      if (!bySectionId[c.sectionId]) {
        bySectionId[c.sectionId] = <ConcurrencySection>{};
      }
      if (c.routeId === initialRouteId) {
        bySectionId[c.sectionId].initialRouteEntry = c;
      }
      if (c.isDominant) {
        bySectionId[c.sectionId].dominantEntry = c;
      }
    });

    const sections: ConcurrencySection[] = Object.values(bySectionId);

    return sections.sort((a, b) => {
      return a.initialRouteEntry.fromMeasure > b.initialRouteEntry.fromMeasure ? 1 : -1;
    });
  }

  private fillInGaps(sections: ConcurrencySection[], initialRouteAndMeasure: RouteAndMeasure) {
    if (!sections.length) {
      return [];
    }
    const initFrom = Math.min(initialRouteAndMeasure.FROM_MEASURE, initialRouteAndMeasure.TO_MEASURE);
    const initTo = Math.max(initialRouteAndMeasure.FROM_MEASURE, initialRouteAndMeasure.TO_MEASURE);
    const results: ConcurrencySection[] = [];
    sections.forEach(section => {
      const lastMeasure = results.length
        ? results[results.length - 1].initialRouteEntry.toMeasure
        : initFrom;

      // account for floating point math
      if ((lastMeasure < section.initialRouteEntry.fromMeasure) && (section.initialRouteEntry.fromMeasure - lastMeasure > .000000000001)) {
        const segment: RouteSegment = {
          routeId: initialRouteAndMeasure.ROUTE_ID,
          fromMeasure: lastMeasure,
          toMeasure: section.initialRouteEntry.fromMeasure
        };
        results.push({
          initialRouteEntry: segment,
          dominantEntry: segment
        });
      }
      results.push(section);
    });

    if ((results[results.length - 1].initialRouteEntry.toMeasure < initTo) && (initTo - results[results.length - 1].initialRouteEntry.toMeasure > .000000000001)) {
      const segment: RouteSegment = {
        routeId: initialRouteAndMeasure.ROUTE_ID,
        fromMeasure: results[results.length - 1].initialRouteEntry.toMeasure,
        toMeasure: initTo
      };
      results.push({
        initialRouteEntry: segment,
        dominantEntry: segment
      });
    }
    return results;
  }

  private joinAdjacentEntriesWithSameRouteId(segments: RouteSegment[]) {
    if (!segments.length) {
      return [];
    }
    const res: RouteSegment[] = [];
    segments.forEach(segment => {
      if (!res.length) {
        res.push(segment);
      } else {
        if (segment.routeId === res[res.length - 1].routeId) {
          res[res.length - 1].toMeasure = segment.toMeasure;
        } else {
          res.push(segment);
        }
      }
    });
    return res;
  }
}

import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// ArcGIS Core
import Basemap from '@arcgis/core/Basemap';
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Zoom from '@arcgis/core/widgets/Zoom';
import Home from '@arcgis/core/widgets/Home';
import DistanceMeasurement2D from '@arcgis/core/widgets/DistanceMeasurement2D';
import Point from '@arcgis/core/geometry/Point';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import * as watchUtils from '@arcgis/core/core/watchUtils';

import { LoggingService } from '../logging.service';

export const ARC_URL_BASE = 'https://www.arcgis.com/sharing/rest/content/items/';
const MAP_INIT_ZOOM = 6;
const MAP_INIT_CENTER = [-93.5, 42.1];

export interface MapAssets {
  basemap: Basemap;
  basemapIowaDOTGrey?: Basemap;
  map: Map;
  view: MapView;
  zoom?: Zoom;
  home?: Home;
  measurementWidget?: DistanceMeasurement2D;
  zoomToFullExtent?: any;
}

export interface MapDimensions {
  mapWidth: number;
  mapHeight: number;
  mapTop: number,
  mapLeft: number
}

@Injectable({
  providedIn: 'root'
})
export class MapService {
  constructor(
    private logging: LoggingService
  ) {
  }

  private view: MapView;
  private map: Map;

  // map initialized
  private _mapAssets = new BehaviorSubject<MapAssets>(null);
  mapAssets$ = this._mapAssets.asObservable();

  // zoom
  private watchHandleZoom: any;
  private _mapZoom = new BehaviorSubject<number>(-1);
  mapZoom$ = this._mapZoom.asObservable();

  // extent change
  private watchHandleExtent: any;
  private _mapExtent = new BehaviorSubject<__esri.Extent>(null);
  mapExtent$ = this._mapExtent.asObservable();

  // layer change
  private _layerChange = new BehaviorSubject<__esri.CollectionChangeEvent<__esri.Layer>>(null);
  layerChange$ = this._layerChange.asObservable();

  initializeMap(mapContainer: ElementRef, measureContainerId: string): Observable<MapAssets> {
    // this.logging.log('🚀 ~ file: map.service.ts ~ line 69 ~ MapService ~ initializeMap ~ initializeMap');
    const container = mapContainer.nativeElement;
    const baseLayer = new VectorTileLayer({
      url: '/assets/opaque-topo-vector.json'
    });

    const basemap = new Basemap({
      baseLayers: [baseLayer],
      title: 'Topographic Vector',
      thumbnailUrl: ARC_URL_BASE + '7dc6cea0b1764a1f9af2e679f642f0f5/info/thumbnail/Topographic_600x400_thumb.jpg'
    });

    const basemapIowaDOTGrey = new Basemap({
      portalItem: {
        id: '805a6f2de49841e8b390cd54d91e53ad'
      }
    });

    const map = new Map({ basemap });
    this.map = map;

    const view = new MapView({
      container: container,
      map: this.map,
      zoom: MAP_INIT_ZOOM,
      center: MAP_INIT_CENTER,
      ui: { components: ['attribution'] }
    });

    // bind
    this.view = view;

    // hide popup on open so we can show OUR custom popup
    // other map views still have working popups
    this.view.popup.watch('visible', isVisible => {
      if (isVisible) {
        this.view.popup.visible = false;
      }
    });


    // layer view create
    // this.view.on('layerview-create', event => {
    //   this.logging.log('🚀 ~ file: map.service.ts ~ line 93 ~ MapService ~ view.on(layerview-create)', event);
    // });

    // layer add
    // this.view.map.allLayers.on('change', event => {
    //   if (event.added.length > 0) {
    // event.added.forEach(l => {
    // this.logging.log('🚀 ~ file: map.service.ts ~ line 117 ~ MapService ~ Layer Added', l.title);
    // });
    // }
    // });

    // load then
    this.view.when().then(view => {

      // zoom change listener
      this.watchHandleZoom = view.watch('zoom', v => {
        this._mapZoom.next(v);
      });

      // Watch view's stationary property for becoming true.
      watchUtils.whenTrue(view, 'stationary', v => {
        if (v && view.extent) {
          this._mapExtent.next(view.extent);
        }
      });

      // This function fires each time an error occurs during the creation of a layerview
      this.view.on('layerview-create-error', function (event) {
        console.error('LayerView failed to create for layer with the id: ', event.layer.id);
      });

      // watch for layer changes
      this.view.map.allLayers.on('change', (event) => {
        this._layerChange.next(event);
      });

      // prevent zooming when highlighting features off-screen
      this.view.popup.goToOverride = () => {
        return;
      };

      const zoom = new Zoom({ view });
      const home = new Home({ view });

      const measurementWidget = new DistanceMeasurement2D({
        view: view,
        container: measureContainerId
        // container: 'measureDistanceDiv'
      });

      const centerPt = new Point({
        x: MAP_INIT_CENTER[0],
        y: MAP_INIT_CENTER[1],
        spatialReference: new SpatialReference({
          wkid: 4326
        })
      });

      const zoomToFullExtent = () => {
        view.zoom = MAP_INIT_ZOOM;
        view.center = centerPt;
      };

      this._mapAssets.next({
        basemap,
        basemapIowaDOTGrey,
        map,
        view,
        zoom,
        home,
        measurementWidget,
        zoomToFullExtent
      });
    });
    return this.mapAssets$;
  }

  dispose() {
    this._mapAssets.next(null);
    // this causes console errors with RxLayers saying layer is already destroyed
    // this.map.destroy();
    // this.view.destroy();
  }

  getViewDimensions(): MapDimensions {
    if (!this.view) {
      throw new Error(`map view not ready -> this.view = ${this.view}`);
    }
    return {
      mapWidth: this.view.width,
      mapHeight: this.view.height,
      mapTop: this.view.container.offsetTop,
      mapLeft: this.view.container.offsetLeft
    };
  }

  getAssets(): Promise<MapAssets> {
    if (!this._mapAssets.value) {
      throw new Error(`map assets are not ready, try subscribing to mapAssets$ instead
      -> this._mapAssets.value = ${this._mapAssets.value}`);
    }
    return new Promise(resolve => {
      resolve(this._mapAssets.value);
    });
  }

  getAssetsObs(): Observable<MapAssets> {
    if (!this._mapAssets.value) {
      throw new Error(`map assets are not ready, try subscribing to mapAssets$ instead
      -> this._mapAssets.value = ${this._mapAssets.value}`);
    }
    return this._mapAssets;
  }

  goHome() {
    this._mapAssets.value.home.go();
  }

  zoomOut() {
    this._mapAssets.value.zoom.zoomOut();
  }

  zoomIn() {
    this._mapAssets.value.zoom.zoomIn();
  }

  async zoomTo(graphics: __esri.Graphic[]) {
    if (this.view) {
      await this.view.goTo(graphics);
    }
  }


  /*
  assets = (async () => {

    const baseLayer = new VectorTileLayer({
      url: '/assets/opaque-topo-vector.json'
    });

    const basemap = new Basemap({
      baseLayers: [ baseLayer ],
      title: 'Topographic Vector',
      thumbnailUrl: ARC_URL_BASE + '7dc6cea0b1764a1f9af2e679f642f0f5/info/thumbnail/Topographic_600x400_thumb.jpg'
    });

    // Left for reference, not a good color scheme for overlay
    /*
    const basemapIowaDOTColor = new Basemap({
      portalItem: {
        id: '77831691f545442e9bb57ed1e8ad7957'
      }
    });
    */

  /*
    const basemapIowaDOTGrey = new Basemap({
      portalItem: {
        id: '805a6f2de49841e8b390cd54d91e53ad'
      }
    });

    const map = new Map({ basemap });

    const view = new MapView({
      map,
      container: 'map', // element with id 'map' in index.html
      zoom: MAP_INIT_ZOOM,
      center: MAP_INIT_CENTER,
      ui: { components: ['attribution'] }
    });
    this.view = view;

    // zoom change listener
    this.watchHandleZoom = this.view.watch('zoom', v => {
      this._mapZoom.next(v);
    });

    // Watch view's stationary property for becoming true.
    watchUtils.whenTrue(this.view, 'stationary', v =>{
      if (v && this.view.extent) {
        this._mapExtent.next(this.view.extent);
      }
    });

    // watch for layer changes
    this.view.map.allLayers.on('change', (event) =>{
      this._layerChange.next(event);
    });

    // prevent zooming when highlighting features off screen
    view.popup.goToOverride = () => {};

    const zoom = new Zoom({ view });

    const home = new Home({ view });

    const measurementWidget = new DistanceMeasurement2D({
      view: view,
      container: 'measureDistanceDiv'
    });

    const zoomToFullExtent = () => {
      this.view.zoom = MAP_INIT_ZOOM;
      this.view.center = MAP_INIT_CENTER;
    };

    return {
      basemap,
      basemapIowaDOTGrey,
      map,
      view,
      zoom,
      home,
      measurementWidget,
      zoomToFullExtent
    };
  })();
  */

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { PinsRxLayerService } from './layer/pins-rx-layer.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { DialogNoticeService } from 'src/app/services/dialog-notice.service';
import { environment } from 'src/environments/environment';
import { ENDPOINT_CONFIG } from 'src/app/services/dynamic-endpoint/endpoint.service.config';

declare let FMEServer: any; // see fmeserver.min.js referenced in index.html

// AWS
const FME_AUTH_URL = ENDPOINT_CONFIG.AWS.FME_TOKEN.url;
const FME_AUTH_URL_API_KEY = ENDPOINT_CONFIG.AWS.FME_TOKEN.apiKey;
// FME
const FME_SERVER_PROD_URL = ENDPOINT_CONFIG.FME.prodServer.url;
const FME_SERVER_TEST_URL = ENDPOINT_CONFIG.FME.testServer.url;
const JOB_STATUS_PROD_ENDPOINT = `${FME_SERVER_PROD_URL}/${ENDPOINT_CONFIG.FME.endpoints.jobStatus}`;
const JOB_STATUS_TEST_ENDPOINT = `${FME_SERVER_TEST_URL}/${ENDPOINT_CONFIG.FME.endpoints.jobStatus}`;
const FME_REPOSITORY = ENDPOINT_CONFIG.FME.repositories.prioritization;
// FME Workspaces
const IMPACTS_FMW = ENDPOINT_CONFIG.FME.workspaces.impacts;
const IMPACTS_NEPA_FMW = ENDPOINT_CONFIG.FME.workspaces.impactsNepa;
const PRIORITY_FMW = ENDPOINT_CONFIG.FME.workspaces.priority;
const PROD_DOWNLOAD_FMW = ENDPOINT_CONFIG.FME.workspaces.download;
const PROD_DOWNLOAD_BATCH_FMW = ENDPOINT_CONFIG.FME.workspaces.downloadBatch;
const HIGHWAY_CANDIDATE_DOWNLOAD = ENDPOINT_CONFIG.FME.workspaces.highwayCandidates;

// FME timeout period Milliseconds...10 minutes
const TIME_OUT_MS = 600000;
const TIME_OUT_MIN = (TIME_OUT_MS / 1000) / 60;

export interface FmeParameter {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class OnDemandFMEService {

  downloadObservable: Observable<any>;
  fmeTestToken: any;
  fmeToken: any;
  timeoutMS: number;
  timeoutMin: number;

  private _nepaImpactJobStatus = new BehaviorSubject<string>(null);
  nepaImpactJobStatus$ = this._nepaImpactJobStatus.asObservable();

  constructor(
    private http: HttpClient,
    private pinsRxLayerService: PinsRxLayerService,
    private userInfoService: UserInfoService,
    private dialogNoticeService: DialogNoticeService
  ) {
  }

  private init = (async () => {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'X-Api-Key': FME_AUTH_URL_API_KEY
      }
    );
    const options = {
      headers: headers,
    };
    this.fmeToken = await this.http.get(FME_AUTH_URL, options).toPromise();
    // production server
    FMEServer.init({
      server: FME_SERVER_PROD_URL,
      token: this.fmeToken
    });
  })();

  setTimeout(minutes?: number) {
    this.timeoutMin = minutes;
    this.timeoutMS = minutes ? (minutes * 1000) * 60 : 0;
  }

  async runHighwayCandidateDownload(timeoutMinutes?: number) {
    await this.init;
    this.setTimeout(timeoutMinutes);
    return this.runDownload(null, null, HIGHWAY_CANDIDATE_DOWNLOAD, false);
  }

  async runImpacts(pin: string) {
    await this.init;
    return this.runJob(pin, IMPACTS_FMW);
  }

  async runImpactsNEPA(pins: string[]) {
    await this.init;
    return this.runJobNEPAImacts(pins, IMPACTS_NEPA_FMW);
  }

  async runPrioritization(pin: string) {
    await this.init;
    return this.runJob(pin, PRIORITY_FMW);
  }

  async runDownloadProject(pin: string, timeoutMinutes?: number) {
    await this.init;
    this.setTimeout(timeoutMinutes);
    return this.runDownload(pin, null, PROD_DOWNLOAD_FMW);
  }

  async runDownloadProjectBatch(pins: string[], timeoutMinutes?: number) {
    await this.init;
    this.setTimeout(timeoutMinutes);
    return this.runDownload(null, pins, PROD_DOWNLOAD_BATCH_FMW);
  }

  private runDownload(pin: string, pins: string[], workspace: string, emailNoticeEnabled = true): Subject<string> {

    const publishedParameters: FmeParameter[] = [];

    if (pin) {
      publishedParameters.push({
        name: 'PIN',
        value: pin
      });
    }

    if (pins) {
      pins.forEach((p, i) => {
        if (i === 0) {
          publishedParameters.push({ name: 'PIN', value: p });
        } else {
          publishedParameters.push({ name: `PIN_${i + 1}`, value: p });
        }
      });
    }

    const statusStream = new Subject<string>();
    statusStream.next('SUBMITTING');

    this.submitDownloadRequest(workspace, publishedParameters).then(async (res) => {
      if (!res || !res.serviceResponse.jobID) {
        if (res.serviceResponse && res.serviceResponse.statusInfo && res.serviceResponse.statusInfo.message) {
          await this.dialogNoticeService.error({ message: 'FME Error:  ' + res.serviceResponse.statusInfo.message });
        } else {
          await this.dialogNoticeService.error({ message: 'FME Error:  ' + 'FME job received no id.' });
        }
        statusStream.next('ERROR');
        return;
      }
      if (res.serviceResponse.jobID && res.serviceResponse.statusInfo.status === 'success') {
        this.dialogNoticeService.alert({
          title: `DOWNLOAD SUBMITTED - Job ID: ${res.serviceResponse.jobID}`,
          message: `Your download request was successfully submitted.
          The zip file should automatically download once processing is complete.
          ${emailNoticeEnabled ?
    'You should also receive an email with a link to the zip file.  The emailed link will only be active for 30 minutes.' :
    ''
}`
        });
        this.getDownloadJobStatusStream(res.serviceResponse.jobID, statusStream, () => {
          console.log(statusStream);
        });
      }
    });
    return statusStream;
  }

  private runJobNEPAImacts(pins: string[], workspace: string): Subject<string> {

    const publishedParameters: FmeParameter[] = [];
    pins.forEach(pin => {
      publishedParameters.push({ name: 'SID', value: pin });
    });

    const statusStream = new Subject<string>();
    statusStream.next('SUBMITTING');
    // subscribe / broadcast the stream to a behavior subject so other services can subscribe
    statusStream.subscribe(s => {
      this._nepaImpactJobStatus.next(s);
    });

    this.submitJob(workspace, { publishedParameters }).then(async (res) => {
      if (!res || !res.id) {
        await this.dialogNoticeService.error({ message: 'FME Error:  ' + 'FME job received no id.' });
        return;
      }
      this.getJobStatusStream(res.id, statusStream, () => {
        console.log(statusStream);
      });
    });
    return statusStream;
  }

  private runJob(pin: string, workspace: string): Subject<string> {

    const publishedParameters: FmeParameter[] = [{
      name: 'SID',
      value: pin
    }];

    const statusStream = new Subject<string>();
    statusStream.next('SUBMITTING');

    this.submitJob(workspace, { publishedParameters }).then(async (res) => {
      if (!res || !res.id) {
        await this.dialogNoticeService.error({ message: 'FME Error:  ' + 'FME job received no id.' });
        return;
      }
      this.getJobStatusStream(res.id, statusStream, () => {
        this.pinsRxLayerService.refreshPin(pin);
      });
    });
    return statusStream;
  }

  private getJobStatusStream(jobId, usingSubject?: Subject<string>, onComplete?: () => void): Subject<string> {
    const s = usingSubject || new Subject<string>();
    let lastVal;
    const interval_ms = 500;
    let executionTime = 0;
    const i = setInterval(() => {
      this.getJobStatus(jobId).then(async (status) => {
        if (lastVal !== status) {
          s.next(status);
        }
        lastVal = status;
        executionTime = executionTime + interval_ms;
        if (executionTime > TIME_OUT_MS || executionTime > this.timeoutMS) {
          s.next('ERROR');
          s.hasError = true;
          clearInterval(i);
          const timeout = this.timeoutMin ? this.timeoutMin : TIME_OUT_MIN;
          await this.dialogNoticeService.error({ message: `FME ERROR, timeout of ${timeout} minute/s exceeded.` });
        }
        if (status === 'SUCCESS') {
          clearInterval(i);
          if (onComplete) {
            onComplete();
          }
        }
      });
    }, interval_ms);
    return s;
  }

  private saveDownloadResult(fmeResult) {
    if (fmeResult.result) {
      const a = document.createElement('a');
      a.href = fmeResult.result.resultDatasetDownloadUrl.replace('http://', 'https://');
      document.body.appendChild(a); // Required for Firefox
      a.click();
      a.remove();
    }
  }

  private getDownloadJobStatusStream(jobId, usingSubject?: Subject<string>, onComplete?: () => void): Subject<string> {
    const s = usingSubject || new Subject<string>();
    let lastVal;
    const interval_ms = 500;
    let executionTime = 0;
    const i = setInterval(() => {
      this.getDownloadJobStatus(jobId).then(async (result) => {
        const status = result.status;
        if (lastVal !== status) {
          s.next(status);
        }
        lastVal = status;
        executionTime = executionTime + interval_ms;
        if (executionTime > TIME_OUT_MS || executionTime > this.timeoutMS) {
          s.next('ERROR');
          s.hasError = true;
          clearInterval(i);
          const timeout = this.timeoutMin ? this.timeoutMin : TIME_OUT_MIN;
          await this.dialogNoticeService.error({ message: `FME ERROR, timeout of ${timeout} minute/s exceeded.` });
        }
        if (status === 'SUCCESS') {
          clearInterval(i);
          this.saveDownloadResult(result);
          s.next(status);
          if (onComplete) {
            onComplete();
          }
        }
      }).catch(async (err) => {
        s.next('ERROR');
        s.hasError = true;
        clearInterval(i);
        await this.dialogNoticeService.error({ message: err });
      });
    }, interval_ms);
    return s;
  }

  private getDownloadJobStatus(jobId): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = JOB_STATUS_PROD_ENDPOINT + jobId;
      FMEServer.customRequest(url, 'GET', (x: any) => {
        if (x.message && x.message.toLowerCase().indexOf('failed') > 0) {
          reject(x.message);
        }
        resolve(x);
      });
    });
  }

  private getJobStatus(jobId): Promise<string> {
    return new Promise<string>(resolve => {
      const url = JOB_STATUS_PROD_ENDPOINT + jobId;
      FMEServer.customRequest(url, 'GET', (x: any) => {
        resolve(x.status);
      });
    });
  }

  private submitJob(workspace, params): Promise<any> {
    return new Promise(resolve => {
      FMEServer.submitJob(FME_REPOSITORY, workspace, params, (x: any) => {
        resolve(x);
      });
    });
  }

  private async submitDownloadRequest(workspace, params: FmeParameter[]): Promise<any> {
    // url parameters
    // single pin vs. multiple pin
    let pinParam = null;
    if (params.length === 1) {
      pinParam = `${params[0].name}=${params[0].value}`;
    } else {
      const paramStrings = params.map(m => m['name'] + '=' + m['value']);
      pinParam = paramStrings.join('&');
    }
    const optShowresult = true;
    const optServiceMode = 'async';
    const optResponseFormat = 'json';
    const optRequesterEmail = environment.isLocalHost ? 'ian.grasshoff@gmail.com' : this.userInfoService.userInfo.value.username;
    // build download url
    const downloadUrl = `${FME_SERVER_PROD_URL}/fmedatadownload/${FME_REPOSITORY}/${workspace}` +
      `?${pinParam}&opt_showresult=${optShowresult}&opt_servicemode=${optServiceMode}&` +
      `opt_requesteremail=${optRequesterEmail}` +
      `&token=${this.fmeToken}&opt_responseformat=` +
      `${optResponseFormat}`;
    // call url
    return this.http.get(downloadUrl).toPromise();
  }

}

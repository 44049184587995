import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

// ArcGIS Core
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';

import { environment } from 'src/environments/environment';
import { MapService } from '../map/map.service';
import { EndpointService } from '../dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from '../dynamic-endpoint/endpoint.service.config';
import { RAMS_EFFECTIVE_DATE_CLAUSE } from 'src/app/services/rams-effective-date-clause';
import { MapClickHandlerService, MapClickMode } from '../map/map-click-handler.service';
import {
  FROM_POINT_SYMBOL,
  ROUTE_SLICE_INCLUDED_SYMBOL,
  ROUTE_SLICE_SYMBOL,
  TO_POINT_SYMBOL,
  WHOLE_ROUTE_SYMBOL
} from './lrs-layer.service.symbology';
import { RxLayer } from '../../rx-layer/rx-layer';
import { CurrentTabService, Tab } from 'src/app/details-pane/details-pane-body/current-tab.service';
import { RouteAndMeasure, RouteAndMeasureService } from './route-and-measure.service';
import { LoggingService } from '../logging.service';
import { AppLayoutService } from '../app-layout.service';

@Injectable({
  providedIn: 'root'
})
export class LrsLayerService {

  private wholeRoutesLayer: RxLayer;
  private routesAndMeasuresLayer: __esri.GraphicsLayer;
  private routeSelectLayer: RxLayer;

  constructor(
    private appLayoutService: AppLayoutService,
    private mapService: MapService,
    private endpointService: EndpointService,
    private mapClickHandlerService: MapClickHandlerService,
    private currentTabService: CurrentTabService,
    private routeAndMeasureService: RouteAndMeasureService,
    private logging: LoggingService
  ) {
    if (!environment.isCommission) {
      this.init();
    }
  }

  async init() {
    // once map is ready
    this.mapService.mapAssets$.pipe(
      filter(assets => assets !== null && assets !== undefined)
    ).subscribe(async (assets) => {

      // this.logging.log('🚀 ~ file: lrs-layer.service.ts ~ line 46 ~ LrsLayerService ~ this.mapService.mapAssets$.pipe ~ assets', assets);
      await this.instantiateLayers(assets.map);

      combineLatest([
        this.appLayoutService.layoutMode$,
        this.currentTabService.currentTab,
        this.routeAndMeasureService.routesAndMeasures,
        this.mapClickHandlerService.mapClickMode
      ]).pipe(debounceTime(1)).subscribe(([layout, tab, routesAndMeasures, clickMode]) => {

        const geomTab = layout === 'details' && tab === Tab.geometry;
        const selectedRoute = routesAndMeasures.find(x => x.SELECTED);
        const selectingRoute = clickMode === MapClickMode.SelectLrsRoute;
        const showSlices = routesAndMeasures.length && geomTab && !selectingRoute;

        this.routesAndMeasuresLayer.removeAll();
        if (showSlices) {
          const sliceGraphics = this.getSliceGraphics(selectedRoute ? [selectedRoute] : routesAndMeasures);
          this.routesAndMeasuresLayer.addMany(sliceGraphics);
        }

        this.wholeRoutesLayer.baseWhereClause.next(selectedRoute
          ? `ROUTE_ID = '${selectedRoute.ROUTE_ID}' and ${RAMS_EFFECTIVE_DATE_CLAUSE}`
          : '1=2');

        this.routeSelectLayer.visible.setValue(selectingRoute);
        this.wholeRoutesLayer.visible.setValue(selectedRoute && !selectingRoute);
      });
    });
  }

  refreshRoute(slice: RouteAndMeasure) {
    if (slice) {
      const sliceGraphic = this.getSliceGraphics([slice]);
      const updatedSlices = this.routesAndMeasuresLayer.graphics
        .filter(g => g.attributes['TMP_GUID'] !== slice.TMP_GUID)
        .toArray()
        .concat(sliceGraphic);
      this.routesAndMeasuresLayer.removeAll();
      // this.routesAndMeasuresLayer.addMany(updatedSlices);
    }
  }

  private async instantiateLayers(map) {
    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.RAMS_PUBLIC_LRS);

    this.wholeRoutesLayer = new RxLayer({
      map,
      title: 'whole routes lrs layer',
      visible: false,
      url: urls.IOWA_LRS_NETWORK,
      baseWhereClause: '1=2',
      renderer: <any>{
        type: 'simple',
        symbol: WHOLE_ROUTE_SYMBOL
      },
      hideLayerWhileUpdating: true,
      order: 1,
      excludePopupTemplate: true
    });

    this.routesAndMeasuresLayer = new GraphicsLayer({
      title: 'route slices lrs layer'
    });

    this.routeSelectLayer = new RxLayer({
      map,
      title: 'route select layer',
      visible: false,
      url: urls.IOWA_LRS_NETWORK,
      baseWhereClause: `${RAMS_EFFECTIVE_DATE_CLAUSE}`
    });

    // wholeRoutesLayer and routeSelectLayer will add themselves to the map since since they are RxLayers
    map.add(this.routesAndMeasuresLayer);
  }

  getSliceGraphics(routesAndMeasures: RouteAndMeasure[]) {
    const lines: __esri.Graphic[] = [];
    const points: __esri.Graphic[] = [];
    routesAndMeasures.forEach(slice => {
      // line graphic

      const lineGraphic = {
        type: 'graphic',
        geometry: {
          type: 'polyline',
          paths: slice.PATHS,
          spatialReference: { wkid: 3857 }
        },
        attributes: slice,
        symbol: slice.INCLUDED ? ROUTE_SLICE_INCLUDED_SYMBOL : ROUTE_SLICE_SYMBOL
      } as any;
      lines.push(lineGraphic);

      // dots at each end
      const nullCheck = p => {
        return p.length && p[0].length && p[0][0].length;
      };
      const firstPoint = p => {
        const firstPath = p[0];
        return firstPath[0];
      };
      const lastPoint = p => {
        const lastPath = p[p.length - 1];
        return lastPath[lastPath.length - 1];
      };
      if (nullCheck(slice.PATHS)) {
        if (slice.FROM_MEASURE) {
          const fp = firstPoint(slice.PATHS);
          const fpPoint = {
            type: 'point',
            x: fp[0],
            y: fp[1],
            spatialReference: { wkid: 3857 }
          };
          const fpGraphic = {
            type: 'graphic',
            geometry: fpPoint,
            symbol: FROM_POINT_SYMBOL,
            attributes: slice
          } as any;
          points.push(fpGraphic);
        }

        if (slice.TO_MEASURE) {
          const lp = lastPoint(slice.PATHS);
          const lpPoint = {
            type: 'point',
            x: lp[0],
            y: lp[1],
            spatialReference: { wkid: 3857 }
          };
          const lpGraphic = {
            type: 'graphic',
            geometry: lpPoint,
            symbol: TO_POINT_SYMBOL,
            attributes: slice,
          } as any;
          points.push(lpGraphic);
        }
      }
    });
    return [
      ...lines,
      ...points
    ];
  }
}

import { RxJsLoggingLevel } from 'src/app/functions/rxjs-debug';

export const environment = {
  production: true,
  isCommission: window.location.href.indexOf('commission') > -1,
  isDev: window.location.href.indexOf('scopingdev') > -1,
  isUat: window.location.href.indexOf('scopinguat') > -1,
  isProduction: window.location.href.indexOf('iowadotprojectpriorities') > -1,
  isLocalHost: window.location.href.indexOf('localhost') > -1,
  loggingLevel: RxJsLoggingLevel.INFO
};

import { Injectable } from '@angular/core';

import { ProjectType } from 'src/app/types/project-types';
import { PinAttributes } from './amalgamator/pin-attributes';

@Injectable({
  providedIn: 'root'
})
export class ProjectInfoService {

  constructor() {
    return;
  }

  getType(pin: string): string {
    if (pin.indexOf('X') === 0) {
      return ProjectType.planning;
    }
    if (pin.indexOf('S') === 0) {
      return ProjectType.scope;
    } else {
      return ProjectType.pin;
    }
  }

  getTypeName(pin: string): string {
    const type = this.getType(pin);
    const projectType = new ProjectType();
    return projectType.getTypeName(type);
  }

  isScope(pin: string): boolean {
    return this.getType(pin) === ProjectType.scope;
  }

  isPin(pin: string): boolean {
    return this.getType(pin) === ProjectType.pin;
  }

  isScopeToPin(atts: PinAttributes): boolean {
    return atts.IS_SCOPE_TO_PIN === 1 ? true : false;
  }

  isPlanning(pin: string): boolean {
    return this.getType(pin) === ProjectType.planning;
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RxJsLoggingLevel } from '../functions/rxjs-debug';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() {
    return;
  }

  log(message: string, data?: any, debugMode = false) {
    if (debugMode || environment.loggingLevel === RxJsLoggingLevel.DEBUG) {
      console.log(message, data);
    }
  }

  error(message: string, data?: any) {
    console.error(message, data);
  }

  warn(message: string, data?: any) {
    console.warn(message, data);
  }

}

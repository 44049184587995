export function setModernFieldType(field: __esri.Field) {
  const conversions = {
    esriFieldTypeDouble: 'double',
    esriFieldTypeSingle: 'single',
    esriFieldTypeDate: 'date',
    esriFieldTypeString: 'string',
    esriFieldTypeSmallInteger: 'small-integer',
    esriFieldTypeInteger: 'integer',
    esriFieldTypeLong: 'long',
    esriFieldTypeOID: 'oid'
  };
  if (Object.keys(conversions).indexOf(field.type) > -1) {
    field.type = conversions[field.type];
  }
}

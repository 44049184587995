<div class="attr-tbl-dialog-titlebar">
  <div class="layer-title">{{ titleOverride || rxLayer.title }}</div>
  <ng-container *ngIf="rxLayer.visibleFieldsPromise | async">
    <!-- spacer -->
    <div class="spacer" *ngIf="railCrossingButtonEnabled">
      <button mat-stroked-button
              color="primary"
              matTooltip="View Rail Crossing Project Info"
              (click)="viewRailCrossingProjectInfo()">
        Rail Crossing Info
      </button>
    </div>
    <!-- spacer -->
    <div class="spacer" *ngIf="lrtpNoticeButtonEnabled">
      <button mat-stroked-button
              color="primary"
              matTooltip="View LRTP Proximity Notice"
              (click)="viewLrtpNotice()">
        LRTP Proximity Notice
      </button>
    </div>
    <div class="spacer"></div>
    <app-filter-bar [rxLayer]="rxLayer" class="filter-bar"></app-filter-bar>
    <ng-container *ngIf="!tableOnly">
      <button [disabled]="!heatButtonEnabled" mat-icon-button tabindex="-1" [matMenuTriggerFor]="heatmapPopup"
              matTooltip="Apply Heatmap">
        <mat-icon [color]="(rxLayer.heatModeEnabled.valueStream | async) ? 'warn' : 'currentColor'">whatshot</mat-icon>
      </button>
      <mat-menu #heatmapPopup="matMenu" class="overflow-visible-menu-panel attr-tbl-dialog-titlebar">
        <div (click)="$event.stopPropagation()" style="margin: -8px 8px">
          <button mat-icon-button (click)="toggleHeatmap(heatMapBlue)">
            <mat-icon>{{ (rxLayer.heatModeEnabled.valueStream | async) ? 'toggle_on' : 'toggle_off' }}</mat-icon>
            Toggle
          </button>
          <ng-container *ngIf="rxLayer.heatModeFields | async">
            <div style="max-height: 320px;overflow-y: auto" class="styled-scroll">
              <div style="padding: 4px 0">
                <div style="cursor: pointer">
                  <div *ngFor="let field of rxLayer.heatModeFields | async"
                       [ngClass]="{'field-option-selected': selectedField === field}" class="field-option" matRipple
                       (click)="fieldSelected(field)">{{ field.alias }}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-menu>
      <button mat-icon-button tabindex="-1" (click)="rxLayer.visible.setValue(!rxLayer.visible.value)"
              matTooltip="Visibility on map">
        <mat-icon>{{ (rxLayer.visible.valueStream | async) ? 'visibility' : 'visibility_off' }}</mat-icon>
      </button>
      <button mat-icon-button tabindex="-1" style="transform: translate3d(2px,0,0)" [matMenuTriggerFor]="opacityPopup"
              matTooltip="Opacity on map" [disabled]="!(rxLayer.visible.valueStream | async)">
        <mat-icon style="transform: translate3d(0,-1px,0)">opacity</mat-icon>
      </button>
      <mat-menu #opacityPopup="matMenu" class="overflow-visible-menu-panel">
        <div (click)="$event.stopPropagation()" style="margin: -8px 8px">
          <mat-slider color="primary" thumbLabel [formControl]="rxLayer.opacity" min="0" max="1"
                      step="0.01"></mat-slider>
        </div>
      </mat-menu>
    </ng-container>
  </ng-container>
  <div *ngIf="!(rxLayer.visibleFieldsPromise | async)" style="flex-grow:1"></div>
  <button mat-icon-button tabindex="-1" (click)="dockBottom()" *ngIf="dock !== 'DockBottom'">
    <mat-icon>expand_more</mat-icon>
  </button>
  <button mat-icon-button tabindex="-1" (click)="fullscreen()" *ngIf="dock !== 'FullScreen'">
    <mat-icon>expand_less</mat-icon>
  </button>
  <button mat-icon-button tabindex="-1" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div *ngIf="memo" class="memo">{{ memo }}</div>
<div *ngIf="impactStats" class="attr-tbl-impact-stats-bar">
  <div *ngIf="impactStats.totalArea && impactStats.totalArea.value" class="attr-tbl-impact-stat">
    <div><span>{{impactStats.totalArea.label}}</span></div>
    <div><span>{{impactStats.totalArea.value | number: '1.2-3'}} {{impactStats.totalArea.unit}}</span></div>
  </div>
  <div *ngIf="impactStats.totalLength && impactStats.totalLength.value" class="attr-tbl-impact-stat">
    <div><span>{{impactStats.totalLength.label}}</span></div>
    <div><span>{{impactStats.totalLength.value | number: '1.2-3'}} {{impactStats.totalLength.unit}}</span></div>
  </div>
</div>
<app-attribute-table [rxLayer]="rxLayer" style="flex-grow: 1"></app-attribute-table>

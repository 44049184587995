import { Injectable } from '@angular/core';
import { EndpointService } from '../dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from '../dynamic-endpoint/endpoint.service.config';
import { esriRequest } from 'src/esri/request';
import { from, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

export class MapLayerConfigEntry {
  OBJECTID: number;
  TITLE: string;
  URL: string;
  CATEGORY: number;
  QUERY: string;
  SORT_ORDER: number;
  REST_UPDATED: number;
  UTC_OFFSET: string;
  DEFAULT_COLUMNS: string;
  CUSTOM_RENDERER: string;
  SUBCATEGORY: number;
  MIN_ZOOM: number;
  MAX_ZOOM: number;
}

export class PinImpactLayerConfigEntry {
  OBJECTID: number;
  TITLE: string;
  ENDPOINT_URL: string;
  PIN_ID_FIELD: string;
  CATEGORY: number;
  SORT_ORDER: number;
  QUERY: string;
  REST_UPDATED: number;
  UTC_OFFSET: string;
  CUSTOM_RENDERER: string;
  DEFAULT_COLUMNS: string;
  INITIAL_IMPACTS: number;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigTableService {

  // used by MapRxLayersService
  mapLayerConfigPromise: Promise<MapLayerConfigEntry[]>;
  mapLayerConfigFieldsPromise: Promise<__esri.Field[]>;

  // used in impacts in details pane
  pinImpactLayerConfigPromise: Promise<PinImpactLayerConfigEntry[]>;

  constructor(
    private endpointService: EndpointService
  ) {
    const urlsPromise = this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);

    this.mapLayerConfigPromise = urlsPromise.then(urls =>
      this.getConfig(urls.PROJECT_SCOPING_MAP_LAYERS)
    );
    this.mapLayerConfigFieldsPromise = urlsPromise.then(urls =>
      this.getFields(urls.PROJECT_SCOPING_MAP_LAYERS)
    );
    this.pinImpactLayerConfigPromise = urlsPromise.then(urls =>
      this.getConfig(urls.PIN_IMPACT_LAYERS)
    );
  }

  getImpactLayerConfigs() {
    return from(this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA)).pipe(
      mergeMap(urls =>
        from(this.getConfig(urls.PIN_IMPACT_LAYERS)).pipe(
          map(configs =>
            configs as PinImpactLayerConfigEntry[]
          )
        )
      ),
      catchError(this.handleError)
    );
  }

  private async getConfig(url: string) {
    const query = {
      outFields: '*',
      where: '1=1',
      f: 'json'
    };
    const result = await esriRequest(url + '/query', { query });
    return result.data.features.map((x: __esri.Graphic) => x.attributes);
  }

  private async getFields(url: string) {
    const query = {
      f: 'json'
    };
    const result = await esriRequest(url, { query });
    return result.data.fields;
  }

  private handleError(error: any) {
    const errorMessage = error;
    console.error(errorMessage);
    // return an observable with a user-facing error message
    return throwError(errorMessage);
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GuidService {

  createGuid() {
    const template = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';
    return template.split('').map(c => {
      return c === '-' ? '-' : this.rndHexChar();
    }).join('');
  }

  private rndHexChar() {
    const rnd = Math.floor(Math.random() * 16);
    return rnd.toString(16);
  }

}

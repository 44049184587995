import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppInjectorService } from './app/services/app-injector.service';
import { environment } from './environments/environment';
import { handleTokens } from './init/handle-tokens';
import { verifyBrowser } from './init/verify-browser';

main();

function main() {

  if (environment.production) {
    enableProdMode();
  }

  const browserResults = verifyBrowser(); // shows alert if necessary
  if (!browserResults.compatible) {
    return;
  }

  const tokenResult = handleTokens(); // handle tokens passed by url
  if (tokenResult.isRedirecting) {
    return; // if redirecting, don't continue loading app
  }

  const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

  bootstrap()
    .then(moduleRef => AppInjectorService.setInjector(moduleRef.injector))
    .catch(err => console.log(err));

}


// import { addVersionIndicator } from './init/version-indicator';
// import { addServicesIndicator } from './init/services-indicator';
// import { handleTokens } from './init/handle-tokens';
// import { addUserIndicator } from './init/user-indicator';
// import { addEnvironmentIndicator } from './init/environment-indicator';
// import { verifyBrowser } from './init/verify-browser';

// main();

// async function main() {

//   const loadingMsgEl = document.getElementById('init-loading-msg');

//   addEnvironmentIndicator();

//   if (environment.production) {
//     enableProdMode(); // enable prod mode if environment is prod
//   }

//   if (!environment.isCommission) {
//     addVersionIndicator(); // add version indicator
//     addServicesIndicator(); // add services indicator
//   }

//   loadingMsgEl.innerHTML = 'Verifying browser compatibility...';
//   const browserResults = verifyBrowser(); // shows alert if necessary
//   if (!browserResults.compatible) {
//     return;
//   }

//   loadingMsgEl.innerHTML = 'Processing url tokens...';
//   const tokenResult = handleTokens(); // handle tokens passed by url
//   if (tokenResult.isRedirecting) {
//     return; // if redirecting, don't continue loading app
//   }

//   addUserIndicator(); // add user indicator

//   loadingMsgEl.innerHTML = 'Loading Application Module...'; // takes much less time with prod build
//   await waitOneTick(); // wait one millisecond to give the dom a chance to update


//   platformBrowserDynamic()
//     .bootstrapModule(AppModule) // bootstrap the application
//     .catch(err => console.error(err));
// }

// async function waitOneTick() {
//   return new Promise(resolve => {
//     setTimeout(() => resolve(null), 1);
//   });
// }

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  toFormData(jsObject): FormData {
    const fd = new FormData();
    Object.keys(jsObject).forEach(key => {
      const val = jsObject[key];
      const isObj = typeof val === 'object';
      fd.append(key, isObj ? JSON.stringify(val) : val);
    });
    return fd;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MapService } from '../map/map.service';
import { RxLayer } from '../../rx-layer/rx-layer';
import { RxLayerOptions } from '../../rx-layer/rx-layer-options';
import { EndpointService } from '../dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from '../dynamic-endpoint/endpoint.service.config';

@Injectable({
  providedIn: 'root'
})
export class BridgeRxLayerService {

  constructor(
    private mapService: MapService,
    private endpointService: EndpointService
  ) {
    this.init();
  }

  // layers
  private _layer = new BehaviorSubject<RxLayer>(null);
  layer$ = this._layer.asObservable();

  private init() {
    this.mapService.mapAssets$.subscribe(async (assets) => {
      const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
      const map = assets.map;
      const config: RxLayerOptions = {
        map,
        title: 'Prospective Bridge Projects',
        visible: false,
        hideFields: ['OBJECTID'],
        url: urls.SCOPING_BRIDGES,
        spatialReference: { wkid: 102100 },
        displayColumns: [
          'PROJECT_SCOPE_ID',
          'PROGRAM_FUNDING',
          'DISTRICT',
          'DESCRIPTION',
          'BUDGET_MIN',
          'BRIDGE_ID',
          'PRIORITY_SIIMS',
          'SIIMS_PROPOSED_WORK',
        ]
      };
      if (this._layer.value === null) {
        this._layer.next(new RxLayer(config));
      } else {
        this._layer.next(this._layer.value);
      }
    });
  }

  getLayer(): RxLayer {
    if (!this._layer.value) {
      throw new Error(`layer is not ready, try subscribing to layer$ instead
      -> this._layers.value = ${this._layer.value}`);
    }
    return this._layer.value;
  }

}

import { Injectable } from '@angular/core';

import { ApprovalPolicy } from '../scope-approval/approval-workflow-policy';
import { EMAIL_HEADER } from './common/header';
import { EMAIL_FOOTER } from './common/footer';
import { getApprovalLink } from './common/get-approval-link';
import { getApprovalStagesHtml } from './common/get-approval-stages-html';
import { EMAIL_COMMON_STYLES } from './common/styles';
import { PinAttributes } from '../services/amalgamator/pin-attributes';
import { FinalApprovalEmailFiles } from './email-content-final-files.service';

@Injectable({
  providedIn: 'root'
})
export class EmailContentInitialSafetyService {
  constructor() {
    return
  }

  getInitialSafetyCreateEmailHtml(policies: ApprovalPolicy[], attributesForPins: PinAttributes[], arrayOfProjTypes: any[][], approvalInfo: any, files?: FinalApprovalEmailFiles, counts?): string {
    return `
    <html>
      <head></head>
      <body>
        <table ${EMAIL_COMMON_STYLES.TABLE_ATTRS}>
          <tbody>
            ${EMAIL_HEADER}
            ${this.getIntroTr({ multi: policies.length > 1, attributesForPins, arrayOfProjTypes })}
            ${policies.map((policy, i) => this.getInitScopeSummaryTr(policy, arrayOfProjTypes, attributesForPins, i, approvalInfo, files, counts || [])).join('')}
            ${EMAIL_FOOTER}
          </tbody>
        </table>
      </body>
    </html>`;
  }

  getImpacts(impactCounts) {
    const LRTPCounts = impactCounts.filter(item => item.title.includes('LRTP'));
    return `
    <tr>
      <td>
        <br>Consistent with Long Range Plan Categories:<br>
        ${LRTPCounts.map(item => `<strong>${item.title}</strong>: Score: ${Math.floor(item.count)}<br>`).join('')}
      </td>
    </tr>`;
  }

  getIntroTr(params: { multi: boolean, attributesForPins: PinAttributes[], arrayOfProjTypes: any[][] }) {
    return `
      <tr>
        <td>
          You are receiving this email because you have elected to be notified when a
          project scope is developed and submitted to PSS that identifies Safety as a
          stated project purpose. ${params.multi ? 'The projects identified below have been entered and are' : 'The project identified below has been entered and is'}
          being vetted in the project scoping and prioritization system. Should you
          have questions about this project, feel free to contact those championing and
          vetting the project listed below:
        </td>
      </tr>`;
  }

  getProjectDetails(projTypes, pinInfo, counts) {
    const { PIN } = pinInfo;
    return `
      <tr>
        <td>
          <br>Project Link: https://iowadotprojectpriorities.com/#pin=${PIN}
          <br><br>Project Purpose – ${projTypes.map(type => `${type.PROJECTTYPE.alias}: ${type.PURPOSEDESCRIPTION}`).join(', ')}
          <br>${counts.length ? this.getImpacts(counts) : ''}
        </td>
      </tr>`;
  }

  getInitScopeSummaryTr(policy: ApprovalPolicy, arrayOfProjTypes, attributesForPins, i, approvalInfo, files, counts): string {
    const pin = attributesForPins[i];
    const projTypes = arrayOfProjTypes[i];
    const impactCounts = counts[i] ? counts[i] : [];
    const { USERNAME } = pin;
    const bridgeFhwaNumber = `Bridge FHWA Number:  <strong>${approvalInfo[0].misc.bridgeFhwaNumber}</strong>`;
    const bridgeNumber = `Bridge Maintenance Number:  <strong>${approvalInfo[0].misc.bridgeMaintenanceNumber}</strong>`;

    return `
      <tr>
        <td>
          <br>
          <br><strong>${policy.scope_id}</strong>
          <br>Funding Sources: <strong>${policy.fundingType}</strong>
          <br>
          <br>
          ${bridgeFhwaNumber}<br>
          ${bridgeNumber}<br>
          <br>Personnel who Entered the project: ${USERNAME}<br>
          ${files ? `<a href="${files.auditsCsvUrl}">Audits csv</a> A listing of who has reviewed and approved the project(s)<br>` : ''}
          <br><strong>Approval Stages</strong>
          <br>${getApprovalStagesHtml(policy)}
          <br><strong><a href="${getApprovalLink(policy.scope_id)}">View Approval Process</a></strong>
          <br>${this.getProjectDetails(projTypes, pin, impactCounts)}
        </td>
      </tr>`;
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppLayoutType } from 'src/app/types/app-layout-type';

@Injectable({
  providedIn: 'root'
})
export class AppLayoutService {

  private _layoutMode = new BehaviorSubject<AppLayoutType>('table');
  layoutMode$ = this._layoutMode.asObservable();

  constructor() {
    return;
  }

  setLayoutMode(mode: AppLayoutType) {
    this._layoutMode.next(mode);
  }

  getLayoutMode(): AppLayoutType {
    return this._layoutMode.getValue();
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';
import { esriRequest } from 'src/esri/request';
import { EndpointService } from './dynamic-endpoint/endpoint.service';
import { RouteAndMeasure } from './layer/route-and-measure.service';

/* ArcGIS */
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { RefPostEntryMode } from '../dialogs/ref-post-entry-dialog/ref-post-entry-dialog.component';

export interface RefPost {
  repostMeasure: number;
  repostName: string;
  repostValue: number;
  repostOffset: number;
  repostMile: number;
  repostMileRound: number;
}

export interface RouteRefPost {
  OBJECTID: number;
  BUSINESS_DATE: number;
  EFFECTIVE_END_DATE: number;
  EFFECTIVE_START_DATE: number;
  EVENT_ID: string;
  MEASURE: number;
  MEASURED_LAT: number;
  MEASURED_LON: number;
  OPPOSITE_SIDE: string;
  REFERENCE_POST_NAME: string;
  REFERENCE_POST_VALUE: string;
  ROUTE_ID: string;
  VIRTUAL: string;
  GEOMETRY: __esri.Geometry;
  MEASURE_PLUS_OFFSET?: number;
}

export interface GetRefPostJSON {
  json_featuretype: string;
  ROUTE_ID: string;
  REFPOST_MEASURE: string;
  REFPOST_OFFSET: string;
  REFPOST_NAME: string;
  REFPOST_VALUE: string;
  REFPOST_LAT: string;
  REFPOST_LONG: string;
  REFPOST_OPPOSITE_SIDE: string;
  REFPOST_EFFECTIVE_START_DATE: string;
  REFPOST_VIRTUAL: string;
  REFPOST_VALUEOFFSET: string;
}

@Injectable({
  providedIn: 'root'
})
export class RefPostService {

  cache = {};

  private _processingRefPostOffset = new BehaviorSubject<boolean>(false);
  processingRefPostOffset$ = this._processingRefPostOffset.asObservable();

  constructor(
    private endpointService: EndpointService
  ) {
  }

  async getReferencePost(route: string, measure: number, options?: { useCache: boolean }): Promise<RefPost> {
    if ((!options) || !options.useCache) {
      return this._getReferencePost(route, measure);
    }
    const key = `${route}-${measure}`;
    if (!this.cache[key]) {
      this.cache[key] = this._getReferencePost(route, measure);
    }
    return this.cache[key];
  }

  getReferencePostsForRoute(slice: RouteAndMeasure, searchMiles: number, mode: RefPostEntryMode) {
    return from(this._getReferencePostsAlongSegment(slice, searchMiles, mode)).pipe(
      map(data => {
        if (data) {
          return data.features.sort(this.refPostCompare).map(g => {
            const rp = g.attributes as RouteRefPost;
            rp.GEOMETRY = g.geometry;
            return rp;
          });
        } else {
          return [];
        }
      }),
      shareReplay(1)
    );
  }

  calculateProjectMiles(from: RefPost, to: RefPost): string {
    if (from !== null && to !== null && from !== undefined && to !== undefined) {
      if (from.repostMile > 0 && to.repostMile > 0) {
        const lengthCalc = Math.abs(Number((to.repostMile - from.repostMile).toFixed(2)));
        return `${lengthCalc} (Miles)`;
      }
      return 'unable to calculate';
    }
    return 'unable to calculate';
  }

  async getLayerUrls() {
    return await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.RAMS_PUBLIC_LRS_FEATURE_SERVER);
  }

  private refPostCompare = (a: __esri.Graphic, b: __esri.Graphic) => {
    const aNum = Number(a.attributes.REFERENCE_POST_VALUE);
    const bNum = Number(b.attributes.REFERENCE_POST_VALUE);
    if (aNum < bNum) {
      return -1;
    }
    if (aNum > bNum) {
      return 1;
    }
    return 0;
  };

  private async _getReferencePostsAlongSegment(slice: RouteAndMeasure, searchMiles: number, mode: RefPostEntryMode): Promise<__esri.supportFeatureSet | void> {
    const measureStart = mode === 'from' ? slice.FROM_MEASURE - searchMiles : slice.TO_MEASURE - searchMiles;
    const measureEnd = mode === 'from' ? slice.FROM_MEASURE + searchMiles : slice.TO_MEASURE + searchMiles;
    const urls = await this.getLayerUrls();
    const featureLayer = new FeatureLayer({
      url: urls.REFERENCE_POSTS
    });
    const query = featureLayer.createQuery();
    query.outFields = ['*'];
    query.where = `EFFECTIVE_END_DATE IS NULL AND ROUTE_ID = '${slice.ROUTE_ID}' AND MEASURE BETWEEN ${measureStart} AND ${measureEnd}`;
    return await featureLayer.queryFeatures(query);
  }


  private async _getReferencePost(route: string, measure: number) {
    const baseUrl = ENDPOINT_CONFIG.SKY_FIRE_GET_REF_POS.url;
    const AppID = 'ProjectPrioritiesAndScoping';
    const url = baseUrl + `?RouteID=${route}&Measure=${measure}&Tolerance=2&Return=1&AppID=${AppID}`;
    const res = await esriRequest(url, {
      responseType: 'json'
    });
    return (res && res.data && res.data[0].ROUTE_ID) ? this.extractSingleRefPost(res.data) : null;
  }

  private extractSingleRefPost(res: any): RefPost {
    const data = res[0] as GetRefPostJSON;
    // Mike / Brad agreed to calc of Repost value plus offset
    // handle edge cases of REPOST_VALUE including alpha chars (ex 212A)
    const repostNumber = data.REFPOST_VALUE.replace(/\D/g, '');
    // calc mileage using offset
    const mileage = (+repostNumber + (+data.REFPOST_OFFSET));
    const rounded = Number(mileage.toFixed(2));
    return <RefPost>{
      repostMeasure: +data.REFPOST_MEASURE,
      repostName: data.REFPOST_NAME,
      repostValue: +data.REFPOST_VALUE,
      repostOffset: +data.REFPOST_OFFSET,
      repostMile: mileage,
      repostMileRound: rounded
    };
  }
}

import { Injectable } from '@angular/core';

import { ApprovedPinInfo } from './approved-pin-info';
import { S3Service } from '../services/s3.service';

export interface FinalApprovalEmailFiles {
  attributesAndPriorityCsvUrl: string;
  auditsCsvUrl: string;
  pssEventsTxtUrl: string;
  impactCountsTxtUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmailContentFinalFilesService {
  constructor(
    private s3Service: S3Service
  ) {
  }

  // uses Lambda to create files in S3 and return links to those files
  async getFinalApprovalEmailFiles(approvedPinsInfo: ApprovedPinInfo[], isAbbreviatedProject: boolean): Promise<FinalApprovalEmailFiles> {
    try {
      const attrsCsvContent = isAbbreviatedProject ? this.getAttrsCsvContentForAbbreviatedProject(approvedPinsInfo) : this.getAttrsCsvContent(approvedPinsInfo);
      const auditsCsvContent = this.getAuditsCsvContent(approvedPinsInfo);
      const pssEventsTxtContent = this.getPssEventsTxtContent(approvedPinsInfo);
      const impactsTxtContent = this.getImpactsTxtContent(approvedPinsInfo);

      const now = +new Date();

      const [
        attributesAndPriorityCsvUrl,
        auditsCsvUrl,
        pssEventsTxtUrl,
        impactCountsTxtUrl
      ] = await Promise.all([
        this.s3Service.saveEmailFile(`attributes-and-priority-${now}.csv`, attrsCsvContent),
        this.s3Service.saveEmailFile(`audits-${now}.csv`, auditsCsvContent),
        this.s3Service.saveEmailFile(`schedule-${now}.txt`, pssEventsTxtContent),
        this.s3Service.saveEmailFile(`impacts-${now}.txt`, impactsTxtContent),
      ]);

      return {
        attributesAndPriorityCsvUrl,
        auditsCsvUrl,
        pssEventsTxtUrl,
        impactCountsTxtUrl
      };
    } catch (err) {
      const src = 'file: email-content-final-files.service.ts ~ line 26 ~ EmailContentFinalFilesService ~ getFinalApprovalEmailFiles';
      console.error(src, err);
      throw new Error(err);
    }
  }

  getAttrsCsvContent(approvedPinsInfo: ApprovedPinInfo[]) {
    const header = [
      'ScopeID',
      'Program Funding Type',
      'Project Purpose',
      'District',
      'County',
      'Primary Route',
      'Location Description',
      'Beginning Milepost (Reference Post)',
      'Ending Milepost (Reference Post)',
      'Level of Study Required',
      'Anticipated Range of ROW Required',
      'Outside Services Expected',
      'Project Development Completed by',
      'Budget Range Min (1000s)',
      'Budget Range Median (1000s)',
      'Budget Range Max (1000s)',
      'Desired Letting Month',
      'Desired Letting Fiscal Year',
      'Level of Documentation Expected',
      'Work Groups Included',
      'Work Types Included',
      'Overall Priority Score',
      'Overall Priority Count',
      'Bridge Score',
      'Bridge Count',
      'Pavement Score',
      'Pavement Count',
      'Traffic Score',
      'Traffic Count',
      'Safety Score',
      'Safety Count',
      'Freight Score',
      'Freight Count',
      'Road Class Score',
      'Road Class Count',
    ].map(x => `"${x}"`).join(',');

    const rows = approvedPinsInfo.map(pin => {
      const m = pin.misc;
      const a = pin.pinAttributes;
      const pc = pin.priorityCounts;
      return [
        a.PIN,
        m.programFunding,
        m.projectPurpose,
        a.DISTRICT,
        m.counties,
        m.readableRouteName,
        a.DESCRIPTION,
        m.refPostFromValue,
        m.refPostToValue,
        m.levelOfStudyRequired,
        m.rowNeed,
        m.outsideServices,
        m.pdCompletedBy,
        a.BUDGETRANGEMIN,
        m.budgetMedianCalc,
        a.BUDGETRANGEMAX,
        m.desiredFiscalMonth,
        a.DESIREDFISCALYEAR,
        m.levelOfDocumentation,
        m.workGroups,
        m.workTypes,
        a.PERFORMANCE_SCORE,
        a.BRIDGE_SCORE,
        pc.BRIDGE_COUNT,
        a.PAVEMENT_SCORE,
        pc.PAVEMENT_COUNT,
        a.TRAFFIC_SCORE,
        pc.TRAFFIC_COUNT,
        a.SAFETY_SCORE,
        pc.SAFETY_COUNT,
        a.FREIGHT_SCORE,
        pc.FREIGHT_COUNT,
        a.IMPORTANCE_SCORE,
        pc.IMPORTANCE_COUNT,
      ].map(x => `"${this.csvCleanseCell(x)}"`).join(',');
    });

    return header + '\n' + rows.join('\n');
  }

  getAttrsCsvContentForAbbreviatedProject(approvedPinsInfo: ApprovedPinInfo[]) {
    const header = [
      'County',
      'Primary Route',
      'Location Description',
      'Work Types Included',
      'Beginning Milepost (Reference Post)',
      'Ending Milepost (Reference Post)',
      // 'Approx. Project Length (mi)',
      'Project Number',
      'Desired Letting Fiscal Year',
      'Desired Letting Month',
      'Budget Range Min (1000s)',
      'Budget Range Median (1000s)',
      'Budget Range Max (1000s)',
      'Project Link',
    ].map(x => `"${x}"`).join(',');

    const rows = approvedPinsInfo.map(pin => {
      const m = pin.misc;
      const a = pin.pinAttributes;
      const pc = pin.priorityCounts;
      return [
        m.counties,
        m.readableRouteName,
        a.DESCRIPTION,
        m.workTypes,
        m.refPostFromValue,
        m.refPostToValue,
        m.refPostLength,
        // m.refPostToValue - m.refPostFromValue,
        '', // placeholder for blank PIN, to be filled in
        a.DESIREDFISCALYEAR,
        m.desiredFiscalMonth,
        a.BUDGETRANGEMIN,
        m.budgetMedianCalc,
        a.BUDGETRANGEMAX,
        `=HYPERLINK(""https://scopinguat.iowadotprojectpriorities.com/#pin=${a.PIN}"")`
      ].map(x => `"${this.csvCleanseCell(x)}"`).join(',');
    });

    return header + '\n' + rows.join('\n');
  }

  getAuditsCsvContent(approvedPinsInfo: ApprovedPinInfo[]) {
    return approvedPinsInfo.map(info => {
      return [
        `"ScopeID:","${this.csvCleanseCell(info.pinAttributes.PIN)}"`,
        `"Created by:","${this.csvCleanseCell(info.pinAttributes.USERNAME)}"`,
        '"User","Roles","Timestamp"',
        info.audits.map(audit => {
          const t = new Date(audit.TIMESTAMP);
          return [
            audit.USER_NAME,
            audit.USER_GROUPS,
            `${t.toLocaleDateString()} ${t.toLocaleTimeString()}`,
          ].map(x => `"${this.csvCleanseCell(x)}"`).join(',');
        }).join('\n')
      ].join('\n');
    }).join('\n\n');
  }

  getPssEventsTxtContent(approvedPinsInfo: ApprovedPinInfo[]) {
    return approvedPinsInfo.map(info => {
      const eventList = info.pssEvents.map(evt => `• ${evt}`).join('\n');
      return `${info.pinAttributes.PIN} Needed Schedule Events\n${eventList}`;
    }).join('\n\n');
  }

  getImpactsTxtContent(approvedPinsInfo: ApprovedPinInfo[]) {
    return approvedPinsInfo.map(info => {
      const formatImpactKey = (x: string) => {
        return x.indexOf('PIN ') === 0 ? x.replace('PIN ', '') : x;
      };

      const impactEntries = info.impactCounts
        .filter(x => x.count > 0)
        .map(x => `${formatImpactKey(x.title)}: ${x.count}`);

      return `${info.pinAttributes.PIN}\n${impactEntries.join('\n')}`;
    }).join('\n\n');
  }

  csvCleanseCell(cellValue: any) {
    return ('' + cellValue)
      .replace(/,/g, '')
      .replace(/\n/g, ' ');
    // .replace(/#/g, '');
  }

}

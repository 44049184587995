import { Injectable } from '@angular/core';

import { Filter, FilterType } from '../rx-layer/filter';
import { EsriFieldType } from '../types/esri-types';
import { FILTER_TYPE_DEFAULTS } from './filter-factory.service.config';
import { GuidService } from './guid.service';

@Injectable({
  providedIn: 'root'
})
export class FilterFactoryService {

  constructor(
    private guidService: GuidService
  ) {
  }

  createFilter(field: __esri.Field): Filter {
    const type = this.getFilterType(field);
    return {
      guid: this.guidService.createGuid(),
      type: type,
      field: field,
      value: this.getDefaultValue(type),
      active: type === FilterType.ContainsString,
    };
  }

  isFilterChip(filter: Filter): boolean {
    const nonChipTypes = [
      FilterType.StageSelect,
      FilterType.MultiFieldSearch,
      FilterType.QuickFilter,
      FilterType.Slider,
      FilterType.Spatial
    ];
    const isChip = nonChipTypes.indexOf(filter.type) > -1 ? false : true;
    return isChip;
  }

  isStageSelect(filter: Filter): boolean {
    const isStage = filter.type === FilterType.StageSelect;
    return isStage;
  }

  isQuickFilter(filter: Filter): boolean {
    const isQuick = filter.type === FilterType.QuickFilter;
    return isQuick;
  }

  isMultiFieldSearch(filter: Filter): boolean {
    const isMutiSearch = filter.type === FilterType.MultiFieldSearch;
    return isMutiSearch;
  }

  private getFilterType(field: __esri.Field) {

    if (Object.prototype.hasOwnProperty.call(FILTER_TYPE_DEFAULTS, field.name)) {
      return FILTER_TYPE_DEFAULTS[field.name];
    }

    if (field.domain || field['isCommaDelimited'] || field['patchedDomain']) {
      return FilterType.MultiSelect;
    }

    const fieldContains = (strs: string[]) => {
      const fieldUpper = field.name.toUpperCase();
      return strs.filter(str => {
        return fieldUpper.indexOf(str) > -1;
      }).length > 0;
    };
    const fieldIsType = (types: string[]) => {
      return types.indexOf(field.type) > -1;
    };

    if (fieldContains(['DISTRICT', 'DSTRCT', 'ROUTE'])) {
      return FilterType.MultiSelect;
    }

    const numberTypes = [
      EsriFieldType.smallInteger,
      EsriFieldType.long,
      EsriFieldType.integer,
      EsriFieldType.oid,
      EsriFieldType.single,
      EsriFieldType.double
    ];

    const costFieldSnippets = [
      'COST',
      'SCORE',
      'YEAR',
      'INDEX',
      'AREA',
      'AGE',
      'RATING',
      'SUFFICIENCY',
      'EST_SUM'
    ];

    if (fieldContains(costFieldSnippets) && fieldIsType(numberTypes)) {
      return FilterType.MinMax;
    }

    if (<any>field.type === 'esriFieldTypeDate') {
      return FilterType.DateMinMax;
    }

    if (fieldIsType(numberTypes)) {
      return FilterType.MinMax;
    }

    if (<any>field.type === 'esriFieldTypeString') {
      return FilterType.ContainsString;
    }

    return FilterType.MultiSelect;
  }

  private getDefaultValue(filterType: FilterType) {
    const now = +(new Date());
    switch (filterType) {
    case FilterType.MinMax:
      return [0, 0];
    case FilterType.ContainsString:
    case FilterType.MultiFieldSearch:
      return '';
    case FilterType.MultiSelect:
      return [];
    case FilterType.DateMinMax:
      return [now, now];
    }
  }
}

import { Injectable } from '@angular/core';
import { FormSubject, SelectOption } from '../types/form-subject';
import { PinsRxLayerService } from './layer/pins-rx-layer.service';
import { EndpointService } from './dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';
import { esriRequest } from 'src/esri/request';

@Injectable({
  providedIn: 'root'
})
export class WorkMatrixService {

  constructor(
    private pinsRxLayerService: PinsRxLayerService,
    private endpointService: EndpointService
  ) {
  }

  private matrixPromise = (async () => {
    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
    const query = {
      f: 'json',
      where: '1=1',
      outFields: 'WORKGROUP,WORKCODE',
      returnGeometry: false
    };
    const result = await esriRequest(urls.WORK_GROUP_MATRIX + '/query', { query });
    const entries: any[] = result.data.features.map(x => x.attributes);
    return entries;
  })();

  async linkWorkFormSubjects(workTypeFormSubject: FormSubject<number[]>, workGroupFormSubject: FormSubject<number[]>) {
    const pinsRxLayer = await this.pinsRxLayerService.getLayer();

    const pinsFields = await pinsRxLayer.fieldsPromise;
    const wgField: __esri.Field = pinsFields.find(f => f.name === 'WORK_GROUPS');
    const wtField: __esri.Field = pinsFields.find(f => f.name === 'WORK_TYPES');

    const wgDomain = wgField['patchedDomain'];
    const wgCodedValues = wgDomain['codedValues'];
    const wgOpts: SelectOption[] = wgCodedValues.map(x => ({
      alias: x.name,
      value: x.code
    }));
    workGroupFormSubject.selectOptions.next(wgOpts);

    // -------------------------

    const entries = await this.matrixPromise;
    const lookup = {};
    entries.forEach(x => {
      if (!lookup['' + x.WORKGROUP]) {
        lookup['' + x.WORKGROUP] = [];
      }
      lookup['' + x.WORKGROUP].push(x.WORKCODE);
    });

    const workCodeAliasLookup = {};
    wtField['patchedDomain']['codedValues'].forEach(cv => {
      workCodeAliasLookup['' + cv.code] = cv.name;
    });

    workGroupFormSubject.valueStream.subscribe((selectedWorkGroups: number[]) => {
      if (selectedWorkGroups === null || !selectedWorkGroups.length) {
        workTypeFormSubject.selectOptions.next([]);
      } else {
        const workTypes = [];
        selectedWorkGroups.forEach(workGroup => {
          if (lookup[workGroup]) {
            lookup[workGroup].forEach(workCode => {
              workTypes.push(workCode);
            });
          } else {
            console.error(`WorkGroup Error: '${workGroup}' not found in matrix lookup.`);
          }
        });
        workTypeFormSubject.selectOptions.next(
          workTypes.map(wt => ({
            alias: workCodeAliasLookup['' + wt],
            value: wt
          }))
        );
      }
    });
  }
}

import { GenericType } from './generic-type';

export class ProjectType extends GenericType {
  // static access
  static readonly pin = 'pin-type';
  static readonly scope = 'scope-type';
  static readonly planning = 'planning-type';
  static readonly scopeToPin = 'scope-to-pin-type';

  // access via class instance
  readonly typeNames: { [key: string]: string } = {};

  constructor() {
    super();
    this.typeNames[ProjectType.pin] = 'PIN';
    this.typeNames[ProjectType.scope] = 'Scope';
    this.typeNames[ProjectType.planning] = 'Planning Scenario';
    this.typeNames[ProjectType.scopeToPin] = 'Scope to PIN';
  }

  getTypeName(type: string) {
    return this.getKeyValue(this.typeNames)(type);
  }

}

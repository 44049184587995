import { Component } from '@angular/core';
import { CHANGELOG } from 'src/changelog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-version-indicator',
  templateUrl: './version-indicator.component.html',
  styleUrls: ['./version-indicator.component.scss']
})
export class VersionIndicatorComponent {

  version = CHANGELOG[0].version;
  isCommission = environment.isCommission;

  constructor() {
    return;
  }

}

export enum ProgramType {
  _NR = 'NR',
  _MI = 'MI',
  _4R = '4R',
  _SR = 'SR',
  _BR = 'BR',
  _3R = '3R',
  _OT = 'OT',
  _MB = 'MB',
  _MP = 'MP',
  _EC = 'E/C',
  _ADA = 'ADA'
}

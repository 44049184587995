import { Injectable } from '@angular/core';

// approval info
import { ApprovalAuditService } from './approval-audit-service';
import { ApprovalPolicyFactory } from './approval-policy-factory';
import { ApprovalReportService } from './approval-report-service';
import { AnyAll, ApprovalPolicy, ApprovalReport } from './approval-workflow-policy';
import { Audit } from './audit';
import { PROGRAM_TYPE_LOOKUP } from 'src/app/scope-approval/program-type-lookup';

// user info
import {
  APP_EMAIL_GROUPS,
  APP_ROLES,
  IOWA_ADMIN_STAFF,
  LRTP_NOTICE_STAFF,
  UserInfoService
} from '../services/user-info.service';

// misc services
import { PinAttributes } from '../services/amalgamator/pin-attributes';
import { createLookups } from '../services/amalgamator/create-lookups';
import { DialogNoticeService } from '../services/dialog-notice.service';
import { ProjectInfoService } from '../services/project-info.service';

// Data Services
import { CountyService } from '../services/county.service';
import { RouteNameService } from '../services/route-name.service';
import { RefPostService } from '../services/ref-post.service';
import { PriorityRxLayersService } from '../services/layer/priority-rx-layers.service';
import { ImpactRxLayersService } from '../services/layer/impact-rx-layers.service';
import { BridgeInfoService } from '../services/bridge-info.service';
import { PinsRxLayerService } from '../services/layer/pins-rx-layer.service';

// Email Content
import { EmailService } from '../services/email.service';
import { getApprovedPinInfo } from '../email/get-approved-pin-info';
import { EmailContentFinalService } from '../email/email-content-final.service';
import { EmailContentInitialService } from '../email/email-content-initial.service';
import { EmailContentPendingService } from '../email/email-content-pending.service';
import { EmailContentInitialSafetyService } from '../email/email-content-initial-safety.service';
import { EmailContentFinalSafetyService } from '../email/email-content-final-safety.service';
import { EmailContentFinalFilesService } from '../email/email-content-final-files.service';

import { environment } from 'src/environments/environment';
import { DetailsPaneFormSubjects } from '../details-pane/details-pane-form-subjects';
import { PriorityImpactService } from '../services/priority-impact-service';
import { EmailContentLrtpService } from '../email/email-content-lrtp.service';
import { OnDemandFMEService } from '../services/on-demand-fme.service';
import { filter, take, tap } from 'rxjs/operators';
import { RouteAndMeasureService } from '../services/layer/route-and-measure.service';
import { ProgramType } from '../types/program-type';

// USED FOR TRACKING WORKFLOW STAGES
const WORKFLOW_PENDING = 'pending';
const WORKFLOW_FINAL_APPROVAL = 'final';
const WORKFLOW_APPROVED = 'approved';
const WORKFLOW_ERROR = 'error';

// FOR TESTING EMAIL NOTICES
const EMAIL_TEST_TO = ['bscholer@hntb.com', 'dwelch@hntb.com', 'rhosack@hntb.com'];
const EMAIL_TEST = ['bscholer@hntb.com'];

@Injectable({
  providedIn: 'root'
})
export class SubmitApproveEmailService {

  constructor(
    private countyService: CountyService,
    private routeNameService: RouteNameService,
    private pinsRxLayerService: PinsRxLayerService,
    private refPostService: RefPostService,
    private routeAndMeasureService: RouteAndMeasureService,
    private priorityRxLayersService: PriorityRxLayersService,
    private impactRxLayersService: ImpactRxLayersService,
    private priorityImpactService: PriorityImpactService,
    private bridgeInfoService: BridgeInfoService,
    private fs: DetailsPaneFormSubjects,
    private emailService: EmailService,
    private userInfoService: UserInfoService,
    private policyFactory: ApprovalPolicyFactory,
    private auditService: ApprovalAuditService,
    private reportService: ApprovalReportService,
    private dialogNoticeService: DialogNoticeService,
    private projectInfoService: ProjectInfoService,
    private emailContentFinalService: EmailContentFinalService,
    private emailContentInitialService: EmailContentInitialService,
    private emailContentPendingService: EmailContentPendingService,
    private emailContentInitialSafetyService: EmailContentInitialSafetyService,
    private emailContentFinalSafetyService: EmailContentFinalSafetyService,
    private emailContentLrtpService: EmailContentLrtpService,
    private emailContentFinalFilesService: EmailContentFinalFilesService,
    private onDemandFMEService: OnDemandFMEService,
  ) {
  }

  async sendEmailNotices(attributesForPins: PinAttributes[], usersToEmailOverride?: string[]) {
    try {
      const pending = [];
      const final = [];

      const res = await Promise.all(
        attributesForPins.map(async attrs => {
          const policy = this.policyFactory.generatePolicy(attrs.PIN, attrs.DISTRICT, attrs.PROGRAM_TYPES);
          const audits = await this.auditService.getAudits(attrs.PIN);
          const report = await this.reportService.getReport(attrs, audits, policy);
          const workflowStage = this.getWorkflowStage(report);
          return {
            attrs,
            policy,
            audits,
            report,
            workflowStage
          };
        })
      );

      // TODO - Filter 'Admin' group from the report.  That way it doesn't show in the emails

      res.forEach(x => {
        switch (x.workflowStage) {
        case WORKFLOW_PENDING:
          pending.push(x);
          break;
        case WORKFLOW_FINAL_APPROVAL:
          final.push(x);
          break;
        case WORKFLOW_APPROVED:
          // do nothing - approved
          break;
        case WORKFLOW_ERROR:
          this.dialogNoticeService.alert({ message: 'unhandled email notice' });
          break;
        default:
          this.dialogNoticeService.alert({ message: 'unhandled email notice' });
          break;
        }
      });

      const emailPromises = [];

      if (pending.length && final.length) {
        this.dialogNoticeService.alert({ message: `Because some of the selected scopes have now reached final approval and others have not, notifications have been split into multiple emails accordingly.` });
      }

      if (pending.length) {
        emailPromises.push(
          this.sendPendingEmail(
            pending.map(x => x.attrs),
            pending.map(x => x.report),
            pending.map(x => x.attrs.USERNAME)
          )
        );
      }

      if (final.length) {
        emailPromises.push(
          this.sendFinalEmail(
            final.map(x => x.attrs),
            final.map(x => x.audits),
            final.map(x => x.policy),
            usersToEmailOverride)
        );
      }
      await emailPromises;

    } catch (err) {
      await this.handleError(err, 'SubmitApproveEmailService.sendEmailNotices()');
    }

  }

  // send initial email to the user that created the scope
  async sendInitialEmail(attributesForPins: PinAttributes[], toAddress: string, isSafety?: boolean) {
    try {
      // don't send for Planning Scopes
      const isPlanning = this.projectInfoService.isPlanning(attributesForPins[0].PIN);
      if (isPlanning) {
        return;
      }

      // get users from various email groups
      const userEmailGroups = await this.getUsersForAllEmailGroups();
      const progTypes = attributesForPins.map(x => x.PROGRAM_TYPES);
      const readableProgramTypes = this.generateReadableProgramTypes(progTypes);
      const readableProjTypes = this.generateReadableProjectTypes(attributesForPins);

      // check 4R funding, then include users from group
      let _4rEmailUsers = null;
      try {
        if (attributesForPins.length === 1) {
          _4rEmailUsers = readableProgramTypes[0].includes(ProgramType._4R) ? userEmailGroups[APP_EMAIL_GROUPS._4R_GROUP] : [];
        } else {
          _4rEmailUsers = [];
        }
      } catch {
        _4rEmailUsers = [];
        console.error(`Unable to get users for 4R email group: ${APP_EMAIL_GROUPS._4R_GROUP}`);
      }

      const policies = attributesForPins.map(attrs => {
        return this.policyFactory.generatePolicy(attrs.PIN, attrs.DISTRICT, attrs.PROGRAM_TYPES);
      });

      // get info
      const approvalInfo = await this.getApprovalInfo(attributesForPins, policies);

      const emailSubject = attributesForPins.length === 1
        ? `Project Scoping - ${attributesForPins[0].PIN}:  New Scope Created`
        : `Project Scoping - New Scopes Created`;
      const emailBody = this.emailContentInitialService.getInitialCreateEmailHtml(policies, approvalInfo);
      const emailTo = environment.isLocalHost ? EMAIL_TEST : [toAddress];
      // CC 4R Email Group
      const emailCc = environment.isLocalHost ? EMAIL_TEST : _4rEmailUsers;
      if (environment.isLocalHost) {
        console.log(`Current "Initial" Email To: ${JSON.stringify(emailTo)}`);
        console.log(`Production "Initial" Email To: ${JSON.stringify(toAddress)}`);
      }
      await this.emailService.sendEmail(emailTo, emailBody, emailSubject, emailCc);

      const emailAddressArray = await this.generateSafetyEmailAddresses(attributesForPins);
      if (isSafety && emailAddressArray.length > 0) {
        const safetyBody = this.emailContentInitialSafetyService.getInitialSafetyCreateEmailHtml(policies, attributesForPins, readableProjTypes, approvalInfo);
        const safetyEmailTo = environment.isLocalHost ? EMAIL_TEST : emailAddressArray;
        await this.emailService.sendEmail(safetyEmailTo, safetyBody, emailSubject);
      }

      // check for LRTP IMPACT PROJECT / send notice
      await this.sendInitialLRTPNotice(policies, attributesForPins, approvalInfo);

    } catch (err) {
      await this.handleError(err, 'SubmitApproveEmailService.sendInitialEmail()');
    }
  }

  async generateFinalEmailTo(audits: Audit[]): Promise<string[]> {
    try {
      const group = APP_EMAIL_GROUPS.PSS_SUBMITTAL_EMAILS;
      const approvalUserEmails = await this.userInfoService.getUsersForEmailGroups([group]);
      const pssSubmitalUsers = await this.userInfoService.getUsersForRoles([APP_ROLES.PSS_SUBMITTAL]);
      const auditEmails = audits.map(f => f.USER_NAME);
      const all: string[] = [
        ...auditEmails,
        ...approvalUserEmails[group],
        ...pssSubmitalUsers[APP_ROLES.PSS_SUBMITTAL]
      ];
      const distinct = {};
      all.forEach(x => {
        distinct[x] = 1;
      });
      // removes HNTB Staff
      return this.userInfoService.removeHntbStaffEmail(Object.keys(distinct));
    } catch (err) {
      const src = 'file: submit-approve-email.service.ts ~ line 239 ~ SubmitApproveEmailService ~ generateFinalEmailTo';
      await this.handleError(err, src);
    }
  }

  // send email to the users within group required to approve scope stage
  private async sendPendingEmail(attributesForPins: PinAttributes[], reports: ApprovalReport[], createdUsers: string[]) {
    try {
      const PINs = attributesForPins.map(x => x.PIN);
      const programTypes = attributesForPins.map(x => x.PROGRAM_TYPES);
      const groupsObj = {};
      reports.forEach(report => {
        report.stages.forEach(stage => {
          if (stage.currentPending) {
            stage.groups.forEach(group => {
              if (!group.approved) {
                groupsObj[group.name] = 1;
              }
            });
          }
        });
      });

      // check for 4R projects, need to notify the district Asst. District Engineer (ADE) aka District Group Members
      // removes HNTB staff users
      let usersToCc = await this.get4rDistrictUsersToCc(attributesForPins);

      const groups = Object.keys(groupsObj);
      const usersForRoles = await this.userInfoService.getUsersForRoles(groups);
      const distinctUsersObj = {};
      Object.values(usersForRoles).forEach(usersForGroup => {
        usersForGroup.forEach(user => {
          distinctUsersObj[user] = 1;
        });
      });
      // add any users who created the PINs
      const allUsers: string[] = Object.keys(distinctUsersObj);
      createdUsers.forEach(c => {
        if (allUsers.indexOf(c) === -1) {
          allUsers.push(c);
        }
      });
      // remove HNTB Staff from email
      const usersToEmail = this.userInfoService.removeHntbStaffEmail(allUsers);
      usersToCc = usersToCc.filter(u => !usersToEmail.includes(u));
      const emailSubject = PINs.length === 1
        ? `Project Scoping - ${PINs[0]}:  Scope Requires Approval`
        : `Project Scoping - Scopes Require Approval`;
      const emailBody = this.emailContentPendingService.getPendingApprovalEmailHtml({ reports, usersForRoles });
      const emailTo = environment.isLocalHost ? EMAIL_TEST : usersToEmail;
      const emailCc = environment.isLocalHost ? EMAIL_TEST : usersToCc;
      console.log(`Current "Pending" Email To: ${JSON.stringify(emailTo)}`);
      console.log(`Production "Pending" Email To: ${JSON.stringify(usersToEmail)}`);
      console.log(`Current "Pending" Email Cc: ${JSON.stringify(emailCc)}`);
      console.log(`Production "Pending" Email Cc: ${JSON.stringify(usersToCc)}`);
      await this.emailService.sendEmail(emailTo, emailBody, emailSubject, emailCc);
    } catch (err) {
      await this.handleError(err, 'SubmitApproveEmailService.sendPendingEmail()');
    }
  }

  private async sendInitialLRTPNotice(policies: ApprovalPolicy[], attributesForPins: PinAttributes[], approvalInfo: any) {
    try {
      // make sure NEPA impact job is complete before we check for reports - this job gets triggered when a new scope is created
      this.onDemandFMEService.nepaImpactJobStatus$.pipe(
        filter(s => s.toLocaleLowerCase() === 'success'),
        tap(s => console.log('NEPA Impact Success -> ', s))
      ).subscribe(async () => {
        const reports = await this.priorityImpactService.getLRTPReports(attributesForPins.map(p => p.PIN));
        if (reports.length > 0) {
          const readableProjTypes = this.generateReadableProjectTypes(attributesForPins);
          const lrtpBody = this.emailContentLrtpService.createInitialEmailHtml(policies, attributesForPins, readableProjTypes, approvalInfo, reports);
          const emailTo = LRTP_NOTICE_STAFF;
          const lrtpSubject = attributesForPins.length === 1
            ? `Project Scoping - ${attributesForPins[0].PIN}:  New Scope Created - LRTP Impacts`
            : `Project Scoping - New Scopes Created - LRTP Impacts`;
          await this.emailService.sendEmail(emailTo, lrtpBody, lrtpSubject);
        }
      });
    } catch (err) {
      await this.handleError(err, 'SubmitApproveEmailService.sendInitialLRTPNotice()');
      throw new Error(err);
    }
  }

  private async sendFinalLRTPNotice(policies: ApprovalPolicy[], attributesForPins: PinAttributes[], approvalInfo: any) {
    try {
      const reports = await this.priorityImpactService.getLRTPReports(attributesForPins.map(p => p.PIN));
      if (reports.length > 0) {
        const readableProjTypes = this.generateReadableProjectTypes(attributesForPins);
        // const readableProjTypes = null; // for testing error handling
        const lrtpBody = this.emailContentLrtpService.createFinalEmailHtml(policies, attributesForPins, readableProjTypes, approvalInfo, reports);
        const emailTo = LRTP_NOTICE_STAFF;
        const lrtpSubject = attributesForPins.length === 1
          ? `LRTP Impacts - ${approvalInfo[0].misc.counties} : Project Scoping - ${approvalInfo[0].misc.readableRouteName} : Scope Approved ${attributesForPins[0].PIN}`
          : `LRTP Impacts - Project Scoping - Scopes Approved`;
        await this.emailService.sendEmail(emailTo, lrtpBody, lrtpSubject);
      }
    } catch (err) {
      await this.handleError(err, 'SubmitApproveEmailService.sendFinalLRTPNotice()');
      throw new Error(err);
    }
  }

  private async sendFinalSafetyEmails(attributesForPins: PinAttributes[], info, approvalInfo) {
    try {
      const { emailSubject, files, MP, ADA } = info;
      const addIsSafetyBoolean = (pinArray: PinAttributes[]): (PinAttributes & { isSafety: boolean })[] => {
        const { selectOptions: ProjTypeOptions } = this.fs.PROJECT_TYPES;
        return pinArray.map(PIN => {
          const selectedTypes = JSON.parse(PIN.PROJECT_TYPES_JSON);
          const safetyOption = ProjTypeOptions.value.find(option => option.alias === 'Safety/Crash');
          return { ...PIN, isSafety: !!selectedTypes.find(type => type.PROJECTTYPE === safetyOption.value) };
        });
      };

      const policies = attributesForPins.map(attrs => {
        return this.policyFactory.generatePolicy(attrs.PIN, attrs.DISTRICT, attrs.PROGRAM_TYPES);
      });

      const allPins = addIsSafetyBoolean(attributesForPins);
      const safetyPins = allPins.filter(pin => pin.isSafety);
      const emailAddressArray = await this.generateSafetyEmailAddresses(attributesForPins);
      if (safetyPins.length && emailAddressArray.length > 0) {
        const counts = await Promise.all(attributesForPins.map(atts => this.impactRxLayersService.getCountsForPin(atts.PIN)));
        const arrayOfProjTypes = this.generateReadableProjectTypes(attributesForPins);
        const safetyBody = await this.emailContentInitialSafetyService.getInitialSafetyCreateEmailHtml(policies, attributesForPins, arrayOfProjTypes, approvalInfo, files, counts);
        const safetyEmailTo = environment.isLocalHost ? EMAIL_TEST : emailAddressArray;
        this.emailService.sendEmail(safetyEmailTo, safetyBody, emailSubject);
      }
    } catch (err) {
      await this.handleError(err, 'SubmitApproveEmailService.sendFinalSafetyEmails()');
    }
  }

  // send the final email to users in the PSS Submittal Emails role
  private async sendFinalEmail(attributesForPins: PinAttributes[], audits: Audit[], policies: ApprovalPolicy[], usersToEmailOverride?: string[]) {
    try {
      let usersToEmail;
      if (usersToEmailOverride) {
        usersToEmail = usersToEmailOverride;
      } else {
        const usersToEmailObj = await this.getAuditsForEach(attributesForPins, audits);
        usersToEmail = await this.getUsersToEmailFinal(usersToEmailObj, policies);
      }

      const emailTo = environment.isLocalHost ? EMAIL_TEST : usersToEmail;

      console.log(`Current "Final" Email To: ${JSON.stringify(emailTo)}`);
      console.log(`Production "Final" Email To: ${JSON.stringify(usersToEmail)}`);

      // get info
      const approvalInfo = await this.getApprovalInfo(attributesForPins, policies);

      // determine if all pins are abbreviated projects or not
      const { selectOptions: ProgTypeOptions } = this.fs.PROGRAM_TYPES;
      const mpOption = ProgTypeOptions.value.find(option => option.alias === 'MP');
      const adaOption = ProgTypeOptions.value.find(option => option.alias === 'ADA');
      const progTypesArray = approvalInfo.map(info => info.pinAttributes.PROGRAM_TYPES);
      const MP = progTypesArray.every(prog => prog === mpOption.value.toString());
      const ADA = progTypesArray.every(prog => prog === adaOption.value.toString());

      const files = await this.emailContentFinalFilesService.getFinalApprovalEmailFiles(approvalInfo, MP || ADA);
      const emailBody = await this.emailContentFinalService.getFinalApprovalEmailHtml(approvalInfo, files, { MP, ADA });
      const emailSubject = attributesForPins.length === 1
        ? `${approvalInfo[0].misc.counties} : Project Scoping - ${approvalInfo[0].misc.readableRouteName} : Scope Approved ${attributesForPins[0].PIN}`
        : `Project Scoping - Scopes Approved`;
      // Bcc Iowa DOT Admin - Hard Coded
      await this.emailService.sendEmail(emailTo, emailBody, emailSubject, null, IOWA_ADMIN_STAFF);
      await this.sendFinalSafetyEmails(attributesForPins, { emailSubject, files, MP, ADA }, approvalInfo);
      await this.sendFinalLRTPNotice(policies, attributesForPins, approvalInfo);
    } catch (err) {
      const src = 'file: submit-approve-email.service.ts ~ line 352 ~ SubmitApproveEmailService ~ sendFinalEmail';
      await this.handleError(err, src);
    }
  }

  private async get4rDistrictUsersToCc(attributesForPins: PinAttributes[]) {
    const districtGroups4R = this.get4rDistrictGroups(attributesForPins);
    if (districtGroups4R.length > 0) {
      const rolesForCc = await this.userInfoService.getUsersForRoles(districtGroups4R);
      let usersToCc = [];
      Object.keys(rolesForCc).forEach(k => {
        rolesForCc[k].forEach(u => usersToCc.push(u));
      });
      usersToCc = [...new Set(usersToCc)];
      return this.userInfoService.removeHntbStaffEmail(usersToCc);
    }
    return [];
  }


  private async getUsersToEmailFinal(usersToEmailObj: any, policies: ApprovalPolicy[]) {
    try {
      // get notice only users
      const noticeOnlyUsers = await this.getApprovalNoticeOnlyUsers(policies);
      // add the notice users
      Object.keys(noticeOnlyUsers).forEach(key => {
        if (!usersToEmailObj[key]) {
          usersToEmailObj[key] = 1;
        }
      });
      // remove hntb staff
      return this.userInfoService.removeHntbStaffEmail(Object.keys(usersToEmailObj));
    } catch (err) {
      console.error(err);
    }
  }

  // checks for 4R funding type and returns district approval groups
  private get4rDistrictGroups(attributes: PinAttributes[]) {
    const districtGroups: string[] = [];
    let programTypeCodes = null;
    let readableProgramTypes = null;
    attributes.forEach(attr => {
      programTypeCodes = attr.PROGRAM_TYPES;
      readableProgramTypes = this.generateReadableProgramTypes([programTypeCodes]);
      if (readableProgramTypes.length > 0 && readableProgramTypes[0].includes(ProgramType._4R)) {
        districtGroups.push(`${APP_ROLES.DISTRICT_X.replace('X', attr.DISTRICT.toString())}`);
      }
    });
    return districtGroups;
  }

  private async getAuditsForEach(attributesForPins: PinAttributes[], audits: Audit[]) {
    try {
      const usersToEmailObj = {};
      const auditsForEach = await Promise.all(attributesForPins.map(async x => {
        // const audits = await this.auditService.getAudits(x.PIN);
        const audit = audits.filter(a => a.PIN === x.PIN);
        const emails = await this.generateFinalEmailTo(audit);
        // check if user who submitted scope is part of email list, if not add them
        if (emails.indexOf(x.USERNAME) === -1) {
          emails.push(x.USERNAME); // add the user who created the scope
        }
        return emails;
      }));
      auditsForEach.forEach(emails => {
        emails.forEach(email => {
          usersToEmailObj[email] = 1;
        });
      });
      return usersToEmailObj;
    } catch (err) {
      const src = 'file: submit-approve-email.service.ts ~ line 389 ~ SubmitApproveEmailService ~ getAuditsForEach';
      await this.handleError(err, src);
    }
  }

  private async generateSafetyEmailAddresses(attributesForPins: PinAttributes[]) {
    const group = APP_EMAIL_GROUPS.SAFETY_GROUP;
    const safetyGroupUserEmails = await this.userInfoService.getUsersForEmailGroups([group]);
    const users = safetyGroupUserEmails[group];
    if (users) {
      // add users who created scope
      attributesForPins.forEach(a => {
        if (users.indexOf(a.USERNAME) === -1) {
          users.push(a.USERNAME);
        }
      });
      return this.userInfoService.removeHntbStaffEmail(users);
    } else {
      return [];
    }
  }

  private getWorkflowStage(report: ApprovalReport): string {
    if (report.stages.length > 0) {
      const pending = report.stages.filter(stage => stage.currentPending).length > 0;
      // note initial email gets sent via scope-save-service.ts after initial save
      return pending ? WORKFLOW_PENDING : WORKFLOW_FINAL_APPROVAL;
    } else {
      return WORKFLOW_ERROR;
    }
  }

  private async getApprovalInfo(attributesForPins, policies: ApprovalPolicy[]): Promise<any> {
    try {
      // const scans = await this.pinsRxLayerService.scansPromise;
      const scans = await this.pinsRxLayerService.scans$.pipe(
        filter(scans => scans !== null && scans !== undefined),
        take(1)
      ).toPromise();

      const lookups = createLookups(scans);
      return await Promise.all(
        attributesForPins.map(attrs => {
          const approvalPolicy = policies.filter(p => p.scope_id === attrs.PIN)[0];
          return getApprovedPinInfo(attrs,
            lookups,
            this.countyService,
            this.routeNameService,
            this.routeAndMeasureService,
            this.refPostService,
            this.priorityRxLayersService,
            this.impactRxLayersService,
            this.auditService,
            this.bridgeInfoService,
            approvalPolicy);
        })
      );
    } catch (err) {
      const src = 'file: submit-approve-email.service.ts ~ line 435 ~ SubmitApproveEmailService ~ getApprovalInfo';
      await this.handleError(err, src);
    }
  }

  private async getApprovalNoticeOnlyUsers(policies: ApprovalPolicy[]) {
    // get NEPA users
    const noticeUsers = [];
    const roles = [];
    const distinct = {};
    policies.forEach(p => {
      p.stages.filter(s => s.operator === AnyAll.NOTICE_ONLY).map(g => {
        g.groups.forEach(group => {
          if (roles.indexOf(group) < 0) {
            roles.push(group);
          }
        });
      });
    });
    if (roles.length > 0) {
      const users = await this.userInfoService.getUsersForRoles(roles);
      Object.keys(users).forEach(k => users[k].forEach(u => noticeUsers.push(u)));
      noticeUsers.forEach(x => {
        distinct[x] = 1;
      });
    }
    return distinct;
  }

  private async getUsersForAllEmailGroups() {
    const groups: string[] = [];
    Object.keys(APP_EMAIL_GROUPS).map(i => {
      groups.push(APP_EMAIL_GROUPS[i]);
    });
    const res = await this.userInfoService.getUsersForEmailGroups(groups);
    return res;
  }

  private generateReadableProgramTypes(programTypes: string[]): string[] {
    const types: any[] = [];
    programTypes.map(p => {
      const a = p.split(',')
        .map(x => x.trim())
        .filter(x => x.length)
        .map(x => PROGRAM_TYPE_LOOKUP[x]);
      types.push(a);
    });
    return types;
  }

  private generateReadableProjectTypes(attributesForPins: PinAttributes[]): any[][] {
    return attributesForPins.map(pin => {
      const selectedTypes = JSON.parse(pin.PROJECT_TYPES_JSON);
      const { selectOptions } = this.fs.PROJECT_TYPES;
      return selectedTypes.map(projectType => {
        const PROJECTTYPE = selectOptions.value.find(option => option.value === projectType.PROJECTTYPE);
        return { ...projectType, PROJECTTYPE, };
      });
    });
  }

  private async handleError(err: any, source: string) {
    await this.sendErrorEmail(JSON.stringify(err?.stack), source);
    await this.dialogNoticeService.error({
      title: 'EMAIL ERROR',
      message: err?.message
    });
    throw new Error(err?.message);
  }

  private async sendErrorEmail(errorMsg: string, source: string) {
    const emailTo = EMAIL_TEST;
    const body = `Source: ${source}
    <br>Error: ${errorMsg}`;
    const subject = `Project Priorities -> ${window.location.href}: EMAIL ERROR - ${source}`;
    await this.emailService.sendEmail(emailTo, body, subject);
  }


}

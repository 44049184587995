import { AnyAll, ApprovalPolicyTemplate } from '../approval-workflow-policy';
import { APP_ROLES } from 'src/app/services/user-info.service';

export const POLICY_TEMPLATE_MP: ApprovalPolicyTemplate = {
  fundingType: 'MP',
  stages: [
    {
      operator: AnyAll.ANY,
      groups: [
        APP_ROLES.SCOPE_PROJECTS,
        APP_ROLES.DISTRICT_X,
        APP_ROLES.ADMIN
      ]
    },
    {
      operator: AnyAll.ALL,
      groups: [
        APP_ROLES.DISTRICT_X
      ]
    },
    // 11/3/2020 - NEPA review not needed for MP projects
    /*{
      operator: AnyAll.ALL,
      groups: [
        APP_ROLES.LEB_NEPA_REVIEW
      ],
      onlyRequiredWhen: {
        operator: AnyAll.ANY,
        conditions: STANDARD_LEB_NEPA_CONDITIONS
      }
    }*/
  ]
};

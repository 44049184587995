import { Injectable } from '@angular/core';

import { esriRequest } from 'src/esri/request';
import { EndpointService } from './dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';
import { FormDataService } from './form-data.service';

@Injectable({
  providedIn: 'root'
})
export class ImpactAuditService {

  constructor(
    private endpointService: EndpointService,
    private formDataService: FormDataService
  ) {
  }

  private initializationPromise: any;
  private serviceUrl: string;

  async deleteAudit(scopeOrPin: string): Promise<__esri.RequestResponse> {
    await this.initialize();
    const res = esriRequest(`${this.serviceUrl}/deleteFeatures`, {
      body: this.formDataService.toFormData({
        where: `PIN = '${scopeOrPin}'`,
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
    return res;
  }

  async getImpactAudits(pins: string[]): Promise<string[]> {
    await this.initialize();
    const pin_numbers = pins.map(x => `'${x}'`).join(',');
    const query = {
      where: `PIN IN (${pin_numbers})`,
      outFields: 'OBJECTID, PIN, TIMESTAMP',
      returnDistinctValues: true,
      returnGeometry: false,
      f: 'json'
    };
    const res: any = await esriRequest(`${this.serviceUrl}/query`, { query });
    return res.data.features.map(x => x.attributes.PIN).filter(x => x !== null);
  }

  async hasImpactAudit(pin: string): Promise<boolean> {
    await this.initialize();
    const query = {
      where: `PIN = '${pin}'`,
      outFields: 'OBJECTID, PIN, TIMESTAMP',
      returnDistinctValues: true,
      returnGeometry: false,
      f: 'json'
    };
    const res: any = await esriRequest(`${this.serviceUrl}/query`, { query });
    const hasImpact = res.data.features.length === 1 ? true : false;
    return hasImpact;
  }

  private async initialize() {
    // prevent concurrent calls firing initialization more than once
    if (!this.initializationPromise) {
      this.initializationPromise = this.doInitialize();
    }
    return this.initializationPromise;
  }

  private async doInitialize() {
    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
    this.serviceUrl = urls.SCOPING_IMPACT_AUDIT;
  }


}

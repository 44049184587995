import { FormControl, ValidatorFn } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

// FormSubject is an extended FormControl
// (see https://angular.io/guide/reactive-forms)
// It adds
// - BehaviorSubject-style valueStream (like .valueChange() except it emits current value on .subscribe())
// - label
// - select options (useful when used with mat-select)

export interface SelectOption {
  alias: string;
  value: any;
}

export class FormSubject<T> extends FormControl {

  label = new BehaviorSubject<string>('');
  selectOptions = new BehaviorSubject<SelectOption[]>([]);

  private _valueStream: Observable<T>;

  static create<T>(options: {
    initValue: T,
    label?: string,
    validators?: ValidatorFn[],
    enabled?: boolean,
    selectOptions?: SelectOption[]
  }) {
    const fs = new FormSubject<T>(options.initValue, options.validators);
    if (options.enabled === false) {
      fs.disable();
    }
    if (options.selectOptions) {
      fs.selectOptions = new BehaviorSubject<SelectOption[]>(options.selectOptions);
    }
    fs.label = new BehaviorSubject<string>(options.label === undefined ? '' : options.label);
    return fs;
  }

  get valueStream() {
    if (!this._valueStream) {
      this.createValueStream();
    }
    return this._valueStream;
  }

  private createValueStream() {
    const b = new BehaviorSubject<T>(this.value);
    this.valueChanges.subscribe(val => b.next(val));
    this._valueStream = b.asObservable();
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BasicDialogComponent, BasicDialogData } from '../basic-dialog/basic-dialog.component';
import { AttachmentService } from 'src/app/services/export-and-doc/attachment.service';

@Component({
  selector: 'app-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.scss']
})
export class AttachmentDialogComponent extends BasicDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AttachmentDialogComponent>,
    public attachmentService: AttachmentService,
    @Inject(MAT_DIALOG_DATA) public data: BasicDialogData
  ) {
    super(dialogRef, data);
  }

  ok() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}

/*
@NgModule({
  declarations: [AttachmentDialogComponent],
  imports: [
    CommonModule,
    AppMaterialModule,
    LayoutModule
  ],
})
export class AttachmentDialogModule {}
*/

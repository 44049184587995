import { Injectable } from '@angular/core';
import { esriRequest } from 'src/esri/request';
import { FormDataService } from 'src/app/services/form-data.service';
import { EndpointService } from './dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';
import { RamsRequestsService } from './rams-requests.service';

export interface BridgeInfo {
  lrsRouteId: string;
  bridgeId: string; // BRIDGE_ID
  structureNumber: number; // NBI8
}

const OUTPUT_BRIDGE_FIELDS = [
  'BRIDGE_ID', // BRIDGE_ID
  'NBI8', // STRUCTURE NUMBER
  'NBI13A', // LRS Route ID
];

const OUTPUT_GEOM_FIELDS = [
  'PSS_PIN_ID',
  'PROJECT_SCOPE_ID'
];

@Injectable({
  providedIn: 'root'
})
export class BridgeInfoService {

  constructor(
    private endpointService: EndpointService,
    private ramsRequestsService: RamsRequestsService,
    private formDataService: FormDataService,
  ) {
  }

  async getBridgeInfoForProject(pinOrScope: string, lrsRouteId: string): Promise<BridgeInfo[]> {
    return new Promise(async (resolve, reject) => {
      const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
      const geom = await this.getProjectGeometry(pinOrScope);
      if (geom) {
        const res = await esriRequest(urls.STATE_BRIDGE_RESULTS + '/query', {
          body: this.formDataService.toFormData({
            outFields: OUTPUT_BRIDGE_FIELDS.join(),
            geometry: geom,
            inSR: geom.spatialReference,
            geometryType: 'esriGeometryPolyline',
            spatialRel: 'esriSpatialRelIntersects',
            distance: 100,
            units: 'esriSRUnit_Foot',
            returnGeometry: false, // must be false when using returnDistinctValues
            returnDistinctValues: true,
            f: 'json'
          }),
          responseType: 'json',
          method: 'post'
        });
        if (!res.data || res.data.features.length < 1) {
          const msg = `request to ${urls.STATE_BRIDGE_RESULTS} did not return features`;
          console.log(msg);
        }
        const bridegData: BridgeInfo[] = [];
        res.data.features.forEach(d => {
          const routeId = d.attributes.NBI13A;
          if (routeId === lrsRouteId) {
            bridegData.push({
              lrsRouteId: routeId,
              bridgeId: d.attributes.BRIDGE_ID,
              structureNumber: d.attributes.NBI8
            });
          }
        });
        // remove any duplicates
        const bridgeDataUnique = bridegData.filter((item, index) => {
          return bridegData.indexOf(item) >= index;
        });
        resolve(bridgeDataUnique);
      } else {
        reject('Unable to get project geometry');
      }
    });
  }

  private async getProjectGeometry(pinOrScope: string) {
    const res = await this.ramsRequestsService.getProjectScopingRoutesByPinScope(pinOrScope);
    if (res.data.geometryType !== 'esriGeometryPolyline') {
      console.error(`Unsupported geomtery type: ${res.data.geometryType}`);
      return null;
    }
    // push paths
    const paths = [];
    res.data.features.forEach(f => f.geometry.paths.forEach(p => paths.push(p)));
    // get geometry
    const polyline = {
      paths: paths,
      spatialReference: res.data.spatialReference
    };
    return polyline;
  }

}

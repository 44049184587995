import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoggingService } from '../services/logging.service';
import { RxLayer } from './rx-layer';

@Injectable({
  providedIn: 'root'
})
export class RxLayersMgmtService {

  private _rxLayers = new BehaviorSubject<RxLayer[]>([]);
  rxLayers$ = this._rxLayers;

  constructor(
    private logging: LoggingService
  ) {
  }

  register(layer: RxLayer) {
    // check if we have it registered already
    const registered = this._rxLayers.value.find(r => r.title === layer.title) ? true : false;
    // replace
    if (registered) {
      this._rxLayers.next([
        ...this._rxLayers.value.filter(r => r.title !== layer.title),
        layer
      ]);
      // add
    } else {
      this._rxLayers.next([
        ...this._rxLayers.value,
        layer
      ]);
    }
  }

  unRegister(layer: RxLayer) {
    this._rxLayers.next([
      ...this._rxLayers.value.filter(r => r.id !== layer.id),
    ]);
  }

  getById(id: string) {
    return this._rxLayers.value.find(r => r.id === id);
  }

  getByTitle(title: string) {
    return this._rxLayers.value.find(r => r.title === title);
  }

  disposeAll() {
    // this.logging.log('🚀 ~ file: rx-layers.service.ts ~ line 49 ~ RxLayersService ~ disposeAll() -> Layer Count:', this._rxLayers.value.length);
    this._rxLayers.value.forEach(r => r.dispose());
    this._rxLayers.next([]);
  }

}

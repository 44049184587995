import { JoinDefinition } from './join-definition';

export enum FilterType {
  MinMax = 'MinMax',
  ContainsString = 'ContainsString',
  MultiSelect = 'MultiSelect',
  DateMinMax = 'DateMinMax',
  StageSelect = 'StageSelect',
  MultiFieldSearch = 'MultiFieldSearch',
  QuickFilter = 'QuickFilter',
  Slider = 'Slider',
  Spatial = 'Spatial'
}

export interface Filter {
  guid: string; // unique identifier for this filter
  type: FilterType;
  field: __esri.Field;
  searchFieldNames?: string[]; // used for search-bar filters, specifies which fields to search in
  value: any;
  active: boolean;
  iconName?: string;
  toolTip?: string;
  filterExpression?: string;
  externalTable?: JoinDefinition; // only used for 'deep' queries
}

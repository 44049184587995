import { ChangeDetectionStrategy, Component, HostBinding, Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppMaterialModule } from 'src/app/modules/material-module';

export interface BasicDialogData {
  data?: any;
  title?: string;
  message: string;
  height?: number;
  width?: number;
  hasCancel?: boolean;
  hasOk?: boolean;
  okBtnText?: string;
  cancelBtnText?: string;
  htmlContent?: string;
}

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicDialogComponent {

  // this needs work, issues with height scaling / background elements
  @HostBinding('style.height') height: string;
  @HostBinding('style.width') width: string;

  hasCancel = true;
  hasOk = true;
  okBtnText = 'Ok';
  cancelBtnText = 'Cancel';
  htmlContent: string;

  constructor(
    public dialogRef: MatDialogRef<BasicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BasicDialogData,
  ) {
    if (typeof this.data.height !== 'undefined') {
      this.height = `${this.data.height.toString()}px`;
    }
    if (typeof this.data.width !== 'undefined') {
      this.width = `${this.data.width.toString()}px`;
    }
    if (typeof this.data.hasCancel === 'boolean') {
      this.hasCancel = this.data.hasCancel;
    }
    if (typeof this.data.hasOk === 'boolean') {
      this.hasOk = this.data.hasOk;
    }
    if (typeof this.data.okBtnText === 'string') {
      this.okBtnText = this.data.okBtnText;
    }
    if (typeof this.data.cancelBtnText === 'string') {
      this.cancelBtnText = this.data.cancelBtnText;
    }
  }

  closeDialog(output) {
    this.dialogRef.close(output);
  }

}

@NgModule({
  declarations: [BasicDialogComponent],
  imports: [CommonModule, AppMaterialModule],
})
export class BasicDialogModule {
}

import { Injectable } from '@angular/core';
import { FormDataService } from '../form-data.service';
import { EndpointService } from '../dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from '../dynamic-endpoint/endpoint.service.config';
import { esriRequest } from 'src/esri/request';

@Injectable({
  providedIn: 'root'
})
export class ScopeSaveDeleteRequestsService {

  constructor(
    private formDataService: FormDataService,
    private endpointService: EndpointService,
  ) {
  }

  private urlsPromise = this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);

  async deleteLookups(PIN: string, deleteImpactAudit = false) {
    const options: __esri.RequestOptions = {
      body: this.formDataService.toFormData({
        where: `PIN = '${PIN}'`,
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    };
    const auditOptions: __esri.RequestOptions = {
      body: this.formDataService.toFormData({
        where: `PIN='${PIN}' or SCOPE='${PIN}'`,
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    };
    const urls = await this.urlsPromise;
    const deletes = [
      esriRequest(urls.WORK_GROUP + '/deleteFeatures', options),
      esriRequest(urls.PROJECT_WORK_TYPES + '/deleteFeatures', options),
      esriRequest(urls.PROJECT_TYPES + '/deleteFeatures', options),
      esriRequest(urls.PROGRAM_TYPES + '/deleteFeatures', options),
      esriRequest(urls.PROJECT_COUNTY + '/deleteFeatures', options),
      esriRequest(urls.SCOPE_APPROVAL_AUDIT + '/deleteFeatures', auditOptions)
    ];
    if (deleteImpactAudit) {
      deletes.push(esriRequest(urls.SCOPING_IMPACT_AUDIT + '/deleteFeatures', options));
    }
    return Promise.all(deletes);
  }

  async clearProjectTypesOnly(PIN: string) {
    const options: __esri.RequestOptions = {
      body: this.formDataService.toFormData({
        where: `PIN = '${PIN}'`,
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    };
    const urls = await this.urlsPromise;
    return esriRequest(urls.PROJECT_TYPES + '/deleteFeatures', options);
  }

  async saveProjectScopingAttributes(attributes, method) {
    const urls = await this.urlsPromise;
    return esriRequest(urls.PROJECT_SCOPING + method, {
      body: this.formDataService.toFormData({
        features: [{ attributes }],
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }

  async deleteProjectScopingAttributes(pin) {
    const urls = await this.urlsPromise;
    return esriRequest(urls.PROJECT_SCOPING + '/deleteFeatures', {
      body: this.formDataService.toFormData({
        where: `PIN = '${pin}'`,
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }

  async getObjectId(pin: string) {
    const urls = await this.urlsPromise;
    const res = await esriRequest(urls.PROJECT_SCOPING + '/query', {
      body: this.formDataService.toFormData({
        where: `PIN = '${pin}'`,
        outFields: 'OBJECTID',
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
    return res.data.features[0].attributes.OBJECTID;
  }

  // Story 1806 Migrate Geometry into RAMS - use RamsRequestsService
  /*
  async saveGeometryForScopeFromBridgeView(attributes, PROJECT_SCOPE_ID: string) {
    const ROUTE_ID = attributes.PRIMARY_ROUTE;
    let FROM_MEASURE = attributes.FROM_MEASURE;
    let TO_MEASURE = attributes.TO_MEASURE;
    if (FROM_MEASURE === TO_MEASURE) {
      FROM_MEASURE -= 0.00001;
      TO_MEASURE += 0.00001;
    }
    const geometry = await this.lrsService.measureToGeometry(ROUTE_ID, FROM_MEASURE, TO_MEASURE);
    const urls = await this.urlsPromise;
    const today = new Date(new Date().toDateString());
    today.setUTCHours(0);
    return esriRequest(urls.SCOPE_AND_PIN_GEOMETRY + '/addFeatures', {
      body: this.formDataService.toFormData({
        features: [{
          attributes: {
            EVENT_ID: this.guidService.createGuid(),
            ROUTE_ID,
            FROM_MEASURE,
            TO_MEASURE,
            PROJECT_SCOPE_ID,
            EFFECTIVE_END_DATE: null,
            EFFECTIVE_START_DATE: +today
          },
          geometry: {
            paths: geometry.paths
          }
        }],
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }

  // Story 1806 Migrate Geometry into RAMS - use RamsRequestsService
  async deleteGeometryForScopeId(PROJECT_SCOPE_ID: string) {
    const options = {
      body: this.formDataService.toFormData({
        where: `PROJECT_SCOPE_ID = '${PROJECT_SCOPE_ID}'`,
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    };
    const urls = await this.urlsPromise;
    return esriRequest(urls.SCOPE_AND_PIN_GEOMETRY + '/deleteFeatures', options);
    // return esriRequest(urls.SCOPE_GEOMETRY + '/deleteFeatures', options);
  }

  // Story 1806 Migrate Geometry into RAMS - use RamsRequestsService
  async addGeometryForScopeId(PROJECT_SCOPE_ID: string, routesAndMeasures: RouteAndMeasure[]) {
    const urls = await this.urlsPromise;
    const today = new Date(new Date().toDateString());
    today.setUTCHours(0);
    // return esriRequest(urls.SCOPE_GEOMETRY + '/addFeatures', {
    return esriRequest(urls.SCOPE_AND_PIN_GEOMETRY + '/addFeatures', {
      body: this.formDataService.toFormData({
        features: routesAndMeasures.map(s => ({
          attributes: {
            EVENT_ID: this.guidService.createGuid(),
            ROUTE_ID: s.ROUTE_ID,
            FROM_MEASURE: s.FROM_MEASURE,
            TO_MEASURE: s.TO_MEASURE,
            PROJECT_SCOPE_ID,
            EFFECTIVE_END_DATE: null,
            EFFECTIVE_START_DATE: +today
          },
          geometry: {
            paths:  s.PATHS
          }
        })),
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }
  */

  async updateWorkGroups(attributes) {
    const urls = await this.urlsPromise;
    return esriRequest(urls.WORK_GROUP + '/updateFeatures', {
      body: this.formDataService.toFormData({
        features: [{ attributes }],
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }

  async saveWorkGroups(workGroups, PIN, PIN_ID) {
    const urls = await this.urlsPromise;
    return esriRequest(urls.WORK_GROUP + '/addFeatures', {
      body: this.formDataService.toFormData({
        features: workGroups.map(x => ({
          attributes: {
            WORKGROUP: +x,
            PIN,
            PIN_ID
          }
        })),
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }

  async saveWorkTypes(workTypes, PIN, PIN_ID) {
    const urls = await this.urlsPromise;
    return esriRequest(urls.PROJECT_WORK_TYPES + '/addFeatures', {
      body: this.formDataService.toFormData({
        features: workTypes.map(x => ({
          attributes: {
            WORKCODE: +x,
            PIN,
            PIN_ID
          }
        })),
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }

  async updateWorkTypes(attributes) {
    const urls = await this.urlsPromise;
    return esriRequest(urls.PROJECT_WORK_TYPES + '/updateFeatures', {
      body: this.formDataService.toFormData({
        features: [{ attributes }],
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }

  async saveProjectPurposes(projectPurposes, PIN, PIN_ID, PROJECT_PURPOSE_DESCS?: { [PROJECTTYPE: string]: string }) {
    const urls = await this.urlsPromise;
    return esriRequest(urls.PROJECT_TYPES + '/addFeatures', {
      body: this.formDataService.toFormData({
        features: projectPurposes.map(x => ({
          attributes: {
            PROJECTTYPE: +x,
            PURPOSEDESCRIPTION: (PROJECT_PURPOSE_DESCS && typeof PROJECT_PURPOSE_DESCS[x] === 'string') ? PROJECT_PURPOSE_DESCS[x] : '',
            PIN,
            PIN_ID
          }
        })),
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }

  async updateProjectPurposes(attributes) {
    const urls = await this.urlsPromise;
    return esriRequest(urls.PROJECT_TYPES + '/updateFeatures', {
      body: this.formDataService.toFormData({
        features: [{ attributes }],
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }

  async saveProgramFunding(programFunding, PIN, PIN_ID, FUNDING_DESCRIPTIONS?: { [PROGRAMTYPE: string]: string }) {
    const urls = await this.urlsPromise;
    return esriRequest(urls.PROGRAM_TYPES + '/addFeatures', {
      body: this.formDataService.toFormData({
        features: programFunding.map(x => ({
          attributes: {
            PROGRAMTYPE: +x,
            FUNDING_DESCRIPTION: (FUNDING_DESCRIPTIONS && typeof FUNDING_DESCRIPTIONS[x] === 'string') ? FUNDING_DESCRIPTIONS[x] : '',
            PIN,
            PIN_ID
          }
        })),
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }

  async saveCounties(counties: string[], PIN, PIN_ID) {
    const urls = await this.urlsPromise;
    return esriRequest(urls.PROJECT_COUNTY + '/addFeatures', {
      body: this.formDataService.toFormData({
        features: counties.map(x => ({
          attributes: {
            COUNTY: x,
            PIN,
            PIN_ID
          }
        })),
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
  }

}

import { PriorityImpactDetailData } from './priority-impact-detail-data';

// NOTE:
// tried to use import { PriorityImpactLayerTitle } from "src/app/services/priority-impact-service";
// impactLayerTitles: [PriorityImpactLayerTitle.HistoricFloodingEvents] // got errors after prod build

export const IMPACT_DETAIL_HISTORIC_FLOOD: PriorityImpactDetailData = {
  dialogTitle: `HIGH PRIORITY IMPACT NOTICE: Historic Flooding Events (1997-2015)`,
  impactLayerTitles: ['Historic Flooding Events (1997-2015)']
};

import { BehaviorSubject, Observable } from 'rxjs';

// An Observable that keeps track of whether or not it has any subscribers.

export class MonitoredBehaviorSubject<T> extends Observable<T> {

  private behSub: BehaviorSubject<T>;
  hasSubscribers: BehaviorSubject<boolean>;
  private subscriberCount = 0;

  constructor(_value: T) {
    super(subscriber => {
      this.onSubscribe(subscriber);
    });
    this.behSub = new BehaviorSubject<T>(_value);
    this.hasSubscribers = new BehaviorSubject<boolean>(false);
  }

  get value() {
    return this.behSub.value;
  }

  next(val: T) {
    this.behSub.next(val);
  }

  private onSubscribe(subscriber) {
    this.increment();
    const subscription = this.behSub.subscribe(x => {
      subscriber.next(x);
    });
    // set teardown method
    subscriber.add(() => {
      this.decrement();
      subscription.unsubscribe();
    });
  }

  private increment() {
    this.subscriberCount++;
    if (this.subscriberCount === 1) {
      this.hasSubscribers.next(true);
    }
  }

  private decrement() {
    this.subscriberCount--;
    if (this.subscriberCount === 0) {
      this.hasSubscribers.next(false);
    }
  }
}

class PreSubmitRule {
  valueGet: (attrs: any) => any;
  valueCheck: (value: any) => boolean;
  description: string;
  associatedTab: string;
  isAbbreviatedProject: boolean;
  isNotAbbreviatedProject: boolean;
}

export const PRE_SUBMIT_RULES: PreSubmitRule[] = [
  {
    valueGet: (attrs: any) => attrs.PROGRAM_TYPES.split(',').map(x => x.trim()).filter(x => x.length),
    valueCheck: value => value.length > 0,
    description: 'At least one program funding type must be selected.',
    associatedTab: 'Project',
    isAbbreviatedProject: true,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.PROJECT_TYPES.split(',').map(x => x.trim()).filter(x => x.length),
    valueCheck: value => value.length > 0,
    description: 'At least one program purpose must be selected.',
    associatedTab: 'Project',
    isAbbreviatedProject: true,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.DESCRIPTION,
    valueCheck: value => value !== null,
    description: 'A location / description is required.',
    associatedTab: 'Project',
    isAbbreviatedProject: true,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.WORK_GROUPS.split(',').map(x => x.trim()).filter(x => x.length),
    valueCheck: value => value.length > 0,
    description: 'At least one work group must be selected.',
    associatedTab: 'Project',
    isAbbreviatedProject: true,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.WORK_TYPES.split(',').map(x => x.trim()).filter(x => x.length),
    valueCheck: value => value.length > 0,
    description: 'At least one work code must be selected.',
    associatedTab: 'Project',
    isAbbreviatedProject: true,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.DISTRICT,
    valueCheck: value => value !== null && value !== undefined,
    description: 'Must specify a district.',
    associatedTab: 'Project',
    isAbbreviatedProject: true,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.LEVELSTUDYREQUIRED,
    valueCheck: value => value !== null,
    description: 'Must indicate what level of study is required.',
    associatedTab: 'Project',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.INTERCHANGEREQUIRED,
    valueCheck: value => value !== null,
    description: 'Must indicate whether or not an interchange is required',
    associatedTab: 'Project',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.DESIREDFISCALYEAR,
    valueCheck: value => value !== null,
    description: 'Must specify desired fiscal year.',
    associatedTab: 'Project',
    isAbbreviatedProject: true,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.DESIRED_FISCAL_MONTH,
    valueCheck: value => value !== null,
    description: 'Must specify desired fiscal month.',
    associatedTab: 'Project',
    isAbbreviatedProject: true,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.PD_COMPLETED_BY,
    valueCheck: value => value !== null,
    description: 'Must specify whom project development will be completed by.',
    associatedTab: 'Project',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.BUDGETRANGEMIN,
    valueCheck: value => value !== null,
    description: 'Must specify budget range minimum.',
    associatedTab: 'Project',
    isAbbreviatedProject: true,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.BUDGETRANGEMAX,
    valueCheck: value => value !== null,
    description: 'Must specify budget range maximum.',
    associatedTab: 'Project',
    isAbbreviatedProject: true,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.TRAFFICCRITICAL,
    valueCheck: value => value !== null,
    description: 'Must specify whether project is traffic critical.',
    associatedTab: 'Project',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.OUTSIDESERVICES,
    valueCheck: value => value !== null,
    description: 'Must specify whether outside services are expected.',
    associatedTab: 'Project',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.SURVEY_REQUIRED,
    valueCheck: value => value !== null,
    description: 'Must specify if survey is required.',
    associatedTab: 'Project',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.ROWNEED,
    valueCheck: value => value !== null,
    description: 'Must specify ROW amount needed.',
    associatedTab: 'ROW/LEB',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.ROWRELOCATION,
    valueCheck: value => value !== null,
    description: 'Must specify whether residential/commercial relocation is needed.',
    associatedTab: 'ROW/LEB',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.TANDE,
    valueCheck: value => value !== null,
    description: 'Must specify whether project affects threatened and endangered species.',
    associatedTab: 'ROW/LEB',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.CULTURALSITE,
    valueCheck: value => value !== null,
    description: 'Must specify whether project is a known archaeological site.',
    associatedTab: 'ROW/LEB',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.HISTORICALSITE,
    valueCheck: value => value !== null,
    description: 'Must specify whether project is associated with a historical site.',
    associatedTab: 'ROW/LEB',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.CONCURRENCEPOINT,
    valueCheck: value => value !== null,
    description: 'Must specify whether project follows concurrence point process.',
    associatedTab: 'ROW/LEB',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.LEVIESAFFECTED,
    valueCheck: value => value !== null,
    description: 'Must specify whether levies are affected.',
    associatedTab: 'ROW/LEB',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  },
  {
    valueGet: (attrs: any) => attrs.LEVELOFDOCUMENTATION,
    valueCheck: value => value !== null,
    description: 'Must specify the expected level of documentation.',
    associatedTab: 'ROW/LEB',
    isAbbreviatedProject: false,
    isNotAbbreviatedProject: true,
  }
];

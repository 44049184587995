import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppLayoutService } from 'src/app/services/app-layout.service';

export enum Tab {
  project = 'project',
  geometry = 'geometry',
  rowole = 'rowole',
  impact = 'impact',
  priority = 'priority',
  costbudget = 'costbudget'
}

export const TABS: Tab[] = environment.isCommission
  ? [Tab.project, Tab.impact, Tab.priority, Tab.costbudget]
  : [Tab.project, Tab.geometry, Tab.rowole, Tab.impact, Tab.priority, Tab.costbudget];

@Injectable({
  providedIn: 'root'
})
export class CurrentTabService {
  currentTab = new BehaviorSubject<Tab>(Tab.project);

  constructor(
    private appLayoutService: AppLayoutService
  ) {
    this.appLayoutService.layoutMode$.subscribe(layout => {
      if (layout === 'table' && this.currentTab.value !== Tab.project) {
        // give time for pane open/close animation
        setTimeout(() => {
          if (this.currentTab.value !== Tab.project) {
            this.currentTab.next(Tab.project);
          }
        }, 500);
      }
    });
  }
}

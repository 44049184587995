import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, skip } from 'rxjs/operators';

import { RxLayer } from '../../rx-layer/rx-layer';
import { MapService } from '../map/map.service';
import { EndpointService } from '../dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from '../dynamic-endpoint/endpoint.service.config';
import { RxLayerOptions } from '../../rx-layer/rx-layer-options';
import { GENERAL_EXCLUDE_FIELDS } from '../../rx-layer/exclude-fields';
import { PinsRxLayerService } from './pins-rx-layer.service';
import { PRIORITY_DEFAULT_COLS, PRIORITY_PIN_KEYS } from './priority-rx-layers.service.config';
import { PriorityCounts } from '../export-and-doc/pininfo';
import { LoggingService } from '../logging.service';
import { AppLayoutService } from '../app-layout.service';


class PriorityRxLayers {
  bridges: RxLayer;
  pavement: RxLayer;
  traffic: RxLayer;
  safety: RxLayer;
  freight: RxLayer;
  roadClass: RxLayer;
  saver: RxLayer;
  atms: RxLayer;
  inrix: RxLayer;
  countyRoadDensity: RxLayer;
  accessLocations: RxLayer;
}

@Injectable({
  providedIn: 'root'
})
export class PriorityRxLayersService {

  constructor(
    private appLayoutService: AppLayoutService,
    private mapService: MapService,
    private endpointService: EndpointService,
    private pinsRxLayerService: PinsRxLayerService,
    private logging: LoggingService
  ) {
    this.init();
  }

  // layers
  private _layers = new BehaviorSubject<PriorityRxLayers>(null);
  layers$ = this._layers.asObservable();

  private allLayers: RxLayer[] = [];

  private init() {
    this.mapService.mapAssets$.pipe(
      filter(assets => assets !== null && assets !== undefined),
    ).subscribe(async (assets) => {
      // this.logging.log('🚀 ~ file: priority-rx-layers.service.ts ~ line 52 ~ PriorityRxLayersService ~ this.mapService.mapAssets$.pipe ~ assets', assets);
      const map = assets.map;
      const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
      const layers: PriorityRxLayers = {
        bridges: this.createLayer('Bridges', urls.BRIDGE_RESULTS, 'bridges', map),
        pavement: this.createLayer('Pavement', urls.PAVEMENT_RESULTS, 'pavement', map),
        traffic: this.createLayer('Traffic', urls.TRAFFIC_RESULTS, 'traffic', map),
        safety: this.createLayer('Safety', urls.SAFETY_RESULTS, 'safety', map),
        freight: this.createLayer('Freight', urls.FREIGHT_RESULTS, 'freight', map),
        roadClass: this.createLayer('Road Class', urls.ROAD_CLASS_RESULTS, 'roadClass', map),
        saver: this.createLayer('Crashes', urls.SAVER_RESULTS, 'saver', map),
        atms: this.createLayer('Traffic Incidents', urls.ATMS_RESULTS, 'atms', map),
        inrix: this.createLayer('INRIX (Delay)', urls.INRIX_RESULTS, 'inrix', map),
        countyRoadDensity: this.createLayer('County Road Density', urls.PIN_COUNTY_ROAD_DENSITY_RESULTS, 'countyRoadDensity', map),
        accessLocations: this.createLayer('Access Locations', urls.LOCATION_RESULTS, 'accessLocations', map)
      };

      Object.keys(layers).map(key => {
        this.allLayers.push(layers[key]);
      });

      this.pinsRxLayerService.selection.pipe(skip(1)).subscribe(s => {
        Object.keys(layers).forEach(key => {
          const rxLayer: RxLayer = layers[key];
          const pinKey = PRIORITY_PIN_KEYS[key];
          const wc = (s && s.pin)
            ? `${pinKey} = '${s.pin}'`
            : '1=2';
          if (wc !== rxLayer.baseWhereClause.value) {
            rxLayer.baseWhereClause.next(wc);
          }
          rxLayer.tableData.refresh();
        });
      });

      this.appLayoutService.layoutMode$.pipe(skip(1)).subscribe(layout => {
        Object.values(layers).forEach(rxLayer => {
          if (layout === 'table' && rxLayer.visible.value) {
            rxLayer.visible.setValue(false);
          }
        });
      });
      this._layers.next(layers);
    });
  }

  private createLayer(title: string, url: string, key: string, map: __esri.Map) {
    const config = <RxLayerOptions>{
      map,
      title,
      visible: false,
      spatialReference: { wkid: 102100 },
      url: url
        .replace('/private/', '/agshost/')
        .split('/query?')[0],
      baseWhereClause: '1=2',
      hideFields: GENERAL_EXCLUDE_FIELDS,
      displayColumns: PRIORITY_DEFAULT_COLS[key]
    };
    return new RxLayer(config);
  }

  async getCountsForPin(pin: string): Promise<PriorityCounts> {
    const layers = this._layers.value;
    const promises = Object.keys(layers).map(async key => {

      const pinKey = PRIORITY_PIN_KEYS[key];
      const where = `${pinKey} = '${pin}'`;

      const count = await layers[key].queryCount({
        where,
        returnCountOnly: true,
        f: 'json'
      });

      return { key, count };
    });
    const res = await Promise.all(promises);
    const obj = {};
    res.forEach(x => obj[x.key] = x.count);
    return obj;
  }

}

const DOMAIN = 'https://gis.iowadot.gov';
const TEST_DOMAIN = 'https://testgis.iowadot.gov';

// services had to be moved to /rams vs. /agshost to deal with security change to ramsportal vs. portal
const PROJ_SCHED = `${DOMAIN}/rams/rest/services`;
//const PROJ_SCHED  = DOMAIN + '/agshost/rest/services/Project_Scheduling';
const RAMS = `${DOMAIN}/rams/rest/services`;
const RAMS_AGSHOST = `${DOMAIN}/agshost/rest/services/RAMS`;

// This is NOT a comprehensive list of all endpoints used.
// There are other services used by this application that
// are not in this config such as reference/boundary layers and
// impact result layers, which are set in separate config tables.

export const ENDPOINT_CONFIG = {
  PPRI_DATA: {
    url: `${PROJ_SCHED}/Project_Priority_Data/FeatureServer`,
    layers: {
      BRIDGE_RESULTS: 'Bridge Results',
      STATE_BRIDGE_RESULTS: 'State Bridge Results',
      SAVER_RESULTS: 'SAVER Results',
      FREIGHT_RESULTS: 'Freight Results',
      TRAFFIC_RESULTS: 'Traffic Results',
      PAVEMENT_RESULTS: 'Pavement Results',
      ROAD_CLASS_RESULTS: 'Road Class Results',
      SICL_INTERSECTION_RESULTS: 'SICL Intersection Results',
      SAFETY_RESULTS: 'Safety Results',
      LOCATION_RESULTS: 'Location Results',
      PIN_COUNTY_ROAD_DENSITY_RESULTS: 'PIN County Road Density Results',
      INRIX_RESULTS: 'INRIX Results',
      COUNTY_ROAD_DENSITY_RESULTS: 'County Road Density Results',
      SYSTEM_PRIORITY_RESULTS: 'System Priority Results',
      ATMS_RESULTS: 'ATMS Results',
      RAMS_TRAFFIC_AND_PAVEMENT_DATA: 'RAMS Traffic and Pavement Data',
      PROJECT_PRIORITY_ATMS_DATA: 'Project Priority ATMS Data',
      PINS: 'PINS',
      PRIORITY_PROJECT_POINT: 'Priority Project Point',
      PRIORITY_PROJECT_LINE: 'Priority Project Line',
      // SCOPE_GEOMETRY: 'Scope Geometry', // Combined into 'Project Geometry'
      // RAMS_PIN_GEOMETRY: 'RAMS PIN Geometry', // Combined into 'Project Geometry'
      SCOPE_AND_PIN_GEOMETRY: 'Project Geometry', // new combined Scope & PIN Geometry
      PIN_IMPACT_LAYERS: 'PIN Impact Layers',
      PROJECT_SCOPING_MAP_LAYERS: 'Project Scoping Map Layers',
      SCOPE_APPROVAL_AUDIT: 'Scope Approval Audit',
      PRIORITY_PROJECT_PINS: 'Priority Project PINS',
      PROJECT_COUNTY: 'Project County',
      PRIORITY_EVENT: 'Priority Event',
      PRIORITY_EVENT_INFO: 'Priority Event Info',
      PROGRAM_TYPES: 'Program Type(s)',
      PROJECT_TYPES: 'Project Type(s)',
      PROJECT_SCOPING: 'Project Scoping',
      PROJECT_WORK_TYPES: 'Project Work Type(s)',
      WORK_GROUP_MATRIX: 'Work Group Matrix',
      WORK_GROUP: 'Work Group',
      PROJECT_NUMBER: 'Project Number',
      PROJECT_PRIORITY_RESULTS: 'Project Priority Results',
      PROJECT_SCORE_WEIGHTS: 'Project Score Weights',
      PROJECT_SCORE_WEIGHTS_DEVELOPMENT: 'Project Score Weights - Development',
      PROJECT_PRIORITY_CONGESTION_COSTS: 'Project Priority Congestion Costs',
      PROJECT_PRIORITY_RESULTS_DEVELOPMENT: 'Project Priority Results - Development',
      COMPLEXITY_SCHEDULE_ADHERANCE: 'Complexity Schedule Adherance',
      SCOPING_COMMISSION_CONFIGURATION: 'Scoping Commission Configuration',
      SYSTEM_PERFORMANCE_SCORE: 'System Performance Score',
      VALUATION: 'Project Priority Valuation',
      SCOPING_BRIDGES: 'Scoping Bridges',
      SCOPING_IMPACT_AUDIT: 'Scoping Impact Audit',
      IOWA_STATE_SENATE_DISTRICTS: 'Iowa State Senate Districts',
      IOWA_STATE_HOUSE_DISTRICTS: 'Iowa State House Districts',
      IOWA_CONGRESSIONAL_DISTRICTS: 'Iowa Congressional Districts',
      HIGHWAY_CANDIDATE: 'Highway Candidate'
    }
  },
  RAMS_ALL_ROUTES: {
    url: `${RAMS_AGSHOST}/All_Routes/FeatureServer`,
    layers: {
      ALL_ROUTES: 'All Routes'
    }
  },
  RAMS_LRS: {
    url: `${RAMS}/RCE_Workspaces/Proj_Scoping_Service_RAMS_PROJ/MapServer/exts/LRSServer`,
    layers: {
      PROJECT_SCOPING: 'Project Scoping'
    }
  },
  RAMS_LRS_MAPSERVER: {
    url: `${RAMS}/RCE_Workspaces/Proj_Scoping_Service_RAMS_PROJ/MapServer`,
    layers: {
      PROJECT_SCOPING: 'Project Scoping'
    }
  },
  RAMS_PUBLIC_LRS: {
    url: `${RAMS}/lrs/MapServer`,
    layers: {
      COUNTY_NUMBER: 'County Number',
      IOWA_LRS_NETWORK: 'IOWA_LRS_NETWORK',
      PROJECT_SCOPING: 'Project Scoping',
      REFERENCE_POSTS: 'Reference Posts'
    }
  },
  RAMS_PUBLIC_LRS_FEATURE_SERVER: {
    url: `${RAMS}/lrs/FeatureServer`,
    layers: {
      IOWA_LRS_NETWORK: 'IOWA_LRS_NETWORK',
      PROJECT_SCOPING: 'Project Scoping',
      REFERENCE_POSTS: 'Reference Posts'
    }
  },
  COUNTY_VIEW: {
    url: 'https://services.arcgis.com/8lRhdTsQyJpO52F1/ArcGIS/rest/services/County_View/FeatureServer',
    layers: {
      IOWA_COUNTIES: 'Iowa Counties'
    }
  },
  DISTRICT_VIEW: {
    url: 'https://services.arcgis.com/8lRhdTsQyJpO52F1/arcgis/rest/services/Transportation_District_View/FeatureServer/0'
  },
  SKY_FIRE_GET_REF_POS: {
    url: 'https://fme.iowadot.gov/fmedatastreaming/SkyFire/getRefPostJSON.fmw'
  },
  FME: {
    prodServer: {
      url: 'https://fme.iowadot.gov'
    },
    testServer: {
      url: 'https://fmetest.iowadot.gov'
    },
    endpoints: {
      jobStatus: 'fmerest/v3/transformations/jobs/id/'
    },
    repositories: {
      prioritization: 'Project Prioritization'
    },
    workspaces: {
      download: 'SCOPING_PROJECT_PACKAGING.fmw',
      downloadBatch: 'SCOPING_PROJECT_PACKAGING_BATCH.fmw',
      impacts: 'ProjectPrio_SCOPING_Impacts_OnDemand.fmw',
      impactsNepa: 'ProjectPrio_SCOPING_NEPAImpacts_OnDemand.fmw',
      priority: 'ProjectPrio_SCOPING_Prioritization_OnDemand.fmw',
      highwayCandidates: 'ProjectPrio_HwyCandidate_Download.fmw'
    }
  },
  AWS: {
    ATTACHMENTS: {
      url: 'https://3ws2y45xd3.execute-api.us-east-1.amazonaws.com/prod',
      apiKey: 'fEegnYTMBi73Bhh9lVCZv2XowlYOjv1q32AtEDco',
      s3Url: 'https://attachments-prod.iowadotprojectpriorities.com'
    },
    CHARTER: {
      DEV: {
        url: 'https://rd41lnpss4.execute-api.us-east-1.amazonaws.com/dev',
        apiKey: 'EBX5ddBhnI3veQ9CrjcGgaofxJ7r9fk71pMYPOZZ'
      },
      PROD: {
        url: 'https://4h85pw7zj4.execute-api.us-east-1.amazonaws.com/prod',
        apiKey: 'Searvdjfjk2gCHinNl7H43M3ZdG9EGjo93aUqdpn'
      }
    },
    EMAIL: {
      // REMOVED
      // DEV: {
      //   url: 'https://a5cluiz7vi.execute-api.us-east-1.amazonaws.com/dev/publish-email-topic',
      //   apiKey: 'gp9uquhHCZ50R8vcOj3TDadz8p0fDKwX1CfBvn2e'
      // },
      PROD: {
        url: 'https://3gf42gnc2l.execute-api.us-east-1.amazonaws.com/prod/publish-email-topic',
        apiKey: 'FdZv2iWaXPaHzgskNWVBTfkuz6BHHhl4grlPxtZf'
      }
    },
    FME_TOKEN: {
      url: 'https://jhm51c7pv0.execute-api.us-east-1.amazonaws.com/prod/fme_token',
      apiKey: 'SJHCn9tLhe1nvuEa4K9S1DUCCIbTJHA1qOxlP47b'
    },
    HASH: {
      url: 'https://dsxlcvqrai.execute-api.us-east-1.amazonaws.com/prod',
      apiKey: 'aTuHthrDpo6IY8MQpI22H7IzKFrQQywG5XNjF3LN'
    },
    USERS: {
      url: 'https://m8wwn2ry5l.execute-api.us-east-1.amazonaws.com/prod',
      apiKey: 'qW2j9127pBCxgtFVQKoQ68r17b4hpkl5GH5zYSv8'
    },
  }
};

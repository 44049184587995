import { Injectable } from '@angular/core';
import { filter, skip } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { RxLayer } from '../../rx-layer/rx-layer';
import { ConfigTableService, PinImpactLayerConfigEntry } from './config-table.service';
import { GENERAL_EXCLUDE_FIELDS, LAYER_SPECIFIC_EXCLUDE_FIELDS } from '../../rx-layer/exclude-fields';
import { RxLayerOptions } from '../../rx-layer/rx-layer-options';
import { MapService } from '../map/map.service';
import { PinsRxLayerService } from './pins-rx-layer.service';
import { ImpactCounts } from '../export-and-doc/pininfo';
import { ImpactAuditService } from '../impact-audit.service';
import { LoggingService } from '../logging.service';
import { esriRequest } from 'src/esri/request';
import { FormDataService } from '../form-data.service';
import { AppLayoutService } from '../app-layout.service';

export class ImpactRxLayers {
  location: RxLayer[];
  safety: RxLayer[];
  traffic: RxLayer[];
  environmental: RxLayer[];
  waterway: RxLayer[];
  route: RxLayer[];
}

interface ImpactStat {
  label: string;
  unit: string;
  value: number;
}

export interface ImpactStats {
  totalArea?: ImpactStat;
  totalLength?: ImpactStat;
}

export interface ImpactRxLayer {
  rxLayer: RxLayer;
  query: string;
  category: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImpactRxLayersService {

  private readonly fNameImpactArea = 'IMPACT_AREA_ACRES';
  private readonly fNameImpactLength = 'IMPACT_AREA_MILES';

  private _pinSelectionHasImpact = new BehaviorSubject<boolean>(null);
  pinSelectionHasImpact$ = this._pinSelectionHasImpact.asObservable();

  private _layers = new BehaviorSubject<ImpactRxLayers>(null);
  layers$ = this._layers.asObservable();

  private _allLayers = new BehaviorSubject<ImpactRxLayer[]>(null);
  allLayers$ = this._allLayers.asObservable();

  private config: PinImpactLayerConfigEntry[] = [];

  constructor(
    private appLayoutService: AppLayoutService,
    private configTableService: ConfigTableService,
    private mapService: MapService,
    private pinsRxLayerService: PinsRxLayerService,
    private impactAuditService: ImpactAuditService,
    private logging: LoggingService,
    private formDataService: FormDataService,
  ) {
    this.init();
  }

  private init() {
    this.mapService.mapAssets$.pipe(
      filter(assets => assets !== null && assets !== undefined)
    ).subscribe(async (assets) => {
      const map = assets.map;
      this.config = await this.configTableService.pinImpactLayerConfigPromise;

      const result: ImpactRxLayers = {
        location: [],
        safety: [],
        traffic: [],
        environmental: [],
        waterway: [],
        route: []
      };

      const sortedConfig = this.config.sort((a, b) => a.SORT_ORDER > b.SORT_ORDER ? 1 : -1);

      const allLayers = [];
      sortedConfig.forEach(x => {
        let hideFields = GENERAL_EXCLUDE_FIELDS;
        if (LAYER_SPECIFIC_EXCLUDE_FIELDS[x.TITLE]) {
          hideFields = hideFields.concat(LAYER_SPECIFIC_EXCLUDE_FIELDS[x.TITLE]);
        }
        const rxLayerParams: RxLayerOptions = {
          map,
          title: x.TITLE,
          visible: false,
          url: x.ENDPOINT_URL
            .replace('/private/', '/agshost/')
            .replace('/ProjectPriorities_PIN_Impacts/', '/Project_Priority_PIN_Impacts/')
            .split('/query?')[0],
          baseWhereClause: '1=2',
          hideFields,
          displayColumns: x.DEFAULT_COLUMNS ? x.DEFAULT_COLUMNS.split(',').map(y => y.trim()).filter(z => z.length) : null
        };
        const categoryKey = [
          'location',
          'environmental',
          'waterway',
          'route',
          'traffic',
          'safety'
        ][x.CATEGORY];

        const rxLayer = new RxLayer(rxLayerParams);
        result[categoryKey].push(rxLayer);
        allLayers.push({ rxLayer, query: x.QUERY, category: categoryKey });
      });
      this._allLayers.next(allLayers);


      this.pinsRxLayerService.selection.pipe(skip(1)).subscribe(async (s) => {
        // check for impact audit - these get wiped on save if the user changes a buffer distance
        if (s && s.pin) {
          const hasImpact = await this.impactAuditService.hasImpactAudit(s.pin);
          this._pinSelectionHasImpact.next(hasImpact);
        }
        this._allLayers.getValue().forEach(x => {
          const q = typeof x.query === 'string' ? x.query.trim() : null;
          const wc = (s && s.pin && this._pinSelectionHasImpact.value)
            ? (q ? `(${q}) and PIN = '${s.pin}'` : `PIN = '${s.pin}'`)
            : '1=2';
          if (wc !== x.rxLayer.baseWhereClause.value) {
            x.rxLayer.baseWhereClause.next(wc);
          }
          x.rxLayer.tableData.refresh();
        });
      });

      this.appLayoutService.layoutMode$.pipe(skip(1)).subscribe(layout => {
        this._allLayers.getValue().forEach(x => {
          if (layout === 'table' && x.rxLayer.visible.value) {
            x.rxLayer.visible.setValue(false);
          }
        });
      });

      this._layers.next(result);
    });
  }

  getLayerConfig(title: string): PinImpactLayerConfigEntry {
    if (!this.config) {
      throw new Error(`config not ready, try subscribing to config$ instead
      -> this.config = ${this.config}`);
    }
    return this.config.find(c => c.TITLE.toLocaleLowerCase() === title.toLocaleLowerCase());
  }

  getLayers(): ImpactRxLayers {
    if (!this._layers.value) {
      throw new Error(`layers are not ready, try subscribing to layer$ instead
      -> this._layers.value = ${this._layers.value}`);
    }
    return this._layers.value;
  }

  getCountsForPin(pin: string): Promise<ImpactCounts[]> {
    const promises = this._allLayers.getValue().map(async x => {
      const where = (typeof x.query === 'string' && x.query.trim().length)
        ? `(${x.query.trim()}) and PIN = '${pin}'`
        : `PIN = '${pin}'`;

      const count = await x.rxLayer.queryCount({
        where,
        returnCountOnly: true,
        f: 'json'
      });

      return {
        title: x.rxLayer.title,
        count,
        category: x.category
      };
    });
    return Promise.all(promises);
  }

  async getStats(layer: RxLayer): Promise<ImpactStats> {
    const fields = await layer.visibleFieldsPromise;
    const statDefs = [];

    //get defs / check for fields
    const { fNameStatTotalA, statsArea } = this.getStatDefArea(fields);
    const { fNameStatTotalL, statsLength } = this.getStatDefLength(fields);

    // add stats if they exist
    if (statsArea) {
      statDefs.push(statsArea);
    }

    if (statsLength) {
      statDefs.push(statsLength);
    }

    // return empty stats if we don't have fields
    if (statDefs.length === 0) {
      console.warn('Failed to get stats defs, check field names');
      return {};
    }

    // query
    const url = `${layer.url}/query`;
    const res = await esriRequest(url, {
      body: this.formDataService.toFormData({
        where: layer.baseWhereClause.value,
        returnGeometry: 'false',
        outFields: ['*'],
        f: 'json',
        outStatistics: statDefs
      }),
      responseType: 'json',
      method: 'post'
    });
    // handle results
    const stats = this.getStatResults(res, fNameStatTotalA, fNameStatTotalL);
    return stats;
  }

  private getStatResults(res: any, fNameStatTotalA, fNameStatTotalL): ImpactStats {
    const stats: ImpactStats = {};
    // handle features
    if (res && res.data.features) {
      const valTotalA = res.data.features[0].attributes[fNameStatTotalA];
      const valTotalL = res.data.features[0].attributes[fNameStatTotalL];
      if (valTotalA) {
        stats.totalArea = {
          label: 'Total Area Within Impact Zone',
          value: res.data.features[0].attributes[fNameStatTotalA],
          unit: 'Acre/s'
        };
      }
      if (valTotalL) {
        stats.totalLength = {
          label: 'Total Length Within Impact Zone',
          value: res.data.features[0].attributes[fNameStatTotalL],
          unit: 'Mile/s'
        };
      }
    } else {
      console.error('Query didn\'t return any features', res);
    }
    return stats;
  }

  private getStatDefArea(fields: __esri.Field[]) {
    const hasField = fields.filter(f => f.name.toLowerCase() === this.fNameImpactArea.toLowerCase()).length > 0 ? true : false;
    if (!hasField) {
      return {};
    }
    const fNameStatTotalA = `${this.fNameImpactArea}_TOTAL`;
    const statsArea = {
      onStatisticField: this.fNameImpactArea,
      outStatisticFieldName: fNameStatTotalA,
      statisticType: 'sum'
    };
    return {
      fNameStatTotalA, statsArea
    };
  }

  private getStatDefLength(fields: __esri.Field[]) {
    const hasField = fields.filter(f => f.name.toLowerCase() === this.fNameImpactLength.toLowerCase()).length > 0 ? true : false;
    if (!hasField) {
      return {};
    }
    const fNameStatTotalL = `${this.fNameImpactLength}_TOTAL`;
    const statsLength = {
      onStatisticField: this.fNameImpactLength,
      outStatisticFieldName: fNameStatTotalL,
      statisticType: 'sum'
    };
    return {
      fNameStatTotalL, statsLength
    };
  }


}

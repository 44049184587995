import { Injectable } from '@angular/core';
import { ScopeIdService } from '../scope-id.service';
import { PinsRxLayerService } from '../layer/pins-rx-layer.service';
import { ScopeSaveDeleteRequestsService } from './scope-save-delete.service.requests';
import { BehaviorSubject } from 'rxjs';
import { AzureAD } from 'src/init/azure-ad';
import { RouteNameService } from '../route-name.service';
import { CountyService } from '../county.service';
import { RefPostService } from '../ref-post.service';
import { HighwayClassService } from '../highway-class.service';
import { RouteAndMeasure } from '../layer/route-and-measure.service';
import { ApprovalPolicyFactory } from 'src/app/scope-approval/approval-policy-factory';
import { PinAttributes } from '../amalgamator/pin-attributes';
import { BridgeConversionService } from 'src/app/services/bridge-conversion.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { environment } from 'src/environments/environment';
import { DialogNoticeService } from 'src/app/services/dialog-notice.service';
import { RamsProjectScopingAttributes, RamsRequestsService } from 'src/app/services/rams-requests.service';
import { GuidService } from 'src/app/services/guid.service';
import { OnDemandFMEService } from 'src/app/services/on-demand-fme.service';
import { ProjectInfoService } from 'src/app/services/project-info.service';
import { LRS_SPATIAL_REF_WKID } from '../layer/lrs.service';
import { filter, take } from 'rxjs/operators';
import { PriorityImpactService } from '../priority-impact-service';
import { RxJsLoggingLevel } from 'src/app/functions/rxjs-debug';

class ScopeSaveParams {
  attributes: PinAttributes;
  writeGeometry: boolean;
  FUNDING_DESCRIPTIONS?: { [PROGRAMTYPE: string]: string };
  PROJECT_PURPOSE_DESCS?: { [PROJECTTYPE: string]: string };
  routesAndMeasures?: RouteAndMeasure[];
  isPlanningScenario?: boolean;
}

class PinSaveParams {
  attributes: PinAttributes;
  writeGeometry: boolean;
  PROJECT_PURPOSE_DESCS?: { [PROJECTTYPE: string]: string };
  routesAndMeasures?: RouteAndMeasure[];
}

@Injectable({
  providedIn: 'root'
})
export class ScopeSaveDeleteService {

  isAdmin: boolean;
  // CRUD status messages
  private _saveComplete = new BehaviorSubject<boolean>(false);
  saveComplete$ = this._saveComplete.asObservable();
  private _deleteComplete = new BehaviorSubject<boolean>(false);
  deleteComplete$ = this._deleteComplete.asObservable();

  constructor(
    private scopeIdService: ScopeIdService,
    private pinsRxLayerService: PinsRxLayerService,
    private scopeSaveDeleteRequestsService: ScopeSaveDeleteRequestsService,
    private ramsRequestsService: RamsRequestsService,
    private onDemandFme: OnDemandFMEService,
    private routeNameService: RouteNameService,
    private countyService: CountyService,
    private refPostService: RefPostService,
    private highwayClassService: HighwayClassService,
    private approvalPolicyFactory: ApprovalPolicyFactory,
    private bridgeConversionService: BridgeConversionService,
    private userInfoService: UserInfoService,
    private dialogNoticeService: DialogNoticeService,
    private guidService: GuidService,
    private projectInfoService: ProjectInfoService,
    private priorityImpactService: PriorityImpactService
  ) {
    this.isAdmin = this.userInfoService.isAdmin;
  }

  save(saveParams: ScopeSaveParams): { progress?: BehaviorSubject<string>; PIN?: Promise<string>; abort?: boolean } {
    const progFunding = this.getArrayFields(saveParams.attributes).progFunding;
    // check for funding
    if (progFunding.length < 1) {
      this.dialogNoticeService.alert(
        {
          title: 'UNABLE TO SAVE',
          message: 'At least one Program Funding type (Project Tab) must be selected.',
          hasOk: true
        });
      return {
        abort: true
      };
    }
    // check for just OT funding type...another needs to be selected as well
    if (progFunding.length === 1 && progFunding[0] === 6) {
      this.dialogNoticeService.alert({
        title: 'UNABLE TO SAVE',
        hasOk: true,
        message: 'You selected just "OT" funding type. At least one other Program Funding type (Project Tab) must be selected as well.'
      });
      return {
        abort: true
      };
    }
    // check for district
    if (saveParams.attributes.DISTRICT === null || saveParams.attributes.DISTRICT === undefined) {
      this.dialogNoticeService.alert({
        title: 'UNABLE TO SAVE',
        message: 'A District (Project Tab) must be selected.',
        hasOk: true
      });
      return {
        abort: true
      };
    }
    const progress = new BehaviorSubject<string>('');
    return {
      progress,
      PIN: (async () => {
        const pr = saveParams.routesAndMeasures ? saveParams.routesAndMeasures.find(x => x.IS_PRIMARY) : null;
        if (pr) {
          saveParams.attributes.READABLE_PRIMARY_ROUTE = await this.routeNameService.getCommonNamePromise(
            pr.ROUTE_ID,
            pr.FROM_MEASURE,
            pr.TO_MEASURE
          );
          saveParams.attributes.HIGHWAY_CLASS = await this.highwayClassService.getHighwayClass(pr.ROUTE_ID);
          const [from, to] = await Promise.all([
            this.refPostService.getReferencePost(pr.ROUTE_ID, pr.FROM_MEASURE, { useCache: false }),
            this.refPostService.getReferencePost(pr.ROUTE_ID, pr.TO_MEASURE, { useCache: false })
          ]);
          if (from && Object.prototype.hasOwnProperty.call(from, 'measure')) {
            saveParams.attributes.BEGIN_MILEPOST = String(from.repostMileRound);
          }
          if (to && Object.prototype.hasOwnProperty.call(to, 'measure')) {
            saveParams.attributes.END_MILEPOST = String(to.repostMileRound);
          }
        } else if (saveParams.attributes.PRIMARY_ROUTE) {
          saveParams.attributes.READABLE_PRIMARY_ROUTE = await this.routeNameService.getCommonNamePromise(
            saveParams.attributes.PRIMARY_ROUTE
          );
          saveParams.attributes.HIGHWAY_CLASS = await this.highwayClassService.getHighwayClass(saveParams.attributes.PRIMARY_ROUTE);
        }
        return this._save(saveParams, progress);
      })(),
      abort: false
    };
  }

  savePin(saveParams: PinSaveParams) {
    const progress = new BehaviorSubject<string>('');
    return {
      progress,
      PIN: <Promise<string>>this._savePin(saveParams, progress)
    };
  }

  delete(pin: string) {
    const progress = new BehaviorSubject<string>('');
    return {
      progress,
      PIN: <Promise<string>>this._delete(pin, progress)
    };
  }

  async deleteMultiple(pinAttrs: PinAttributes[]) {
    const progress = new BehaviorSubject<string>('');
    // check if the user can delete pins
    const canDelete = await this._canDelete(pinAttrs);
    if (!canDelete.delete) {
      progress.error(`ACCESS DENIED - CANNOT DELETE SCOPES: ${canDelete.pins.map(m => m).join()}`);
      return {
        progress,
        abort: true
      };
    } else {
      const deletes = [];
      progress.next('Deleting Scopes');
      pinAttrs.forEach((p, index) => {
        // last item, call reconcile, otherwise don't reconcile
        if (index === pinAttrs.length - 1) {
          deletes.push(this._delete(p.PIN, progress, true));
        } else {
          deletes.push(this._delete(p.PIN, progress, false));
        }
      });
      if (environment.isLocalHost && environment.loggingLevel === RxJsLoggingLevel.DEBUG) {
        console.log(`starting multiple delete..${pinAttrs.length} scopes to delete`);
      }
      const res = await Promise.all(deletes);
      return {
        progress,
        PINS: res
      };
    }
  }

  getArrayFields(attributes) {
    const getArray = (key: string, altKey?: string) => {
      let val;
      if (typeof key === 'string' && Object.prototype.hasOwnProperty.call(attributes, key)) {
        val = attributes[key];
      } else if (typeof altKey === 'string') {
        val = attributes[altKey];
      } else {
        val = [];
      }
      if (typeof val === 'string') {
        return val.split(',').map(x => x.trim()).filter(x => x.length);
      } else if (typeof val === 'object' && Object.prototype.hasOwnProperty.call(val, 'length')) {
        return val;
      } else {
        return [];
      }
    };
    return {
      workGroups: getArray('WORK_GROUPS'),
      workTypes: getArray('WORK_TYPES'),
      progFunding: getArray('PROGRAM_FUNDING', 'PROGRAM_TYPES'),
      projPurpose: getArray('PROJECT_PURPOSE', 'PROJECT_TYPES'),
      counties: getArray('COUNTIES'),
      routes: getArray('ROUTES')
    };
  }

  private async _canDelete(pinAttrs: PinAttributes[]) {
    const userName = this.userInfoService.userInfo.value.username;
    // check for pins - DO THIS FIRST
    const pins = pinAttrs.filter(p => this.projectInfoService.isPin(p.PIN));
    if (pins.length > 0) {
      const msg = pins.length > 1 ?
        `${pins.map(m => m.PIN).join(', ')} are PINs and cannot be deleted.` :
        `${pins.map(m => m.PIN).join(', ')} is a PIN and cannot be deleted.`;
      await this.dialogNoticeService.error({ title: 'DELETE ERROR', message: msg });
      return {
        delete: false,
        pins: pinAttrs.map(m => m.PIN)
      };
    }
    // if user is Admin
    if (this.isAdmin) {
      return {
        delete: true,
        pins: null
      };
    } else {
      // if not admin, filter projects to check if user created the project
      const notOwned = pinAttrs.filter(p => {
        return p.USERNAME.toLowerCase() !== userName.toLowerCase();
      });
      const ownershipIssue = notOwned.length > 0 ? true : false;
      if (ownershipIssue) {
        const msg = notOwned.length === 1 ?
          `${notOwned.map(m => m.PIN).join(', ')} was not created by you.  You can't delete the scope unless you have the 'Admin' role.` :
          `${notOwned.map(m => m.PIN).join(', ')} were not created by you.  You can't delete the scopes unless you have the 'Admin' role.`;
        await this.dialogNoticeService.error({ title: 'DELETE ERROR', message: msg });
      }
      return {
        delete: !ownershipIssue,
        pins: notOwned.map(m => m.PIN)
      };
    }
  }

  private async _save(saveParams: ScopeSaveParams, progress: BehaviorSubject<string>) {
    const isNew = typeof saveParams.attributes.PIN !== 'string';
    const prefix = `${isNew ? 'Creating' : 'Updating'} scope: `;

    progress.next(prefix + 'validating attributes...');

    const v = this.validation(saveParams.attributes);
    if (!v.valid) {
      progress.next(v.message);
      await this.dialogNoticeService.error({ title: 'SAVE ERROR', message: v.message });
      return;
    }

    // get attributes object to post to main Project Scoping table
    const projScopeObj = await this.createProjectScopingObject(saveParams.attributes);
    const arrayVals = this.getArrayFields(saveParams.attributes);

    if (isNew) { // set defaults for new
      projScopeObj.STATUS = 'Scoping';
      projScopeObj.USERNAME = AzureAD.user.email;
      progress.next(prefix + 'generating scope id...');
      projScopeObj.PIN = await this.scopeIdService.generateScopeID(saveParams.attributes.PRIMARY_ROUTE, saveParams.isPlanningScenario);
      projScopeObj.PIN_ID = this.scopeIdService.getNumericScopeID(projScopeObj.PIN);
    }

    // make sure to set/update next approver...that way if funding type changes this will get updated
    projScopeObj.NEXT_APPROVER = arrayVals.progFunding.length
      ? this.approvalPolicyFactory.getFirstApprovers(projScopeObj.PIN, saveParams.attributes.DISTRICT, arrayVals.progFunding.join(','))
      : '';

    const PIN: string = projScopeObj.PIN;
    const PIN_ID: number = projScopeObj.PIN_ID;

    const r = this.scopeSaveDeleteRequestsService;

    progress.next(prefix + 'saving primary attributes...');
    const projScopeMethod = isNew ? '/addFeatures' : '/updateFeatures';
    if (!isNew) {
      projScopeObj.OBJECTID = await r.getObjectId(PIN);
    }
    await r.saveProjectScopingAttributes(projScopeObj, projScopeMethod);

    const isBridgeRequest = Object.prototype.hasOwnProperty.call(saveParams.attributes, 'FROM_MEASURE')
      && Object.prototype.hasOwnProperty.call(saveParams.attributes, 'TO_MEASURE');
    // handle bridges
    if (isBridgeRequest && saveParams.writeGeometry) {
      let FROM_MEASURE = saveParams.attributes['FROM_MEASURE'];
      let TO_MEASURE = saveParams.attributes['TO_MEASURE'];
      if (FROM_MEASURE === TO_MEASURE) {
        FROM_MEASURE -= 0.00001;
        TO_MEASURE += 0.00001;
      }
      const rm: RouteAndMeasure = {
        ROUTE_ID: saveParams.attributes.PRIMARY_ROUTE,
        FROM_MEASURE: FROM_MEASURE,
        TO_MEASURE: TO_MEASURE,
        PATHS: null,
        WKID: LRS_SPATIAL_REF_WKID,
        TMP_GUID: this.guidService.createGuid(),
        SELECTED: true,
        LOADING: false,
        IS_PRIMARY: true,
        JUST_CREATED: true,
      };
      saveParams.routesAndMeasures = [rm];
      progress.next(prefix + 'writing geometry...');
      // Story 1806 Migrate Geometry into RAMS
      await this.ramsRequestsService.saveGeometryForScopeFromBridgeView(saveParams.attributes, PIN);
    }

    // Scopes / PINS created outside the bridge conversion dialog
    if (!isBridgeRequest && saveParams.writeGeometry) {
      await this._writeGeometry(isNew, PIN, saveParams).catch(error => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      });
    }

    if (!isNew) { // if not new, clear relational tables for the given PIN
      progress.next(prefix + 'clearing previous lookup values...');
      await r.deleteLookups(PIN);
    }

    progress.next(prefix + 'writing lookup values...');
    const writes = []; // promises for write requests
    if (arrayVals.workGroups.length) {
      writes.push(r.saveWorkGroups(arrayVals.workGroups, PIN, PIN_ID));
    }
    if (arrayVals.workTypes.length) {
      writes.push(r.saveWorkTypes(arrayVals.workTypes, PIN, PIN_ID));
    }
    if (arrayVals.projPurpose.length) {
      writes.push(r.saveProjectPurposes(arrayVals.projPurpose, PIN, PIN_ID, saveParams.PROJECT_PURPOSE_DESCS));
    }
    if (arrayVals.progFunding.length) {
      writes.push(r.saveProgramFunding(arrayVals.progFunding, PIN, PIN_ID, saveParams.FUNDING_DESCRIPTIONS));
    }
    // get counties for projects created outside bridge conversion dialog
    const counties = await this.countyService.getCountiesFromRoutesAndMeasures(saveParams.routesAndMeasures);
    if (counties.length) {
      const countyNames = counties.map(x => x.name);
      writes.push(r.saveCounties(countyNames, PIN, PIN_ID));
    }

    await Promise.all(writes);

    progress.next(`Scope ${isNew ? 'created' : 'updated'}: ${PIN}, refreshing attributes...`);
    await this.pinsRxLayerService.refreshPin(PIN);
    progress.next(`Scope ${isNew ? 'created' : 'updated'}: ${PIN}`);

    // do this last, trigger impact job for new scope.  This is to add a check for LEB NEPA during approval
    if (isNew) {
      progress.next('running initial impacts...');
      (await this.onDemandFme.runImpactsNEPA([PIN])).pipe(
        // tap(res => console.log('FME NEPA Impacts ->', res)),
        filter(res => res.toLowerCase() === 'success'),
      ).subscribe(() => this.runPriorityImpactReport(PIN));
    }
    this._saveComplete.next(true);
    return PIN;
  }

  private runPriorityImpactReport(pin) {
    // check for priority impacts like LRTP / Rail crossings / flooding etc
    this.priorityImpactService.runReport([pin]).subscribe(report => {
      return; // there was a console log here, but it was annoying. I'm not sure if I can remove the whole subscribe tho.
    });
  }

  private async _writeGeometry(isNew, PIN, saveParams) {
    const isPin = this.projectInfoService.isPin(PIN);
    const canSave = saveParams.routesAndMeasures.length > 0 ? true : false;
    if (canSave) {
      // handle a PIN geom update for Admin User
      if (isPin && this.isAdmin) {
        const scopeID = saveParams.attributes.PROJECT_SCOPE_ID;
        // time for effective start
        const today = new Date(new Date().toDateString());
        today.setUTCHours(0);
        const attributes: RamsProjectScopingAttributes = {
          PSS_PIN_ID: PIN,
          PROJECT_SCOPE_ID: scopeID,
          EFFECTIVE_START_DATE: +today,
          FROM_MEASURE: saveParams.routesAndMeasures[0].FROM_MEASURE,
          TO_MEASURE: saveParams.routesAndMeasures[0].TO_MEASURE
        };
        // Story 1806 Migrate Geometry into RAMS
        console.log('TO DO, filter routes and measures and only add new ones...');
        return await this.ramsRequestsService.updateAttributes(scopeID, attributes, PIN);
        /* WARNING - During Testing it was possibly to delete geometry by accident - DO NOT USE THIS, LEFT FOR REFERENCE
        await this.ramsRequestsService.CAUTION_deleteGeometryForPIN(PIN);
        return await this.ramsRequestsService.addGeometry(scopeID, saveParams.routesAndMeasures, PIN);
        */
      }
      // handle a previous SCOPE / delete then add geom again
      if (!isNew && !isPin) {
        // Story 1806 Migrate Geometry into RAMS
        await this.ramsRequestsService.deleteGeometryForScopeId(PIN);
        return await this.ramsRequestsService.addGeometry(PIN, saveParams.routesAndMeasures);
      }
      // handle new geometry
      if (isNew) {
        // Story 1806 Migrate Geometry into RAMS
        return await this.ramsRequestsService.addGeometry(PIN, saveParams.routesAndMeasures);
      } else {
        return new Promise(async (res, reject) => {
          const msg = 'UNABLE TO WRITE GEOMETRY';
          console.error(msg);
          await this.dialogNoticeService.error({ title: 'SAVE ERROR', message: msg });
          reject(msg);
        });
      }
    } else {
      return new Promise(async (res, reject) => {
        const msg = 'UNABLE TO SAVE';
        console.error(msg);
        await this.dialogNoticeService.error({ title: 'SAVE ERROR', message: msg });
        reject(msg);
      });
    }
  }

  private async _savePin(saveParams: PinSaveParams, progress: BehaviorSubject<string>) {
    const prefix = 'Updating PIN: ';

    // get attributes object to post to main Project Scoping table
    const projScopeObj = await this.createProjectScopingObject(saveParams.attributes);

    const PIN = projScopeObj.PIN;
    const PIN_ID = projScopeObj.PIN_ID;

    const r = this.scopeSaveDeleteRequestsService;

    // update PIN geometry
    if (saveParams.writeGeometry) {
      progress.next(prefix + 'saving geometry...');
      await this._writeGeometry(false, PIN, saveParams).catch(error => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      });
    }

    progress.next(prefix + 'saving primary attributes...');
    const projScopeMethod = '/updateFeatures';
    projScopeObj.OBJECTID = await r.getObjectId(PIN);
    await r.saveProjectScopingAttributes(projScopeObj, projScopeMethod);

    progress.next(prefix + 'clearing previous project type lookup values...');
    await r.clearProjectTypesOnly(PIN);

    progress.next(prefix + 'writing project type lookup values...');
    const writes = []; // promises for write requests
    const arrayVals = this.getArrayFields(saveParams.attributes);

    if (arrayVals.projPurpose.length) {
      writes.push(r.saveProjectPurposes(arrayVals.projPurpose, PIN, PIN_ID, saveParams.PROJECT_PURPOSE_DESCS));
    }

    await Promise.all(writes);

    progress.next(`PIN  updated: ${PIN}, refreshing attributes...`);
    await this.pinsRxLayerService.refreshPin(PIN);
    progress.next(`PIN updated: ${PIN}`);
    return PIN;
  }

  private async _delete(PIN: string, progress: BehaviorSubject<string>, reconcile?: boolean) {
    const msgs = [];
    // call to reset the bridge conversion
    msgs.push('Resetting Bridge Table: ' + PIN);
    progress.next(msgs[0]);
    await this.bridgeConversionService.resetConversion(PIN);

    msgs.push('Deleting scope: ' + PIN);
    progress.next(msgs[1]);
    const r = this.scopeSaveDeleteRequestsService;
    const rams = this.ramsRequestsService; // Story 1806 Migrate Geometry into RAMS

    await Promise.all([
      r.deleteLookups(PIN, true),
      r.deleteProjectScopingAttributes(PIN),
      // Story 1806 Migrate Geometry into RAMS
      rams.deleteGeometryForScopeId(PIN, reconcile)
    ]);

    msgs.push('Scope deleted: ' + PIN);
    progress.next(msgs[2]);
    if (environment.isLocalHost && environment.loggingLevel === RxJsLoggingLevel.DEBUG) {
      console.log(`${msgs.map(m => m).join('\n')}`);
    }
    await this.pinsRxLayerService.refreshPin(PIN);
    this._deleteComplete.next(true);
    return PIN;
  }

  private validation(attributes) {
    if (!attributes.PRIMARY_ROUTE) {
      return {
        valid: false,
        message: 'The submitted scope/pin does not have a primary route.'
      };
    }
    // check for PIN and user not being an Admin
    if (attributes.PIN && this.projectInfoService.isPin(attributes.PIN) && !this.isAdmin) {
      return {
        valid: false,
        message: 'Saving is currently only permitted for scopes, not for PINs.'
      };
    }
    return {
      valid: true,
      message: null
    };
  }

  private async createProjectScopingObject(attributes: PinAttributes) {

    // PinAttributes is a combination of attributes from ProjectScoping and other tables.
    // this function extracts out just the ProjectScoping attributes

    const scans = await this.pinsRxLayerService.scans$.pipe(
      take(1)
    ).toPromise();

    const projScopeObj: any = {};
    if (scans) {
      scans.projectScoping.fields.forEach(f => {
        if (Object.prototype.hasOwnProperty.call(attributes, f.name)) {
          projScopeObj[f.name] = attributes[f.name];
        }
      });
      return projScopeObj;
    } else {
      return attributes;
    }

  }
}

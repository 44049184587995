import { Injectable } from '@angular/core';
import { PRE_SUBMIT_RULES } from './pre-submit-rules';
import { PinAttributes } from '../services/amalgamator/pin-attributes';
import { DetailsPaneFormSubjects } from '../details-pane/details-pane-form-subjects';


export interface ScopeReadyResult {
  pin: string;
  pass: boolean;
  failures: string[];
}

@Injectable({
  providedIn: 'root'
})
export class PreSubmitCheckService {

  constructor(
    private fs: DetailsPaneFormSubjects,
  ) {
  }

  validateScope(attrs: PinAttributes): ScopeReadyResult {
    const { selectOptions } = this.fs.PROGRAM_TYPES;
    const mpOption = selectOptions.value.find(option => option.alias === 'MP');
    const adaOption = selectOptions.value.find(option => option.alias === 'ADA');
    const isAbbreviatedProject = mpOption.value.toString() === attrs.PROGRAM_TYPES || adaOption.value.toString() === attrs.PROGRAM_TYPES;

    const failures: string[] = PRE_SUBMIT_RULES
      .filter(rule => isAbbreviatedProject ? rule.isAbbreviatedProject : rule.isNotAbbreviatedProject)
      .filter(rule => {
        const val = rule.valueGet(attrs);
        const pass = rule.valueCheck(val);
        return !pass;
      })
      .map(rule => `${rule.description} (${rule.associatedTab} tab)`);

    return {
      pin: attrs.PIN,
      pass: failures.length === 0,
      failures
    };
  }
}

export const ROUTE_SLICE_SYMBOL: any = {
  type: 'simple-line',
  color: 'rgba(0, 255, 0, 0.4)',
  width: '6px',
  style: 'solid'
};

export const ROUTE_SLICE_INCLUDED_SYMBOL: any = {
  type: 'simple-line',
  color: 'rgba(0, 192, 0, 0.8)',
  width: '6px',
  style: 'solid'
};

export const WHOLE_ROUTE_SYMBOL: any = {
  type: 'simple-line',
  color: 'rgba(64, 90, 40, 0.4)',//'rgba(64,128,64,.5)',
  width: '6px',
  style: 'solid'
};

export const FROM_POINT_SYMBOL: any = {
  type: 'simple-marker',
  color: 'rgba(76, 225, 80, 1)',
  size: 10,
  style: 'circle'
};

export const TO_POINT_SYMBOL: any = {
  type: 'simple-marker',
  color: 'rgba(244, 67, 54, 1)',
  size: 10,
  style: 'circle'
};

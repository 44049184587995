import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Slider
import { NgxSliderModule } from '@angular-slider/ngx-slider';

// Material Design
import { AppMaterialModule } from './material-module';
// End Material Design
import { FlexLayoutModule } from '@angular/flex-layout';

// Directives and Pipes
import { TutorialTargetDirective } from '../directives/tutorial-target.directive';
import { TutorialDialogTargetDirective } from '../directives/tutorial-dialog-target.directive';
import { ReplacePipe } from '../pipes/replace.pipe';
import { CallbackPipe } from '../pipes/callback.pipe';
import { MeterToMilePipe } from '../pipes/meter-to-mile.pipe';
import { TypeofPipe } from '../pipes/typeof.pipe';
import { CommaSeparatedFormatterPipe } from '../pipes/comma-separated-formatter.pipe';

@NgModule({
  declarations: [
    TutorialTargetDirective,
    TutorialDialogTargetDirective,
    ReplacePipe,
    CallbackPipe,
    MeterToMilePipe,
    TypeofPipe,
    CommaSeparatedFormatterPipe
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    NgxSliderModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
  ],
  exports: [
    CommonModule,
    AppMaterialModule,
    NgxSliderModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TutorialTargetDirective,
    TutorialDialogTargetDirective,
    RouterModule,
    ReplacePipe,
    CallbackPipe,
    MeterToMilePipe,
    TypeofPipe,
    CommaSeparatedFormatterPipe,
    FlexLayoutModule,
  ]
})
export class SharedModule {
}

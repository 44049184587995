import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, from } from 'rxjs';

import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { EndpointService } from './dynamic-endpoint/endpoint.service';
import { DialogService } from './dialog.service';
import { LookupService } from './lookup.service';
import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';
import { ImpactRxLayer, ImpactRxLayersService } from './layer/impact-rx-layers.service';
import { UtilityService } from './utility.service';
import { filter, take } from 'rxjs/operators';

export enum PriorityImpactLayerTitle {
  LRTPMobilitySafety = 'LRTP Mobility & Safety',
  RailCrossingProjects = 'Rail Crossing Projects',
  PreviousFloodingEvents = 'Previous Flooding Events',
  HistoricFloodingEvents = 'Historic Flooding Events (1997-2015)'
}

export interface PriorityImpactReport {
  PIN: string;
  PINCOUNT: number;
  layerTitle: PriorityImpactLayerTitle;
}

@Injectable({
  providedIn: 'root'
})
export class PriorityImpactService {

  private _report = new BehaviorSubject<PriorityImpactReport[]>(null);
  report$ = this._report.asObservable();

  private allLayers$ = new BehaviorSubject<ImpactRxLayer[]>(null);

  constructor(
    private endpointService: EndpointService,
    private lookupService: LookupService,
    private dialogService: DialogService,
    private impactRxLayersService: ImpactRxLayersService,
    private utilityService: UtilityService
  ) {
    this.impactRxLayersService.allLayers$.pipe(
      filter(layers => layers !== null && layers !== undefined),
      take(1)
    ).subscribe(layers => this.allLayers$.next(layers));
  }

  runReport(pins: string[], showIndividualDialogs = false, showReportDialog = true) {
    forkJoin([
      from(this.impactNoticeLRTPProject(pins, showIndividualDialogs)),
      from(this.impactNoticeRailCrossingProject(pins, showIndividualDialogs)),
      from(this.impactNoticeFlooding(pins, showIndividualDialogs))
    ]).subscribe(result => {
      const reports = [].concat([], result.filter(r => r.length > 0));
      const flatReports = this.utilityService.flattenArray(reports) as PriorityImpactReport[];
      this._report.next(flatReports);
      if (showReportDialog) {
        this.showReportDialog(flatReports);
      }
    });
    return this.report$;
  }

  showReportDialog(reports: PriorityImpactReport[]) {
    if (reports.length > 0) {
      this.dialogService.openPriorityImpactReportDialog(reports, `HIGH PRIORITY IMPACT NOTICES!`);
    }
  }

  async impactNoticeFlooding(pins: string[], showDialog = true) {
    const previousFloods = await this.getPriorityImpactReports(
      pins,
      PriorityImpactLayerTitle.PreviousFloodingEvents
    );
    const historicFloods = await this.getPriorityImpactReports(
      pins,
      PriorityImpactLayerTitle.HistoricFloodingEvents
    );
    const reports = previousFloods.concat(historicFloods);
    if (reports.length > 0 && showDialog) {
      await this.dialogService.openPriorityImpactDetailDialog(reports);
    }
    return reports;
  }

  async impactNoticeLRTPProject(pins: string[], showDialog = true) {
    const reports = await this.getLRTPReports(pins);
    if (reports.length > 0 && showDialog) {
      await this.dialogService.openPriorityImpactDetailDialog(reports);
    }
    return reports;
  }

  async getLRTPReports(pins: string[]): Promise<PriorityImpactReport[]> {
    // ignore these program types
    const ignoreTypes = ['MP', 'MB', 'BR', 'MI'];
    // get funding types
    const programTypes = await this.lookupService.getProgramTypes(pins);
    const notifyPins = programTypes.filter(f =>
      f.PROGRAM_TYPE_CODES.filter(p =>
        !ignoreTypes.includes(p)
      ).length > 0
    );
    if (notifyPins.length > 0) {
      const layer = this.getLayerByTitle(PriorityImpactLayerTitle.LRTPMobilitySafety);
      if (layer) {
        return (await this.queryImpactFeatureLayer(pins, layer.rxLayer.url)).map((r: PriorityImpactReport) => {
          r.layerTitle = PriorityImpactLayerTitle.LRTPMobilitySafety;
          return r;
        });
      } else {
        const urlsPromise = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
        console.warn(`Failed to find layer, check the layer title: ${PriorityImpactLayerTitle.LRTPMobilitySafety} in ${urlsPromise.PIN_IMPACT_LAYERS}`);
        return [];
      }
    }
    return [];
  }

  async impactNoticeRailCrossingProject(pins: string[], showDialog = true) {
    const reports = await this.getPriorityImpactReports(
      pins,
      PriorityImpactLayerTitle.RailCrossingProjects
    );
    if (reports.length > 0 && showDialog) {
      await this.dialogService.openPriorityImpactDetailDialog(reports);
    }
    return reports;
  }

  getRailCrossingProjectUrl() {
    const layer = this.getLayerByTitle(PriorityImpactLayerTitle.RailCrossingProjects);
    if (layer) {
      return layer.rxLayer.url;
    }
    return null;
  }

  private async getPriorityImpactReports(pins: string[], layerTitle: PriorityImpactLayerTitle): Promise<PriorityImpactReport[]> {
    const layer = this.getLayerByTitle(layerTitle);
    if (layer) {
      return (await this.queryImpactFeatureLayer(pins, layer.rxLayer.url)).map((r: PriorityImpactReport) => {
        r.layerTitle = layerTitle;
        return r;
      });
    } else {
      const urlsPromise = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
      console.warn(`Failed to find layer, check the layer title: ${layerTitle} in ${urlsPromise.PIN_IMPACT_LAYERS}`);
      return [];
    }
  }

  private async queryImpactFeatureLayer(pins: string[], layerUrl) {
    const pinList = pins.map(x => `'${x}'`).join(',');
    const sqlWhere = `PIN IN (${pinList})`;
    const statsDef = {
      statisticType: 'count',
      onStatisticField: 'PIN',
      outStatisticFieldName: 'PINCOUNT'
    } as any;

    const fl = new FeatureLayer({
      url: layerUrl
    });

    const res = await fl.queryFeatures({
      where: sqlWhere,
      returnGeometry: false,
      outFields: ['*'],
      groupByFieldsForStatistics: ['PIN'],
      outStatistics: [statsDef]
    });
    return res.features.map(f => f.attributes);
  }

  private getLayerByTitle(title: string) {
    const allLayers = this.allLayers$.getValue();
    if (allLayers) {
      return allLayers.find(x => x.rxLayer.title.toLowerCase() === title.toLowerCase());
    }
    return null;
  }

}

<div style="display: flex;">
  <div>
    <div class="display-name">{{ displayName }}</div>
    <div class="email">{{ email }}</div>
  </div>
  <div style="flex-grow:1"></div>
  <button mat-stroked-button color="primary" (click)="signOut()">Sign Out</button>
</div>
<div *ngIf="!isCommission">
  <div style="height: 12px;"></div>
  <div>
    <mat-form-field appearance="fill" class="slim" style="width: 100%">
      <mat-label>Email Groups</mat-label>
      <mat-select [formControl]="emailGroupsFs" multiple>
        <mat-option *ngFor="let group of emailGroups" [value]="group">{{ group }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div style="padding-top: 8px;display: flex;align-items: center; justify-content: center" *ngIf="loading | async">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
  <div style="padding-top: 8px;display: flex;flex-direction: row-reverse"
       *ngIf="(showSave | async) && !(loading | async)">
    <button mat-stroked-button color="primary" (click)="saveEmailGroupChanges()">Save</button>
    <div style="width: 8px;"></div>
    <button mat-stroked-button color="primary" (click)="discardEmailGroupChanges()">Discard Changes</button>
  </div>
  <div style="height: 12px;"></div>
  <div style="background: rgba(0, 0, 0, 0.04);padding: 8px 12px; border-radius: 4px">
    <div class="roles-title">Roles</div>
    <div *ngFor="let role of (roles | async)" class="role-name">{{ role }}</div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

interface NavLink {
  label: string;
  link: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navLinks: NavLink[];
  activeLink = '/';
  environmentName = null;

  constructor(
    private router: Router
  ) {
    this.navLinks = [
      {
        label: 'Home',
        link: '/',
      },
      {
        label: 'Dashboard',
        link: '/dashboard',
      }
    ];
  }

  ngOnInit(): void {
    this.environmentName = this.getEnvironmentName();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeLink = this.router.url;
      }
    });
  }

  navigate(route: string) {
    this.activeLink = route;
    if (route === '/') {
      this.router.navigate(['']);
    } else {
      this.router.navigate([route]);
    }
  }

  private getEnvironmentName() {
    if (environment.isLocalHost) {
      return 'LOCAL';
    }
    if (environment.isDev) {
      return 'DEV';
    }
    if (environment.isCommission) {
      return 'Commission';
    }
    if (environment.isUat) {
      return 'UAT';
    }
    // do this last
    if (environment.isProduction) {
      return 'Production';
    } else {
      return 'Unknown';
    }
  }


}

import { Injectable, SecurityContext } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { BasicDialogComponent, BasicDialogData } from 'src/app/dialogs/basic-dialog/basic-dialog.component';
import { ErrorDialogComponent } from 'src/app/dialogs/error-dialog/error-dialog.component';
import { WarnDialogComponent } from '../dialogs/warn-dialog/warn-dialog.component';
import { LazyDialogService } from './lazy-dialog.service';
import { ConfirmDialogData, ConfirmDialogMode } from '../types/confirm-dialog-mode';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'any'
})
export class DialogNoticeService {

  constructor(
    private dialog: MatDialog,
    private dom: DomSanitizer,
    private lazyDialogService: LazyDialogService
  ) {
  }

  async alert(config: BasicDialogData): Promise<MatDialogRef<BasicDialogComponent, any>> {
    const dialogConfig = this.configure(config);
    dialogConfig.data.title = config.title ? config.title : 'ALERT';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    // const dialogRef = this.dialog.open(BasicDialogComponent, dialogConfig);
    const dialogRef = await this.lazyDialogService.open('basic-dialog', dialogConfig);
    const htmlMessage = typeof config.htmlContent === 'string' ? config.htmlContent : '';
    dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlMessage);
    return dialogRef;
  }

  async error(config: BasicDialogData): Promise<MatDialogRef<ErrorDialogComponent, any>> {
    const dialogConfig = this.configure(config);
    dialogConfig.data.title = config.title ? config.title : 'ERROR';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    // const dialogRef = this.dialog.open(ErrorDialogComponent, dialogConfig);
    const dialogRef = await this.lazyDialogService.open('error-dialog', dialogConfig);
    const htmlMessage = typeof config.htmlContent === 'string' ? config.htmlContent : '';
    dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlMessage);
    return dialogRef;
  }

  async warn(config: BasicDialogData): Promise<MatDialogRef<WarnDialogComponent, any>> {
    const dialogConfig = this.configure(config);
    dialogConfig.data.title = config.title ? config.title : 'WARNING';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    //const dialogRef = this.dialog.open(WarnDialogComponent, dialogConfig);
    const dialogRef = await this.lazyDialogService.open('warn-dialog', dialogConfig);
    const htmlMessage = typeof config.htmlContent === 'string' ? config.htmlContent : '';
    dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlMessage);
    return dialogRef;
  }

  async confirm(config: ConfirmDialogData): Promise<MatDialogRef<ConfirmDialogComponent, any>> {
    const dialogConfig = this.configure(config);
    dialogConfig.data.title = config.title ? config.title : 'CONFIRM';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    //return this.dialog.open(ConfirmDialogComponent, dialogConfig);
    return await this.lazyDialogService.open('confirm-dialog', dialogConfig);
  }

  async confirmYesNo(config: ConfirmDialogData): Promise<MatDialogRef<ConfirmDialogComponent, any>> {
    const dialogConfig = this.configure(config);
    dialogConfig.data.mode = ConfirmDialogMode.modeYesNo;
    dialogConfig.data.title = config.title ? config.title : 'CONFIRM';
    dialogConfig.disableClose = true; // we don't want the user to close the dialog by clicking outside it..
    //return this.dialog.open(ConfirmDialogComponent, dialogConfig);
    return await this.lazyDialogService.open('confirm-dialog', dialogConfig);
  }


  private configure(config: BasicDialogData): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    const dialogWidth = +config.width > 0 ? +config.width : 500;
    const hasCancel = typeof config.hasCancel === 'boolean' ? config.hasCancel : false;
    const hasOk = typeof config.hasOk === 'boolean' ? config.hasOk : true;
    const okBtnText = typeof config.okBtnText === 'string' ? config.okBtnText : 'Ok';
    const cancelBtnText = typeof config.cancelBtnText === 'string' ? config.cancelBtnText : 'Cancel';
    // dialogConfig.width = `${dialogWidth}px`;
    // const dialogHeight = +config.height > 0 ? +config.height : 150;
    dialogConfig.data = {
      title: config.title,
      message: config.message,
      width: dialogWidth,
      hasCancel: hasCancel,
      hasOk: hasOk,
      okBtnText: okBtnText,
      cancelBtnText: cancelBtnText
      // height: dialogHeight
    };
    return dialogConfig;
  }


}

export class ProjectStage {
  // static access
  static readonly pinApproved = 'PIN_APPROVED';
  static readonly pinProgrammed = 'PIN_PROGRAMMED';
  static readonly scopeApproved = 'SCOPE_APPROVED';
  static readonly scopeInApproval = 'SCOPE_INAPPROVAL';
  static readonly scopePlanScenario = 'SCOPE_PLAN_SCENARIO';
  static readonly scopeScoping = 'SCOPE_SCOPING';

  // access via class instance
  readonly pinApproved: string;
  readonly pinProgrammed: string;
  readonly scopeApproved: string;
  readonly scopeInApproval: string;
  readonly scopePlanScenario: string;
  readonly scopeScoping: string;

  constructor() {
    this.pinApproved = ProjectStage.pinApproved;
    this.pinProgrammed = ProjectStage.pinProgrammed;
    this.scopeApproved = ProjectStage.scopeApproved;
    this.scopeInApproval = ProjectStage.scopeInApproval;
    this.scopePlanScenario = ProjectStage.scopePlanScenario;
    this.scopeScoping = ProjectStage.scopeScoping;
  }
}

import { BasicDialogData } from 'src/app/dialogs/basic-dialog/basic-dialog.component';

export class ConfirmDialogMode {
  static readonly modeYesNo = 'yesNo';

  constructor() {
    return;
  }
}

export interface ConfirmDialogData extends BasicDialogData {
  mode?: ConfirmDialogMode;
}

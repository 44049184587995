import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HNTB_ADMIN_STAFF, UserInfoService } from './user-info.service';
import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';

const EMAIL_LAMBDA_URL = `${ENDPOINT_CONFIG.AWS.EMAIL.PROD.url}`;
const API_KEY = `${ENDPOINT_CONFIG.AWS.EMAIL.PROD.apiKey}`;
const EMAIL_FROM = 'no-reply@mail.priorities.iowadotpi.com';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private headers = new HttpHeaders(
    {
      'X-Api-Key': API_KEY
    }
  );

  constructor(
    private userInfoService: UserInfoService,
    private httpClient: HttpClient
  ) {
  }

  sendEmail(toAddresses: string[], body: string, subject: string, CcAddresses?: string[], BccAddresses?: string[]) {
    try {
      const toAddressesClean = this.userInfoService.removeHntbStaffEmail(toAddresses);
      const ccAddressesClean = CcAddresses ? this.userInfoService.removeHntbStaffEmail(CcAddresses) : [];
      const email = {
        ToAddresses: toAddressesClean,
        CcAddresses: ccAddressesClean,
        BccAddresses: BccAddresses ? BccAddresses.concat(HNTB_ADMIN_STAFF) : HNTB_ADMIN_STAFF,
        BodyText: body,
        Subject: subject,
        FromAddress: EMAIL_FROM
      };
      const emailJson = JSON.stringify(email);
      return this.httpClient.post(EMAIL_LAMBDA_URL, emailJson, {
        headers: this.headers
      }).toPromise();
    } catch (err) {
      const src = 'file: email.service.ts ~ line 45 ~ EmailService ~ sendEmail';
      console.error(src, err);
      throw new Error(err);
    }
  }

  sendEmailToAdmins(body: string, subject: string) {
    try {
      const email = {
        ToAddresses: HNTB_ADMIN_STAFF,
        CcAddresses: null,
        BccAddresses: null,
        BodyText: body,
        Subject: subject,
        FromAddress: EMAIL_FROM
      };
      const emailJson = JSON.stringify(email);
      return this.httpClient.post(EMAIL_LAMBDA_URL, emailJson).toPromise();
    } catch (err) {
      const src = 'file: email.service.ts ~ line 54 ~ EmailService ~ sendEmailToAdmins';
      console.error(src, err);
      throw new Error(err);
    }
  }
}

import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

// ArcGIS Core
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

import { PinsRxLayerService } from './pins-rx-layer.service';
import { CLASS_BREAKS, SIMPLE_BLACK_RENDERER, SIMPLE_GRAY_RENDERER } from './pins-rx-layer.service.symbology';
import { MapService } from '../map/map.service';
import { SCORE_COLORS_SEMI_TRANSPARENT } from 'src/score-color-config';
import { environment } from 'src/environments/environment';
import { CurrentTabService, Tab } from 'src/app/details-pane/details-pane-body/current-tab.service';
import { LoggingService } from '../logging.service';
import { AppLayoutService } from '../app-layout.service';

@Injectable({
  providedIn: 'root'
})
export class PinsSelectionLayerService {
  defaultRenderer: any; // default renderer
  selectionLayer: any; // selection layer

  constructor(
    private appLayoutService: AppLayoutService,
    private pinsRxLayerService: PinsRxLayerService,
    private mapService: MapService,
    private currentTabService: CurrentTabService,
    private logging: LoggingService
  ) {
    this.init();
  }

  private async init() {

    this.mapService.mapAssets$.pipe(
      filter(assets => assets !== null && assets !== undefined),
    ).subscribe(assets => {
      // this.logging.log('🚀 ~ file: pins-selection-layer.service.ts ~ line 39 ~ PinsSelectionLayerService ~ this.mapService.mapAssets$.pipe ~ assets', assets);
      if (assets.map) {
        assets.map.add(this.selectionLayer);
      }
    });

    this.selectionLayer = this.getSelectionLayer();

    // store the default renderer
    this.defaultRenderer = this.selectionLayer.renderer;

    this.pinsRxLayerService.opacityBeforeSelection.valueStream.subscribe(x => {
      this.selectionLayer.opacity = x;
    });

    this.pinsRxLayerService.selection.subscribe(async selection => {
      const toDeletePromise = this.selectionLayer.queryFeatures();
      const toAdd = selection
        ? [
          {
            attributes: JSON.parse(selection.attributesJson),
            geometry: selection.geometry
          }
        ]
        : [];
      const toDelete = await toDeletePromise.then(x => x.features);
      if (toDelete.length || toAdd.length) {
        this.selectionLayer.applyEdits(<any>{
          deleteFeatures: toDelete,
          addFeatures: toAdd
        });
      }
    });

    // handle selection layer based on tab selection
    await this.renderSelectionLayer();
  }

  // render the selection layer based on current tab
  private renderSelectionLayer() {
    // apply rendering / display behavior based on details tab
    if (!environment.isCommission) {
      combineLatest([
        this.appLayoutService.layoutMode$,
        this.currentTabService.currentTab])
        .subscribe(async ([layout, tab]) => {
          // show the selection layer for all tabs but geometry
          if (!this.selectionLayer.visible && tab !== Tab.geometry) {
            this.selectionLayer.visible = true;
          }
          // apply the default renderer
          if (!this.selectionLayer.renderer.type !== SIMPLE_GRAY_RENDERER.type) {
            this.selectionLayer.renderer = this.defaultRenderer;
          }
          if (layout === 'details') {

            const wider_SIMPLE_GRAY_RENDERER = {
              ...SIMPLE_GRAY_RENDERER,
              symbol: {
                ...SIMPLE_GRAY_RENDERER.symbol,
                width: '16px'
              }
            };

            switch (tab) {
            // hide selection layer for Geometry tab
            case Tab.geometry:
              this.selectionLayer.visible = false;
              break;
              // apply gray renderer
            case Tab.impact:
              // call to buffer handled by impact-action.component
              // this.selectionLayer.renderer = wider_SIMPLE_GRAY_RENDERER;
              // now using the impact buffer service, hence the reset to default
              this.selectionLayer.renderer = SIMPLE_BLACK_RENDERER;
              break;
              // apply gray renderer
            case Tab.priority:
              this.selectionLayer.renderer = wider_SIMPLE_GRAY_RENDERER;
              break;
            }
          }
        });
    }
  }

  private getSelectionLayer() {
    return new FeatureLayer({
      fields: [
        {
          type: 'oid',
          name: 'OBJECTID',
          alias: 'ObjectID'
        },
        {
          type: 'double',
          name: 'PERFORMANCE_SCORE',
          alias: 'Performance Score'
        },
        {
          type: 'string',
          name: 'PIN',
          alias: 'PIN'
        }
      ],
      source: [],
      renderer: <any>{
        type: 'class-breaks',
        field: 'PERFORMANCE_SCORE',
        classBreakInfos: CLASS_BREAKS,
        defaultSymbol: {
          type: 'simple-line',
          color: SCORE_COLORS_SEMI_TRANSPARENT.black,
          width: '6px',
          style: 'solid'
        }
      },
      geometryType: 'polyline',
      spatialReference: { wkid: 3857 }
    });
  }
}

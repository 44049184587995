import { Injectable } from '@angular/core';
import { MapService } from './map.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LoggingService } from '../logging.service';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class PopupWidgetService {

  features = new BehaviorSubject<__esri.Graphic[]>([]);
  selectedFeature = new BehaviorSubject<__esri.Graphic>(null);
  selectedFeatureIndex = new BehaviorSubject<number>(-1);

  // ids
  private svgId = 'svg-popup';

  constructor(
    private mapService: MapService,
    private logging: LoggingService
  ) {

    this.mapService.mapAssets$.pipe(
      filter(assets => assets !== null && assets !== undefined),
    ).subscribe(assets => {
      // this.logging.log('🚀 ~ file: popup-widget.service.ts ~ line 20 ~ PopupWidgetService ~ this.mapService.mapAssets$.pipe ~ assets', assets);
      const popup = assets.view.popup;

      popup.watch('features', f => {
        if (f && this.features.value !== f) {
          this.features.next(f);
        }
      });

      popup.watch('selectedFeature', f => {
        if (f && this.selectedFeature.value !== f) {
          this.selectedFeature.next(f);
        }
      });

      popup.watch('selectedFeatureIndex', i => {
        if (i && this.selectedFeatureIndex.value !== i) {
          this.selectedFeatureIndex.next(i);
        }
      });
      this.selectedFeatureIndex.subscribe(i => {
        if (i && popup.selectedFeatureIndex !== i) {
          popup.selectedFeatureIndex = i;
        }
      });
    });
  }

  removeSvgLinePath() {
    try {
      // remove previous
      const node = document.getElementById(this.svgId);
      if (node) {
        document.body.removeChild(node);
      }
    } catch (err) {
      console.error('Failed to remove svg line ->', err);
    }
  }

  drawSvgLinePath(
    start: { x: number, y: number },
    end: { x: number, y: number },
    zIndex: number,
    color: string) {
    try {

      // remove previous just in case
      const node = document.getElementById(this.svgId);
      if (node) {
        document.body.removeChild(node);
      }

      // create new elements
      // svg
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      svg.setAttribute('id', this.svgId);
      svg.style.position = 'absolute';
      svg.style.width = '100%';
      svg.style.height = '100%';
      svg.style.zIndex = `${zIndex}`;

      // line
      const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      line.setAttribute('id', 'svg-popup-line');
      line.setAttribute('x1', `${start.x}`);
      line.setAttribute('y1', `${start.y}`);
      line.setAttribute('x2', `${end.x}`);
      line.setAttribute('y2', `${end.y}`);
      line.setAttribute('stroke', color);
      line.setAttribute('stroke-width', '2');
      line.setAttribute('stroke-dasharray', '4,1');

      // start point
      const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      circle.setAttribute('id', 'svg-popup-cir-start');
      circle.setAttribute('cx', `${start.x}`);
      circle.setAttribute('cy', `${start.y}`);
      circle.setAttribute('r', '5');
      circle.setAttribute('stroke', color);
      circle.setAttribute('stroke-width', '3');
      circle.setAttribute('fill', 'white');

      // add
      svg.appendChild(line);
      svg.appendChild(circle);
      document.body.appendChild(svg);
    } catch (err) {
      console.error(`Failed to draw SVG Line ->`, err);
    }
  }

  getInitialMapPopupPosition(clickPos: { x: number, y: number }) {

    // map view dim
    const dim = this.mapService.getViewDimensions();

    // update click position using the map left / top
    // this accounts for a change in the map size / ie split screen etc
    clickPos.x = clickPos.x + dim.mapLeft;
    clickPos.y = clickPos.y + dim.mapTop;

    const position: any = {
      top: `${clickPos.y}px`,
      left: `${clickPos.x}px`
    };
    return position;
  }

  updateMapPopupPosition(ref: MatDialogRef<any>, clickPos: { x: number, y: number }, drawConnector = false) {
    const container = document.getElementById(ref.id);
    const position = this.calcPopUpPosition(container, clickPos);

    // convert to string
    Object.keys(position).forEach(k => {
      position[k] = `${position[k]}px`;
    });

    ref.updatePosition(position);

    // draw svg line connecting popup to click location / do this after the updated location
    if (drawConnector) {
      // currently only using the upper-left
      const corner = this.getClosetCorner(container, clickPos);
      const zIndex = 999; // CDK overlay is 1000
      // offsets to adjust placement
      const yStartOffset = 30;
      const yEndOffset = 28;
      this.drawSvgLinePath(
        { x: clickPos.x, y: clickPos.y - yStartOffset },
        { x: corner.x, y: corner.y - yEndOffset },
        zIndex,
        '#3850DA'
      );
    }
  }

  async next() {
    const view = (await this.mapService.getAssets()).view;
    view.popup.next();
  }

  async previous() {
    const view = (await this.mapService.getAssets()).view;
    view.popup.previous();
  }

  async close() {
    const view = (await this.mapService.getAssets()).view;
    view.popup.close();
    this.removeSvgLinePath();
  }

  private calcPopUpPosition(container: HTMLElement, clickPos: { x: number, y: number }) {
    const position: any = {};

    // map view dim
    const dim = this.mapService.getViewDimensions();

    // offsets
    const xOffset = 10;
    const yOffset = 10;

    // margins
    const leftMargin = 4;
    const rightMargin = 52; // margin for controls on along right side
    const topMargin = 60; // margin for title bar / search
    const bottomMargin = 4;

    // popup rect
    const popUpRect = container.getBoundingClientRect();

    // calc top/left position using click X/Y and the rect size
    // location is below and right of click position
    position.left = clickPos.x + (popUpRect.width / 4) + xOffset;
    position.top = clickPos.y + (popUpRect.height / 4) + yOffset;

    // calculate bounds
    const boundary = {
      top: topMargin + dim.mapTop,
      bottom: dim.mapHeight - bottomMargin,
      left: leftMargin + dim.mapLeft,
      right: dim.mapWidth - rightMargin
    };

    // check left
    if (position.left < boundary.left) {
      position.left = boundary.left;
    }

    // check top
    if (position.top < boundary.top) {
      position.top = boundary.top;
    }

    // check bottom
    if ((position.top + popUpRect.height) > boundary.bottom) {
      position.top = popUpRect.top - (popUpRect.bottom - boundary.bottom);
    }

    // check right
    if ((position.left + popUpRect.width) > boundary.right) {
      position.left = popUpRect.left - (popUpRect.right - boundary.right);
    }

    return position;
  }

  private getClosetCorner(container: HTMLElement, clickPos: { x: number, y: number }) {
    const rect = container.getBoundingClientRect();

    const corners = [
      { x: rect.left, y: rect.top, location: 'top-left' },
      //{x: rect.right, y: rect.top, location: 'top-right'},
      // issue with the bottom location because the rect height is wrong, can't detect when it's been drawn/updated
      // split the height in to account for the issue
      //{x: rect.left, y: rect.bottom - (rect.height / 2), location: 'bottom-left'},
      //{x: rect.right, y: rect.bottom - (rect.height / 2), location: 'bottom-right'}
    ];

    let smallestDistance = 0;
    let result;
    corners.forEach((corner, i) => {
      // distance between points formula
      const distance = Math.round(
        Math.sqrt(
          Math.pow((corner.x - clickPos.x), 2) +
          Math.pow((corner.y - clickPos.y), 2)
        )
      );
      corner['distance'] = distance;
      // set value on first loop
      if (i === 0) {
        smallestDistance = distance;
        result = corner;
      }
      if (distance < smallestDistance) {
        smallestDistance = distance;
        result = corner;
      }
    });
    return result;
  }

}

import { SCORE_COLORS_MIN_MAX, SCORE_COLORS_SEMI_TRANSPARENT } from 'src/score-color-config';

export const SIMPLE_GRAY_RENDERER: any = {
  type: 'simple',
  symbol:
    {
      type: 'simple-line',
      color: [104, 104, 104, 0.8], // dary gray
      width: '6px',
      style: 'solid',
      cap: 'round',
      join: 'round'
    }
};

export const SIMPLE_BLACK_RENDERER: any = {
  type: 'simple',
  symbol:
    {
      type: 'simple-line',
      color: [0, 0, 0, 0.8], // black
      width: '6px',
      style: 'solid',
      cap: 'round',
      join: 'round'
    }
};

export const CIM_LINE_RENDERER: any = {
  type: 'simple',
  symbol: {
    type: 'cim',
    // CIM Line Symbol
    data: {
      type: 'CIMSymbolReference',
      symbol: {
        type: 'CIMLineSymbol',
        symbolLayers: [
          {
            type: 'CIMSolidStroke',
            effects: [],
            enable: true,
            capStyle: 'Round',
            joinStyle: 'Round',
            lineStyle3D: 'Strip',
            miterLimit: 10,
            width: 3,
            // white
            color: [
              255,
              255,
              255,
              100
            ]
          },
          {
            type: 'CIMSolidStroke',
            effects: [],
            enable: true,
            capStyle: 'Round',
            joinStyle: 'Round',
            lineStyle3D: 'Strip',
            miterLimit: 10,
            width: 6,
            // cyan
            color: [
              0,
              255,
              255,
              200
            ]
          }
        ]
      }
    }
  }
};

const dotStyle = 'dot';
const dotWidth = '10px';

export const CLASS_BREAKS = [
  {
    minValue: SCORE_COLORS_MIN_MAX.redPoint.min,
    maxValue: SCORE_COLORS_MIN_MAX.redPoint.max,
    symbol: {
      type: 'simple-line',
      color: SCORE_COLORS_SEMI_TRANSPARENT.red,
      width: dotWidth,
      style: dotStyle
    }
  },
  {
    minValue: SCORE_COLORS_MIN_MAX.orangePoint.min,
    maxValue: SCORE_COLORS_MIN_MAX.orangePoint.max,
    symbol: {
      type: 'simple-line',
      color: SCORE_COLORS_SEMI_TRANSPARENT.orange,
      width: dotWidth,
      style: dotStyle
    }
  },
  {
    minValue: SCORE_COLORS_MIN_MAX.orangePoint.min,
    maxValue: SCORE_COLORS_MIN_MAX.orangePoint.max,
    symbol: {
      type: 'simple-line',
      color: SCORE_COLORS_SEMI_TRANSPARENT.orange,
      width: dotWidth,
      style: dotStyle
    }
  },
  {
    minValue: SCORE_COLORS_MIN_MAX.yellowPoint.min,
    maxValue: SCORE_COLORS_MIN_MAX.yellowPoint.max,
    symbol: {
      type: 'simple-line',
      color: SCORE_COLORS_SEMI_TRANSPARENT.yellow,
      width: dotWidth,
      style: dotStyle
    }
  },
  {
    minValue: SCORE_COLORS_MIN_MAX.greenPoint.min,
    maxValue: SCORE_COLORS_MIN_MAX.greenPoint.max,
    symbol: {
      type: 'simple-line',
      color: SCORE_COLORS_SEMI_TRANSPARENT.green,
      width: dotWidth,
      style: dotStyle
    }
  },
  {
    minValue: SCORE_COLORS_MIN_MAX.bluePoint.min,
    maxValue: SCORE_COLORS_MIN_MAX.bluePoint.max,
    symbol: {
      type: 'simple-line',
      color: SCORE_COLORS_SEMI_TRANSPARENT.blue,
      width: dotWidth,
      style: dotStyle
    }
  },
  {
    minValue: SCORE_COLORS_MIN_MAX.red.min,
    maxValue: SCORE_COLORS_MIN_MAX.red.max,
    symbol: {
      type: 'simple-line',
      color: SCORE_COLORS_SEMI_TRANSPARENT.red,
      width: '6px',
      style: 'solid'
    }
  }, {
    minValue: SCORE_COLORS_MIN_MAX.orange.min,
    maxValue: SCORE_COLORS_MIN_MAX.orange.max,
    symbol: {
      type: 'simple-line',
      color: SCORE_COLORS_SEMI_TRANSPARENT.orange,
      width: '6px',
      style: 'solid'
    }
  }, {
    minValue: SCORE_COLORS_MIN_MAX.yellow.min,
    maxValue: SCORE_COLORS_MIN_MAX.yellow.max,
    symbol: {
      type: 'simple-line',
      color: SCORE_COLORS_SEMI_TRANSPARENT.yellow,
      width: '6px',
      style: 'solid'
    }
  }, {
    minValue: SCORE_COLORS_MIN_MAX.green.min,
    maxValue: SCORE_COLORS_MIN_MAX.green.max,
    symbol: {
      type: 'simple-line',
      color: SCORE_COLORS_SEMI_TRANSPARENT.green,
      width: '6px',
      style: 'solid'
    }
  }, {
    minValue: SCORE_COLORS_MIN_MAX.blue.min,
    maxValue: SCORE_COLORS_MIN_MAX.blue.max,
    symbol: {
      type: 'simple-line',
      color: SCORE_COLORS_SEMI_TRANSPARENT.blue,
      width: '6px',
      style: 'solid'
    }
  }
];

export const SCOPE_PIN_DEFAULT_RENDERER: any = {
  type: 'class-breaks',
  field: 'PERFORMANCE_SCORE',
  classBreakInfos: CLASS_BREAKS,
  defaultSymbol: {
    type: 'simple-line',
    color: SCORE_COLORS_SEMI_TRANSPARENT.black,
    width: '6px',
    style: 'solid'
  }
};

// controls when to render as marker line vs. solid
const valueExp = `
var length = Length($feature, 'feet');
var score = $feature.PERFORMANCE_SCORE;
var pointScore = score + 100;
return IIF(length < 5000, pointScore, score);
`;

export const SCOPE_PIN_SHORT_SEGMENTS_RENDERER: any = {
  type: 'class-breaks',
  classBreakInfos: CLASS_BREAKS,
  valueExpression: valueExp,
  defaultSymbol: {
    type: 'simple-line',
    color: SCORE_COLORS_SEMI_TRANSPARENT.black,
    width: '6px',
    style: 'solid'
  }
};


import { MultiScannerResults } from './multi-scanner';
import { ScanResults } from './scan';

export function createLookups(scans: MultiScannerResults) {

  const lookups: any = {
    projectType: getLookup(scans.projectTypes, 'PROJECTTYPE'),
    programType: getLookup(scans.programTypes, 'PROGRAMTYPE'),
    workGroup: getLookup(scans.workGroups, 'WORKGROUP'),
    workType: getLookup(scans.workTypes, 'WORKCODE')
  };

  const domainFields = [
    'LEVELSTUDYREQUIRED',
    'OUTSIDESERVICES',
    'LEVELOFDOCUMENTATION',
    'ROWNEED',
    'DESIRED_FISCAL_MONTH',
    'PD_COMPLETED_BY',
    'SURVEY_REQUIRED',
    'ROWRELOCATION',
    'TANDE',
    'CULTURALSITE',
    'HISTORICALSITE',
    'LEVIESAFFECTED',
    'CONCURRENCEPOINT',
    'INTERCHANGEREQUIRED',
    'TRAFFICCRITICAL'
  ];

  domainFields.forEach(field => {
    lookups[field] = getLookup(scans.projectScoping, field);
  });

  return lookups;
}

function getLookup(scanResults: ScanResults, fieldName: string) {
  const field = scanResults.fields.find(f => f.name === fieldName);
  const options = (field && field.domain) ? field.domain['codedValues'] : [];
  const lookup = {};
  options.forEach(cv => lookup[cv.code] = cv.name);
  return lookup;
}

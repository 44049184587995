import { Injectable } from '@angular/core';
import { PinsRxLayerService } from '../layer/pins-rx-layer.service';
import { S3File, S3Service } from '../s3.service';
import { BehaviorSubject } from 'rxjs';
import { UserInfoService } from '../user-info.service';
import { PinAttributes } from '../amalgamator/pin-attributes';
import { ENDPOINT_CONFIG } from '../dynamic-endpoint/endpoint.service.config';

const ATTACHMENT_URL = ENDPOINT_CONFIG.AWS.ATTACHMENTS.s3Url;

export interface Attachment extends S3File {
  url: string;
}

interface AttachmentsState {
  loading: boolean;
  loadingAction: string;
  attachments: Attachment[];
  displayCount: string;
  canModify: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(
    private pinsRxLayerService: PinsRxLayerService,
    private s3Service: S3Service,
    private userInfoService: UserInfoService
  ) {
    this.isAdmin = this.userInfoService.isAdmin;
    this.user = this.userInfoService.userInfo.value.username;

    this.pinsRxLayerService.selection.subscribe(selection => {
      this.setState(selection);
    });
  }

  private isAdmin: boolean;
  private user: string;

  state = new BehaviorSubject<AttachmentsState>({
    loading: false,
    loadingAction: '',
    attachments: [],
    displayCount: '',
    canModify: false
  });

  async getAttachments(pin: string, signedUrl = true): Promise<Attachment[]> {
    const res = await this.s3Service.getFolderContents(pin, signedUrl);
    const attachments: Attachment[] = res.map(r => {
      const attachment: Attachment = r as Attachment;
      attachment.url = `${ATTACHMENT_URL}/${attachment.Key}`;
      return attachment;
    });
    return attachments;
  }

  async postAttachment(file: File) {
    const selection = this.pinsRxLayerService.selection.value;
    if (selection && selection.pin) {
      this.state.next({
        loading: true,
        loadingAction: 'Uploading file...',
        attachments: [],
        displayCount: '',
        canModify: false
      });
      await this.s3Service.postFile(file, selection.pin);
      this.setState(selection);
    }
  }

  async deleteAttachment(attachment: S3File) {
    const selection = this.pinsRxLayerService.selection.value;

    if (selection && selection.pin) {
      this.state.next({
        loading: true,
        loadingAction: 'Deleting file...',
        attachments: [],
        displayCount: '',
        canModify: false
      });
      await this.s3Service.deleteFile(attachment);
      this.setState(selection);
    }
  }

  private async setState(selection) {
    if (selection && selection.pin) {
      const atts: PinAttributes = JSON.parse(selection.attributesJson);
      this.state.next({
        loading: true,
        loadingAction: 'Loading file list...',
        attachments: [],
        displayCount: '',
        canModify: false
      });
      const res = await this.getAttachments(selection.pin);
      this.state.next({
        loading: false,
        loadingAction: '',
        attachments: res,
        displayCount: `(${res.length})`,
        canModify: this.isAdmin || this.user === atts.USERNAME
      });
    } else {
      this.state.next({
        loading: false,
        loadingAction: '',
        attachments: [],
        displayCount: '',
        canModify: false
      });
    }
  }

}

import { Injectable } from '@angular/core';

import { FormDataService } from 'src/app/services/form-data.service';
import { EndpointService } from 'src/app/services/dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from 'src/app/services/dynamic-endpoint/endpoint.service.config';
import { GuidService } from 'src/app/services/guid.service';
import { esriRequest } from 'src/esri/request';
import { RouteAndMeasure } from 'src/app/services/layer/route-and-measure.service';
import { RAMS_VERSIONS } from 'src/app/services/rams-versions';
import { RAMS_EFFECTIVE_DATE_CLAUSE } from 'src/app/services/rams-effective-date-clause';
import { environment } from 'src/environments/environment';
import { UserInfoService } from 'src/app/services/user-info.service';
import { DialogNoticeService } from './dialog-notice.service';
import { EmailService } from './email.service';
import { RxJsLoggingLevel } from '../functions/rxjs-debug';

export interface RamsProjectScopingAttributes {
  EVENT_ID?: string;
  ROUTE_ID?: string;
  FROM_MEASURE?: number;
  TO_MEASURE?: number;
  BUSINESS_DATE?: number;
  EFFECTIVE_START_DATE?: number;
  EFFECTIVE_END_DATE?: number;
  USER_CREATE?: string;
  USER_MOD?: string;
  PROJECT_SCOPE_ID?: string;
  PSS_PIN_ID?: string;
  LOCERROR?: string;
}

export interface ReconcileVersions {
  targetVersion: string;
  editVersion: string;
}

// Story 1806 Migrate Geometry into RAMS - written to handle RAMS calls
@Injectable({
  providedIn: 'root'
})
export class RamsRequestsService {

  constructor(
    private formDataService: FormDataService,
    private endpointService: EndpointService,
    private guidService: GuidService,
    private userInfoService: UserInfoService,
    private dialogNoticeService: DialogNoticeService,
    private emailService: EmailService
  ) {
  }

  private urls = this.getRamsUrlAndLayerId();

  // Story 1806 Migrate Geometry into RAMS - needs to be updated
  async saveGeometryForScopeFromBridgeView(attributes, PROJECT_SCOPE_ID: string, reconcile?: boolean) {
    const withReconcile = reconcile ? reconcile : true; // defaults to true for reconcile with post
    const urls = await this.urls;
    const today = new Date(new Date().toDateString());
    today.setUTCHours(0);
    // route info
    const ROUTE_ID = attributes.PRIMARY_ROUTE;
    let FROM_MEASURE = attributes.FROM_MEASURE;
    let TO_MEASURE = attributes.TO_MEASURE;
    if (FROM_MEASURE === TO_MEASURE) {
      FROM_MEASURE -= 0.00001;
      TO_MEASURE += 0.00001;
    }
    // applyEdits params
    const addsEditParams = [{
      id: urls.layerId,
      adds: [{
        attributes: {
          EVENT_ID: this.guidService.createGuid(),
          ROUTE_ID: ROUTE_ID,
          FROM_MEASURE: FROM_MEASURE,
          TO_MEASURE: TO_MEASURE,
          PROJECT_SCOPE_ID: PROJECT_SCOPE_ID,
          EFFECTIVE_END_DATE: null,
          EFFECTIVE_START_DATE: +today
        },
        isRedline: false,
        geometryDirection: 'withRoute'
      }]
    }];
    const results = await esriRequest(urls.lrsWorkspace + '/applyEdits', {
      body: this.formDataService.toFormData({
        edits: addsEditParams,
        f: 'json',
        gdbVersion: RAMS_VERSIONS.EDIT
      }),
      responseType: 'json',
      method: 'post'
    });
    return this._applyEditsResultHandler(results, withReconcile);
  }

  // Story 1806 Migrate Geometry into RAMS - needs to be updated
  async addGeometry(PROJECT_SCOPE_ID: string, routesAndMeasures: RouteAndMeasure[], PSS_PIN_ID?: string, reconcile?: boolean) {
    const withReconcile = reconcile ? reconcile : true; // defaults to true for reconcile with post
    const urls = await this.urls;
    const PSS_PIN = typeof PSS_PIN_ID === 'string' ? PSS_PIN_ID : null;
    // time for effective start
    const today = new Date(new Date().toDateString());
    today.setUTCHours(0);
    // applyEdits params
    const addsParams = [
      {
        id: urls.layerId,
        adds: routesAndMeasures.map(s => ({
          attributes: {
            EVENT_ID: this.guidService.createGuid(),
            ROUTE_ID: s.ROUTE_ID,
            FROM_MEASURE: s.FROM_MEASURE,
            TO_MEASURE: s.TO_MEASURE,
            PROJECT_SCOPE_ID: PROJECT_SCOPE_ID,
            PSS_PIN_ID: PSS_PIN,
            EFFECTIVE_END_DATE: null,
            EFFECTIVE_START_DATE: +today
          },
          isRedline: false,
          geometryDirection: 'withRoute'
        }))
      }
    ];
    const results = await esriRequest(urls.lrsWorkspace + '/applyEdits', {
      body: this.formDataService.toFormData({
        edits: addsParams,
        f: 'json',
        gdbVersion: RAMS_VERSIONS.EDIT
      }),
      responseType: 'json',
      method: 'post'
    });
    if (environment.isLocalHost && environment.loggingLevel === RxJsLoggingLevel.DEBUG) {
      console.log(`Added Geometry for PROJECT_SCOPE_ID: ${PROJECT_SCOPE_ID}, PSS_PIN_ID: ${PSS_PIN_ID}`);
    }
    return this._applyEditsResultHandler(results, withReconcile);
  }

  // use very carefully, this should only be allowed by ADMIN users
  /*
  async CAUTION_deleteGeometryForPIN(PSS_PIN_ID: string, reconcile?: boolean) {
    // another check for admin, just in case
    if (this.userInfoService.isAdmin) {
      const withReconcile = reconcile ? reconcile : true; // defaults to true for reconcile with post
      const urls = await this.urls;
      // applyEdits deletes params
      const deletesParams = [
        {
          id : urls.layerId,
          deletes: [{
            where: `PSS_PIN_ID = '${PSS_PIN_ID}' AND ` + RAMS_EFFECTIVE_DATE_CLAUSE
          }]
        }
      ];
      const results = await esriRequest(urls.lrsWorkspace + '/applyEdits', {
        body: this.formDataService.toFormData({
          edits: deletesParams,
          f: 'json',
          gdbVersion: RAMS_VERSIONS.EDIT
        }),
        responseType: 'json',
        method: 'post'
      });
      if (environment.isLocalHost && environment.verboseLogging) {
        console.log(`Deleted Geometry for PIN: ${PSS_PIN_ID}`);
      }
      return this._applyEditsResultHandler(results, withReconcile);
    } else {
      const msg = `${this.userInfoService.userInfo.value.username} does not have Admin role, unable to delete`;
      console.error(msg);
      return new Promise((resolve, reject) => {
        reject(msg);
      });
    }
  }
  */

  async deleteGeometryForScopeId(PROJECT_SCOPE_ID: string, reconcile?: boolean) {
    const withReconcile = typeof reconcile === 'boolean' ? reconcile : true; // defaults to true for reconcile with post
    const urls = await this.urls;
    // applyEdits deletes params
    const deletesParams = [
      {
        id: urls.layerId,
        deletes: [{
          where: `PROJECT_SCOPE_ID = '${PROJECT_SCOPE_ID}' AND PSS_PIN_ID IS NULL AND ` + RAMS_EFFECTIVE_DATE_CLAUSE
        }]
      }
    ];
    const results = await esriRequest(urls.lrsWorkspace + '/applyEdits', {
      body: this.formDataService.toFormData({
        edits: deletesParams,
        f: 'json',
        gdbVersion: RAMS_VERSIONS.EDIT
      }),
      responseType: 'json',
      method: 'post'
    });
    return this._applyEditsResultHandler(results, withReconcile);
  }

  // use with extreme caution...deletes Geometry for a scope to pin record.
  async CAUTION_deleteGeometryForScopeToPin(PROJECT_SCOPE_ID?: string, PSS_PIN_ID?: string, reconcile?: boolean) {
    // another check for admin, just in case
    if (this.userInfoService.isAdmin) {
      const withReconcile = reconcile ? reconcile : true; // defaults to true for reconcile with post
      let where = null;
      const hasScope = typeof PROJECT_SCOPE_ID === 'string' ? true : false;
      const hasPin = typeof PSS_PIN_ID === 'string' ? true : false;
      if (hasPin && hasScope) {
        where = `PSS_PIN_ID ='${PSS_PIN_ID}' AND PROJECT_SCOPE_ID = '${PROJECT_SCOPE_ID}' AND ` + RAMS_EFFECTIVE_DATE_CLAUSE;
      }
      if (hasPin && !hasScope) {
        where = `PSS_PIN_ID ='${PSS_PIN_ID}' AND PROJECT_SCOPE_ID IS NULL AND ` + RAMS_EFFECTIVE_DATE_CLAUSE;
      }
      if (hasScope && !hasPin) {
        where = `PSS_PIN_ID IS NULL AND PROJECT_SCOPE_ID = '${PROJECT_SCOPE_ID}' AND ` + RAMS_EFFECTIVE_DATE_CLAUSE;
      }
      const urls = await this.urls;
      // applyEdits deletes params
      const deletesParams = [
        {
          id: urls.layerId,
          deletes: [{
            where: where
          }]
        }
      ];
      const results = await esriRequest(urls.lrsWorkspace + '/applyEdits', {
        body: this.formDataService.toFormData({
          edits: deletesParams,
          f: 'json',
          gdbVersion: RAMS_VERSIONS.EDIT
        }),
        responseType: 'json',
        method: 'post'
      });
      return this._applyEditsResultHandler(results, withReconcile);
    } else {
      const msg = `${this.userInfoService.userInfo.value.username} does not have Admin role, unable to delete`;
      console.error(msg);
      return new Promise((resolve, reject) => {
        reject(msg);
      });
    }
  }

  async updateAttributes(PROJECT_SCOPE_ID: string, attributes: RamsProjectScopingAttributes, PSS_PIN_ID?: string, reconcile?: boolean) {
    const withReconcile = reconcile ? reconcile : true; // defaults to true for reconcile with post
    let where = null;
    const hasScope = typeof PROJECT_SCOPE_ID === 'string' ? true : false;
    const hasPin = typeof PSS_PIN_ID === 'string' ? true : false;
    if (hasPin && hasScope) {
      where = `PSS_PIN_ID ='${PSS_PIN_ID}' AND PROJECT_SCOPE_ID = '${PROJECT_SCOPE_ID}' AND ` + RAMS_EFFECTIVE_DATE_CLAUSE;
    }
    if (hasPin && !hasScope) {
      where = `PSS_PIN_ID ='${PSS_PIN_ID}' AND PROJECT_SCOPE_ID IS NULL AND ` + RAMS_EFFECTIVE_DATE_CLAUSE;
    }
    if (hasScope && !hasPin) {
      where = `PSS_PIN_ID IS NULL AND PROJECT_SCOPE_ID = '${PROJECT_SCOPE_ID}' AND ` + RAMS_EFFECTIVE_DATE_CLAUSE;
    }
    const urls = await this.urls;
    // applyEdits deletes params
    const updatesParams = [{
      id: urls.layerId,
      updates: [
        {
          where: where,
          attributes: attributes,
        }
      ]
    }];
    const results = await esriRequest(urls.lrsWorkspace + '/applyEdits', {
      body: this.formDataService.toFormData({
        edits: updatesParams,
        f: 'json',
        gdbVersion: RAMS_VERSIONS.EDIT
      }),
      responseType: 'json',
      method: 'post'
    });
    return this._applyEditsResultHandler(results, withReconcile);
  }

  private async _applyEditsResultHandler(results: any, withReconcile: boolean) {
    return new Promise(async (resolve, reject) => {
      if (results.data.success === true) {
        if (withReconcile) {
          await this.reconcileVersion(true)
            .then(r => resolve(r))
            .catch(c => reject(c));
        } else {
          resolve(results);
        }
      } else {
        reject(results);
      }
    });
  }

  async getRamsUrlAndLayerId() {
    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.RAMS_LRS);
    const mapUrl = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.RAMS_LRS_MAPSERVER);
    const lrsUrl = ENDPOINT_CONFIG.RAMS_LRS.url;
    const projectPrioritiesID = Number(urls.PROJECT_SCOPING.replace(lrsUrl, '').replace('/', ''));
    return {
      lrsWorkspace: lrsUrl,
      ProjectScopingLayer: mapUrl.PROJECT_SCOPING,
      layerId: projectPrioritiesID
    };
  }

  async reconcileVersion(post?: boolean): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const withPost = post ? post : false;
      const ramsUrl = ENDPOINT_CONFIG.RAMS_LRS.url;
      const versionInfo: ReconcileVersions = {
        targetVersion: RAMS_VERSIONS.TARGET,
        editVersion: RAMS_VERSIONS.EDIT
      };
      try {
        const res = await esriRequest(ramsUrl + '/reconcileVersion', {
          body: this.formDataService.toFormData({
            targetVersion: versionInfo.targetVersion,
            editVersion: versionInfo.editVersion,
            withPost: withPost,
            f: 'json'
          }),
          responseType: 'json',
          method: 'post'
        });
        if (environment.isLocalHost && environment.loggingLevel === RxJsLoggingLevel.DEBUG) {
          console.log(`Reconciled: ${JSON.stringify(res)}`);
        }
        if (res.data && res.data?.error) {
          console.error(`Reconcile Error: ${res}`);
          resolve(res);
        }
        if (res.data && res.data?.hasConflicts) {
          await this.conflictsHandler();
          resolve(res);
        } else {
          resolve(res);
        }
      } catch (err) {
        await this.dialogNoticeService.error({
          title: 'RECONCILE FAILURE',
          message: `Please try again.  If this error persists, please contact the system administrator.
          Error Info: ${JSON.stringify(err)}`
        });
        reject(err);
      }
    });
  }

  async getProjectScopingRoutesByPin(pin) {
    const urls = await this.urls;
    const res = await esriRequest(urls.ProjectScopingLayer + '/query', {
      body: this.formDataService.toFormData({
        where: RAMS_EFFECTIVE_DATE_CLAUSE + ` and (PSS_PIN_ID = '${pin}')`,
        outFields: 'ROUTE_ID,FROM_MEASURE,TO_MEASURE,PSS_PIN_ID,PROJECT_SCOPE_ID',
        gdbVersion: RAMS_VERSIONS.VIEW, // version for testing, version is optional
        returnGeometry: true,
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
    return res;
  }

  async getProjectScopingRoutesByPinScope(pinOrScope: string) {
    const urls = await this.urls;
    const res = await esriRequest(urls.ProjectScopingLayer + '/query', {
      body: this.formDataService.toFormData({
        where: RAMS_EFFECTIVE_DATE_CLAUSE + `AND (PSS_PIN_ID = '${pinOrScope}' OR PROJECT_SCOPE_ID = '${pinOrScope}')`,
        outFields: 'ROUTE_ID,FROM_MEASURE,TO_MEASURE,PSS_PIN_ID,PROJECT_SCOPE_ID',
        gdbVersion: RAMS_VERSIONS.VIEW, // version for testing, version is optional
        returnGeometry: true,
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });
    return res;
  }

  private async conflictsHandler() {
    const msg = 'Reconcile Conflicts Detected, might have to manually reconcile and post!';
    console.error(msg);
    await this.emailService.sendEmailToAdmins(msg, 'PP/S RECONCILE CONFLICTS!');
  }

}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { RxLayer } from 'src/app/rx-layer/rx-layer';
import { PinsRxLayerService } from 'src/app/services/layer/pins-rx-layer.service';
import { MapService } from 'src/app/services/map/map.service';
import { PINS_RX_LAYER_HIDE_FIELDS } from 'src/app/services/layer/pins-rx-layer.hide-fields';
import { Filter, FilterType } from 'src/app/rx-layer/filter';
import { FilterFactoryService } from 'src/app/services/filter-factory.service';
import { SubmitApproveService } from 'src/app/scope-approval/submit-approve.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { PinAttributes } from 'src/app/services/amalgamator/pin-attributes';
import { DialogNoticeService } from 'src/app/services/dialog-notice.service';
import { ProjectStage } from 'src/app/types/project-stages';
import { ApprovalEmailDialogComponent } from './approval-email-dialog/approval-email-dialog.component';
import { TutorialActionService } from 'src/app/services/tutorial-action.service';
import { ClonerService } from 'src/app/services/cloner.service';
import { filter, take } from 'rxjs/operators';
import { EmailService } from '../../services/email.service';

const heightConfig = {
  FullScreen: 'calc(100vh - 16px)',
  DockBottom: '450px'
};

const classConfig = {
  FullScreen: 'attr-tbl-dialog-extra-shadow',
  DockBottom: ''
};

@Component({
  templateUrl: './approval-workflow-dialog.component.html',
  styleUrls: ['./approval-workflow-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApprovalWorkflowDialogComponent implements OnInit, OnDestroy {

  rxLayer: RxLayer;
  viewDetailsForPin = new BehaviorSubject<string>(null);
  submitApproveAction = 'Submit';
  refreshing = false;
  isAdmin: boolean;
  canDisableSendEmail = false;
  canSubmitApprove = false;
  dock = 'DockBottom';
  canResendEmail = false;

  // used to determine if filters should be written back to pinsRxLayer;
  private numberOfFilterChanges = 0;
  private pinsRxLayer: RxLayer;
  private subs: Subscription[] = [];
  private hashApprovalFilter$ = new BehaviorSubject<Filter>(null);
  private tableRowsLoading$ = new BehaviorSubject<boolean>(null);

  private _willNotSendEmail = new BehaviorSubject<boolean>(false);
  public willNotSendEmail = this._willNotSendEmail.asObservable();

  @HostBinding('style.height') height = heightConfig[this.dock];
  @HostBinding('class') class = classConfig[this.dock];

  constructor(
    public dialogRef: MatDialogRef<ApprovalWorkflowDialogComponent>,
    private clonerService: ClonerService,
    private filterFactory: FilterFactoryService,
    private dialog: MatDialog,
    private pinsRxLayerService: PinsRxLayerService,
    private cdr: ChangeDetectorRef,
    private mapService: MapService,
    public submitApproveService: SubmitApproveService,
    private userInfoService: UserInfoService,
    private dialogNoticeService: DialogNoticeService,
    private tutorialActionService: TutorialActionService,
    private emailService: EmailService,
    // private lazyDialogService: LazyDialogService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.isAdmin = this.userInfoService.isAdmin;
    this.canDisableSendEmail = this.userInfoService.canDisableSendEmail;
    this.willNotSendEmail = this._willNotSendEmail.asObservable();
    this._willNotSendEmail.next(this.userInfoService.canDisableSendEmail);
  }

  async ngOnInit() {
    this.rxLayer = await this.createRxLayer();
    await this.handleApproveHash();
    this.subs.push(
      this.rxLayer.filters.subscribe(filters => {
        this.numberOfFilterChanges++;

        const stageFilter = filters.find(f => f.type === FilterType.StageSelect);
        if (!stageFilter) {
          this.dialogNoticeService.alert({ message: 'Stage filter could not be found.' });
          return;
        }
        const canSubmit = stageFilter.value.indexOf(ProjectStage.scopeScoping) > -1;
        const canApprove = stageFilter.value.indexOf(ProjectStage.scopeInApproval) > -1;
        if (canSubmit && !canApprove) {
          this.submitApproveAction = 'Submit';
        } else if (canApprove && !canSubmit) {
          this.submitApproveAction = 'Approve';
        } else {
          this.submitApproveAction = 'Submit / Approve';
        }
        this.cdr.markForCheck();
      })
    );
    this.subs.push(
      this.rxLayer.tableData.selection.changed.subscribe(value => {
        if (this.rxLayer.tableData.selection.selected.length === 0) {
          this.canResendEmail = false;
          this.canSubmitApprove = false;
          this.cdr.markForCheck();
          return false;
        } else {
          if (this.rxLayer.tableData.selection.selected.length >= 1) {
            (async () => {
              const res = await this.rxLayer.queryFeatures({
                where: `OBJECTID in (${this.rxLayer.tableData.selection.selected.join(',')})`,
                returnGeometry: false,
                outFields: '*',
                f: 'json'
              });
              // handle the selection / check PIN status for Submit and or email resend
              this.handleSelection(res);
            })();
          } else {
            this.canResendEmail = false;
            this.canSubmitApprove = false;
          }
        }
        setTimeout(() => {
          this.highLightSelection();
        }, 1000);
      })
    );
    this.cdr.markForCheck();
    // subscribe to override - for tutorial to display workflow
    this.tutorialActionService.approvalOverride$.subscribe(override => {
      if (override.PIN) {
        this.showDetails({ PIN: override.PIN });
      } else {
        this.viewDetailsForPin.next(null);
      }
    });
  }

  ngOnDestroy() {
    // console.log('numberOfFilterChanges', this.numberOfFilterChanges);
    if (this.numberOfFilterChanges > 1) {
      this.pinsRxLayer.filters.next(this.rxLayer.filters.value);
    }
    this.subs.forEach(sub => sub.unsubscribe());
    this.rxLayer.dispose();
  }

  tableRowsLoading(event: boolean) {
    this.tableRowsLoading$.next(event);
  }

  async resendEmail() {
    this.cdr.markForCheck();
    try {
      const attsForEach = await this.getPinAttributesByOBJECTIDs(this.rxLayer.tableData.selection.selected);
      const config = {
        data: { attsForEach }
      };
      // await this.lazyDialogService.open('approval-email-dialog', config)
      this.dialog.open(ApprovalEmailDialogComponent, config);
    } catch (error) {
      await this.dialogNoticeService.error({ message: error });
    }
    this.cdr.markForCheck();
  }

  async submit() {
    const atts: PinAttributes[] = this.rxLayer.tableDataSelectedRows;
    const res = await this.submitApproveService.submitOrApproveScopes(atts);
    if (!res.abort) {
      await this.refreshFeatures();
      // select PIN..this will force a refresh of the Details Pane...
      atts.forEach(a => this.pinsRxLayerService.selectPin(a.PIN));
    }
  }

  showDetails(row) {
    this.viewDetailsForPin.next(row.PIN);
  }

  showApprovalDiagram() {
    // const docUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/assets/scope-approval-process.pdf`;
    window.open('https://projectpriorities-assets.s3.amazonaws.com/docs/scope-approval-process.pdf', '_blank');
  }

  async emailTest() {
    const OBJECTIDs = this.rxLayer.tableData.selection.selected;
    const attsForEach = await this.getPinAttributesByOBJECTIDs(OBJECTIDs);
    const config = {
      data: { attsForEach }
    };
    this.dialog.open(ApprovalEmailDialogComponent, config);
    // await this.lazyDialogService.open('approval-email-dialog', config);
  }

  fullscreen() {
    this.dock = 'FullScreen';
    this.height = heightConfig[this.dock];
    this.class = classConfig[this.dock];
    this.cdr.markForCheck();
  }

  dockBottom() {
    this.dock = 'DockBottom';
    this.height = heightConfig[this.dock];
    this.class = classConfig[this.dock];
    this.cdr.markForCheck();
  }

  // handles the selection result data / check PIN status for Submit and or email resend
  private handleSelection(res) {
    const selectionCount = res.length;
    const canSubmit = [];
    const canEmail = [];
    res.forEach(data => {
      const atts: PinAttributes = data.attributes;
      if (atts.STATUS === 'Approved') {
        canEmail.push(atts.OBJECTID);
      } else {
        canSubmit.push(atts.OBJECTID);
      }
    });
    // check email selection counts
    if (selectionCount === canEmail.length) {
      this.canResendEmail = true;
    } else {
      this.canResendEmail = false;
    }
    // check canSubmit selection counts
    if (selectionCount === canSubmit.length) {
      this.canSubmitApprove = true;
    } else {
      this.canSubmitApprove = false;
    }
  }

  private async createPinsFilter() {
    // create filter
    const pinsVisibleFields = await this.pinsRxLayer.visibleFieldsPromise;
    const pinField = pinsVisibleFields.find(field => field.name === 'PIN');
    const newFilter = this.filterFactory.createFilter(pinField);
    newFilter.value = this.data?.hash ? [this.data.hash] : [];
    newFilter.active = true;
    return newFilter;
  }

  private rxLayerHasFilter(filter: Filter) {
    return this.rxLayer.filters.value
      .filter(f => f.type === filter.type && f.value === filter.value).length === 1
      ? true : false;
  }

  // to handle #approve hash data
  private async handleApproveHash() {
    // old call to show the details
    // this.showDetails({PIN: this.data.hash});

    // check if the filter exists, if not create it
    if (!this.hashApprovalFilter$.value) {
      const newFilter = await this.createPinsFilter();
      this.hashApprovalFilter$.next(newFilter);
    }

    // used to check if the filter was already applied
    const filterApplied = this.rxLayerHasFilter(this.hashApprovalFilter$.value);

    // if has site was loaded with #approve hash
    if (this.data && this.data.hash && !filterApplied) {
      // check if scope exists
      const scopeExists = await this.scopeExists(this.data.hash);
      if (scopeExists) {
        // apply approval hash filter once the table rows are loaded
        this.tableRowsLoading$.pipe(
          filter(loading => !loading),
          take(1)
        ).subscribe(() => {
          const filters = [...this.rxLayer.filters.value, this.hashApprovalFilter$.value];
          this.rxLayer.filters.next(filters);
        });
        // highlight and zoom to project
        this.pinsRxLayerService.selectPin(this.data.hash);
      } else {
        await this.dialogNoticeService.error({ message: `${this.data.hash} doesn't appear to exist, unable to load Scope Approval` });
      }
    }
  }

  private async scopeExists(pin: string) {
    const res = await this.pinsRxLayer.queryFeatures({
      where: `PIN = '${pin}'`,
      returnGeometry: false,
      outFields: 'PIN',
      f: 'json'
    });
    if (res.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  private async highLightSelection() {
    const OBJECTIDs = this.rxLayer.tableData.selection.selected;
    if (OBJECTIDs.length > 0) {
      // highlight first item only, otherwise if a large set is selected map goes CRAZY
      const oid = [OBJECTIDs[0]];
      const attrsForEach = await this.getPinAttributesByOBJECTIDs(oid);
      attrsForEach.forEach(item => {
        this.pinsRxLayerService.selectPin(item.PIN);
      });
    } else {
      this.pinsRxLayerService.selectPin(null);
    }
  }

  private async getPinAttributesByOBJECTIDs(OBJECTIDs: number[]): Promise<PinAttributes[]> {
    const where = `OBJECTID in (${OBJECTIDs.join(',')})`;
    return this.pinsRxLayerService.getAttributesForPins(where);
  }

  private async createRxLayer() {
    this.pinsRxLayer = await this.pinsRxLayerService.getLayer();

    const initFeatures = await this.pinsRxLayer.queryFeatures({
      where: `PIN like 'S%'`,
      returnGeometry: false,
      outFields: '*',
      f: 'json'
    });

    const fields = await this.pinsRxLayer.fieldsPromise;
    // Need to take(1) to complete - https://github.com/ReactiveX/rxjs/issues/2536
    const mapAssets = await this.mapService.mapAssets$.pipe(
      filter(assets => assets !== null && assets !== undefined),
      take(1)).toPromise();
    const map = mapAssets.map;

    const filters = this.clonerService.deepClone<Filter[]>(this.pinsRxLayer.filters.value);
    const stageFilter = filters.find(f => f.type === FilterType.StageSelect);
    if (!stageFilter) {
      this.dialogNoticeService.alert({ message: 'Stage filter could not be found.' });
      return;
    } else {
      stageFilter.value = stageFilter.value.filter(x => x.indexOf('PIN_') === -1);
    }

    return new RxLayer({
      map,
      title: 'Scope Approval',
      visible: false,
      displayColumns: ['PIN', 'DESCRIPTION', 'STATUS', 'USERNAME', 'NEXT_APPROVER', 'DISTRICT', 'PROGRAM_TYPES'],
      filters,
      hideFields: PINS_RX_LAYER_HIDE_FIELDS,
      spatialReference: { wkid: 102100 },
      clientSideGraphicOptions: {
        displayField: 'PIN',
        geometryType: 'polyline',
        fields,
        initFeatures
      }
    });
  }

  private async refreshFeatures() {
    this.refreshing = true;
    this.cdr.markForCheck();
    const currentFeatures = await this.rxLayer.queryFeatures({
      where: '1=1',
      returnGeometry: 'false',
      outFields: '*',
      f: 'json'
    });
    await this.rxLayer.applyEdits({
      deleteFeatures: currentFeatures
    });
    const newFeatures = await this.pinsRxLayer.queryFeatures({
      where: `PIN like 'S%'`,
      returnGeometry: false,
      outFields: '*',
      f: 'json'
    });
    await this.rxLayer.applyEdits({
      addFeatures: newFeatures
    });
    const ps = this.rxLayer.tableData.pageState.value;
    this.rxLayer.tableData.pageState.next({
      index: 0,
      size: ps.size,
      orderBy: ps.orderBy
    });
    this.refreshing = false;
    this.cdr.markForCheck();
  }

  updateWillNotSendEmail = (event: boolean) => {
    this.submitApproveService.willNotSendEmail.next(event);
  }
}

/*
@NgModule({
  declarations: [ApprovalWorkflowDialogComponent],
  imports: [SharedModule, AuditDetailsModule, ApprovalEmailDialogModule],
})
export class ApprovalWorkflowDialogModule {}
*/

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { ArcgisSecurityService } from './arcgis-security.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private arcgisSecurityService: ArcgisSecurityService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.getCredentials().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
      // tap(activate => console.log('Can Aactivate ->', activate))
    );
  }

  private getCredentials() {
    return this.arcgisSecurityService.credential$.pipe(
      tap(cred => this.loginPortalUser(cred)),
      filter(cred => !!cred),
      take(1)
    );
  }

  private loginPortalUser(cred: __esri.Credential) {
    if (!cred) {
      this.arcgisSecurityService.login();
    }
  }

}

import { Injectable } from '@angular/core';
import { RelatedTableLookups } from './related-table-lookups';
import { MultiScannerResults } from './multi-scanner';
import { environment } from 'src/environments/environment';
import { PRIORITY_RESULTS_FIELD_EXCLUDE, VALUATION_FIELD_EXCLUDE } from './amalgamator.fields';
import { PinAttributes } from './pin-attributes';
import { ProjectStage } from 'src/app/types/project-stages';

// This Amalgamator takes feature collections from many endpoints and
// combines them into one set of features that represents scopes and pins.

@Injectable({
  providedIn: 'root'
})
export class FeaturesAmalgamator {

  createPins(multiScannerResults: MultiScannerResults): __esri.Graphic[] {

    const lu = new RelatedTableLookups(multiScannerResults);
    const features = multiScannerResults.projectScoping.features.map(f => {
      return <__esri.Graphic>{
        attributes: f.attributes
      };
    });

    // fill in geometry and attributes from other layers
    features.forEach((g: __esri.Graphic) => {
      const atts: PinAttributes = g.attributes;

      const PIN = atts.PIN; // either the PIN or Scope ID

      // Project Number
      const PROJECT_NUMBER = lu.projectNumber___byPin[PIN];
      atts.PROJECT_NUMBER = PROJECT_NUMBER ? PROJECT_NUMBER[0].attributes.PROJECT_NUMBER : null;

      // STAGE ----------------------------------------------------------------
      const isPin = this.isPin(g);
      const isPlan = this.isPlan(g);
      if (isPin) {
        atts.STAGE = `PIN_${atts.PROG_EST_SUM > 0 ? 'PROGRAMMED' : 'APPROVED'}`;
      } else if (isPlan) {
        atts.STAGE = ProjectStage.scopePlanScenario;
      } else {
        atts.STAGE = `SCOPE_${atts.STATUS.toUpperCase()}`;
      }

      // SCOPE TO PIN Flag
      atts.IS_SCOPE_TO_PIN = atts.PIN.indexOf('S') === -1
      && atts.PROJECT_SCOPE_ID && atts.PROJECT_SCOPE_ID.length > 0 ? 1 : 0;

      // PRIORITY RESULTS (priority, mobility, complexity) --------------------
      const priorityResEntries = lu.priorityResults_byPin[PIN];

      if (priorityResEntries) {

        const priorityRes = priorityResEntries[0].attributes;

        Object.keys(priorityRes).forEach(key => {
          if (PRIORITY_RESULTS_FIELD_EXCLUDE.indexOf(key) > -1) {
            return;
          }
          if (Object.prototype.hasOwnProperty.call(atts, key)) {
            // console.error('attribute already exists: ' + key);
          } else {
            atts[key] = priorityRes[key];
          }
        });
      }

      if (!Object.prototype.hasOwnProperty.call(atts, 'PERFORMANCE_SCORE')) {
        atts.PERFORMANCE_SCORE = null; // ensure property exists, even if with null value
      }


      // VALUATION ------------------------------------------------------------
      const valuationEntries = lu.valuationConfig__byPin[PIN];

      if (valuationEntries) {
        const valuation = valuationEntries[0].attributes;

        Object.keys(valuation).forEach(key => {
          if (VALUATION_FIELD_EXCLUDE.indexOf(key) > -1) {
            return;
          }
          if (Object.prototype.hasOwnProperty.call(atts, key)) {
            // console.error('attribute already exists: ' + key);
          } else {
            atts[key] = valuation[key];
          }
        });
      }

      // MANY-TO-ONE FIELDS ---------------------------------------------------

      const distinct = (list: string[]) => {
        const distinctObj = {};
        list.forEach(entry => distinctObj[entry] = 1);
        return Object.keys(distinctObj);
      };

      const extractList = (entries: __esri.Graphic[], attrName?: string) => {
        const attsList = entries ? entries.map(x => x.attributes) : [];
        if (!attrName) {
          return attsList;
        }
        const stringList = attsList.map(x => ('' + x[attrName]).trim());
        return distinct(stringList);
      };

      atts.PROJECT_TYPES = extractList(lu.projectTypes____byPin[PIN], 'PROJECTTYPE').join(', ');
      atts.PROGRAM_TYPES = extractList(lu.programTypes____byPin[PIN], 'PROGRAMTYPE').join(', ');
      atts.WORK_TYPES = extractList(lu.workTypes_______byPin[PIN], 'WORKCODE').join(', ');
      atts.WORK_GROUPS = extractList(lu.workGroups______byPin[PIN], 'WORKGROUP').join(', ');
      atts.COUNTIES = extractList(lu.counties________byPin[PIN], 'COUNTY').join(', ');
      atts.IOWA_STATE_SENATE_DISTRICTS = extractList(lu.iowaStateSenateDistricts_byPIN[PIN], 'STATE_SENATE_DISTRICT').join(', ');
      atts.IOWA_STATE_HOUSE_DISTRICTS = extractList(lu.iowaStateHouseDistricts_byPIN[PIN], 'STATE_HOUSE_DISTRICT').join(', ');
      atts.IOWA_CONGRESSIONAL_DISTRICTS = extractList(lu.iowaCongressionalDistricts_byPIN[PIN], 'US_HOUSE_DISTRICT').join(', ');
      atts.IOWA_US_HOUSE_REP_NAME = extractList(lu.iowaCongressionalDistricts_byPIN[PIN], 'US_HOUSE_REP_NAME').join(', ');

      const progTypes = extractList(lu.programTypes____byPin[PIN]).map(x => ({
        PROGRAMTYPE: x.PROGRAMTYPE,
        FUNDING_DESCRIPTION: x.FUNDING_DESCRIPTION
      }));
      atts.PROGRAM_TYPES_JSON = JSON.stringify(progTypes);

      const projTypes = extractList(lu.projectTypes____byPin[PIN]).map(x => ({
        PROJECTTYPE: x.PROJECTTYPE,
        PURPOSEDESCRIPTION: x.PURPOSEDESCRIPTION
      }));
      atts.PROJECT_TYPES_JSON = JSON.stringify(projTypes);


      // GEOMETRY -------------------------------------------------------------
      const geometryLookup = this.isPin(g) ? lu.geometries_byPin : lu.geometries_byScopeId;
      const geometries = geometryLookup[PIN] || (lu.geometries_byScopeId[PIN] || []);
      atts.HAS_GEOMETRY = geometries.length ? 1 : 0;

      const polylines = geometries.map(x => <__esri.Polyline>x.geometry);
      g.geometry = this.combinePolylines(polylines);

      const routesAndMeasures = geometries
        .sort((a, b) => {
          // sort first by whether routes are primary
          const aIsPrimary = a.attributes.ROUTE_ID === atts.PRIMARY_ROUTE ? 1 : 0;
          const bIsPrimary = b.attributes.ROUTE_ID === atts.PRIMARY_ROUTE ? 1 : 0;
          if ((!!aIsPrimary) || (!!bIsPrimary)) {
            return aIsPrimary < bIsPrimary ? 1 : -1;
          }
          // then by length (longest first)
          const lengthA = Math.abs(a.attributes.TO_MEASURE - a.attributes.FROM_MEASURE);
          const lengthB = Math.abs(b.attributes.TO_MEASURE - b.attributes.FROM_MEASURE);
          return lengthA < lengthB ? 1 : -1;
        })
        .map(x => ({
          ROUTE_ID: x.attributes.ROUTE_ID,
          FROM_MEASURE: x.attributes.FROM_MEASURE,
          TO_MEASURE: x.attributes.TO_MEASURE,
          PATHS: x.geometry['paths']
        }));
      atts.ROUTES_AND_MEASURES_JSON = JSON.stringify(routesAndMeasures);
    });

    const filteredOutExpiredMP = this.filterExpiredMP(features);
    // filter results if environment is commission or uat
    if (environment.isCommission) {
      return filteredOutExpiredMP.filter((g: __esri.Graphic) => {
        const entries: any = lu.commissionConfig__byPin[g.attributes.PIN];
        const entry = (entries && entries.length) ? entries[0] : null;
        const specialInclude = entry && entry.attributes.COMMISSION_VIEW !== 0;
        if (specialInclude) {
          return true;
        } else {
          const isRedFile = g.attributes.STATUS === 'Red File';
          return g.attributes.HAS_GEOMETRY && this.isPin(g) && !isRedFile;
        }
      });
    }
    if (environment.isUat) {
      return filteredOutExpiredMP.filter((g: __esri.Graphic) => {
        return g.attributes.HAS_GEOMETRY;
      });
    }

    return filteredOutExpiredMP;
  }

  private isPin(f: __esri.Graphic): boolean {
    const isScope = f.attributes.PIN.indexOf('S') === 0;
    const isPlan = f.attributes.PIN.indexOf('X') === 0;
    return !(isScope || isPlan);
  }

  private isPlan(f: __esri.Graphic): boolean {
    return f.attributes.PIN.indexOf('X') === 0;
  }

  // combine many polylines into a single polyline by concatenating paths
  private combinePolylines(polylines: __esri.Polyline[]) {
    const paths = [];
    polylines.forEach(polyline => {
      polyline.paths.forEach(path => paths.push(path));
    });
    return <__esri.Polyline>{
      type: 'polyline',
      paths,
      spatialReference: { wkid: 3857 }
    };
  }

  private filterExpiredMP(features: any[]): any[] {
    const monthDiff = (d1: Date, d2: Date): number => {
      let months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth() + 1;
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    };

    const filteredResults = features.filter(feat => {
      const { DESIREDFISCALYEAR, DESIRED_FISCAL_MONTH, PROGRAM_TYPES, PIN } = feat.attributes;
      // MP program Type is = '8'
      const isOnlyMP = PROGRAM_TYPES === '8';
      if (!isOnlyMP) {
        return true;
      } else {
        const today = new Date();
        const scope = new Date(DESIREDFISCALYEAR, DESIRED_FISCAL_MONTH);
        const numMonths = monthDiff(scope, today);
        const maxNumMonthsTillExpired = 12;
        const isTooOld = numMonths > maxNumMonthsTillExpired;
        const isScope = PIN[0].toUpperCase() === 'S';

        // if (isTooOld && isScope && !environment.production) {
        //   console.log(`MP project ${PIN} is ${numMonths} months old and will be filtered out`, feat.attributes);
        // }
        return !(isTooOld && isScope);
      }
    });

    return filteredResults;
  }
}

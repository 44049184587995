import { Injectable } from '@angular/core';

import { ApprovedPinInfo } from './approved-pin-info';
import { EMAIL_COMMON_STYLES } from './common/styles';
import { EMAIL_HEADER } from './common/header';
import { EMAIL_FOOTER } from './common/footer';
import { getApprovalLink } from './common/get-approval-link';
import { getApprovalStagesHtml } from './common/get-approval-stages-html';
import { FinalApprovalEmailFiles } from './email-content-final-files.service';
import { ApprovalPolicy } from '../scope-approval/approval-workflow-policy';
import { AttachmentService } from 'src/app/services/export-and-doc/attachment.service';
import { PRIORITY_KEYS } from '../services/layer/priority-rx-layers.service.config';

@Injectable({
  providedIn: 'root'
})
export class EmailContentFinalService {
  constructor(
    private attachmentService: AttachmentService
  ) {
  }

  async getFinalApprovalEmailHtml(approvedPinsInfo: ApprovedPinInfo[], finalApprovalEmailFiles: FinalApprovalEmailFiles, fundingTypes: { [fundingType: string]: boolean }) {
    try {
      const attachments = await this.getAttachments(approvedPinsInfo[0]);
      return `
      <html>
        <head></head>
        <body>
          <table ${EMAIL_COMMON_STYLES.TABLE_ATTRS}>
            <tbody>
              ${EMAIL_HEADER}
              ${this.getIntroTr({ multi: approvedPinsInfo.length > 1, approvedPinsInfo, fundingTypes })}
              ${approvedPinsInfo.length === 1 ? (
    this.getAuditsTr(approvedPinsInfo[0])
        + this.getScopeSummary(approvedPinsInfo[0])
        + this.getScheduleTr(approvedPinsInfo[0])
        + this.getImpactsTr(approvedPinsInfo[0])
  ) : ''}
              ${this.getLinks(finalApprovalEmailFiles)}
              ${attachments}
              ${EMAIL_FOOTER}
            </tbody>
          </table>
        </body>
      </html>`;
    } catch (err) {
      console.error('🚀 ~ file: email-content-final.service.ts ~ line 27 ~ EmailContentFinalService ~ getFinalApprovalEmailHtml ~ err', err);
      throw new Error(err);
    }
  }

  getIntroTr(params: { multi: boolean, approvedPinsInfo: ApprovedPinInfo[], fundingTypes: { [fundingType: string]: boolean } }) {
    // find the funding type with a true value. If none, then the variable will be undefined.
    const abbreviatedProjectFunding = Object.keys(params.fundingTypes).map(fundingType => ({
      fundingType,
      value: params.fundingTypes[fundingType]
    })).find(x => x.value === true);
    if (abbreviatedProjectFunding) {
      const fundingTypeKey = {
        MP: `${params.multi ? 'Project Numbers for the Pavement Maintenance (MP) projects' : 'a Project Number for the Pavement Maintenance (MP) project'}`,
        ADA: `${params.multi ? 'PIN Numbers for the ADA projects' : 'a PIN Number for the ADA project'}`
      };
      const text = params.multi
        ? `
          The projects identified in the attached files have been properly entered and vetted in the project scoping and prioritization system.
          <b>Please create ${fundingTypeKey[abbreviatedProjectFunding.fundingType]} listed below</b> within the Project Scheduling System (PSS).
          If you have additional questions or concerns, please don’t hesitate to contact:`
        : `
          The project identified below has been properly entered and vetted in the project scoping and prioritization system.
          <b>Please create ${fundingTypeKey[abbreviatedProjectFunding.fundingType]} listed below</b> within the Project Scheduling System (PSS).
          If you have additional questions or concerns, please don’t hesitate to contact:
        `;
      return `<tr><td>${text}</td></tr>`;
    } else {
      const text = params.multi
        ? `
          The scopes listed in the attached files have been properly entered and vetted in the
          project scoping and prioritization system. Please create a Project Identification Number (PIN) and
          the appropriate projects within the Project Scheduling System (PSS) for each new PIN. If you have
          additional questions or concerns, please don't hesitate to contact the individuals listed in the
          audit file who entered and approved the scope.`
        : `
          The scope listed below has been properly entered and vetted in the project scoping and prioritization
          system. Please create a Project Identification Number (PIN) and the appropriate projects within the
          Project Scheduling System (PSS). If you have additional questions or concerns, please don't hesitate
          to contact the individuals listed below who entered and approved the project.
        `;
      return `<tr><td>${text}</td></tr>`;
    }
  }

  async getAttachments(approvedPinInfo: ApprovedPinInfo) {
    let links = '';
    const attachments = await this.attachmentService.getAttachments(approvedPinInfo.pinAttributes.PIN, false);
    if (attachments.length > 0) {
      attachments.forEach(item => links += `<br><a href=${encodeURI(item.url)}>${item.FileName}</a>`);
    } else {
      links = '<br>None';
    }
    return `
    <tr>
      <td>
      <br><b>File Attachments</b>
        ${links}
      </td>
    </tr>`;
  }

  getLinks(finalApprovalEmailFiles: FinalApprovalEmailFiles) {
    return `
    <tr>
      <td>
        <br>
        <a href="${finalApprovalEmailFiles.attributesAndPriorityCsvUrl}">Attributes and Priority csv</a> A list of attributes and priority scores for the project(s)<br>
        <a href="${finalApprovalEmailFiles.auditsCsvUrl}">Audits csv</a> A listing of who has reviewed and approved the project(s)<br>
        <a href="${finalApprovalEmailFiles.pssEventsTxtUrl}">Schedule of PSS events txt</a> A schedule of the PSS events associated with the project(s)<br>
        <br>
        <br>
        </td>
        </tr>`;
    // <a href="${finalApprovalEmailFiles.impactCountsTxtUrl}">Impacts txt</a>
  }

  getScopeSummary(approvedPinInfo: ApprovedPinInfo) {
    const m = approvedPinInfo.misc;
    const a = approvedPinInfo.pinAttributes;
    const pc = approvedPinInfo.priorityCounts;
    const yearMonthFormatOptions = {
      'To Letting': val => `${val}`,
      'Through Planning (to concept)': val => `${val} (D00,B00,P00)`,
      'Through Design': val => `${val} (D04/D05)`
    };
    const defaultFormat = val => `${val}`;
    const formatYearMonth = yearMonthFormatOptions[m.levelOfStudyRequired] || defaultFormat;
    let surveyRequired = '';
    switch (a.SURVEY_REQUIRED) {
    case 0:
      surveyRequired = 'No';
      break;
    case 1:
      surveyRequired = 'Yes';
      break;
    case 2:
      surveyRequired = 'Unknown';
      break;
    default:
      surveyRequired = 'Undefined';
      break;
    }
    return `
    <tr>
      <td>
        <br><br>
        <div style="${EMAIL_COMMON_STYLES.SECTION_TITLE_STYLE}">${approvedPinInfo.pinAttributes.PIN}</div>
        <div style="${EMAIL_COMMON_STYLES.SECTION_TITLE_STYLE}">Project Information</div>
        <strong>Program Funding Type: </strong> ${m.programFunding}<br>
        <strong>Project Purpose:      </strong> ${m.projectPurpose}<br>
        <strong>Bridge FHWA Number:          </strong> ${m.bridgeFhwaNumber}<br>
        <strong>Bridge Maintenance Number:    </strong> ${m.bridgeMaintenanceNumber}<br>
        <strong>District:             </strong> ${a.DISTRICT}<br>
        <strong>County:               </strong> ${m.counties}<br>
        <strong>Primary Route:        </strong> ${m.readableRouteName}<br>
        <strong>Location Description: </strong> ${a.DESCRIPTION}<br>
        <strong>Additional Justification: </strong> ${a.ADDITIONAL_JUSTIFICATION}<br>
        <br>
      </td>
    </tr>
    <tr>
      <td>
        <table ${EMAIL_COMMON_STYLES.TABLE_ATTRS}>
          <tr valign="top">
            <td width="50%">
              <table ${EMAIL_COMMON_STYLES.TABLE_ATTRS}>
                <tr><td><strong>Beginning Milepost (Ref. Post): </strong> ${m.refPostFromValue} (${m.refPostFromName})</td></tr>
                <tr><td><strong>Ending Milepost (Ref. Post):    </strong> ${m.refPostToValue} (${m.refPostToName})</td></tr>
                <tr><td><strong>Approx. Primary Route Length (Ref. Post):          </strong> ${m.refPostLength}</td></tr>
                <tr><td><strong>Primary Route Length (LRS):          </strong> ${m.lrsPrimaryLength} (Miles)</td></tr>
                <tr><td><strong>Total Length of All Segments (LRS):         </strong> ${m.lrsTotalLength} (Miles)</td></tr>
                <tr><td><strong>Level of Study Required:             </strong> ${m.levelOfStudyRequired}</td></tr>
                <tr><td><strong>Anticipated Range of ROW Required:   </strong> ${m.rowNeed}</td></tr>
                <tr><td><strong>Outside Services Expected:           </strong> ${m.outsideServices}</td></tr>
                <tr><td><strong>Project Development Completed by:    </strong> ${m.pdCompletedBy}</td></tr>
              </table>
            </td>
            <td>
              <table ${EMAIL_COMMON_STYLES.TABLE_ATTRS}>
                <tr><td><strong>Budget Range Min (1000's):       </strong>$${a.BUDGETRANGEMIN}</td></tr>
                <tr><td><strong>Budget Range Median (1000's):    </strong> ${m.budgetMedianCalc}</td></tr>
                <tr><td><strong>Budget Range Max (1000's):       </strong>$${a.BUDGETRANGEMAX}</td></tr>
                <tr><td><strong>Desired Letting Month:           </strong> ${formatYearMonth(m.desiredFiscalMonth)}</td></tr>
                <tr><td><strong>Desired Letting Fiscal Year:     </strong> ${formatYearMonth(a.DESIREDFISCALYEAR)}</td></tr>
                <tr><td><strong>Level of Documentation Expected: </strong> ${m.levelOfDocumentation}</td></tr>
                <tr><td><strong>Survey Required:                 </strong> ${surveyRequired}</td></tr>
              </table>
            </td>
          </tr>
        </table>
        <strong>Work Groups Included: </strong> ${m.workGroups}<br>
        <strong>Work Types Included: </strong> ${m.workTypes}<br>
        <br>
        <hr>
      </td>
    </tr>
    <tr>
      <td>
        <br>
        <div style="${EMAIL_COMMON_STYLES.SECTION_TITLE_STYLE}">Priority</div>
        <strong>Overall Priority </strong>: Score: ${Math.floor(a.PERFORMANCE_SCORE)}<br>
        <strong>Bridge           </strong>: Score: ${Math.floor(a.BRIDGE_SCORE)} Count: ${pc[PRIORITY_KEYS.bridges]}<br>
        <strong>Pavement         </strong>: Score: ${Math.floor(a.PAVEMENT_SCORE)} Count: ${pc[PRIORITY_KEYS.pavement]}<br>
        <strong>Traffic          </strong>: Score: ${Math.floor(a.TRAFFIC_SCORE)} Count: ${pc[PRIORITY_KEYS.traffic]}<br>
        <strong>Safety           </strong>: Score: ${Math.floor(a.SAFETY_SCORE)} Count: ${pc[PRIORITY_KEYS.safety]}<br>
        <strong>Freight          </strong>: Score: ${Math.floor(a.FREIGHT_SCORE)} Count: ${pc[PRIORITY_KEYS.freight]}<br>
        <strong>Road Class       </strong>: Score: ${Math.floor(a.IMPORTANCE_SCORE)} Count: ${pc[PRIORITY_KEYS.roadClass]}
      </td>
    </tr>`;
  }

  getScheduleTr(approvedPinInfo: ApprovedPinInfo) {
    const bulletedEvents = approvedPinInfo.pssEvents.map(evt => `• ${evt}`);
    if (!bulletedEvents.length) {
      bulletedEvents.push('(none)');
    }
    return `
    <tr>
      <td>
        <br>
        <div style="${EMAIL_COMMON_STYLES.SECTION_TITLE_STYLE}">
          Needed Schedule Events
        </div>
        ${bulletedEvents.join('<br>')}
      </td>
    </tr>`;
  }

  getAuditsTr(approvedPinInfo: ApprovedPinInfo) {
    const createdByEmail = approvedPinInfo.pinAttributes.USERNAME;
    const sortedAudits = approvedPinInfo.audits.sort((a, b) => a.TIMESTAMP - b.TIMESTAMP);
    let approvalStages = '';
    if (approvedPinInfo.approvalPolicy) {
      approvalStages = this.getApprovalStages(approvedPinInfo.approvalPolicy);
    }
    return `
    <tr>
      <td>
        <br>
        <hr>
        <br>
        Scope Created By<br>
        <a href="mailto:${createdByEmail}">${createdByEmail}<a/><br>

        Approved By<br>
        <table ${EMAIL_COMMON_STYLES.TABLE_ATTRS}>
          <tbody>
          ${sortedAudits.map(audit =>
    `<tr>
              <td style="padding-right: 8px">
                <a href="mailto:${audit.USER_NAME}">${audit.USER_NAME}<a/> as ${audit.USER_GROUPS.split(',').join(', ')}
              </td>
            </tr>`
  ).join('')}
          </tbody>
        </table>
      </td>
    </tr>
    <br>
    ${approvalStages}
    `;
  }

  getImpactsTr(approvedPinInfo: ApprovedPinInfo) {

    const formatImpactKey = (x: string) => {
      return x.indexOf('PIN ') === 0 ? x.replace('PIN ', '') : x;
    };

    const impactEntries = approvedPinInfo.impactCounts
      .filter(x => x.count > 0)
      .map(x => `<strong>${formatImpactKey(x.title)}</strong>: ${x.count}`);

    const impactDistance = approvedPinInfo.pinAttributes.IMPACT_DISTANCE ? approvedPinInfo.pinAttributes.IMPACT_DISTANCE : 0.25;
    return `
    <tr>
      <td>
        <br>
        <div style="${EMAIL_COMMON_STYLES.SECTION_TITLE_STYLE}">
          Impacts <span style="font-size:14px">(potential impacts or identified items within ${impactDistance} miles of the project area)</span>
        </div>
        ${impactEntries.length ? impactEntries.join('<br>') : '(none)'}
      </td>
    </tr>`;
  }

  getApprovalStages(policy: ApprovalPolicy): string {
    return `
    <tr>
      <td>
        <strong>Approval Stages</strong><br>
        ${getApprovalStagesHtml(policy)}<br>
        <strong><a href="${getApprovalLink(policy.scope_id)}">View Approval Process</a></strong>
      </td>
    </tr>`;
  }


}

class Release {
  upcoming?: boolean;
  version: string;
  date: string;
  completed: string[];
}

export const CHANGELOG: Release[] = [
  {
    upcoming: false,
    version: "3.34",
    date: "08/17/2023",
    completed: [
      'Home - Add LRTP to charter',
      'Housekeeping - Upgrade Angular to v14',
      'Housekeeping - Migrate to MSAL from ADAL',
    ]
  },
  {
    upcoming: false,
    version: "3.33",
    date: "10/25/2022",
    completed: [
      "Home - Added ability for specific users to not send emails when approving scopes"
    ]
  },
  {
    upcoming: false,
    version: "3.32",
    date: "10/4/2022",
    completed: [
      "Dashboard - Cost & Budget - Fix page",
      "Housekeeping - Add eslint"
    ]
  },
  {
    upcoming: false,
    version: "3.31",
    date: "8/4/2022",
    completed: [
      "Dashboard - Cascading Filters - Selecting County updates District",
      "Home - Impact Disclaimer"
    ]
  },
  {
    upcoming: false,
    version: "3.30",
    date: "6/23/2022",
    completed: [
      "Dashboard - Cascading Filters - improved performance using client side filtering",
      "Dashboard - Multi-Select Filters",
      "Dashboard - Scope/PIN layer control with improved symbology",
      "Dashboard - Trigger prioritization during System to Planning Scope Conversion"
    ]
  },
  {
    upcoming: false,
    version: "3.29",
    date: "6/9/2022",
    completed: [
      "Dashboard - Cascading Filters",
      "Dashboard - County / District Filter will apply def. expression to map layer",
      "Dashboard - Tool to save / convert system segments to 'Planning Scenario Project'"
    ]
  },
  {
    upcoming: false,
    version: "3.28",
    date: "5/26/2022",
    completed: [
      "Dashboard - Ready to test",
      "Dashboard - New Filtering System using Form",
      "Dashboard - Road Class / County Filter",
      "Dashboard - Export Segment List to CSV",
      "Dashboard - Toggle to Display Top Segments"
    ]
  },
  {
    upcoming: false,
    version: "3.27",
    date: "4/14/2022",
    completed: [
      "Pending Email notices - District users will get Cc on 4R program type"
    ]
  },
  {
    upcoming: false,
    version: "3.26",
    date: "3/31/2022",
    completed: [
      "Added impact tab disclaimer and rearrange impact tab layout",
      "Added impact disclaimer to charter",
      "Updated charter services to nodejs v14"
    ]
  },
  {
    upcoming: false,
    version: "3.25",
    date: "2/24/2022",
    completed: [
      "Updates to Hash Dialog to better handle map layer settings"
    ]
  },
  {
    upcoming: false,
    version: "3.24",
    date: "2/2/2022",
    completed: [
      "Revised display of primary route (lengths) info on the geometry tab",
      "Added total project segment lengths to final email",
      "Revised length information for final email",
      "Downgrade to ArcGIS 4.21.2 to deal with table sorting error"
    ]
  },
  {
    upcoming: false,
    version: "3.23",
    date: "1/25/2022",
    completed: [
      "Added priority impact dialog notification system (includes map)",
      "Enabled LRTP Mobility & Safety Impact email"
    ]
  },
  {
    upcoming: false,
    version: "3.22",
    date: "1/6/2022",
    completed: [
      "Upgrade - ArcGIS JS API 4.22.2",
      "Upgrade - Angular 13 / Material 13",
      "Added LRTP Mobility & Safety Impact Notice Dialog and email"
    ]
  },
  {
    upcoming: false,
    version: "3.21",
    date: "12/9/2021",
    completed: [
      "Upgrade - ArcGIS JS API 4.21.2",
      "Added testing service",
      "Improved RAMS reconcile error handling",
      "Added email notice when RAMS reconcile conflicts are detected",
      "Added Rail Crossing Projects Dialog System",
      "Rail Crossing Projects check during new project creation and approval workflow",
      "Updates to Ref. Post entry dialog - map labels and new text for mile post info",
      "Normalize County Names"
    ]
  },
  {
    upcoming: false,
    version: "3.20",
    date: "10/27/2021",
    completed: [
      "Geometry Tab - New Reference Post entry mode using offsets",
      "County Name Service - Switch to RAMS"
    ]
  },
  {
    upcoming: false,
    version: "3.19",
    date: "9/23/2021",
    completed: [
      "Fix - updates to Tutorial/Training Panels",
      "Switched to lazy loading for most dialogs"
    ]
  },
  {
    upcoming: false,
    version: "3.18",
    date: "7/30/2021",
    completed: [
      "Upgrade - ArcGIS JS API 4.20",
      "Upgrade - Angular 12 / Material 12",
      "Fix - Various changes made to accommodate ArcGIS JS API 4.20 upgrade",
      "Fix - maintain priority score after updating existing scope",
      "Fix - updating work group and/or work code enables save button",
      "Fix - Impact Distance - set default value so form is valid",
      "Fix - Popup location based on map view size vs. document",
      "Fix - Various minor bug fixes to improve user experience",
      "Added - Impact Distance - zoom to buffer on distance change or tab switch",
      "Added - Geometry segment color when marked to 'include'",
      "Added - Clear button for search input box",
      "Added - Improved Popup placement and callout line"
    ]
  },
  {
    upcoming: false,
    version: "3.17",
    date: "6/24/2021",
    completed: [
      "Added - Impact Details - Area and Distance Stats",
      "Added - Impact Zone - always draw the buffer graphic on top of other layers",
      "Bug Fix - 'Spatial Filter' triggering multiple pending approval dialogs",
      "Added - Scope to PIN Attribute Flag for Filtering",
      "Added - Quick Filter for Scope to PIN",
      "Added - Search will find previous Scope ID for a Scope to PIN Project",
      "Added - PIN Hash will honor Scope to PIN project"
    ]
  },
  {
    upcoming: false,
    version: "3.16",
    date: "5/27/2021",
    completed: [
      "Added - Highway Candidate Data Entry - First Release",
      "Added - Ability to modify impact buffer distance",
      "Added - 'System Scores' Layer Control, separate from other reference layers",
      "Added - 'Spatial Filter' to main table, filters records by map extent"
    ]
  },
  {
    upcoming: false,
    version: "3.15",
    date: "4/16/2021",
    completed: [
      "Planning Slider - First Release",
      "Security change and Service Migration to /ramsportal"
    ]
  },
  {
    upcoming: false,
    version: "3.14",
    date: "4/1/2021",
    completed: [
      "Construction event quick filter"
    ]
  },
  {
    upcoming: false,
    version: "3.13",
    date: "3/18/2021",
    completed: [
      "Planning Scenario project type added"
    ]
  },
  {
    upcoming: false,
    version: "3.12",
    date: "3/4/2021",
    completed: [
      "Allow user to edit a scope based on shared roles"
    ]
  },
  {
    upcoming: false,
    version: "3.11",
    date: "2/18/2021",
    completed: [
      "Added Measure Distance widget",
      "Added \"Additional Justification Field\" to project entry tab and final email",
      "Removed LEB NEPA as a required approval stage.  Users with the role will still get email of final approval",
      "Final email formatting adjustments"
    ]
  },
  {
    upcoming: false,
    version: "3.10",
    date: "1/21/2021",
    completed: [
      "Upgrade Angular/Material to version 11",
      "Added 'My Pending Approvals' Dialog Notification"
    ]
  },
  {
    upcoming: false,
    version: "3.09",
    date: "11/12/2020",
    completed: [
      "NR funding, goes to Division approval if budget max is >= 500,000",
      "Added Legislative District Filters to Main table (State/US House & Senate)",
      "Delete Multiple Projects -> Only reconcile RAMS once",
      "Added Iowa DOT Greyscale Basemap",
      "Trigger on demand NEPA impact calculation for new scope creation",
      "Impact Audit Table Fix on save of existing project",
      "Save/Load Hashes Per User Profile"
    ]
  },
  {
    upcoming: false,
    version: "3.08",
    date: "11/6/2020",
    completed: [
      "Removed NEPA review for MP/MB funding types",
      "Added Service Health Dialog",
      "Changed email body for initial creation email"
    ]
  },
  {
    upcoming: false,
    version: "3.07",
    date: "9/24/2020",
    completed: [
      "Hardcoded list of Admins get Bcc on all emails",
      "App Emails:  Changed from address to mail.priorities.iowadotpi.com to avoid junk folder",
      "Trigger on demand impact calcution for new scope creation",
      "Check to make sure impact calculations were run before submit/approve"
    ]
  },
  {
    upcoming: false,
    version: "3.06",
    date: "6/25/2020",
    completed: [
      "Updated Scope Approval Process Diagram",
      "Added 4R Email group, CC during initial scope creation",
      "Added MAIL FROM domain to AWS account",
      "Updated County Names View Service URL"
    ]
  },
  {
    upcoming: false,
    version: "3.05",
    date: "6/11/2020",
    completed: [
      "Submit / Approve Workflow - Disable Attachments when multiple projects selected"
    ]
  },
  {
    upcoming: false,
    version: "3.04",
    date: "6/4/2020",
    completed: [
      "RAMS Road Network / All Routes Service URL Change"
    ]
  },
  {
    upcoming: false,
    version: "3.03",
    date: "5/7/2020",
    completed: [
      "Charter Fixes - logo and batch generation"
    ]
  },
  {
    upcoming: false,
    version: "3.02",
    date: "4/24/2020",
    completed: [
      "Approval Audits dialog formatting, enable scrollable content"
    ]
  },
  {
    upcoming: false,
    version: "3.01",
    date: "3/19/2020",
    completed: [
      "Scope to PIN -> Duplicate PIN removed from Scoping Table",
      "Prompt for attachments during submit/approve process",
      "Drag/Drop attachments menu",
      "Final Email - attachment links"
    ]
  },
  {
    upcoming: false,
    version: "3.00",
    date: "3/5/2020",
    completed: [
      "Scope to PIN, check for Duplicate PIN in Scoping Table",
      "Redirect from scopinguat.iowadotprojectpriorities.com -> https://iowadotprojectpriorities.com",
      "Initial email subject line change to \"New Scope Created\""
    ]
  },
  {
    upcoming: false,
    version: "2.99",
    date: "2/20/2020",
    completed: [
      "Added E/C Funding Type",
      "Users with Admin role can delete PIN route segment",
      "Added Contact Form / Email Group to message Admins",
      "Enabled database (DynamoDB) logging of outgoing email notifications",
      "Impact Tab - Counts greater than 0 are red and bold"
    ]
  },
  {
    upcoming: false,
    version: "2.98",
    date: "2/6/2020",
    completed: [
      "Increased FME timeout to 10 minutes"
    ]
  },
  {
    upcoming: false,
    version: "2.97",
    date: "1/23/2020",
    completed: [
      "Renamed OLE to LEB",
      "Added App Role for LEB, moved users",
      "Removed Admin role and HNTB staff from pending email groups",
      "Added basic Help Dialog with contact info"
    ]
  },
  {
    upcoming: false,
    version: "2.96",
    date: "1/9/2020",
    completed: [
      "Added Bridge ID to initial submission email and safety email",
      "Fixed Charter map image issue after RAMS geometry migration",
      "Reconcile RAMS geometry versions on load and project save",
      "Users with Admin role can alter PIN geometry"
    ]
  },
  {
    upcoming: false,
    version: "2.95",
    date: "12/23/2019",
    completed: [
      "Removed HNTB staff @iowadot.us addresses from notification emails",
      "Added user that created a scope to the addresses for pending email notices",
      "Migration of Project Scoping Geometry into RAMS",
      "Added Survey Required Field to Bridge Projects Dialog",
      "Added 'No Score' to Legend for Priority Score"
    ]
  },
  {
    upcoming: false,
    version: "2.95",
    date: "12/19/2019",
    completed: [
      "Renamed Charter filed to PIN",
      "Completed multicharter Zip process"
    ]
  },
  {
    upcoming: false,
    version: "2.94",
    date: "12/13/2019",
    completed: [
      "Select geometry using a polygon",
      "Fixed main table footer issue on resize"
    ]
  },
  {
    upcoming: false,
    version: "2.93",
    date: "11/14/2019",
    completed: [
      "Enabled batch project download from main table",
      "Enabled batch delete scopes from main table",
      "Resolved issue related to only selecting OT funding type",
      "Added batch charter to main table",
      "Completed Safety Email for scope creation and submission",
      "Added Survey Required field to project tab",
      "Fixed bug with approval workflow dialog, no need to close after an approval",
      "Made Survery Required a required field",
      "Fixed bug with missing approval steps",
      "Added a confirm dialog when closing the bridge table after fields have been edited"
    ]
  },
  {
    upcoming: false,
    version: "2.92",
    date: "10/31/2019",
    completed: [
      "Highlight and zoom to project when app loads with #approve link",
      "Improved Alert / Confirm / Error dialogs",
      "Resolved issue related to saving edits to a PIN that has no geometry",
      "Resolved highlight/zoom issue for multi-selection within approval dialog",
      "Changed the display of reference post To/From values using an offset calculation",
      "Changed final approval email to show updated reference post To/From values",
      "Added Bridge FHWA / Maintenance Number to final approval email",
      "Added Project Number Filter to map table, as well as Estimate Information Advanced Filter",
      "Added Desired Month as a required field",
      "Updated county sorting and letting dates on final approval email",
      "Restricted project dates to be future dates only",
      "Admin site: Email group adding now possible through the app",
      "Admin site: Added Azure AD authentication"
    ]
  },
  {
    upcoming: false,
    version: "2.91",
    date: "10/17/2019",
    completed: [
      "Disable previous submissions within the 'Prospective Bridge Projects' dialog",
      "Increased Bridge Projects Batch Submission Size to 25",
      "Copied MP workflow over to ADA funding types",
      `Enabled 'Convert Scope to PIN' button for MP/ADA funding types`,
      `Update audit table during 'Convert Scope to PIN' process`,
      "Added safety email group for safety purpose projects"
    ]
  },
  {
    upcoming: false,
    version: "2.90",
    date: "10/10/2019",
    completed: [
      "Added required ROW/LEB fields to the 'Prospective Bridge Projects' dialog",
      "Limited BR/MB funding types within 'Prospective Bridge Projects' dialog",
      "Deleting Scope/PIN deletes any approval audits",
      "Scope Approval Dialog, only allow submission of scopes that have not been approved"
    ]
  },
  {
    upcoming: false,
    version: "2.89",
    date: "10/3/2019",
    completed: [
      "Resolved issue with scope approval dialog needed to be opened/closed after submission",
      "Changed subject line for final Scope Approved Email",
      "List Projects that require LEB-NEPA during batch submission through 'Prospective Bridge Projects' dialog",
      "Disabled Program Funding and Project Purpose select within 'Prospective Bridge Projects' dialog"
    ]
  },
  {
    upcoming: false,
    version: "2.88",
    date: "9/26/2019",
    completed: [
      "Run on-demand LEB-NEPA impacts for projects submitted in batch through 'Prospective Bridge Projects' dialog",
      "Filtered out MP projects with a letting date more than 12 months in the past",
      "Updated MP project submission email CSV file",
      "Disabled Program Funding field when scope is approved",
      "Hid convert scope to pin button for MP projects"
    ]
  },
  {
    upcoming: false,
    version: "2.87",
    date: "9/19/2019",
    completed: [
      "Added approval policy templates for MB, MP, RAIL, and ADA",
      "Updates to notification email verbiage",
      "Include attachments for final approval email",
      "Hiding fields for MP funding type in the details pane"
    ]
  },
  {
    upcoming: false,
    version: "2.86",
    date: "8/29/2019",
    completed: [
      "Resolve issue with cancelation of submission/approval from modal where groups are designatied",
      "Require at least one Program Funding selection for a scope on save",
      "Better display long descriptions in details pane header",
      "Increase pin geometry width on Impact and Priority tabs for gap analysis",
      "Exclude \"R00\" event in final approval email when ROW acres is 0"
    ]
  },
  {
    upcoming: false,
    version: "2.85",
    date: "8/22/2019",
    completed: [
      "Remove requests and loading indicator for deprecated routes endpoint",
      "App loading optimizations",
      "Resolve issue where counties were not saved dynamically for scopes",
      "Resolve issues with marking tutorial as seen",
      "Disable popups for PINs while on geometry tab",
      "Split and replace routes segments automatically with dominant routes segments",
      "Updates to account for new Program Funding types",
      "Prevent unnecessary refresh when scope approval process is canceled",
      "Always use OFFICIAL_COMMON_NAME instead of WINTER_ROUTE_NAME for route aliases",
      "Resolve issue with symbology being tied to tab after details pane is closed"
    ]
  },
  {
    upcoming: false,
    version: "2.84",
    date: "8/15/2019",
    completed: [
      "Upgrade to Angular 8.2",
      "Upgrade to ArcGIS JavaScript API 4.12",
      "Styling fixes for progress messages during Generate Charter and Download Data",
      "Implement fix for missing fields in query for CSV export",
      "Styling fixes for tutorial",
      "Resolve issue with map-click-mode in tutorial geometry section",
      "Use highlight color on geometry list to show which routes were just added",
      "Resolve Scope-to-PIN issue with deprecated Route table",
      "Indicate \"Next Approver\" for scopes"
    ]
  },
  {
    upcoming: false,
    version: "2.83",
    date: "8/6/2019",
    completed: [
      "Store if user has seen tutorial within database rather than local storage.",
      "Write highway class automatically for scopes",
      "Allow advanced filters in scope approval modal",
      "Move filter-bar to second row in approval modal",
      "Remove deprecated Routes field",
      "Added expandable fields to project detail tab",
      "Setup native ArcGIS javascript screenshot function and removed print service",
      "Apply opacity to impact layers"
    ]
  },
  {
    upcoming: false,
    version: "2.82",
    date: "8/1/2019",
    completed: [
      "Added check for localhost, approval emails sent to developers.",
      "Added user who submitted scope to email approval workflows",
      "Added role for 'PSS Submittal', users with role included get the final email",
      "Approval stage workflow changes: 4R, MI, and NR",
      "Updated workflow diagram",
      "Switched app role 'Bureau' to 'Division'",
      "Excluded non-approval roles from Submit/Approve dialog",
      "Allowed resending of final-stage scope approval emails",
      "Allow specifying particular users for final email resend",
      "Allow copy/paste on disabled form fields"
    ]
  },
  {
    upcoming: false,
    version: "2.81",
    date: "7/25/2019",
    completed: [
      "Fixed pre-submit rule for missing DESCRIPTION",
      "Enabled ability for a user with LEB NEPA Review Role to edit any scopes ROW / LEB fields",
      "Fix issue with getting ROUTE_ID when getting counties for geometry",
      "Save county names for scopes",
      "Save Begin Milepost and End Milepost for scopes",
      "Better handle missing mileposts",
      "Better handle unfound route aliases",
      "Added dynamic geometry service url for generating charter map image",
      "Scope Approval Dialog - map visible and projects highlighted on select",
      "Scope Approval link - navigates to table with filter applied",
      "Changes to tutorial and filter buttons specific to the commission site"
    ]
  },
  {
    upcoming: false,
    version: "2.80",
    date: "7/18/2019",
    completed: [
      "Allow non-admin to create new scope",
      "Scope to PIN workflow enabled for gdbVersion=PROJ with reconcile to QAQC",
      "Enabled on-demand impacts / priorities for all projects, not just Scope"
    ]
  },
  {
    upcoming: false,
    version: "2.79",
    date: "7/11/2019",
    completed: [
      "Fix issue with PROJECT_SCOPE_ID field",
      "Enabled live email notices for scope approval workflow",
      "Write filters from approval dialog back to main table if changes",
      "Combined SCOPE and PIN geometry layer"
    ]
  },
  {
    upcoming: false,
    version: "2.78",
    date: "6/27/2019",
    completed: [
      "Fix issue with getting counties (use Angular service instead of Lambda service)",
      "Fix issue where Hold for Fiscal Year was not saved as domain",
      "Use common name for primary route alias and winter route for other route aliases",
      "Save readable primary routes automatically for scopes"
    ]
  },
  {
    upcoming: false,
    version: "2.77",
    date: "6/13/2019",
    completed: [
      "Added Scope Approval Process Diagram",
      "Updates to email notice workflow",
      "Fixed link in stage approval email"
    ]
  },
  {
    upcoming: false,
    version: "2.76-alpha",
    date: "5/30/2019",
    completed: [
      "Prevent saving scopes unless user entered scope or is Admin",
      "Remove depricated \"Route\" in favor of \"Primary Route\"",
      "Fixed merge for approval workflows with multiple funding types",
      "On Demand Project Packaging - enabled download file access outside of DOT network",
      "Add per-PIN and per-Scope file attachments",
      "Removed duplicate routes listed in select primary route popup",
      "Have approval table, when opened, use a copy of the current filters from the main table",
      "Fixed ability to drag table off-screen",
      "Enabled download service for scopes",
      "Added check icon for fields required for approval",
      "Tutorial - Completed Tab 4, set panel titles",
      "Enabled initial email notice on project creation",
      "Enabled email notice workflows for test group"
    ]
  },
  {
    upcoming: false,
    version: "2.75",
    date: "5/16/2019",
    completed: [
      "On Demand Project Packaging - Enabled for FME Production Server",
      "Priority Tab - Changed layer names to Crashes, Traffic Incidents, and INRIX (Delay)",
      "Scoping Approval Email - Enabled sending to groups for scopes that need approval",
      "Scoping email - removed LRS measure, reorder items",
      "Fix issue where scopes duplicate and old attributes are reloaded",
      "For 4R, MI, and NR, remove LEB and Program Management approval requirement",
      "Allow saving PINs if user has Admin role",
      "Fixes for table-only layers"
    ]
  },
  {
    upcoming: false,
    version: "2.74",
    date: "5/2/2019",
    completed: [
      "Scoping email - reorder items",
      "Scoping email - include reference posts",
      "Scoping email - median budget estimate",
      "Scoping email - verified project link opens scope in app",
      "Disable sort clear on tables",
      "Preliminary on-demand project packaging",
      "Better error handling for FME",
      "Tutorial - refactored for extensibility/multiple step groups",
      "Tutorial - refactored to use svg mask component",
      "Tutorial - additonal training/stages added",
      "Removed extraneous fields from PIN filters",
      "Resolved table overflow styling issue"
    ]
  },
  {
    upcoming: false,
    version: "2.73",
    date: "4/18/2019",
    completed: [
      "Upgrade to ArcGIS JavaScript API 4.11",
      "Optimizations to FeatureLayer request handling",
      "Fix for refresh of Impact and Priority results after On-Demand FME",
      "Use Chart.js directly rather than with wrapper for Angular upgrade compatibility",
      "Upgrade to Angular 8 beta",
      "Resolve renderer issue where showing/hiding geometry tab contents caused app to freeze",
      "Resolve renderer loading issue with checkbox and details-button columns in approval modal",
      "Resolve issue with table total filtered count not loading",
      "Allow selecting email groups from user menu",
      "Automatically query counties for PIN geometry",
      "Treat new geometry as a savable/discardable change",
      "Resolve geometry tab styling issue for zoomed-in screens",
      "Error handling for empty results from LRS server",
      "Resolve issue where null dates were diplayed as \"Dec 31 1969\"",
      "Geometry Color Change to Grey on Impacts and Priority tabs",
      "Charter formatting updates",
      "Remove edit controls for geometries that are not editable",
      "Continue showing transparent pins (aside from the one selected) while in details-pane mode",
      "On map click menu, prevent goTo zoom for features outside of the map view",
      "Prevent changing selected PIN via map click while details pane is open"
    ]
  },
  {
    upcoming: false,
    version: "2.72",
    date: "4/4/2019",
    completed: [
      "Ability to create charter report for PINs",
      "Change Highway Class filter type to MultiSelect"
    ]
  },
  {
    upcoming: false,
    version: "2.71",
    date: "3/21/2019",
    completed: [
      "Resolved issue with loading scopes for approval",
      "Application loading performance improvements",
      "Include email test"
    ]
  },
  {
    upcoming: false,
    version: "2.70",
    date: "3/7/2019",
    completed: [
      "Show project description in header",
      "Set details pane to project tab upon close",
      "Remove pins with no geometry in UAT",
      "Include District and Program Funding as default fields for scope approval",
      "Include \"Has Geometry\" as filterable field",
      "Handle users with uppercased email addresses",
      "Handle users with lowercased email addresses",
      "Prevent issue where CSV exports are interpreted by Excel as SYLK",
      "Formatting for valuation fields",
      "Prevent/cancel unnecessary http requests for count data",
      "Require \"Scope Projects\" role for scoping new projects",
      "Application loading performance improvements"
    ]
  },
  {
    upcoming: false,
    version: "2.69",
    date: "2/21/2019",
    completed: [
      "Use native SQL filter engine rather than custom filter engine for client-side layers",
      "List user roles in user menu",
      "Expand stage filter to include separate filters for Scoping, InApproval, and Approved",
      "Combine standard filters and advanced filters into single menu",
      "Opacity control for layers on map",
      "Updates to training panels",
      "Fixes to ensure priority and impact results are refreshed after running on-demand FME",
      "Universal csv export for attribute tables",
      "Filters for approval workflow table that are distinct from main table",
      "Include valuation fields in main table",
      "Redesigned UI for LRS geometry editor",
      "Load layer endpoints by title rather than by number"
    ]
  },
  {
    upcoming: false,
    version: "2.68",
    date: "2/7/2019",
    completed: [
      "Include AppID for SkyFire reference post queries",
      "Use roles from new role manager"
    ]
  },
  {
    upcoming: false,
    version: "2.67",
    date: "1/31/2019",
    completed: [
      "Migration of all private endpoints to query ArcGIS Portal"
    ]
  },
  {
    upcoming: false,
    version: "2.66",
    date: "1/10/2019",
    completed: [
      "Include valuation data for select PINS",
      "Format valuation for currency and decimals"
    ]
  },
  {
    upcoming: true,
    version: "2.65",
    date: "12/27/2018",
    completed: [
      "Save HOLD_FISCAL_YEAR as 1 or 0 rather than boolean",
      "Added dot indicator for tutorial"
    ]
  },
  {
    upcoming: false,
    version: "2.64",
    date: "12/13/2018",
    completed: [
      "Remove access to bridge modal on commission view",
      "Include designated scopes in commission view",
      "Remove select filters for commission",
      "Show additional score fields in main table for commission",
      "Remove DEV_EST_SUM from main table for commission",
      "Rename \"Importance\" to \"Road Class\" in several locations",
      "Use latest calculation infographics",
      "Change filter type to min-max for select fields",
      "Include designated Red File pins in commission view",
      "Resolve tab index mismatch for commission",
      "Show district priority for all funding types",
      "Sort multiselect options by alias for PIN filters",
      "PIN approval email performance tuning and error handling"
    ]
  },
  {
    upcoming: false,
    version: "2.63",
    date: "11/29/2018",
    completed: [
      "Use seperate weighted score results for dev",
      "Initial implementation of tutorial",
      "Upgrade Angular Material version",
      "Connect to SIIMS Bridge endpoint",
      "Multiple tabs for Bridge view",
      "Use SkyFire to get nearest reference posts",
      "Sort coded values (fixes issues with Desired Month options order)",
      "Add subtext with time warning for on-demand priority and impacts"
    ]
  },
  {
    upcoming: false,
    version: "2.62",
    date: "11/15/2018",
    completed: [
      "Include ability to specify pin or scope in hash",
      "Include project link in scope approval email",
      "Remove Geometry and ROW/LEB tabs for commission view",
      "Remove PINs with no geometry from commission view",
      "Remove changelog from commission view",
      "Remove filters related to ROW/LEB from commission view",
      "Remove approve button in UAT",
      "Added District Priority for SR funding type",
      "Add sample BR dialog",
      "Use new RAMS endpoint for determining counties",
      "Formatting for scope approval email"
    ]
  },
  {
    upcoming: false,
    version: "2.61",
    date: "11/1/2018",
    completed: [
      "Use tables rather than flex layout for email template",
      "Load only New, Active, and Complete PINs commission view",
      "Remove estimate information for commission view",
      "Remove Road Analyzer and PathWeb for commission view",
      "Remove priority modals for commission view",
      "Remove schedule events modal for commission view",
      "Remove reference layers for commission view",
      "Remove \"deep\" filteres for commission view",
      "Persist username for new scopes",
      "Restrict for commission group in Graph API Service",
      "Handle counties with null values",
      "Scope confirmation email formatting",
      "Update score writeup documents",
      "Include entry user in batch-select modal",
      "Remove batch select modal button from commission view",
      "Include \"Project Development Completed by\" in confirmation email"
    ]
  },
  {
    upcoming: false,
    version: "2.60",
    date: "10/18/2018",
    completed: [
      "Use colored \"from\" and \"to\" edit icons for editing geometry.",
      "Designate \"PIN\" types in filter tooltips",
      "Use new safety score endpoint",
      "Correct field mismatch for Total Fatal Cost",
      "Include suggested schedule on scope approval email",
      "Include impact counts in scope approval email",
      "Include priority scores in scope approval email",
      "Trigger on-demand prioritization from Priority tab",
      "Trigger on-demand impacts from Impacts tab",
      "Include commission client id for azure login"
    ]
  },
  {
    upcoming: false,
    version: "2.59",
    date: "10/4/2018",
    completed: [
      "Include test Portal Bridge layer",
      "Upgrade to ArcGIS JS API 4.8",
      "Restore search widget for v4.8",
      "Add caption to impacts tab",
      "Use correct domain value for defaulting \"Expected Level of Documentation\" to \"Programmatic Categorical Exclusion\"",
      "Update title for planning budget header",
      "Fix csv date formatting issue",
      "Include Congestion Cost endpoint url configuration",
      "Include table for Cost of Crashes math",
      "Show passenger congestion cost breakdown in modal with formula",
      "Show commercial congestion cost breakdown in modal with formula",
      "Add valuation draft table graphic to Cost & Budget tab",
      "Add Fiscal Month and checkbox for holding fiscal year",
      "Include field for \"Project Development Completed by Other\" on project tab",
      "Make missing domains not prevent approval confirmation email",
      "Include budget and date fields in approval email",
      "Update row approval stage condition (for rowneed >= 5 Acres) to account for new domain options"
    ]
  },
  {
    upcoming: false,
    version: "2.58",
    date: "9/20/2018",
    completed: [
      "Use public RAMS for initial load and rce only for edits",
      "Remove Acquisition Anticipated (since it is the same as ROW > 0)",
      "Add funding descriptions for funding type",
      "Dialog to select which security groups to approve on behalf of",
      "Use pre-submit checks and prompt with dialog when scopes are missing required fields",
      "Include email test in scope approval dialog",
      "Display Dev and Prog Est Sum instead of Budget Range Min/Max for PINs",
      "Include \"Hold Fiscal Year\" on project tab",
      "Only Admin, LEB Approver, and LEB Nepa Review can edit Expected Level of Documentation & Concurrence Point Process",
      "Expected Level of Documentation defaults to \"Programmatic Categorical Exclusion\"",
      "Concurrence Point Process defaults to “No”",
      "Remove RAMS Query WebGL test layer",
      "Uncheck all details pane impact and priority layers when details pane closes",
      "Resolve issues where score pills were cleared when loading detail for PIN a second time"
    ]
  },
  {
    upcoming: false,
    version: "2.57",
    date: "9/6/2018",
    completed: [
      "Use alises from combined results service on Cost & Budget items",
      "Only show complexity and schedule adherence for pins",
      "Use #save= prefix to distinguish save hashes from Azure tokens in hash",
      "Only require last 4 approval groups for 4R when Budget Max >= 10 million"
    ]
  },
  {
    upcoming: false,
    version: "2.56",
    date: "8/23/2018",
    completed: [
      "Alert when pending approval stage cannot be determined",
      "Better feedback in cases where funding type has not been assigned",
      "Query impact results to determine when certain approval stages are required",
      "Updates to ApprovalPolicy class format for workflow configuration",
      "AzureAD login integration",
      "Use username from Azure in audits for approval workflow",
      "Use AD groups from Azure/GraphAPI for approval workflow",
      "Restore save hashes after loading #id_token",
      "Prevent frame load after #access_token when acquiring user groups"
    ]
  },
  {
    version: "2.55",
    date: "8/9/2018",
    completed: [
      "Include PINs with status of \"Red File\"",
      "Fixes for approval workflow policy validation and merging",
      "Simplify approval workflow steps diagram",
      "Remove PIN column from audit table",
      "Require district-specific approvals",
      "Updates to approval workflow policies",
      "Add Projet Prupose descriptions",
      "Use PIN_ID rather than GLOBALID for removing related attributes",
      "Resolve copy-by-reference issue for district approval groups",
      "Include \"Environmental Classification\" (LEVELOFDOCUMENTATION) on ROW / LEB tab",
      "Include budget range fields and rearange dropdowns on Priority tab",
      "Update Audit fields",
      "Prevent scope delete and geometry edit for scopes where STATUS is not \"Scoping\"",
      "Move \"Outside Services Expected\" and \"Estimate Information\" to Project Tab",
      "Use more readable opacity on readonly fields",
      "Rename Planning Budget title to \"Annual Cost of Congestion and Crashes\"",
      "Include SAVER on Priority tab"
    ]
  },
  {
    version: "2.54",
    date: "7/26/2018",
    completed: [
      "Slide-over view to see details for scopes in batch select modal",
      "Include audit table in approval details for scope",
      "Include stage diagram in approval details for scope",
      "Update checks for distinguishing scopes from pins to use new statuses",
      "Add workflow policies for each project funding type",
      "Merge function to create combined policies for scopes with multiple funding types",
      "Add service for determining remaining approval stages given audits for a scope",
      "Implement submission from batch-submit modal which changes scope status and adds audit entry",
      "Include \"Approved\" in statuses queried on initial load",
      "Add reset function to put scopes that are \"InApproval\" back to \"Scoping\""
    ]
  },
  {
    version: "2.53",
    date: "7/19/2018",
    completed: [
      "Ensure EST_SUM filters use min/max type",
      "Switch on-demand fme to use real job rather than test job"
    ]
  },
  {
    version: "2.52",
    date: "7/6/2018",
    completed: [
      "Add endpoint for audit table",
      "Allow basic submit and approve for approval workflow",
      "Use \"Scoping\" rather than \"Scoped\" as initial scope status",
      "Adjust for new feature layer numbers according to testgis publish"
    ]
  },
  {
    version: "2.51",
    date: "6/29/2018",
    completed: [
      "Use polling to show current status of on-demand fme job",
      "Save visibility of map layers via hash",
      "Show year count for target year options",
      "Set more district fields to use multiselect filters",
      "Include email test for batch submit"
    ]
  },
  {
    version: "2.50",
    date: "6/14/2018",
    completed: [
      "Move the ROW/LEB tab to be just before impact",
      "On boundary layers for DOT Disticts and maintenance districts, use multi select instead of a range for filter values",
      "Switch all http requests to use esri request in order to prevent redirect for authentication",
      "Preliminary FME On-Demand test"
    ]
  },
  {
    version: "2.49",
    date: "5/31/2018",
    completed: [
      "Join priority results using new Scope ID"
    ]
  },
  {
    version: "2.48",
    date: "5/24/2018",
    completed: [
      "Upgrade ArcGIS JS API to version 4.7",
      "Upgrade Angular to version 6",
      "Use new Scope ID format",
      "Join multi-select values for scopes by PIN_ID rather than GLOBALID",
      "Change hash tooltip description",
      "Save active columns via hash for main table and modal tables",
      "Use UniqueIDs to prevent duplicate impact counts",
      "More informative impact configuration error messages"
    ]
  },
  {
    version: "2.47",
    date: "5/18/2018",
    completed: [
      "Reorganize reference and boundary layers",
      "Increase default minScale to 10000000",
      "List specs for current hash in popup",
      "Show \"from\" and \"to\" on primary route",
      "Wrap whereclauses in parentheses",
      "Add buttons to clear filters on map layers",
      "Include \"Activate All\" button for non-pin-indexed impacts",
      "Return to first page when active filters change",
      "Disable \"Save\" on scopes until there are changes to be saved",
      "Show map popups over details pane",
      "Fix issue where priority pills did not refresh",
      "Show spinner in modal tables while loading features"
    ]
  },
  {
    version: "2.46",
    date: "5/10/2018",
    completed: [
      "Load readable names for RAMS routes",
      "List most recent geometries first when creating a scope",
      "Add sample batch select modal for scopes",
      "Explicitly set RAMS effective dates for scope geometry",
      "Avoid leaving orphaned geometries when deleting scope",
      "Set default minScales at 2000000",
      "Allow ID and ORIGKEY fields on modals",
      "Add toggle to decrease PIN opacity on map"
    ]
  },
  {
    version: "2.45",
    date: "5/4/2018",
    completed: [
      "Use top 10 impacts initially, allow enabling of others"
    ]
  },
  {
    version: "2.44",
    date: "4/27/2018",
    completed: [
      "Handling of missing config fields.",
      "Use DEFAULT_COLUMNS configuration for first fields on global reference and priority layers",
      "Designate Primary Route"
    ]
  },
  {
    version: "2.43",
    date: "4/18/2018",
    completed: [
      "Display \"No Geometry\" in rows for PINs with no geometry",
      "Persist filters for map layers when saving hash"
    ]
  },
  {
    version: "2.42",
    date: "4/3/2018",
    completed: [
      "Implemented map-click-popup to for selecting from features near click",
      "Use environment-specific endpoints",
      "Use pin-indexed inpacts for review",
      "Use impacts with combined geometry for test",
      "Reincorporate score pills into new attribute tables",
      "Use lazy-loading strategy for route and measure geometry"
    ]
  },
  {
    version: "2.41",
    date: "3/19/2018",
    completed: [
      "Differed loading for counts, attributes, and Popup Templates for priority and reference layers",
      "Filters for global reference an priority layers"
    ]
  },
  {
    version: "2.40",
    date: "3/1/2018",
    completed: [
      "Add release changelog",
      "Remove programmed percentiles for non-programmed pins (and vice versa)",
      "Show total number of programmed/not-programmed in rank (rank/out-of)",
      "Reset table to first page when filters change",
      "Indicate where impact result endpoints are missing required fields",
      "Fix issue with unresponsive priority filter selector",
      "Color inrix geometery by congestion hours (green > yellow > red where green is 0 hours and red is 10,000 or more)",
      "Add \"Zoom to visible scale\" button",
      "Add \"Zoom to extent\" button"
    ]
  },
  {
    version: "2.39",
    date: "2/22/2018",
    completed: [
      "Display separate programmed and non programmed complexity percentiles",
      "Saving map location and which columns are visible in hash",
      "Floodplain field removed per floodplain impact",
      "Removing “Outside Services Expected” for existing pins",
      "Display “Estimate Information” in a filterable in-modal table",
      "Added planning budget section",
      "Include Societal Crash and Annual Bottleneck in cost"
    ]
  },
  {
    version: "2.38",
    date: "2/15/2018",
    completed: [
      "Remove floodplain field in lieu of floodplain impact",
      "Move \"levies affected\" to ole tab",
      "Move complexity percentile to complexity table",
      "Add preliminary infrastructure for loading project-level info",
      "Include non-score mobility fields for filter and display",
      "Handle null values for domains",
      "Remove deprecated form fields",
      "Use non-concatenating strategy for search filters",
      "Re-implement sorting by alias for table fields",
      "Use aliases as dynamic field labels",
      "Display complexity percentile information",
      "Use lookup tables for County and Route"
    ]
  },
  {
    version: "2.37",
    date: "2/1/2018",
    completed: [
      "Add filterable tables for Mobility inputs (ATMS, INRIX, County Road Density, Access Location)",
      "Use config to exclude irrelevant fields from filters and tables",
      "Use datepickers for date range filters",
      "Use nested search menu for extended filters",
      "Apply queries to reference layers",
      "Implement multiselects with domains for extended filter fields"
    ]
  }
];

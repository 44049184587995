import { Injectable } from '@angular/core';

import { EndpointService } from '../dynamic-endpoint/endpoint.service';
import { MultiScannerResults, PROJ_SCOPE_WHERE } from './multi-scanner';
import { ENDPOINT_CONFIG } from '../dynamic-endpoint/endpoint.service.config';
import { RAMS_EFFECTIVE_DATE_CLAUSE } from 'src/app/services/rams-effective-date-clause';
import { RAMS_VERSIONS } from 'src/app/services/rams-versions';
import { ScanResults } from './scan';
import { esriRequest } from 'src/esri/request';
import { environment } from 'src/environments/environment';
import { UserInfoService } from '../user-info.service';

@Injectable({
  providedIn: 'root'
})
export class SinglePinGetter {
  constructor(
    private endpointService: EndpointService,
    private userInfoService: UserInfoService
  ) {
    this.isUserPlanScenario = this.userInfoService.isPlanningScenario;
  }

  isUserPlanScenario = false;

  async getScanResultsForSinglePin(PIN: string): Promise<MultiScannerResults> {
    // reconcile (no post) RAMS to make sure we have latest changes
    // await this.ramsRequestsService.reconcileVersion();
    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
    const ramsUrls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.RAMS_LRS_MAPSERVER);

    const queries: any = {
      projectScoping: this.query(
        urls.PROJECT_SCOPING,
        PROJ_SCOPE_WHERE + ` AND (PIN = '${PIN}')`
      ),
      // Story 1806 Migrate Geometry into RAMS - written to handle RAMS calls
      scopeGeometry: this.query(
        ramsUrls.PROJECT_SCOPING,
        RAMS_EFFECTIVE_DATE_CLAUSE + ` AND (PROJECT_SCOPE_ID = '${PIN}' AND PSS_PIN_ID IS NULL)`,
        'ROUTE_ID,FROM_MEASURE,TO_MEASURE,PROJECT_SCOPE_ID',
        RAMS_VERSIONS.VIEW // version is optional, otherwise the service displays data from RAMS.PROJ
      ),
      // Story 1806 Migrate Geometry into RAMS - written to handle RAMS calls
      pinGeometry: this.query(
        ramsUrls.PROJECT_SCOPING,
        RAMS_EFFECTIVE_DATE_CLAUSE + ` AND (PSS_PIN_ID = '${PIN}')`,
        'ROUTE_ID,FROM_MEASURE,TO_MEASURE,PSS_PIN_ID',
        RAMS_VERSIONS.VIEW // version is optional, otherwise the service displays data from RAMS.PROJ
      ),
      priorityResults: this.query(
        environment.isDev || environment.isLocalHost ? urls.PROJECT_PRIORITY_RESULTS_DEVELOPMENT : urls.PROJECT_PRIORITY_RESULTS,
        `PIN = '${PIN}'`
      ),
      projectTypes: this.query(
        urls.PROJECT_TYPES,
        `(PROJECTTYPE IS NOT NULL) AND (PIN = '${PIN}')`,
        'PROJECTTYPE,PURPOSEDESCRIPTION,PIN'
      ),
      programTypes: this.query(
        urls.PROGRAM_TYPES,
        `(PROGRAMTYPE IS NOT NULL) AND (PIN = '${PIN}')`,
        'PROGRAMTYPE,PIN,FUNDING_DESCRIPTION'
      ),
      workTypes: this.query(
        urls.PROJECT_WORK_TYPES,
        `PIN = '${PIN}'`,
        'WORKCODE,PIN'
      ),
      workGroups: this.query(
        urls.WORK_GROUP,
        `PIN = '${PIN}'`,
        'WORKGROUP,PIN'
      ),
      counties: this.query(
        urls.PROJECT_COUNTY,
        `PIN = '${PIN}'`,
        'COUNTY,PIN'
      ),
      iowaStateSenateDistricts: this.query(
        urls.IOWA_STATE_SENATE_DISTRICTS,
        `PIN = '${PIN}'`,
        'STATE_SENATE_DISTRICT,STATE_SENATE_REP_NAME,PIN'
      ),
      iowaStateHouseDistricts: this.query(
        urls.IOWA_STATE_HOUSE_DISTRICTS,
        `PIN = '${PIN}'`,
        'STATE_HOUSE_DISTRICT,STATE_HOUSE_REP_NAME,PIN'
      ),
      iowaCongressionalDistricts: this.query(
        urls.IOWA_CONGRESSIONAL_DISTRICTS,
        `PIN = '${PIN}'`,
        'US_HOUSE_DISTRICT,US_HOUSE_REP_NAME,PIN'
      ),
      valuation: this.query(
        urls.VALUATION,
        `PIN = '${PIN}'`
      ),
      projectNumber: this.query(
        urls.PROJECT_NUMBER,
        `PIN = '${PIN}'`
      )
    };
    if (environment.isCommission) {
      queries.commissionConfig = this.query(urls.SCOPING_COMMISSION_CONFIGURATION,
        `PIN = '${PIN}'`,
        'PIN,COMMISSION_VIEW');
    }

    const promisesToAwait = [
      queries.projectScoping,
      queries.scopeGeometry,
      queries.pinGeometry,
      queries.priorityResults,
      queries.projectTypes,
      queries.programTypes,
      queries.workTypes,
      queries.workGroups,
      queries.counties,
      queries.iowaStateSenateDistricts,
      queries.iowaStateHouseDistricts,
      queries.iowaCongressionalDistricts,
      queries.valuation,
      queries.projectNumber
    ];
    if (environment.isCommission) {
      promisesToAwait.push(queries.commissionConfig);
    }
    const res = await Promise.all(promisesToAwait);

    const scanResults: MultiScannerResults = {
      projectScoping: res[0],
      scopeGeometry: res[1],
      pinGeometry: res[2],
      priorityResults: res[3],
      projectTypes: res[4],
      programTypes: res[5],
      workTypes: res[6],
      workGroups: res[7],
      counties: res[8],
      iowaStateSenateDistricts: res[9],
      iowaStateHouseDistricts: res[10],
      iowaCongressionalDistricts: res[11],
      valuation: res[12],
      projectNumber: res[13]
    };
    if (environment.isCommission) {
      scanResults.commissionConfig = res[14];
    }
    return scanResults;
  }


  private async query(url, _where?, _outFields?, _gdbVersion?) {
    const where = _where || '1=1';
    const outFields = _outFields || '*';
    const gdbVersion = _gdbVersion || '';
    const res = await esriRequest(url + '/query', {
      query: {
        where,
        outFields,
        outSR: 3857,
        gdbVersion: gdbVersion,
        f: 'json'
      }
    });
    return <ScanResults>{
      features: res.data.features,
      fields: null
    };
  }

}

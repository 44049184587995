import { Injectable } from '@angular/core';
import { esriRequest } from 'src/esri/request';
import { EndpointService } from './dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';
import { RAMS_EFFECTIVE_DATE_CLAUSE } from 'src/app/services/rams-effective-date-clause';
import { DialogNoticeService } from './dialog-notice.service';

@Injectable({
  providedIn: 'root'
})
export class HighwayClassService {
  constructor(
    private endpointService: EndpointService,
    private dialogNoticeService: DialogNoticeService
  ) {
  }

  async getHighwayClass(routeId: string) {
    try {
      const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.RAMS_ALL_ROUTES);
      const query = {
        where: `ROUTE_ID = '${routeId}' and ${RAMS_EFFECTIVE_DATE_CLAUSE}`,
        outFields: 'OFFICIAL_ROUTE_PREFIX',
        returnDistinctValues: true,
        returnGeometry: false,
        f: 'json'
      };
      const res: any = await esriRequest(`${urls.ALL_ROUTES}/query`, { query });
      const prefix = res.data.features && res.data.features.length
        ? res.data.features[0].attributes.OFFICIAL_ROUTE_PREFIX
        : null;

      const lookup = {
        I: 'Interstate',
        IA: 'Iowa',
        US: 'US'
      };
      if (Object.keys(lookup).indexOf(prefix) > -1) {
        return lookup[prefix];
      } else {
        return null;
      }
    } catch (err) {
      await this.dialogNoticeService.error({
        title: 'HIGHWAY CLASS ERROR',
        message: `Failed to lookup RouteID: ${routeId}.  Service may be down...`
      });
    }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

import { Audit } from 'src/app/scope-approval/audit';
import { ApprovalAuditService } from 'src/app/scope-approval/approval-audit-service';
import { ApprovalPolicyFactory } from 'src/app/scope-approval/approval-policy-factory';
import { ApprovalReportService } from 'src/app/scope-approval/approval-report-service';
import { ApprovalReport } from 'src/app/scope-approval/approval-workflow-policy';
import { DialogNoticeService } from 'src/app/services/dialog-notice.service';
import { HNTB_ADMIN_STAFF } from 'src/app/services/user-info.service';
import { environment } from 'src/environments/environment';
import { SubmitApproveEmailService } from 'src/app/scope-approval/submit-approve-email.service';

@Component({
  selector: 'app-approval-email-dialog',
  templateUrl: './approval-email-dialog.component.html',
  styleUrls: ['./approval-email-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApprovalEmailDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ApprovalEmailDialogComponent>,
    private approvalPolicyFactory: ApprovalPolicyFactory,
    private auditService: ApprovalAuditService,
    private approvalReportService: ApprovalReportService,
    private cdr: ChangeDetectorRef,
    private dialogNoticeService: DialogNoticeService,
    private submitApproveEmailService: SubmitApproveEmailService
  ) {
  }

  verifyingAudits = true;
  auditsByPin: { [pin: string]: Audit[] } = {};
  approvalReportByPin: { [pin: string]: ApprovalReport } = {};
  nonApprovedPins = [];
  sending = false;
  receiverOptions: string[];
  control = new FormControl([]);

  async ngOnInit() {
    await Promise.all(
      this.data.attsForEach.map(async atts => {
        const audits = await this.auditService.getAudits(atts.PIN);
        if (!audits) {
          this.dialogNoticeService.alert({ message: 'No audits could be found for PIN: ' + atts.PIN });
        }
        this.auditsByPin[atts.PIN] = audits;

        const policy = this.approvalPolicyFactory.generatePolicy(atts.PIN, atts.DISTRICT, atts.PROGRAM_TYPES);
        if (!policy) {
          this.dialogNoticeService.alert({ message: 'No approval policy could be found for PIN: ' + atts.PIN });
        }
        const report = await this.approvalReportService.getReport(atts, audits, policy);
        if (!report) {
          this.dialogNoticeService.alert({ message: 'Approval report could not be generated for PIN: ' + atts.PIN });
        }
        this.approvalReportByPin[atts.PIN] = report;

        this.receiverOptions = await this.submitApproveEmailService.generateFinalEmailTo(audits);
        // add HNTB staff
        if (environment.isLocalHost) {
          this.receiverOptions = [...this.receiverOptions, ...HNTB_ADMIN_STAFF];
        }
        this.control.setValue(this.receiverOptions);
      })
    );
    this.nonApprovedPins = Object.keys(this.approvalReportByPin).filter(pin => {
      const report = this.approvalReportByPin[pin];
      return report.stages.filter(s => !s.passed).length > 0;
    });
    this.verifyingAudits = false;
    this.cdr.markForCheck();
  }

  async send() {
    this.sending = true;
    this.cdr.markForCheck();

    const attsForEach = this.data.attsForEach;
    await this.submitApproveEmailService.sendEmailNotices(attsForEach, this.control.value);
    this.dialogRef.close();
  }
}

/*
@NgModule({
  declarations: [ApprovalEmailDialogComponent],
  imports: [SharedModule],
  exports:[ApprovalEmailDialogComponent]
})
export class ApprovalEmailDialogModule {}
*/

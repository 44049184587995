import { BehaviorSubject, Subscription } from 'rxjs';
import { MonitoredBehaviorSubject } from './monitored-behavior-subject';
import { debounceTime, filter, skip, take } from 'rxjs/operators';
import { RxLayerOptions } from './rx-layer-options';
import { FormSubject } from 'src/app/types/form-subject';
import { SelectionModel } from '@angular/cdk/collections';
import { RxLayer } from './rx-layer';

export interface PageState {
  index: number;
  size: number;
  orderBy: string[]; // empty array or [field + direction] (e.g ['OBJECTID DESC'])
}

export class RxLayerTableData {
  // used for tables with checkboxes
  selection = new SelectionModel<number>(true, []);

  // hooks for use with table along with tableRows observable below
  pageState = new BehaviorSubject<PageState>({ index: 0, size: 25, orderBy: [] });

  // columns for table
  private _displayColumns: FormSubject<string[]>;
  get displayColumns() {
    if (!this._displayColumns) {
      this._displayColumns = this.setupDisplayColumns();
    }
    return this._displayColumns;
  }

  // features matching wholeWhereClause for the current page
  tableRows = new MonitoredBehaviorSubject<any[]>(null);
  tableRowsLoading = new BehaviorSubject<boolean>(false);

  // count of features matching baseWhereClause
  baseCount = new MonitoredBehaviorSubject<number>(null);
  baseCountLoading = new BehaviorSubject<boolean>(false);

  // count of features matching wholeWhereClause
  filteredCount = new MonitoredBehaviorSubject<number>(null);
  filteredCountLoading = new BehaviorSubject<boolean>(false);

  _entireFeatureCollection = []; // used by attribute table to determine page of selected pin

  constructor(
    private rxLayer: RxLayer,
    private rxLayerOptions: RxLayerOptions
  ) {
  }

  // used for canceling http requests
  private tableRowsAbortController = new AbortController();
  private baseCountAbortController = new AbortController();
  private filteredCountAbortController = new AbortController();

  private needsUpdate = new BehaviorSubject<('table-rows' | 'base-count' | 'filtered-count')[]>([]);
  private layerDestroyed = false;

  // keep table rows and counts updated, but only when their observables are being subscribed to
  private subs: Subscription[] = [
    this.rxLayer.destroyed.pipe(
      filter(destroyed => destroyed !== null && destroyed !== undefined)
    ).subscribe(destroyed =>
      this.layerDestroyed = destroyed
    ),
    this.pageState.pipe(skip(1)).subscribe(() => {
      // skip initial state
      if (this.tableRows.hasSubscribers.value) {
        this.requestUpdate('table-rows');
      }
    }),
    this.rxLayer.wholeWhereClause.pipe(skip(1)).subscribe(() => {
      // skip initial state
      const { size, orderBy } = this.pageState.value;
      this.pageState.next({ index: 0, size, orderBy }); // reset to first page
      // page reset will cause 'table-rows' update if necessary
      if (this.baseCount.hasSubscribers.value) {
        this.requestUpdate('base-count');
      }
      if (this.filteredCount.hasSubscribers.value) {
        this.requestUpdate('filtered-count');
      }
    }),
    this.tableRows.hasSubscribers.subscribe(hasSubs => {
      hasSubs ? this.requestUpdate('table-rows') : this.tableRows.next(null);
    }),
    this.baseCount.hasSubscribers.subscribe(hasSubs => {
      hasSubs ? this.requestUpdate('base-count') : this.baseCount.next(null);
    }),
    this.filteredCount.hasSubscribers.subscribe(hasSubs => {
      hasSubs ? this.requestUpdate('filtered-count') : this.filteredCount.next(null);
    }),
    this.needsUpdate
      .pipe(
        filter(nu => !!nu.length),
        debounceTime(1)
      )
      .subscribe(nu => {
        // debounceTime is important because it prevents duplicating the same request multiple times at once
        if (nu.indexOf('table-rows') > -1) {
          this.updateTableRows();
        }
        if (nu.indexOf('base-count') > -1) {
          this.updateBaseCount();
        }
        if (nu.indexOf('filtered-count') > -1) {
          this.updateFilteredCount();
        }
        this.needsUpdate.next([]);
      })
  ];

  private requestUpdate(item: 'table-rows' | 'base-count' | 'filtered-count') {
    const current = this.needsUpdate.value;
    if (current.indexOf(item) === -1 && !this.layerDestroyed) {
      this.needsUpdate.next([...current, item]);
    }
  }

  refresh() {
    if (this.tableRows.hasSubscribers.value) {
      this.requestUpdate('table-rows');
    }
    if (this.baseCount.hasSubscribers.value) {
      this.requestUpdate('base-count');
    }
    if (this.filteredCount.hasSubscribers.value) {
      this.requestUpdate('filtered-count');
    }
  }

  dispose() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private async updateTableRows() {
    const { index, size, orderBy } = this.pageState.value;
    const where = this.rxLayer.wholeWhereClause.value;
    const params = {
      where: where.length ? where : '1=1',
      returnGeometry: false,
      outFields: '*',
      resultOffset: index * size,
      resultRecordCount: size,
      orderByFields: orderBy,
      f: 'json'
    };

    if (this.tableRowsAbortController) {
      this.tableRowsAbortController.abort();
    }
    this.tableRowsAbortController = new AbortController();
    const abortSignal = this.tableRowsAbortController.signal;

    this.tableRowsLoading.next(true);

    try {
      // check if layer is destroyed
      if (this.rxLayer.featureLayer && this.rxLayer.featureLayer.destroyed) {
        console.warn('feature layer is destroyed');
        return;
      }
      let features = await this.rxLayer.queryFeatures(params, abortSignal);

      if (this.rxLayerOptions.clientSideGraphicOptions) {
        this._entireFeatureCollection = features;
        features = features.slice(index * size, (index + 1) * size);
      }
      const rows = features.map(x => x.attributes);

      this.tableRows.next(rows);
    } catch (err) {
      if (err.name !== 'AbortError') {
        console.error(err);
      }
    }

    this.tableRowsAbortController = null;
    this.tableRowsLoading.next(false);
  }

  private async updateBaseCount() {
    const where = this.rxLayer.baseWhereClause.value;

    const params = {
      where: where.length ? where : '1=1',
      returnCountOnly: true,
      f: 'json'
    };

    if (this.baseCountAbortController) {
      this.baseCountAbortController.abort();
    }
    this.baseCountAbortController = new AbortController();
    const abortSignal = this.baseCountAbortController.signal;

    this.baseCountLoading.next(true);
    try {
      const count = await this.rxLayer.queryCount(params, abortSignal);
      this.baseCount.next(count);
    } catch (err) {
      if (err.name !== 'AbortError') {
        console.log(err);
      }
    }

    this.baseCountAbortController = null;
    this.baseCountLoading.next(false);
  }

  private async updateFilteredCount() {
    const where = this.rxLayer.wholeWhereClause.value;

    const params = {
      where,
      returnCountOnly: true,
      f: 'json'
    };

    if (this.filteredCountAbortController) {
      this.filteredCountAbortController.abort();
    }
    this.filteredCountAbortController = new AbortController();
    const abortSignal = this.filteredCountAbortController.signal;

    this.filteredCountLoading.next(true);
    try {
      const count = await this.rxLayer.queryCount(params, abortSignal);
      this.filteredCount.next(count);
    } catch (err) {
      if (err.name !== 'AbortError') {
        console.log(err);
      }
    }

    this.filteredCountAbortController = null;
    this.tableRowsLoading.next(false);
  }

  private setupDisplayColumns() {
    const preDefinedCols = this.rxLayerOptions.displayColumns;

    const initVal = preDefinedCols && preDefinedCols.length ? preDefinedCols : [];

    const displayColsFs = new FormSubject<string[]>(initVal);

    let initSet: Promise<void>;
    if (!initVal.length) {
      // use first five fields when fields resolves
      initSet = this.rxLayer.visibleFieldsPromise.then(fields => {
        const firstFive = fields.slice(0, 5).map(f => f.name);
        displayColsFs.setValue(firstFive);
      });
    }

    // set select options
    this.rxLayer.visibleFieldsPromise.then(fields => {
      const opts = fields.map(x => ({ value: x.name, alias: x.alias }));
      displayColsFs.selectOptions.next(opts);
    });

    // mark nonDefaultCols if cols are manually changed
    (async () => {
      if (initSet) {
        await initSet;
      }
      displayColsFs.valueChanges.pipe(
        take(1)
      ).subscribe(() => {
        this.rxLayer.nonDefaultCols = true;
      });
    })();

    return displayColsFs;
  }
}

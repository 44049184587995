import { Injectable } from '@angular/core';
import { EndpointService } from './dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from './dynamic-endpoint/endpoint.service.config';
import { esriRequest } from 'src/esri/request';

@Injectable({
  providedIn: 'root'
})
export class ScopeIdService {

  constructor(
    private endpointService: EndpointService
  ) {
  }

  projectScopingFieldsPromise = (async () => {
    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
    const res = await esriRequest(urls.PROJECT_SCOPING, {
      query: {
        f: 'json'
      }
    });
    return res.data.fields;
  })();

  async generateScopeID(primaryRoute: string, isPlanningScenario = false) {
    const isInvalid = x => x === undefined || x === null;
    if (isInvalid(primaryRoute)) {
      throw new Error('missing primary route');
    }

    const routeString = primaryRoute.substr(6, 4);

    const now = new Date();
    let month = (now.getMonth() + 1) + '';
    if (month.length === 1) {
      month = '0' + month;
    }
    let day = now.getDate() + '';
    if (day.length === 1) {
      day = '0' + day;
    }
    const year = (now.getFullYear() + '').substr(2, 2);
    const dateString = month + day + year;

    const initSID = isPlanningScenario ? `X${routeString}-${dateString}-` : `S${routeString}-${dateString}-`;

    const urls = await this.endpointService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);
    const res = await esriRequest(urls.PROJECT_SCOPING + '/query', {
      query: {
        where: `PIN like '${initSID}%'`,
        outFields: 'PIN',
        returnGeometry: false,
        f: 'json'
      }
    });
    const features = res.data.features;

    let suffix = (features.length + 1) + '';
    if (suffix.length === 1) {
      suffix = '0' + suffix;
    }
    return initSID + suffix;
  }

  getNumericScopeID(sid: string): number {
    return +sid.split('').filter(x => !isNaN(<any>x)).join('');
  }
}

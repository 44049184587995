export const PRIORITY_KEYS = {
  bridges: 'bridges',
  pavement: 'pavement',
  traffic: 'traffic',
  safety: 'safety',
  freight: 'freight',
  roadClass: 'roadClass',
  saver: 'saver',
  atms: 'atms',
  inrix: 'inrix',
  countyRoadDensity: 'countyRoadDensity',
  accessLocations: 'accessLocations'
};

export const PRIORITY_PIN_KEYS = {
  bridges: 'PP_PIN',
  pavement: 'PP_PIN',
  traffic: 'PP_PIN',
  safety: 'PIN',
  freight: 'PIN',
  roadClass: 'PP_PIN',
  saver: 'PP_PIN',
  atms: 'PIN',
  inrix: 'PIN',
  countyRoadDensity: 'PIN',
  accessLocations: 'PIN'
};

export const PRIORITY_DEFAULT_COLS = {
  bridges: ['PP_BRIDGE_SCORE', 'PP_BRIDGE_FLAG', 'PP_DECK_AREA', 'PP_CURRENT_YEAR', 'PP_BRIDGE_AGE'],
  pavement: ['PAVEMENT_SCORE', 'PAVEMENTFLAG', 'SYSTEM', 'ROUTE', 'DIR'],
  traffic: ['PP_TRAFFIC_SCORE', 'PCE', 'PCELANE'],
  safety: ['SAFETY_SCORE', 'FATAL_CRASH_RATE', 'TOTAL_CRASH_RATE', 'CRASHES_PER_MILE', 'TOTAL_CRASHES', 'TOTAL_FATAL_CRASHES'],
  freight: ['FREIGHT_SCORE', 'MILES', 'FREIGHT_WITHIN_0_5MILES', 'FREIGHT_WITHIN_5_20MILES'],
  roadClass: ['FEDFUNC', 'ROUTEID', 'NUMBER_LANES', 'ROAD_STATUS'],
  saver: ['CRASH_KEY', 'CRASH_DATE', 'FATALITIES', 'INJURIES'],
  atms: ['DATE_TIME', 'DURATION', 'CLOSE_DATE', 'OPEN_DATE', 'FATALITY', 'INJURY'],
  inrix: ['XDSEGID', 'PREVIOUSXD', 'NEXTXDSEGI'],
  countyRoadDensity: ['ROAD_DENSITY_SCORE', 'POPULATION_SCORE', 'FEDFUNC_WEIGHT'],
  accessLocations: ['ACCESS_LOCATION_SCORE', 'COUNTY_NUMBER', 'YEAR', 'PERMIT_NUMBER', 'PROJECT_NUMBER']
};

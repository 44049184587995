import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AzureAD } from 'src/init/azure-ad';
import { TutorialActionService } from '../services/tutorial-action.service';

@Component({
  selector: 'app-user-indicator',
  templateUrl: './user-indicator.component.html',
  styleUrls: ['./user-indicator.component.scss']
})
export class UserIndicatorComponent implements OnInit, AfterViewInit {

  // to trigger menu for tutorial
  @ViewChild(MatMenuTrigger, { static: false }) userMenuTrigger: MatMenuTrigger;
  @ViewChild('userButton', { static: false }) userButton: ElementRef;

  userName = AzureAD.user.email;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private tutorialActionService: TutorialActionService,
    private renderer: Renderer2
  ) {
  }


  ngAfterViewInit(): void {
    // override Esri style to account for the button width
    const style = `.esri-attribution__powered-by {
      margin-right: ${this.userButton.nativeElement.offsetWidth + 6}px;
    }`;
    this.createGlobalStyle(style);
  }

  ngOnInit(): void {
    // override to open menu
    this.tutorialActionService.userMenuOverride$.subscribe((isOpen) => {
      if (isOpen) {
        this.userMenuTrigger.openMenu();
      } else {
        try {
          this.userMenuTrigger.closeMenu();
        } catch {
          return;
        }
      }
    });
  }

  createGlobalStyle(css: string) {
    const el = this.renderer.createElement('style');
    const cssText = this.renderer.createText(css);

    this.renderer.appendChild(el, cssText);
    this.renderer.appendChild(this.document.head, el);
  }

}

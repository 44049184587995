import { GenericType } from '../types/generic-type';

export interface EsriFeature {
  attributes: any;
  geometry: any;
}

export class EsriFieldNames {
  // static access
  static readonly objectId = 'OBJECTID';
  static readonly globalId = 'GLOBALID';

  constructor() {
    return;
  }
}

export class EsriFieldType extends GenericType {

  // access via class instance
  readonly smallInteger = 'esriFieldTypeSmallInteger';
  readonly integer = 'esriFieldTypeInteger';
  readonly single = 'esriFieldTypeSingle';
  readonly double = 'esriFieldTypeDouble';
  readonly long = 'esriFieldTypeLong';
  readonly string = 'esriFieldTypeString';
  readonly date = 'esriFieldTypeDate';
  readonly oid = 'esriFieldTypeOID';
  readonly geometry = 'esriFieldTypeGeometry';
  readonly blob = 'esriFieldTypeBlob';
  readonly raster = 'esriFieldTypeRaster';
  readonly guid = 'esriFieldTypeGUID';
  readonly globalId = 'esriFieldTypeGlobalID';
  readonly xml = 'esriFieldTypeXML';

  // static access
  static readonly smallInteger = 'esriFieldTypeSmallInteger';
  static readonly integer = 'esriFieldTypeInteger';
  static readonly single = 'esriFieldTypeSingle';
  static readonly double = 'esriFieldTypeDouble';
  static readonly long = 'esriFieldTypeLong';
  static readonly string = 'esriFieldTypeString';
  static readonly date = 'esriFieldTypeDate';
  static readonly oid = 'esriFieldTypeOID';
  static readonly geometry = 'esriFieldTypeGeometry';
  static readonly blob = 'esriFieldTypeBlob';
  static readonly raster = 'esriFieldTypeRaster';
  static readonly guid = 'esriFieldTypeGUID';
  static readonly globalId = 'esriFieldTypeGlobalID';
  static readonly xml = 'esriFieldTypeXML';

  // access via class instance
  readonly typeNames: { [key: string]: string } = {};

  constructor() {
    super();
    this.typeNames[EsriFieldType.smallInteger] = 'esriFieldTypeSmallInteger';
    this.typeNames[EsriFieldType.integer] = 'esriFieldTypeInteger';
    this.typeNames[EsriFieldType.single] = 'esriFieldTypeSingle';
    this.typeNames[EsriFieldType.double] = 'esriFieldTypeDouble';
    this.typeNames[EsriFieldType.long] = 'esriFieldTypeLong';
    this.typeNames[EsriFieldType.string] = 'esriFieldTypeString';
    this.typeNames[EsriFieldType.date] = 'esriFieldTypeDate';
    this.typeNames[EsriFieldType.oid] = 'esriFieldTypeOID';
    this.typeNames[EsriFieldType.geometry] = 'esriFieldTypeGeometry';
    this.typeNames[EsriFieldType.blob] = 'esriFieldTypeBlob';
    this.typeNames[EsriFieldType.raster] = 'esriFieldTypeRaster';
    this.typeNames[EsriFieldType.guid] = 'esriFieldTypeGUID';
    this.typeNames[EsriFieldType.globalId] = 'esriFieldTypeGlobalID';
    this.typeNames[EsriFieldType.xml] = 'esriFieldTypeXML';
  }

  getTypeName(type: string) {
    return this.getKeyValue(this.typeNames)(type);
  }

}


import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RxLayer } from 'src/app/rx-layer/rx-layer';
import { HEATMAP_RENDERER_BLUE } from 'src/app/rx-layer/rx-layer.symbology';
import { PriorityImpactLayerTitle, PriorityImpactService } from 'src/app/services/priority-impact-service';
import { ImpactStats } from 'src/app/services/layer/impact-rx-layers.service';
import { PinsRxLayerService } from 'src/app/services/layer/pins-rx-layer.service';

const heightConfig = {
  FullScreen: 'calc(100vh - 16px)',
  DockBottom: '450px'
};

const classConfig = {
  FullScreen: 'attr-tbl-dialog-extra-shadow',
  DockBottom: ''
};

export interface AttrTblDialogData {
  rxLayer: RxLayer;
  tableOnly: boolean;
  memo: string;
  titleOverride: string;
  impactStats: ImpactStats;
}

@Component({
  templateUrl: './attribute-table-dialog.component.html',
  styleUrls: ['./attribute-table-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributeTableDialogComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  rxLayer: RxLayer;
  tableOnly: boolean;
  memo: string;
  titleOverride: string;
  impactStats: ImpactStats;
  dock = 'DockBottom';
  heatMapBlue = HEATMAP_RENDERER_BLUE;
  heatButtonEnabled = false;
  visible = false;
  heatSupport = false;
  railCrossingButtonEnabled = false;
  lrtpNoticeButtonEnabled = false;
  selectedField: __esri.Field;

  private selectedPin: string;

  @HostBinding('style.height') height = heightConfig[this.dock];
  @HostBinding('class') class = classConfig[this.dock];

  constructor(
    public dialogRef: MatDialogRef<AttributeTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: AttrTblDialogData,
    private cdr: ChangeDetectorRef,
    private priorityImpactService: PriorityImpactService,
    private pinsRxLayerService: PinsRxLayerService
  ) {
    this.rxLayer = this.data.rxLayer;
    this.tableOnly = this.data.tableOnly;
    this.memo = this.data.memo;
    this.titleOverride = this.data.titleOverride;
    this.impactStats = this.data.impactStats;
  }

  async ngOnInit() {
    this.subscribe();
    this.railCrossingButtonEnabled = false;
    if (this.titleOverride?.toLowerCase().indexOf(PriorityImpactLayerTitle.RailCrossingProjects.toLowerCase()) > -1) {
      this.railCrossingButtonEnabled = true;
    }

    this.lrtpNoticeButtonEnabled = false;
    if (this.titleOverride?.toLowerCase().indexOf(PriorityImpactLayerTitle.LRTPMobilitySafety.toLowerCase()) > -1) {
      const reports = await this.priorityImpactService.getLRTPReports([this.selectedPin]);
      if (reports.length > 0) {
        this.lrtpNoticeButtonEnabled = true;
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  async viewRailCrossingProjectInfo() {
    await this.priorityImpactService.impactNoticeRailCrossingProject([this.selectedPin]);
  }

  async viewLrtpNotice() {
    await this.priorityImpactService.impactNoticeLRTPProject([this.selectedPin]);
  }

  fieldSelected(field: __esri.Field) {
    this.selectedField = field;
    this.heatMapBlue['field'] = field.name;
    // if heatmap is on, apply the new renderer that uses the field
    if (this.rxLayer.heatModeEnabled.value) {
      this.rxLayer.applyHeatMode(this.heatMapBlue);
    }
  }

  // subscribe to rxLayer events
  private subscribe() {
    // visible
    this.subs.push(
      this.pinsRxLayerService.selection.pipe(
        filter(selection => selection !== null)
      ).subscribe(selection => this.selectedPin = selection.pin),
      this.rxLayer.visible.valueStream.subscribe(value => {
        this.visible = value;
        if (this.visible && this.heatSupport) {
          this.heatButtonEnabled = true;
        } else {
          this.heatButtonEnabled = false;
        }
      })
    );
    // heat mode support
    this.subs.push(
      this.rxLayer.heatModeSupported.subscribe(value => {
        this.heatSupport = value;
        if (this.visible && this.heatSupport) {
          this.heatButtonEnabled = true;
        } else {
          this.heatButtonEnabled = false;
        }
      })
    );
  }

  fullscreen() {
    this.dock = 'FullScreen';
    this.height = heightConfig[this.dock];
    this.class = classConfig[this.dock];
    this.cdr.markForCheck();
  }

  dockBottom() {
    this.dock = 'DockBottom';
    this.height = heightConfig[this.dock];
    this.class = classConfig[this.dock];
    this.cdr.markForCheck();
  }

  toggleHeatmap(renderer: any) {
    if (this.rxLayer.visible.value && this.rxLayer.heatModeSupported) {
      if (this.rxLayer.heatModeEnabled.value) {
        this.rxLayer.restoreDefaultRenderer();
      } else {
        this.rxLayer.applyHeatMode(renderer);
      }
    }
  }
}

/*
@NgModule({
  declarations: [AttributeTableDialogComponent],
  imports: [],
})
export class AttributeTableDialogModule {}
*/

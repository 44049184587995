import { HostListener, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { LegacyHashService } from './legacy-hash.service';
import { AllModalSettings, HashApiDeleteReponse, HashApiReponse, SaveHashContents } from './save-hash.models';
import { MapService } from '../map/map.service';
import { DialogNoticeService } from 'src/app/services/dialog-notice.service';
import { ENDPOINT_CONFIG } from 'src/app/services/dynamic-endpoint/endpoint.service.config';
import { LoggingService } from '../logging.service';
import { StorageKeys } from 'src/app/services/hash/storage-keys';
import { HashKeys } from 'src/app/services/hash/hash-keys';
import { UserInfoService } from '../user-info.service';

const HASH_API_URL = ENDPOINT_CONFIG.AWS.HASH.url;
const HASH_API_KEY = ENDPOINT_CONFIG.AWS.HASH.apiKey;

export const MODAL_SETTING_SEPARATOR = '@';

@Injectable({
  providedIn: 'root'
})
export class HashingService {

  @HostListener('window:hashchange', ['$event'])
  onChange() {
    this.onHashChange();
  }

  private _currentHashContent = new BehaviorSubject<SaveHashContents>(null);
  currentHashContent$ = this._currentHashContent.asObservable();

  private _clearHash = new BehaviorSubject<SaveHashContents>(null);
  clearHash$ = this._clearHash.asObservable();

  private _myHashes = new BehaviorSubject<SaveHashContents[]>([]);
  myHashes$ = this._myHashes.asObservable();

  private _approvalHash = new BehaviorSubject<string>(null);
  approvalHash$ = this._approvalHash.asObservable();

  private _currentHash = new BehaviorSubject<string>(null);
  currentHash$ = this._currentHash.asObservable();

  private _processing = new BehaviorSubject<boolean>(false);
  processing$ = this._processing.asObservable();

  constructor(
    private http: HttpClient,
    private userInfoService: UserInfoService,
    private legacyHashService: LegacyHashService,
    private dialogNoticeService: DialogNoticeService,
    private logging: LoggingService,
    private mapService: MapService,
  ) {
  }

  private headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'X-Api-Key': HASH_API_KEY
    }
  );

  init() {
    // when hash changes, trigger call to get content
    this._currentHash.pipe(
      filter(hash => hash !== null)
    ).subscribe(hash => {
      this.getHashContent(hash).subscribe(content =>
        this._currentHashContent.next(content)
      );
    });

    // load hash from sessiong storage - gets set from main.ts /  handleTokens()
    // url save hash
    const initSaveHash = this.getSaveHashFromSession();
    this._currentHash.next(initSaveHash);

    // approval hash
    const initApprovalHash = this.getApprovalHash();
    const scope = initApprovalHash ? initApprovalHash.replace('#approve=', '') : null;
    this._approvalHash.next(scope);

    this.setUrl(initSaveHash || initApprovalHash);

    this.getHashContent(initSaveHash).subscribe(content => {
      // this.logging.log('🚀 ~ file: hashing.service.ts ~ line 83 ~ HashingService ~ this.getHashContent ~ content', content);
      this._currentHashContent.next(content);
    });

    this.getMyHashes().subscribe(hashes => {
      // this.logging.log('🚀 ~ file: hashing.service.ts ~ line 87 ~ HashingService ~ this.getMyHashes ~ hashes', hashes);
      this._myHashes.next(hashes);
    });

    combineLatest([
      this.mapService.mapAssets$,
      this._currentHashContent
    ]).pipe(
      filter(([assets, currentHash]) => assets !== null && currentHash !== null)
    ).subscribe(([assets, currentHash]) => {
      if (currentHash && currentHash.mapPosition) {
        const pos = currentHash.mapPosition;
        assets.view.center = <any>[pos.lng, pos.lat];
        assets.view.zoom = pos.zoom;
      }
    });
  }

  deleteHashFromDynamo(PKGuid: string, clear = true): Observable<HashApiDeleteReponse> {
    this._processing.next(true);
    const options = {
      headers: this.headers,
    };
    const url = `${HASH_API_URL}/DeleteHashFilter/${PKGuid}`;
    const { hash, key } = this.getHashFromUrl();
    return this.http.delete(url, options).pipe(
      tap((result: HashApiDeleteReponse) => {
        this._processing.next(false);
        if (result && result.data) {
          const guid = hash.replace(`${key}=`, '');
          if (result.data === guid && clear) {
            this.clearHash(true);
          }
        }
      }),
      map(res => res as HashApiDeleteReponse),
      catchError(this.handleError)
    );
  }

  setSaveHash(hash: string) {
    this._processing.next(true);
    window.location.hash = `${HashKeys.save}=${hash}`;
    this.getHashContent(hash).subscribe(content => {
      this._currentHashContent.next(content);
      this._currentHash.next(hash);
      this._processing.next(false);
    });
  }

  clearHash(zoomMapFull = false) {
    this._clearHash.next(this._currentHashContent.value);
    this._clearHash.next(null);
    this._currentHash.next(null);
    this._currentHashContent.next(null);
    if (zoomMapFull) {
      this.mapService.mapAssets$.pipe(
        filter(assets => assets !== null || assets !== undefined)
      ).subscribe(assets => {
        assets?.zoomToFullExtent();
      });
    }
    this.setUrl('');
  }

  getModalSettings(content?: SaveHashContents) {
    const settings = content?.modalSettings;
    const completeSettings: AllModalSettings[] = [];
    if (settings) {
      Object.keys(settings).forEach(key => {
        const parts = key.split(MODAL_SETTING_SEPARATOR);
        completeSettings.push({
          cols: settings[key].cols ? settings[key].cols : null,
          filters: settings[key].filters ? settings[key].filters : null,
          title: parts[0],
          url: parts[1],
          visible: settings[key].visible ? settings[key].visible : null
        });
      });
    }
    return completeSettings;
  }

  getModalSettingsLayerTitles(content: SaveHashContents) {
    return this.getModalSettings(content).map(g => g?.title);
  }

  saveHashToDynamo(contents: SaveHashContents): Observable<HashApiReponse> {
    this._processing.next(true);
    const options = {
      headers: this.headers,
    };
    const json = JSON.stringify(contents);
    const url = `${HASH_API_URL}/SaveHashFilter`;
    return this.http.post(url, json, options).pipe(
      tap((res: HashApiReponse) => {
        this._processing.next(false);
        const guid: string = <string>res.data.PKGuid;
        window.location.hash = `${HashKeys.save}=${guid}`;
        this._currentHashContent.next(res.data);
      }),
      catchError(this.handleError)
    );
  }

  getMyHashes(): Observable<SaveHashContents[]> {
    this._processing.next(true);
    const userName = this.userInfoService.userInfo.value.username;
    const url = `${HASH_API_URL}/GetHashFilterByUser/${userName}`;
    const options = {
      headers: this.headers
    };
    return this.http.get<any>(url, options).pipe(
      map(res => {
        this._processing.next(false);
        if (res.data && res.data.length > 0) {
          const results = res.data as SaveHashContents[];
          results.forEach((hash: SaveHashContents) => {
            if (hash.hashVersion !== '1.2') {
              hash = this.legacyHashService.translateOlderVersion(hash);
            }
            if (!Object.prototype.hasOwnProperty.call(hash, 'modalSettings')) {
              hash.modalSettings = {};
            }
          });
          // sort by date
          const sorted = results.sort((a, b) => {
            const A = a.CreatedOn;
            const B = b.CreatedOn;
            if (A > B) {
              return 1;
            } else if (A < B) {
              return -1;
            }
          });
          this._myHashes.next(sorted);
          return sorted;
        } else {
          this._myHashes.next([]);
          return [];
        }
      }),
      catchError(this.handleError)
    );
  }

  private onHashChange() {
    const { hash, key } = this.getHashFromUrl();
    if (key === HashKeys.save) {
      // this triggers a call to get new hash content
      this._currentHash.next(hash.split('=')[1]);
    }
  }

  private getHashFromUrl() {
    const hash = window.location.hash;
    const key = hash.split('=')[0];
    return { hash, key };
  }

  private getSaveHashFromSession() {
    const saveHash = sessionStorage.getItem(StorageKeys.scopingSaveHash);
    sessionStorage.removeItem(StorageKeys.scopingSaveHash);
    return saveHash;
  }

  private getApprovalHash() {
    const approvalHash = sessionStorage.getItem(StorageKeys.approvalHash);
    sessionStorage.removeItem(StorageKeys.approvalHash);
    if (!approvalHash) {
      return null;
    }
    return approvalHash;
  }

  private setUrl(hash: string) {
    if (hash) {
      window.location.hash = hash;
    } else {
      window.location.hash = '';
      history.replaceState('', document.title, window.location.pathname); // remove '#'
    }
  }

  private getHashContent(hash: string) {
    const options = {
      headers: this.headers
    };
    if (!hash) {
      return of(null);
    }
    const token = hash.replace(`${HashKeys.save}=`, '');
    const url = `${HASH_API_URL}/GetHashFilterById/${token}`;
    return this.http.get(url, options).pipe(
      tap(async (res: any) => {
        if (res.data && res.data.length !== 1) {
          (await this.dialogNoticeService.confirmYesNo({
            title: 'HASH LOAD ERROR',
            message: 'The hash failed to load, it may no longer exist.  Would you like to clear it?'
          })).afterClosed().subscribe(clear => {
            if (clear) {
              this.clearHash();
            }
          });
        }
      }),
      map((res: any) => {
        if (res.data && res.data.length === 1) {
          const hash = res.data[0];
          const contents: SaveHashContents = hash.hashVersion === '1.2'
            ? hash : this.legacyHashService.translateOlderVersion(hash);

          if (!Object.prototype.hasOwnProperty.call(contents, 'modalSettings')) {
            contents.modalSettings = {};
          }
          return <SaveHashContents>contents;
        }
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    this._processing.next(false);
    const errorMessage = error;
    console.error(errorMessage);
    // return an observable with a user-facing error message
    return throwError(errorMessage);
  }

}

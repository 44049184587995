import { Injectable } from '@angular/core';

import esriConfig from '@arcgis/core/config';
import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
import Portal from '@arcgis/core/portal/Portal';
import esriId from '@arcgis/core/identity/IdentityManager';
import { BehaviorSubject, from, Observable, of } from 'rxjs';

export interface ClientSettings {
  arcgisAppId: string;
  arcgisPortalUrl: string;
}

// RAMS portal
const SETTINGS: ClientSettings = {
  arcgisAppId: 'JUGzllFLuHgPz87v',
  arcgisPortalUrl: 'https://gis.iowadot.gov/ramsportal'
};

// MAIN portal
const SETTINGS_PORTAL: ClientSettings = {
  arcgisAppId: 'Tw3qdpxnpqzs1UYC',
  arcgisPortalUrl: 'https://gis.iowadot.gov/portal'
};

@Injectable({
  providedIn: 'root'
})
export class ArcgisSecurityService {

  private oAuthInfo: OAuthInfo;
  private portal: Portal;
  private sharingUrl: string;

  private _credential$ = new BehaviorSubject<__esri.Credential>(null);
  credential$ = this._credential$.asObservable();

  constructor() {

    // add trusted servers
    esriConfig.request.trustedServers.push('gis.iowadot.gov');
    esriConfig.request.trustedServers.push('https://gis.iowadot.gov');
    esriConfig.request.trustedServers.push('https://programs.iowadnr.gov');

    this.sharingUrl = `${SETTINGS.arcgisPortalUrl}/sharing`;
    this.oAuthInfo = new OAuthInfo(<any>{
      appId: SETTINGS.arcgisAppId,
      portalUrl: SETTINGS.arcgisPortalUrl,
      popup: false
    });
    esriId.registerOAuthInfos([this.oAuthInfo]);
  }

  login(): Observable<__esri.Credential> {
    return from(this._login());
  }

  logout(): Observable<boolean> {
    esriId.destroyCredentials();
    return of(true);
  }

  private async _login(): Promise<__esri.Credential> {
    let cred;
    // trying to fix this makes a mess of a simple function
    // https://stackoverflow.com/a/61952149
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      console.log(this.sharingUrl);
      await esriId.checkSignInStatus(this.sharingUrl).then(async () => {
        esriId.getCredential(this.sharingUrl).then(cred => {
          this.handleEsriIdSignedIn().then(() => {
            this._credential$.next(cred);
            resolve(cred);
          });
        });
      }).catch(async () => {
        await this.handleEsriIdSignedIn();
        cred = await esriId.getCredential(this.sharingUrl) as any;
        this._credential$.next(cred);
        resolve(cred);
      });
    });
  }

  private handleEsriIdSignedIn(): Promise<__esri.PortalUser> {
    return new Promise(resolve => {
      this.portal = new Portal({
        url: SETTINGS.arcgisPortalUrl
      });
      this.portal.load().then(() => {
        resolve(this.portal.user as __esri.PortalUser);
      });
    });
  }
}

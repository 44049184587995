import { Injectable } from '@angular/core';

import { PriorityImpactLayerTitle } from 'src/app/services/priority-impact-service';
import { IMPACT_DETAIL_HISTORIC_FLOOD } from '../priority-impact-detail-dialog/models/impact-detail-historic-flood';
import {
  IMPACT_DETAIL_LRTP_MOBILITY_SAFETY
} from '../priority-impact-detail-dialog/models/impact-detail-lrtp-mobility-safety';
import { IMPACT_DETAIL_PREVIOUS_FLOOD } from '../priority-impact-detail-dialog/models/impact-detail-previous-flood';
import {
  IMPACT_DETAIL_RAIL_CROSSING_PROJECT
} from '../priority-impact-detail-dialog/models/impact-detail-rail-crossings';
import { PriorityImpactDetailData } from '../priority-impact-detail-dialog/models/priority-impact-detail-data';

export interface PriorityImpactDialogData {
  data: PriorityImpactDetailData;
}

@Injectable({
  providedIn: 'root'
})
export class PriorityImpactDialogFactoryService {
  getDialogData(layerTitle: PriorityImpactLayerTitle) {
    const dialogData: any = {
      [PriorityImpactLayerTitle.LRTPMobilitySafety]: {
        data: IMPACT_DETAIL_LRTP_MOBILITY_SAFETY
      },
      [PriorityImpactLayerTitle.RailCrossingProjects]: {
        data: IMPACT_DETAIL_RAIL_CROSSING_PROJECT
      },
      [PriorityImpactLayerTitle.PreviousFloodingEvents]: {
        data: IMPACT_DETAIL_PREVIOUS_FLOOD
      },
      [PriorityImpactLayerTitle.HistoricFloodingEvents]: {
        data: IMPACT_DETAIL_HISTORIC_FLOOD
      }
    };
    return dialogData[layerTitle] as PriorityImpactDialogData;
  }

}

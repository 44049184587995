import { Injectable } from '@angular/core';
import { AnyAll, ApprovalReport, ApprovalStageReport, GroupReport } from './approval-workflow-policy';
import { Audit } from './audit';
import { EndpointService } from '../services/dynamic-endpoint/endpoint.service';
import { ENDPOINT_CONFIG } from '../services/dynamic-endpoint/endpoint.service.config';
import { FormDataService } from '../services/form-data.service';
import { esriRequest } from 'src/esri/request';
import { PinAttributes } from '../services/amalgamator/pin-attributes';

@Injectable({
  providedIn: 'root'
})
export class ApprovalAuditService {
  constructor(
    private endpointsService: EndpointService,
    private formDataService: FormDataService
  ) {
  }

  async submitOrApproveIndividualScope(attrs: PinAttributes, user: string, user_groups: string[], report: ApprovalReport, approvalBatch?: string) {

    const unpassedStages = report.stages.filter(s => s.required).filter(s => !this.willPassStage(s, user_groups));
    const willPassAllStages = unpassedStages.length === 0;

    // PIN and PIN_ID are only filled in after a Scope becomes a PIN.
    const audit: Audit = {
      PIN: null,
      PIN_ID: null,
      USER_NAME: user,
      USER_GROUPS: user_groups.join(','),
      PREVIOUS_STATUS: attrs.STATUS,
      CURRENT_STATUS: willPassAllStages ? 'Approved' : 'InApproval',
      TIMESTAMP: +(new Date()),
      SCOPE: attrs.PIN,
      SCOPE_ID: attrs.PIN_ID
    };

    const OBJECTID = await this.getPinObjectId(attrs.PIN);

    const anyAllToBooleanOperator = {
      ANY: ' or ',
      ALL: ' and '
    };

    const urls = await this.endpointsService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);

    const statusUpdate = esriRequest(urls.PROJECT_SCOPING + '/updateFeatures', {
      body: this.formDataService.toFormData({
        features: [{
          attributes: {
            OBJECTID,
            STATUS: audit.CURRENT_STATUS,
            NEXT_APPROVER: willPassAllStages ? '' : unpassedStages[0].groups.map(x => x.name).sort((a, b) => a > b ? 1 : -1).join(anyAllToBooleanOperator[unpassedStages[0].operator]),
            APPROVAL_BATCH: approvalBatch ? approvalBatch : null
          }
        }],
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });

    const auditAdd = esriRequest(urls.SCOPE_APPROVAL_AUDIT + '/addFeatures', {
      body: this.formDataService.toFormData({
        features: [{ attributes: audit }],
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });

    await Promise.all([statusUpdate, auditAdd]);
  }

  async reset(PIN: string, NEXT_APPROVER: string) {

    const OBJECTID = await this.getPinObjectId(PIN);

    const urls = await this.endpointsService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);

    const statusUpdate = esriRequest(urls.PROJECT_SCOPING + '/updateFeatures', {
      body: this.formDataService.toFormData({
        features: [{
          attributes: {
            OBJECTID,
            STATUS: 'Scoping',
            NEXT_APPROVER
          }
        }],
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });

    const removeAudits = esriRequest(urls.SCOPE_APPROVAL_AUDIT + '/deleteFeatures', {
      body: this.formDataService.toFormData({
        where: `PIN='${PIN}' or SCOPE='${PIN}'`,
        f: 'json'
      }),
      responseType: 'json',
      method: 'post'
    });

    await Promise.all([statusUpdate, removeAudits]);
  }

  async getAudits(pin: string): Promise<Audit[]> {
    const urls = await this.endpointsService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);

    const query = {
      where: `PIN='${pin}' or SCOPE='${pin}'`,
      outFields: '*',
      f: 'json'
    };
    const res = await esriRequest(urls.SCOPE_APPROVAL_AUDIT + '/query', { query });

    return res.data.features.map(f => f.attributes);
  }

  private willPassStage(stage: ApprovalStageReport, user_groups: string[]) {
    if (stage.passed) {
      return true;
    }

    // groups (for this stage) that either are approved or will be approved after this user approves
    const approvedGroups = stage.groups.filter((group: GroupReport) => {
      return group.approved || user_groups.indexOf(group.name) > -1;
    });
    switch (stage.operator) {
    case AnyAll.ANY:
      return approvedGroups.length > 0;
    case AnyAll.ALL:
      return approvedGroups.length === stage.groups.length;
    default:
      return false;
    }
  }

  private async getPinObjectId(PIN) {
    const urls = await this.endpointsService.getLayerUrls(ENDPOINT_CONFIG.PPRI_DATA);

    const res = await esriRequest(urls.PROJECT_SCOPING + '/query', {
      query: {
        where: `PIN = '${PIN}'`,
        returnGeometry: false,
        outFields: 'OBJECTID',
        f: 'json'
      },
      responseType: 'json',
      method: 'post'
    });

    if ((!res) || (!res.data) || (!res.data.features) || (!res.data.features.length)) {
      throw new Error(`Could not find OBJECTID for PIN: ${PIN}`);
    }
    if (res.data.features.length > 1) {
      throw new Error(`Found multiple OBJECTIDs for PIN: ${PIN}`);
    }

    return res.data.features[0].attributes.OBJECTID;
  }
}

import { Injectable } from '@angular/core';

import * as geometryEngineAsync from '@arcgis/core/geometry/geometryEngineAsync';

export enum DistanceUnits {
  miles = 'miles'
}

@Injectable({
  providedIn: 'root'
})
export class GeometryEngineService {

  engine: __esri.geometryEngineAsync;

  constructor() {
    this.init();
  }

  private init = async () => {
    this.engine = geometryEngineAsync;
  };

  async buffer(inputGeom: __esri.Geometry, distance: number, unit: DistanceUnits) {
    const res = await this.engine.geodesicBuffer(inputGeom, distance, unit);
    return res;
  }

  async intersect(geom1, geom2) {
    const res = await this.engine.intersect(geom1, geom2);
    console.log(res);
    return res;
  }

  async getGeodesicLength(line: __esri.Geometry, unit: DistanceUnits) {
    return await this.engine.geodesicLength(line, unit);
  }

  // returns the endpoints (first/last) points for polylines after they are clipped
  async getEndpointsByClip(polylines: [__esri.Polyline], extent: any) {
    // for storing clip results
    const clips = [];
    // for storing the endpoints
    const endpoints = [];
    polylines.forEach(async p => {
      clips.push(this.engine.clip(p, extent));
    });
    // clip the polylines
    const clipped = await Promise.all(clips);
    // get the endpoints
    let x, y;
    clipped.forEach(c => {
      const polyline = c as __esri.Polyline;
      // loop the segments
      // REMEMBER forEach() is not promise-aware
      polyline.paths.forEach(verts => {
        const lastIndex = (verts.length - 1);
        // first point
        x = verts[0][0];
        y = verts[0][1];
        // add First Point to array
        // push to array because forEach() is not promise-aware
        endpoints.push(this.createPoint(x, y, polyline.spatialReference));
        // last point
        x = verts[lastIndex][0];
        y = verts[lastIndex][1];
        // add Last Point to array
        // push to array because forEach() is not promise-aware
        endpoints.push(this.createPoint(x, y, polyline.spatialReference));
      });
    });
    return endpoints;
  }

  private createPoint(x, y, spatialReference) {
    const point = {
      spatialReference: spatialReference,
      x: x,
      y: y
    };
    return point;
  }
}

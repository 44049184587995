import { AnyAll, ApprovalPolicyTemplate } from '../approval-workflow-policy';
import { STANDARD_LEB_NEPA_CONDITIONS } from './standard-leb-nepa-conditions';
import { APP_ROLES } from 'src/app/services/user-info.service';

export const POLICY_TEMPLATE_MI: ApprovalPolicyTemplate = {
  fundingType: 'MI',
  stages: [
    {
      operator: AnyAll.ANY,
      groups: [
        APP_ROLES.SCOPE_PROJECTS,
        APP_ROLES.ADMIN
      ]
    },
    {
      operator: AnyAll.ALL,
      groups: [
        APP_ROLES.DISTRICT_X
      ]
    },
    {
      operator: AnyAll.NOTICE_ONLY, // THIS WILL only send an email notice during final approval
      groups: [
        APP_ROLES.LEB_NEPA_REVIEW
      ],
      onlyRequiredWhen: {
        operator: AnyAll.ANY,
        conditions: STANDARD_LEB_NEPA_CONDITIONS
      }
    },
    {
      operator: AnyAll.ALL,
      groups: [
        APP_ROLES.DIVISION
      ]
    },
  ]
};

<div class="flex-start-container h100p">
  <div class="main-title simple-flex-container" (click)="navigate('/')">
    <img class="iowadot-logo" src="https://projectpriorities-assets.s3.amazonaws.com/images/iowadot-logo-black.png"/>
    Project Priorities
  </div>
  <div class="simple-flex-container environment-indicator">{{ environmentName }}</div>
  <!-- Version / Service Info -->
  <app-version-indicator></app-version-indicator>
  <app-service-health></app-service-health>
  <app-user-indicator></app-user-indicator>
  <!-- Nav -->
  <div class="simple-flex-container nav-bar-links">
    <nav mat-tab-nav-bar>
      <a mat-tab-link *ngFor="let navLink of navLinks" style="height: 40px;"
         (click)="navigate(navLink.link)"
         [active]="activeLink === navLink.link">{{ navLink.label }}</a>
    </nav>
  </div>

</div>

export function generatePopupTemplate(fields: __esri.Field[], title) {
  const fieldInfos = fields.map(f => ({
    fieldName: f.name,
    visible: true,
    label: f.alias
  }));
  const content = [{
    type: 'fields',
    fieldInfos
  }];
  return { title, content };
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SubLayers } from '../map-buttons/map-buttons.component';
import { Filter } from '../rx-layer/filter';
import { RxLayer } from '../rx-layer/rx-layer';

export interface FilterOverride {
  filter: Filter;
  rxLayer: RxLayer;
}

@Injectable({
  providedIn: 'root'
})
export class TutorialActionService {


  private _approvalOverride = new BehaviorSubject<any>(false);
  approvalOverride$ = this._approvalOverride.asObservable();

  private _filterMenuOverride = new BehaviorSubject<boolean>(false);
  filterMenuOverride$ = this._filterMenuOverride.asObservable();

  private _filterValueOverride = new BehaviorSubject<FilterOverride>(null);
  filterValueOverride$ = this._filterValueOverride.asObservable();

  private _legendMenuOverride = new BehaviorSubject<boolean>(false);
  legendOverride$ = this._legendMenuOverride.asObservable();

  private _opacityMenuOverride = new BehaviorSubject<boolean>(false);
  opacityOverride$ = this._opacityMenuOverride.asObservable();

  private _refLayersMenuOverride = new BehaviorSubject<boolean>(false);
  referenceLayersMenuOverride$ = this._refLayersMenuOverride.asObservable();

  private _searchValueOverride = new BehaviorSubject<string>('');
  searchValueOverride$ = this._searchValueOverride.asObservable();

  private _sysLayersMenuOverride = new BehaviorSubject<boolean>(false);
  systemLayersMenuOverride$ = this._sysLayersMenuOverride.asObservable();

  private _userMenuOverride = new BehaviorSubject<boolean>(false);
  userMenuOverride$ = this._userMenuOverride.asObservable();

  private _subLayerMenuOverride: SubLayers = {
    Location: new BehaviorSubject<boolean>(false),
    Environmental: new BehaviorSubject<boolean>(false),
    Waterway: new BehaviorSubject<boolean>(false),
    Route: new BehaviorSubject<boolean>(false),
    Traffic: new BehaviorSubject<boolean>(false),
    Safety: new BehaviorSubject<boolean>(false),
    'Bridges & Pavement': new BehaviorSubject<boolean>(false),
  };

  constructor() {
    return;
  }

  // getters
  getSearchValueOverride() {
    return this._searchValueOverride.getValue();
  }

  getSubLayerMenuOverride() {
    return this._subLayerMenuOverride;
  }

  // setters
  setApprovalOverride(value: any) {
    this._approvalOverride.next(value);
  }

  setFilterMenuOverride(open: boolean) {
    this._filterMenuOverride.next(open);
  }

  setFilterValueOverride(filter: Filter, rxLayer: RxLayer) {
    this._filterValueOverride.next({
      filter: filter,
      rxLayer: rxLayer
    });
  }

  setLegendMenuOverride(open: boolean) {
    this._legendMenuOverride.next(open);
  }

  setOpacityMenuOverride(open: boolean) {
    this._opacityMenuOverride.next(open);
  }

  setReferenceLayersMenuOverride(open: boolean) {
    this._refLayersMenuOverride.next(open);
  }

  setSubLayerMenuOverride(layerToOpen: keyof SubLayers) {
    Object.keys(this._subLayerMenuOverride).map(subLayer => {
      if (layerToOpen !== subLayer) {
        this._subLayerMenuOverride[subLayer].next(false);
      }
    });
    this._subLayerMenuOverride[layerToOpen].next(true);
  }

  setSystemLayersMenuOverride(open: boolean) {
    this._sysLayersMenuOverride.next(open);
  }

  setSearchValueOverride(value: string) {
    this._searchValueOverride.next(value);
  }

  setUserMenuOverride(open: boolean) {
    this._userMenuOverride.next(open);
  }

}

import { Injectable } from '@angular/core';
import { AnyAll, ApprovalPolicyTemplate } from './approval-workflow-policy';

@Injectable({
  providedIn: 'root'
})
export class ApprovalPolicyMergeService {

  mergePolicyTemplates(policyTemplates: ApprovalPolicyTemplate[]): ApprovalPolicyTemplate {
    // console.log('Policies: ' + JSON.stringify(policies));

    const stages = [];
    const stagesJsonized = [];

    let maxStages = 0;
    const fundingTypesObj = {};

    policyTemplates.forEach(policy => {
      maxStages = Math.max(maxStages, policy.stages.length);
      fundingTypesObj[policy.fundingType] = true;
    });

    for (let i = 0; i < maxStages; i++) {
      policyTemplates.forEach(p => {
        if (!p.stages[i]) {
          return;
        }
        const stage = p.stages[i];
        const json = JSON.stringify(stage);
        if (stagesJsonized.indexOf(json) > -1) {
          return;
        }
        stages.push(stage);
        stagesJsonized.push(json);
      });
    }

    const distinctStages = this.removeRedundantStages(stages);
    //  console.log(`${stages.length} - Stages: ${JSON.stringify(stages)}`);
    //  console.log(`${distinctStages.length} - Distinct Stages:  ${JSON.stringify(distinctStages)}`);

    return {
      fundingType: Object.keys(fundingTypesObj).join(','),
      stages: distinctStages
    };
  }

  private removeRedundantStages(stages: any[]) {
    // Logic
    // Groups - Any
    //  -- Remove Duplicate Stages, Combine into a single "any item"
    // Groups - All
    //  -- Remove Duplicate Stages
    // Groups - All
    //  - If single stage, check other stages that might contain the item, if they do then remove the single stage
    // Groups - Any or All
    //  - Remove any duplicate single items
    // If we have two duplicates (not considering conditions), one with conditions and one without...drop the stage with conditions
    let results = [];
    const resultsAny = [];
    let currentStages = [];
    // make a copy of stages
    results = results.concat(stages);
    currentStages = currentStages.concat(stages);
    for (let s = 0; s < currentStages.length; s++) {
      const stage = currentStages[s];
      // handle ANY
      if (stage.operator === AnyAll.ANY) {
        // add first item, first time through
        if (resultsAny.length < 1) {
          resultsAny.push(stage);
        }
        const groupsAny = this.combineAnyStageGroups(resultsAny[0].groups, stage.groups);
        resultsAny[0].groups = groupsAny;
      }
      // 1. Check for duplicates
      // get array of matching indexes
      const groupMatchIndexes = this.getGroupMatchIndexes(stage, results);
      // single stage / check for any other stages that contain it..
      if (stage.groups.length === 1) {
        const singleMatchIndexes = this.doesGroupMatchOthers(stage.groups[0], results);
        // if we have a match on our group compared to other groups, remove the single item
        if (singleMatchIndexes.length > 1) {
          singleMatchIndexes.forEach(i => {
            // null except for our current item
            if (i !== s) {
              results[s] = null;
            }
          });
        }
      }
      // if more than one group index we have duplicate groups
      if (groupMatchIndexes.length > 1) {
        groupMatchIndexes.forEach(i => {
          // null except for our current item
          if (i !== s) {
            results[s] = null;
          }
        });
      }
    }
    const resulNoNull = results.filter(r => r !== null); // filter out the null
    const resultALL = resulNoNull.filter(r => r.operator !== AnyAll.ANY); // remove any
    const final = resultsAny.concat(resultALL); // add back in the combined any results
    return final;
  }

  private combineAnyStageGroups(resultGroups: string[], stageGroups: string[]): any[] {
    stageGroups.forEach(g => {
      if (resultGroups.indexOf(g) < 0) {
        resultGroups.push(g);
      }
    });
    return resultGroups.sort();
  }

  private getGroupMatchIndexes(stage: any, items: any[]) {
    const matchIndexes = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i] !== null) {
        const itemGroups = items[i].groups;
        try {
          const objectsMatch = this.doGroupsMatch(stage.groups, itemGroups);
          if (objectsMatch) {
            matchIndexes.push(i);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    return matchIndexes;
  }

  // compare a single group and find a matching stage that contains multiple groups
  private doesGroupMatchOthers(group: string, stages: any[]) {
    const matchIndexes = [];
    for (let i = 0; i < stages.length; i++) {
      const s = stages[i];
      if (stages[i] !== null) {
        const idx = s.groups.indexOf(group);
        if (idx > -1 && s.groups.length > 1) {
          matchIndexes.push(idx);
        }
      }
    }
    return matchIndexes;
  }

  // compare just groups
  private doGroupsMatch(groupsA: any[], groupsB: any[]): boolean {
    const aSort = groupsA.sort();
    const bSort = groupsB.sort();
    return this.doObjectsMatch(aSort, bSort);
  }

  // compare objects to see if they match
  private doObjectsMatch(a: any, b: any): boolean {
    const aJSON = JSON.stringify(a);
    const bJSON = JSON.stringify(b);
    return aJSON === bJSON;
  }
}
